import React from 'react';
import PropTypes from 'prop-types';

import {
    Tooltip,
  } from 'reactstrap';

import { FormattedMessage, injectIntl } from 'react-intl'; 

import s from './Allergens.module.scss';

class Allergens extends React.Component {
    static propTypes = {
        listOfAllergens: PropTypes.array.isRequired,
        locale: PropTypes.string.isRequired,
        islistview: PropTypes.bool,
        listOfFoodTypes: PropTypes.array,
        isMixedWithFoodTypes: PropTypes.bool,
        customAllergensIcon: PropTypes.array,
        customFoodtypesIcon: PropTypes.array
    };

    static defaultProps = {
      islistview: false,
      isMixedWithFoodTypes: false
    };

    constructor(props) {
      super(props);

      this._isMounted = false;
  
      this.state = {
        tooltipsAllergens: []
      }
  
      this.toggleTooltip = this.toggleTooltip.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
  
    toggleTooltip(id, field) {
      const newState = [...this.state[field]];
      newState.fill(false);
  
      if (!this.state[field][id]) {
        newState[id] = true;
      }
  
      this._isMounted && this.setState({
        [field]: newState,
      });
    }

    render() {
      const { locale } = this.props.intl
      const { customAllergensIcon, customFoodtypesIcon } = this.props
      //console.log('customAllergensIcon => ', customAllergensIcon)
      //console.log('customFoodtypesIcon => ', customFoodtypesIcon)

      // if more than 6 allergens then we need to send allergens to the next line when displying them
      let nbOfAllergensAndFoodTypes = this.props.listOfAllergens ? this.props.listOfAllergens.length : 0
      nbOfAllergensAndFoodTypes += this.props.listOfFoodTypes ? this.props.listOfFoodTypes.length : 0

      const styleObj = {}
      if (locale === 'ar') {
        styleObj.paddingTop = '20px'
      }
      if (nbOfAllergensAndFoodTypes > 6) {
        styleObj.width = '230px'
      }

        return(
            <div 
              className={this.props.islistview? s.allergensForListView : s.allergens}
              style={styleObj}>
              {
                !this.props.islistview ? (
                  <span className={s.title}>
                    <FormattedMessage id="Allergens.component.title" defaultMessage="Allergens" />
                </span>
                ) : null
              }
              {
                this.props.listOfAllergens ? (
                  this.props.listOfAllergens.map((allergen, index) => {
                    // console.log('current allergen => ', allergen)
                    // if current allergen matches a restaurant custom allergen icon then we override it
                    if (customAllergensIcon) {
                      const filteredCustomAllergensIcon = customAllergensIcon.filter((customAllergen) => customAllergen.allergen.id === allergen.id)
                      if (filteredCustomAllergensIcon.length > 0) {
                        allergen.icon.url = filteredCustomAllergensIcon[0].icon.url
                        if (allergen.icon.formats) {
                          allergen.icon.formats.thumbnail.url = filteredCustomAllergensIcon[0].icon.url
                          if (filteredCustomAllergensIcon[0].icon.formats && filteredCustomAllergensIcon[0].icon.formats.thumbnail) {
                            allergen.icon.formats.thumbnail.url = filteredCustomAllergensIcon[0].icon.formats.thumbnail.url
                          }
                        }
                      }
                    }
                    return (
                        <div key={'allergen-' + allergen.id + '-' + index}>
                          <div 
                            id={"allergen-" + allergen.id}
                            style={{ 
                              backgroundImage: `url(${allergen.icon && allergen.icon.formats && allergen.icon.formats.thumbnail ?
                                allergen.icon.formats.thumbnail.url : allergen.icon.url})`
                              }}
                          />
                          {
                            !this.props.islistview ?
                            <Tooltip placement="top" 
                            isOpen={this.state.tooltipsAllergens[index]} 
                            toggle={() => this.toggleTooltip(index,"tooltipsAllergens")} 
                              target={"allergen-" + allergen.id}>
                                {allergen.name_i18n[this.props.locale]}
                            </Tooltip> :
                            <span className={s.allergenLabelListView}>
                              {allergen.name_i18n[this.props.locale]}
                            </span>
                          }
                        </div>
                      );
                    })
                ) : null
              }
              {
                 this.props.isMixedWithFoodTypes && this.props.listOfFoodTypes ?
                  (
                    // foodtypes
                    this.props.listOfFoodTypes.map((foodtype, index) => {
                      if (customFoodtypesIcon) {
                        const filteredCustomFoodtypesIcon = customFoodtypesIcon.filter((customFoodtype) => customFoodtype.foodtype.id === foodtype.id)
                        if (filteredCustomFoodtypesIcon.length > 0) {
                          foodtype.icon.url = filteredCustomFoodtypesIcon[0].picture.url
                          if (foodtype.icon.formats) {
                            foodtype.icon.formats.thumbnail.url = filteredCustomFoodtypesIcon[0].picture.url
                            if (filteredCustomFoodtypesIcon[0].picture.formats && filteredCustomFoodtypesIcon[0].picture.formats.thumbnail) {
                              foodtype.icon.formats.thumbnail.url = filteredCustomFoodtypesIcon[0].picture.formats.thumbnail.url
                            }
                          }
                        }
                      }
                      return (
                        <div key={'foodtype-' + foodtype.id + '-' + index}>
                          <div 
                            id={"foodtype-" + foodtype.id}
                            style={{ 
                              backgroundImage: `url(${foodtype.icon && foodtype.icon.formats && foodtype.icon.formats.thumbnail ?
                                foodtype.icon.formats.thumbnail.url : foodtype.icon.url})`
                              }}
                          />
                          {
                            !this.props.islistview ?
                            <Tooltip placement="top" 
                            isOpen={this.state.tooltipsAllergens[index]} 
                            toggle={() => this.toggleTooltip(index,"tooltipsAllergens")} 
                              target={"foodtype-" + foodtype.id}>
                                {foodtype.name_i18n[this.props.locale]}
                            </Tooltip> :
                            <span className={s.allergenLabelListView}>
                              {foodtype.name_i18n[this.props.locale]}
                            </span>
                          }
                        </div>
                      );
                    })
                 ) :null
              }
            </div>
        );
    }
}

export default injectIntl(Allergens);
