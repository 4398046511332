import { RESTAURANTS_REQUEST , RESTAURANTS_SUCCESS, RESTAURANTS_FAILURE,
         RESTAURANTCOLLECTION_REQUEST , RESTAURANTCOLLECTION_SUCCESS, RESTAURANTCOLLECTION_FAILURE,
         RESTAURANT_INFO_REQUEST , RESTAURANT_INFO_SUCCESS, RESTAURANT_INFO_FAILURE} 
from '../actions/restaurant';

// The menu reducer.
export default function restaurant(state = {
  isFetching: false,
  listOfRestaurants: undefined,
  errorMessage: '',
}, action) {
  switch (action.type) {
    case RESTAURANTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: ''
      });
    case RESTAURANTS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        listOfRestaurants: action.listOfRestaurants,
        errorMessage: '',
      });
    case RESTAURANTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.message,
      });
    case RESTAURANTCOLLECTION_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: ''
      });
    case RESTAURANTCOLLECTION_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        restaurantCollection: action.restaurantCollection,
        errorMessage: '',
      });
    case RESTAURANTCOLLECTION_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.message,
      });
    case RESTAURANT_INFO_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: ''
      });
    case RESTAURANT_INFO_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        restaurantInfo: action.restaurantInfo,
        errorMessage: '',
      });
    case RESTAURANT_INFO_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.message,
      });
    default:
      return state;
  }
}
