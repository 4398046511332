exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/**\n * Customized version of bootstrap using variables from _variables.scss.\n * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.\n */\n/*\n * Typography\n * ======================================================================== */\n/**\n * Custom application mixins available through out the app\n */\n/*html, body {\n    max-width: 100%;\n    overflow-x: hidden;\n    -webkit-overflow-scrolling: touch;\n}*/\n.Restaurants_root__AYAIz .tile {\n  display: inline-block; }\n\n.Restaurants_galleryControls__Kqz8O {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 1rem;\n  position: absolute;\n  right: 20px;\n  margin-top: -8px; }\n\n.Restaurants_gallery__1eW-B {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));\n  grid-gap: 15px;\n  margin-bottom: 40px; }\n  @media (max-width: 575.98px) {\n    .Restaurants_gallery__1eW-B {\n      grid-template-columns: repeat(1, 1fr); } }\n\n.Restaurants_picture__3vxIl {\n  padding: 3px;\n  border-radius: 0.3rem;\n  background-color: #fff; }\n  .Restaurants_picture__3vxIl > a {\n    overflow: hidden; }\n  .Restaurants_picture__3vxIl .figure-img {\n    width: 100%;\n    transition: all 0.2s ease-in-out;\n    border-radius: 0.3rem;\n    height: 190px; }\n    @media (max-width: 767.98px) {\n      .Restaurants_picture__3vxIl .figure-img {\n        transition: none; } }\n  .Restaurants_picture__3vxIl:hover .figure-img {\n    -webkit-transform: scale(1.1, 1.1);\n            transform: scale(1.1, 1.1); }\n    @media (max-width: 767.98px) {\n      .Restaurants_picture__3vxIl:hover .figure-img {\n        -webkit-transform: none;\n                transform: none; } }\n\n.Restaurants_description__1H_uj {\n  padding-right: 0.5rem;\n  padding-bottom: 0.85rem;\n  padding-left: 0.5rem;\n  text-align: initial; }\n", ""]);

// exports
exports.locals = {
	"root": "Restaurants_root__AYAIz",
	"galleryControls": "Restaurants_galleryControls__Kqz8O",
	"gallery": "Restaurants_gallery__1eW-B",
	"picture": "Restaurants_picture__3vxIl",
	"description": "Restaurants_description__1H_uj"
};