exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/**\n * Customized version of bootstrap using variables from _variables.scss.\n * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.\n */\n/*\n * Typography\n * ======================================================================== */\n/**\n * Custom application mixins available through out the app\n */\n/*html, body {\n    max-width: 100%;\n    overflow-x: hidden;\n    -webkit-overflow-scrolling: touch;\n}*/\n.FilterElement_filterElement__3vNiR {\n  display: flex;\n  align-items: center;\n  margin-right: 30px;\n  margin-bottom: 20px; }\n  .FilterElement_filterElement__3vNiR:last-child {\n    margin-left: 0; }\n\n.FilterElement_filterElementLable__B1zSP {\n  margin-right: 10px;\n  font-weight: 400; }\n", ""]);

// exports
exports.locals = {
	"filterElement": "FilterElement_filterElement__3vNiR",
	"filterElementLable": "FilterElement_filterElementLable__B1zSP"
};