import React from 'react';
import PropTypes from 'prop-types';

import {
    Tooltip,
  } from 'reactstrap';

import s from './Foodtypes.module.scss';
import { injectIntl } from 'react-intl'; 

class Foodtypes extends React.Component {
    static propTypes = {
        foodCategoryLabel: PropTypes.string,
        listOfFoodtypes: PropTypes.array.isRequired,
        locale: PropTypes.string.isRequired,
        islistview: PropTypes.bool
    };

    static defaultProps = {
      islistview: false
    };
  
    constructor(props) {
      super(props);

      this._isMounted = false;
  
      this.state = {
        tooltipsFoodtypes: []
      }
  
      this.toggleTooltip = this.toggleTooltip.bind(this);
    }

    componentDidMount() {
      this._isMounted = true;
    }
  
    componentWillUnmount() {
      this._isMounted = false;
    }
  
    toggleTooltip(id, field) {
      const newState = [...this.state[field]];
      newState.fill(false);
  
      if (!this.state[field][id]) {
        newState[id] = true;
      }
  
      this._isMounted && this.setState({
        [field]: newState,
      });
    }

    render() {
      const { locale } = this.props.intl
      return(
        <div className={this.props.islistview? s.foodtypeForListView : s.foodtypes}>
          <span className={this.props.islistview? s.titleForListView : s.title}
            style={locale === 'ar' ? {marginRight: 0, marginLeft: 10} : {}}>
            {this.props.foodCategoryLabel}
          </span>
          {
            this.props.listOfFoodtypes.map((foodtype, index) => {
              return (
                  <div key={'foodtype-' + foodtype.id + '-' + index}>
                    <div style={{ backgroundImage: `url(${foodtype.icon && foodtype.icon.formats && foodtype.icon.formats.thumbnail ? foodtype.icon.formats.thumbnail.url : foodtype.icon.url})` }} 
                      id={"foodtype-" + index} />
                    {
                      !this.props.islistview ?
                      <Tooltip placement="top" 
                      isOpen={this.state.tooltipsFoodtypes[index]} 
                      toggle={() => this.toggleTooltip(index,"tooltipsFoodtypes")} 
                        target={"foodtype-" + index}>
                          {foodtype.name_i18n[this.props.locale]}
                      </Tooltip>
                      :
                      <span className={s.foodtypeLabelListView}>
                      {foodtype.name_i18n[this.props.locale]}
                    </span>
                    }
                  </div>
              );
            })
          }
        </div>
      );
    }
}

export default injectIntl(Foodtypes);
