import React from 'react';
import PropTypes from 'prop-types';
import Widget from '../Widget/Widget';
import {
  Badge
} from 'reactstrap';

import {
  BootstrapTable,
  TableHeaderColumn,
} from 'react-bootstrap-table';

import {FormattedMessage} from 'react-intl';
import $ from 'jquery';

import './AddongroupItemsTable.modules.scss';

class AddongroupItemsTableWithoutFormsy extends React.Component {

  static propTypes = {
    addongroup: PropTypes.object.isRequired,
    currency: PropTypes.object.isRequired,
    currencyNumberOfDecimals: PropTypes.number.isRequired,
    locale: PropTypes.string.isRequired,
    isShowPrice: PropTypes.bool,
    isHidePriceColumn: PropTypes.bool,
    isShowInWidget: PropTypes.bool,
    disableSelection: PropTypes.bool,
    setValue: PropTypes.func.isRequired, // send the selected value on radio or checkboxes to the parent
    menugroupidforinput: PropTypes.string, // to give a unique name to the radio and checkboxes
    menuid: PropTypes.string, // the combo menu in which those addons are visible
    menuitemid: PropTypes.string.isRequired, // the menu item id to which the addons belongs
    customAllergenIcons: PropTypes.array,
    customFoodtypeIcons: PropTypes.array
  };

  constructor(props) {
    super(props);

    this.state = {
      listOfSelectedAddongroupItems: []
    };

    this.pictureFormatter = this.pictureFormatter.bind(this);
    this.priceFormatter = this.priceFormatter.bind(this);
    this.inputFormatter = this.inputFormatter.bind(this);
    this.changeValue = this.changeValue.bind(this);
  }

  componentDidMount() {

    const {addongroup, menugroupidforinput, menuitemid} = this.props;

    // set the default value to an empty object
    this.props.setValue({});

    //console.log('addongroup => ', addongroup);

    const addongroupitems = addongroup.addonitems;

    addongroupitems.forEach(addongroupitem => {
      // if there is a menu item size that should be preselected
      // and that we did not set any value yet then we do it once
      if(addongroupitem.isPreselected) {
        const arrayTemp = [...this.state.listOfSelectedAddongroupItems, addongroupitem];
        this.setState({
          listOfSelectedAddongroupItems: arrayTemp
        });
        const objectToReturn = {};
        objectToReturn.menuitemId = menuitemid;
        objectToReturn.menuitemsgroupid = menugroupidforinput;
        objectToReturn.addongroupsId = this.props.addongroup.id;
        objectToReturn.selectedAddonGroup = this.props.addongroup;
        objectToReturn.selectedAddons = arrayTemp.map(addonitem => {
          return {
            addonitemId: addonitem.id,
            selectedAddonItem: addonitem
          }
        });
        this.props.setValue(objectToReturn);
      }
    });
    
  }

  componentDidUpdate(prevProps) {
    // addons are disabled which means that the parent
    // menu item size has been deselected
    if(this.props.disableSelection && 
      this.state.listOfSelectedAddongroupItems.length > 0){
        this.setState({
          listOfSelectedAddongroupItems: []
        });
      // resetting the value
      this.props.setValue(undefined);
    }

    if(prevProps.addongroup.id !== this.props.addongroup.id) {
      //console.log('here 1');
      this.setState({
        listOfSelectedAddongroupItems: []
      });

      // resetting the value
      this.props.setValue(undefined);
      // reset all radio buttons
      const {addongroup, menugroupidforinput, menuitemid} = this.props;

      $('input[name="'+(menugroupidforinput !== '' ? "addongroup-" + menugroupidforinput + "-" + this.props.addongroup.id : "addongroup-" + this.props.addongroup.name)+'"]').prop('checked', false);


      //console.log('this.state.selectedmenuitemsize => ', this.state.selectedmenuitemsize);
      
      const addongroupitems = addongroup.addonitems;

      addongroupitems.forEach(addongroupitem => {
        // if there is a menu item size that should be preselected
        // and that we did not set any value yet then we do it once
        if(addongroupitem.isPreselected) {
          const arrayTemp = [...this.state.listOfSelectedAddongroupItems, addongroupitem];
          this.setState({
            listOfSelectedAddongroupItems: arrayTemp
          });
          const objectToReturn = {};
          objectToReturn.menuitemId = menuitemid;
          objectToReturn.menuitemsgroupid = menugroupidforinput;
          objectToReturn.addongroupsId = this.props.addongroup.id;
          objectToReturn.selectedAddonGroup = this.props.addongroup;
          objectToReturn.selectedAddons = arrayTemp.map(addonitem => {
            return {
              addonitemId: addonitem.id,
              selectedAddonItem: addonitem
            }
          });
          this.props.setValue(objectToReturn);
        }
      });
    }

    // if we are in combo mode then menus are switched by tabs
    // and if they have the same menuitems then they do not get updated
    // that is why we use the menu id to make sure we detect a change between
    // combo menus
    if(prevProps.menuid !== '' && prevProps.menuid !== this.props.menuid) {
      //console.log('here 2');
      this.setState({
        listOfSelectedAddongroupItems: []
      });

      // resetting the value
      this.props.setValue(undefined);
      // reset all radio buttons
      const {addongroup, menugroupidforinput, menuitemid} = this.props;
      $('input[name="'+(menugroupidforinput !== '' ? "addongroup-" + menugroupidforinput + "-" + this.props.addongroup.id : "addongroup-" + this.props.addongroup.name)+'"]').prop('checked', false);

      //console.log('this.state.selectedmenuitemsize => ', this.state.selectedmenuitemsize);

      

      const addongroupitems = addongroup.addonitems;

      addongroupitems.forEach(addongroupitem => {
        // if there is a menu item size that should be preselected
        // and that we did not set any value yet then we do it once
        if(addongroupitem.isPreselected) {
          const arrayTemp = [...this.state.listOfSelectedAddongroupItems, addongroupitem];
          this.setState({
            listOfSelectedAddongroupItems: arrayTemp
          });
          const objectToReturn = {};
          objectToReturn.menuitemId = menuitemid;
          objectToReturn.menuitemsgroupid = menugroupidforinput;
          objectToReturn.addongroupsId = this.props.addongroup.id;
          objectToReturn.selectedAddonGroup = this.props.addongroup;
          objectToReturn.selectedAddons = arrayTemp.map(addonitem => {
            return {
              addonitemId: addonitem.id,
              selectedAddonItem: addonitem
            }
          });
          this.props.setValue(objectToReturn);
        }
      });
    }
  }
  
  priceFormatter(cell) {
    let price = cell
    if (price > 0) {
      if (this.props.currency.numberOfDecimals <= 2) {
        price = parseFloat(price.toFixed(this.props.currency.numberOfDecimals))
      } else {
        price = price.toFixed(this.props.currency.numberOfDecimals)
      }
    }
    return (
      price > 0 ?
      <Badge color="primary" className="priceMenuItem">
        {this.props.currency.symbol} {price}
      </Badge>
      : 
      <Badge color="secondary" className="priceMenuItem">
        N/A
      </Badge>
    );
  };

  pictureFormatter (cell, row) {
    if(row.pictures.length > 0) {
      return (
        <div className="pictureAddonitem" key={"pictureMenuItemSizeCell-"+row.id} style={{
          backgroundImage: `url(${row.pictures[0].formats && row.pictures[0].formats.thumbnail ? row.pictures[0].formats.thumbnail.url : row.pictures[0].url})`
        }}>
        </div>
      );
    } else {
      return (<div></div>);
    }
  }

  nameFormatter (cell, row) {
    //console.log(row);
    return (
      <span>
        {row.name_i18n[this.props.locale]}
      </span>
    );
  }

  inputFormatter(cell, row) {
    let isInputChecked = false;
    const {menugroupidforinput, disableSelection, menuitemid} = this.props;
    if (this.state.listOfSelectedAddongroupItems.length > 0) {
      const tempFilteredArray = this.state.listOfSelectedAddongroupItems
                                  .filter(addongroupitem => addongroupitem.id === row.id);
      isInputChecked = tempFilteredArray.length > 0;
    }

    // if checkbox or radio button - multiple choice or single choice

    if(this.props.addongroup.isMultipleSelectionAllowed) {
      // checkbox
      return (<input type="checkbox" 
              id={menuitemid + "-" + row.id}
              disabled={disableSelection}
              className="option-input checkbox"
              name={menugroupidforinput !== '' ? "addongroup-" + menugroupidforinput + "-" + this.props.addongroup.id : "addongroup-" + this.props.addongroup.name}
              value={JSON.stringify(row)} 
              checked={isInputChecked && !disableSelection}
              onChange={this.changeValue}
              ></input>);
    } else {
      // radio button
      return (
        <input type="radio" 
              id={menuitemid + "-" + row.id}
              disabled={disableSelection}
              className="option-input radio"
              name={menugroupidforinput !== '' ? "addongroup-" + menugroupidforinput + "-" + this.props.addongroup.id : "addongroup-" + this.props.addongroup.name}
              value={JSON.stringify(row)} 
              checked={isInputChecked && !disableSelection}
              onChange={this.changeValue}
              ></input>
      );
    }
  }

  changeValue(event) {
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    const selectedAddongroupItem = JSON.parse(event.currentTarget.value);
    if(this.props.addongroup.isMultipleSelectionAllowed) {
      // if element already exists it means that we are unselecting it
      const indexOfAddongroupItem = this.state.listOfSelectedAddongroupItems
                                      .findIndex(aoi => aoi.id === selectedAddongroupItem.id);
      const arrayTemp = [...this.state.listOfSelectedAddongroupItems];
      const {menugroupidforinput, menuitemid} = this.props;
      // element already exists and we remove it
      if (indexOfAddongroupItem >= 0) {
        arrayTemp.splice(indexOfAddongroupItem, 1);
        const objectToReturn = {};
        objectToReturn.menuitemId = menuitemid;
        objectToReturn.menuitemsgroupid = menugroupidforinput;
        objectToReturn.addongroupsId = this.props.addongroup.id;
        objectToReturn.selectedAddonGroup = this.props.addongroup;
        objectToReturn.selectedAddons = arrayTemp.map(addonitem => {
          return {
            addonitemId: addonitem.id,
            selectedAddonItem: addonitem
          }
        });
        this.props.setValue(objectToReturn);
        this.setState({
          listOfSelectedAddongroupItems: arrayTemp
        });
      } else {
        // element does not exist and we add it
        arrayTemp.push(selectedAddongroupItem);
        const objectToReturn = {};
        objectToReturn.menuitemId = menuitemid;
        objectToReturn.menuitemsgroupid = menugroupidforinput;
        objectToReturn.addongroupsId = this.props.addongroup.id;
        objectToReturn.selectedAddonGroup = this.props.addongroup;
        objectToReturn.selectedAddons = arrayTemp.map(addonitem => {
          return {
            addonitemId: addonitem.id,
            selectedAddonItem: addonitem
          }
        });
        this.props.setValue(objectToReturn);
        this.setState({
          listOfSelectedAddongroupItems: arrayTemp
        });
      }
    } else {
      const {menugroupidforinput, menuitemid} = this.props
      // single selection mode
      const arrayTemp = [selectedAddongroupItem];
      //console.log('valueToReturn => ', valueToReturn);
      const objectToReturn = {};
      objectToReturn.menuitemId = menuitemid;
      objectToReturn.menuitemsgroupid = menugroupidforinput;
      objectToReturn.addongroupsId = this.props.addongroup.id;
      objectToReturn.selectedAddonGroup = this.props.addongroup;
      objectToReturn.selectedAddons = arrayTemp.map(addonitem => {
        return {
          addonitemId: addonitem.id,
          selectedAddonItem: addonitem
        }
      });
      this.props.setValue(objectToReturn);
      this.setState({
        listOfSelectedAddongroupItems: arrayTemp
      });
    }
  }

  render() {
    const options = {
      sizePerPage: 10,
      paginationSize: 3,
    };

    const {locale, addongroup, isHidePriceColumn} = this.props;

    //console.log('addongroup => ', addongroup);

    const addongroupitems = addongroup.addonitems;

    const bootstrapTable = (
      <BootstrapTable data={addongroupitems}
                          version="4" 
                          options={options} 
                          tableContainerClass={`table-striped table-hover`}
                          onClickCell={this.displayModalPopup}>
        <TableHeaderColumn className="d-none" columnClassName="d-none" dataField="id" isKey>
          <span className="fs-sm">ID</span>
        </TableHeaderColumn>
        <TableHeaderColumn className="colTwentyPercentWidth headerRadioButtonAddongroupItems"
                            columnClassName="colTwentyPercentWidth radiobutton"
                            dataField="name"
                            dataFormat={this.inputFormatter.bind(this)}>
          </TableHeaderColumn>
        <TableHeaderColumn dataField="name"
                          className="d-none d-md-table-cell colTwentyPercentWidth"
                          columnClassName="d-none d-md-table-cell colTwentyPercentWidth"
                          dataFormat={this.pictureFormatter}>
        </TableHeaderColumn>
        <TableHeaderColumn dataField="name" 
                          className="colSixtyPercentWidth nameField"
                          columnClassName="colSixtyPercentWidth nameField"
                          dataFormat={this.nameFormatter.bind(this)}>
          <span className="fs-sm">
            {
              this.props.isShowInWidget?
                <FormattedMessage id="AddongroupItemTableWithoutFormsy.BootstrapTable.TableHeaderColumn.name.label"
                                            defaultMessage="Name" />
                :
                addongroup.name_i18n[locale]
            }
          </span>
        </TableHeaderColumn>
        {
          !isHidePriceColumn ?
            <TableHeaderColumn dataField="price" 
                              className="colTwentyPercentWidth priceField"
                              columnClassName="colTwentyPercentWidth priceField"
                              dataFormat={this.priceFormatter}>
              <span className="fs-sm">
                <FormattedMessage id="AddongroupItemTableWithoutFormsy.BootstrapTable.TableHeaderColumn.price.label"
                                                  defaultMessage="Price" />
              </span>
            </TableHeaderColumn> : null
        }
      </BootstrapTable>
    );

    //console.log('addongroupitemsTable => ', addongroupitems);

    return (
      <div className="rootAddongroupitemWithoutFormsy">
        {
            this.props.isShowInWidget ?
              <Widget title={
                              <h4>
                                <span className="fw-semi-bold">
                                    {addongroup.name_i18n[locale]}
                                </span>
                              </h4>
                            } 
                      collapse>
                      {bootstrapTable}
              </Widget> 
              :
              bootstrapTable
        }
      </div>
    );
  }

}

AddongroupItemsTableWithoutFormsy.defaultProps = {
  isShowPrice: true,
  isHidePriceColumn: false,
  isShowInWidget: true,
  menugroupidforinput: '',
  menuid: '',
  disableSelection: false
};

export default AddongroupItemsTableWithoutFormsy;
