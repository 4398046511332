import React from 'react';
import PropTypes from 'prop-types';
//`import { Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { actionGetRestaurantsFromServer, actionGetRestaurantCollectionFromServer } from '../../actions/restaurant';
import { navigateToNewPage } from '../../actions/navigation';
import {
  // Button,
  // ButtonGroup,
  // Breadcrumb,
  // BreadcrumbItem,
  // Badge,
  // Tooltip,
  Row,
  // Col
} from 'reactstrap';

import { FormattedMessage, injectIntl } from 'react-intl'; 
import ProgressIndicator from '../../components/ProgressIndicator';

import queryString from 'query-string';

import s from './Restaurants.module.scss';

import FlexBanner from "flex-banner";

// Not best practice but this flag has been added to avoid a double call to the server
// looking at the isFetching property connected to the reducer which is not fast enougth
// changing value when a call to the backend is sent in the didupdate function and end
// up with another useless call to the server before the isFetching property is updated
// by default this flag is at false indicating that no call has been made to the server
// as soon as a call is made then it is put as true and only back to false when the
// component is unmounted
let isAFetchFromApiInProgress = false;

class Restaurants extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    this._isMounted = false;

    this.state = {
      tooltipRefreshDataFromServer: [false],
    };

    this.refreshRestaurantsFromServer = this.refreshRestaurantsFromServer.bind(this);
    this.refreshRestaurantCollectionFromServer = this.refreshRestaurantCollectionFromServer.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.scrollToRestaurant = this.scrollToRestaurant.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    // dispatch back button url and page title name
    const { dispatch } = this.props;
    dispatch(navigateToNewPage());
    //console.log('this.props.isFetching in componentDidMount => ', this.props.isFetching);
    //console.log('isAFetchFromApiInProgress => ', isAFetchFromApiInProgress);
    if (this.props.listOfRestaurants === undefined && !this.props.isFetching && !isAFetchFromApiInProgress) {
      //console.log('çalling server 0');
      const restaurantCollectionId = this.props.match.params.restaurantcollectionid;
      // set that we are in restaurant collection mode
      localStorage.setItem(`restaurantCollection-slug-${restaurantCollectionId}`, restaurantCollectionId);
      // we check if we are in restaurant colletion mode
      if(restaurantCollectionId) {
         this.refreshRestaurantCollectionFromServer();
      } else {
        // this is the list of restaurants mode
        this.refreshRestaurantsFromServer();
      }
    }
  }

  componentDidUpdate(prevProps) {
    // dispatch back button url and page title name
    const { dispatch } = this.props;
    dispatch(navigateToNewPage());

    /*const el = this.myRef.current;
    console.log("el => ", el);
    //const elem = el.getElementsByClassName('Banner_bannerSlideDown__NAV-_');
    const elem = el.getElementsByTagname('a');
    if (elem) {
      console.log("elem => ", elem);
    }*/

    const restaurantCollectionId = this.props.match.params.restaurantcollectionid;
      // we check if we are in restaurant colletion mode
    if(restaurantCollectionId) {
      if(this.props.restaurantCollection === undefined &&
        prevProps.restaurantCollection === undefined &&
        !this.props.isFetching && !isAFetchFromApiInProgress) {
        //console.log('çalling server 1');
        this.refreshRestaurantCollectionFromServer(true);
      }
    } else {
      if(this.props.listOfRestaurants === undefined &&
        prevProps.listOfRestaurants === undefined &&
        !this.props.isFetching && !isAFetchFromApiInProgress) {
        //console.log('çalling server 1');
        this.refreshRestaurantsFromServer(true);
      }
    }

    if(this.props.restaurantCollection) {
      const {intl} = this.props;
      const {locale} = intl;
      const { restaurantCollection } = this.props;
      if (this.props.restaurantCollection.type) {
        localStorage.setItem(`restaurantCollection-type-${restaurantCollectionId}`, this.props.restaurantCollection.type);
      }
      dispatch(navigateToNewPage(restaurantCollection.name_i18n[locale]));
    }

    const restaurantAnchor = localStorage.getItem('restaurant-link') 
    if (restaurantAnchor) {
      // when the selected category is in the state and in the active menu category
      // which means we have now selected the right tab then we scroll to the menu
      // item in this tab
      //console.log('this.state.activeMenuCategoryIdInState => ', this.state.activeMenuCategoryIdInState);
      //console.log('this.activeMenuCategoryId => ', this.activeMenuCategoryId);
      // if(this.state.activeMenuCategoryIdInState === this.activeMenuCategoryId) {
        //console.log('scroll to menu item => ', this.props.location.hash);
      this.scrollToRestaurant(restaurantAnchor);
      //}
    }
  }

  componentWillUnmount() {
    isAFetchFromApiInProgress = false;
    this._isMounted = false;
  }

  scrollToRestaurant(restaurantAnchor) {
    const el = document.getElementById(restaurantAnchor);
    //console.log('el => ', el);
    if (el) {
      el.scrollIntoView({behavior: "auto", block: "center", inline: "nearest"});
      // remove the hash anchor from the local storage
      localStorage.removeItem('restaurant-link') 
    }
  }


  refreshRestaurantsFromServer(forceCallToServer = false) {
    const { dispatch } = this.props;
    // we put the flag that a call to the server is being done
    isAFetchFromApiInProgress = true;

    if((!this.props.isFetching 
      && (typeof this.props.listOfRestaurants === 'undefined')) || forceCallToServer) {
        dispatch(actionGetRestaurantsFromServer());
    }
  }

  refreshRestaurantCollectionFromServer(forceCallToServer = false) {
    const { dispatch } = this.props;
    // we put the flag that a call to the server is being done
    isAFetchFromApiInProgress = true;

    if((!this.props.isFetching 
      && (typeof this.props.restaurantCollection === 'undefined')) || forceCallToServer) {
        const restaurantCollectionId = this.props.match.params.restaurantcollectionid;
        dispatch(actionGetRestaurantCollectionFromServer(restaurantCollectionId));
    }
  }

  toggleTooltip(id, field) {
    const newState = [...this.state[field]];
    newState.fill(false);

    if (!this.state[field][id]) {
      newState[id] = true;
    }

    this._isMounted && this.setState({
      [field]: newState,
    });
  }

  render() {
    const {intl} = this.props;
    const {locale} = intl;
    let listOfRestaurantConfigs = this.props.listOfRestaurants;
    const { restaurantCollection } = this.props;
    const restaurantCollectionId = this.props.match.params.restaurantcollectionid;
    const queryStringParamMode = queryString.parse(this.props.location.search).mode
    const queryStringParamLocationNumber = queryString.parse(this.props.location.search).locationnumber
    const queryStringParamGroupCode = queryString.parse(this.props.location.search).groupcode
    // we check if we are in restaurant colletion mode
    if(restaurantCollectionId && restaurantCollection && restaurantCollection.groupOfRestaurants) {
      // assign the restaurants that are part of the restaurant collection that is displayed
      listOfRestaurantConfigs = restaurantCollection.groupOfRestaurants.filter(restaurantConfig => restaurantConfig.restaurant.isActive && restaurantConfig.isActive);
    }

    let promotionBanner = null 
    if (restaurantCollection) {
      promotionBanner = restaurantCollection.promotionBanner
    }

    const {restaurantcollectionid} = this.props.match.params;

    //console.log('restaurants col id => ', restaurantcollectionid);

    return (
      <div ref={this.myRef} className={s.root}>
        {
          promotionBanner && promotionBanner.isActive ? 
          <FlexBanner
            title={promotionBanner.promotionDescription_i18n[locale]}
            ctaLink={promotionBanner.urlToOpen}
            ctaTitle="Learn More"
            animationTime={1}
            delayToShowBanner={0}
            wrapperStyle={{ backgroundColor: "" }} // empty as _overrides.scss file takes over for theming
            mainStyleTitle={{ color : "" }} // empty as _overrides.scss file takes over for theming
            mainStyleLink={{ color: "" }} // empty as _overrides.scss file takes over for theming
            isCenter={true}
          /> : null
        }
        {
          <Row>
            {/*
              <Col>
                <Breadcrumb>
                  <BreadcrumbItem active>
                    <FormattedMessage id="Restaurants.Breadcrumbitem.Restaurants" defaultMessage="Kiosks" />&nbsp;&nbsp;
                    {listOfRestaurantConfigs && listOfRestaurantConfigs.length > 0 ? <Badge color="primary">{listOfRestaurantConfigs.length}</Badge> : null}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            */}
            {/*
              <Col className={locale === 'ar' ? "d-sm-down-none" : "d-sm-down-none text-right"}>
                <div className={s.galleryControls} style={locale === 'ar' ? {display: 'block', position: 'initial'} : {}}>
                  <ButtonGroup id="buttonRefreshDataFromServer">
                    <Button onClick={() => {
                        const restaurantCollectionIdVal = this.props.match.params.restaurantcollectionid;
                        // we check if we are in restaurant colletion mode
                        if(restaurantCollectionIdVal) {
                            this.refreshRestaurantCollectionFromServer(true);
                        } else {
                          this.refreshRestaurantsFromServer(true);
                        }
                      }} color="default"><i className="la la-refresh"></i></Button>
                  </ButtonGroup>
                  <Tooltip placement="top" isOpen={this.state.tooltipRefreshDataFromServer[0]} toggle={() => this.toggleTooltip(0, 'tooltipRefreshDataFromServer')} 
                    target={"buttonRefreshDataFromServer"}>
                    <FormattedMessage id="Restaurants.button.refresh.data.from.server" defaultMessage="Refresh list of restaurants" />
                  </Tooltip>
                </div>
              </Col>
            */}
          </Row>
        }
        {this.props.isFetching ? <ProgressIndicator />: null}
        {listOfRestaurantConfigs && listOfRestaurantConfigs.length === 0 ?<span><FormattedMessage id="Restaurants.Page.No.Restaurants.Available" defaultMessage="No available restaurants" />.</span> : null}
        <div className={s.gallery}>
          {
            listOfRestaurantConfigs && listOfRestaurantConfigs.length > 0?
            listOfRestaurantConfigs.map((restaurantConfig, index) => {
              const { restaurant, deliverymethod, description_i18n, picture } = restaurantConfig
              const key = 'restaurants-page-' + restaurant.name + index;
              const anchor = restaurant ? restaurant.slug + '-' + index : null;
              return (
                <div id={'restaurant-link-' + anchor} key={key} className={`${s.picture} card`}>
                  <a href='#/' onClick={(e) => {
                      e.preventDefault()
                      if (restaurantcollectionid && restaurant) {
                        localStorage.setItem('restaurant-link', `restaurant-link-${anchor}`);
                        window.location.href = `#/app/${deliverymethod.code}/restaurant/${restaurant.slug}/menus?rcid=${restaurantcollectionid}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamLocationNumber ? '&locationnumber=' + queryStringParamLocationNumber : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`;
                      } else {
                        if (queryStringParamLocationNumber && restaurant) {
                          window.location.href = `#/app/${deliverymethod.code}/restaurant/${restaurant.slug}/menus?locationnumber=${queryStringParamLocationNumber}${queryStringParamMode ? '?mode=' + queryStringParamMode : '?'}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`
                        } else {
                          if (restaurant) {
                            window.location.href = `#/app/${deliverymethod.code}/restaurant/${restaurant.slug}/menus${queryStringParamMode ? '?mode=' + queryStringParamMode : ''}`
                          }
                        }
                      }
                  }}>
                    <img className="figure-img" src={
                      picture && picture.url ? picture.url :
                      (
                        restaurant.backgroundPicture.formats && restaurant.backgroundPicture.formats.medium ? 
                        restaurant.backgroundPicture.formats.medium.url : restaurant.backgroundPicture.url
                      )
                    } alt={restaurant.name} />  
                    <div className={s.description}>
                      <h5 
                        className="mt-0 mb-xs fw-semi-bold"
                        dangerouslySetInnerHTML={{__html: description_i18n[locale]}}
                      >
                      </h5>
                      <span>
                        {
                          restaurant.cuisinetypes.map((cuisinetype) => {
                            return cuisinetype.name_i18n[locale] + " "
                          })
                        }
                      </span>
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <span className="text-muted">{restaurant.streetNameAndNumber}</span>
                      </div>
                    </div>
                  </a>
                </div>
              );
            }) : null
          }
        </div>
      </div>);
  } // enf of render
} // end of class

function mapStateToProps(store) {
  return {
    isFetching: store.restaurant.isFetching,
    errorMessage: store.restaurant.errorMessage,
    listOfRestaurants: store.restaurant.listOfRestaurants,
    restaurantCollection: store.restaurant.restaurantCollection
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(Restaurants)));
