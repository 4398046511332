exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/**\n * Customized version of bootstrap using variables from _variables.scss.\n * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.\n */\n/*\n * Typography\n * ======================================================================== */\n/**\n * Custom application mixins available through out the app\n */\n/*html, body {\n    max-width: 100%;\n    overflow-x: hidden;\n    -webkit-overflow-scrolling: touch;\n}*/\n.Landing_root__3YbCb {\n  text-align: center; }\n  .Landing_root__3YbCb img {\n    width: 290px; }\n  .Landing_root__3YbCb .col {\n    padding: 15px 0px; }\n\n.Landing_explanationText__2q9Yd {\n  position: relative;\n  top: -23px;\n  right: 20px;\n  padding-left: 3px;\n  display: inline-block;\n  width: 290px;\n  word-wrap: break-word; }\n\n.Landing_tutorialText__3sfnJ {\n  width: 280px;\n  display: inline-block !important; }\n\n.Landing_tutorialNumber__124fe {\n  position: relative;\n  left: -10px;\n  font-size: 18px;\n  font-weight: 600; }\n\n.Landing_colTutorialNumber__2OZ3o {\n  padding-left: 0 !important; }\n", ""]);

// exports
exports.locals = {
	"root": "Landing_root__3YbCb",
	"explanationText": "Landing_explanationText__2q9Yd",
	"tutorialText": "Landing_tutorialText__3sfnJ",
	"tutorialNumber": "Landing_tutorialNumber__124fe",
	"colTutorialNumber": "Landing_colTutorialNumber__2OZ3o"
};