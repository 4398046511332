import React from 'react';
import PropTypes from 'prop-types';
import s from './Properties.module.scss';

class Properties extends React.Component {
    static propTypes = {
        listOfFoodtypes: PropTypes.array.isRequired,
        listOfAllergens: PropTypes.array.isRequired,
        locale: PropTypes.string.isRequired,
        customAllergenIcons: PropTypes.array,
        customFoodtypeIcons: PropTypes.array
    };
  
    constructor(props) {
      super(props);

      this._isMounted = false;
    }

    componentDidMount() {
      this._isMounted = true;
    }
  
    componentWillUnmount() {
      this._isMounted = false;
    }

    render() {

      const { customAllergenIcons, customFoodtypeIcons } = this.props

        return(
          <div className={s.propertiesForListView}>
              {
                this.props.listOfAllergens.map((allergen, index) => {     
                  if (customAllergenIcons) {
                    const filteredCustomAllergensIcon = customAllergenIcons.filter((customAllergen) => customAllergen.allergen.id === allergen.id)
                    if (filteredCustomAllergensIcon.length > 0) {
                      allergen.icon.url = filteredCustomAllergensIcon[0].icon.url
                      if (allergen.icon.formats) {
                        allergen.icon.formats.thumbnail.url = filteredCustomAllergensIcon[0].icon.url
                        if (filteredCustomAllergensIcon[0].icon.formats && filteredCustomAllergensIcon[0].icon.formats.thumbnail) {
                          allergen.icon.formats.thumbnail.url = filteredCustomAllergensIcon[0].icon.formats.thumbnail.url
                        }
                      }
                    }
                  }
                  return (
                    <div key={'allergen-' + allergen.id + '-' + index}>
                      <div 
                        id={"allergen-" + allergen.id}
                        style={{ 
                          backgroundImage: `url(${allergen.icon && allergen.icon.formats && allergen.icon.formats.thumbnail ?
                            allergen.icon.formats.thumbnail.url : allergen.icon.url})`
                          }}
                      />
                        <span className={s.propertiesLabelListViewAllergens}>
                          {allergen.name_i18n[this.props.locale]}
                        </span>
                    </div>
                  );
                })
              }
              {
                this.props.listOfFoodtypes.map((foodtype, index) => {
                  if (customFoodtypeIcons) {
                    const filteredCustomFoodtypesIcon = customFoodtypeIcons.filter((customFoodtype) => customFoodtype.foodtype.id === foodtype.id)
                    if (filteredCustomFoodtypesIcon.length > 0) {
                      foodtype.icon.url = filteredCustomFoodtypesIcon[0].picture.url
                      if (foodtype.icon.formats) {
                        foodtype.icon.formats.thumbnail.url = filteredCustomFoodtypesIcon[0].picture.url
                        if (filteredCustomFoodtypesIcon[0].picture.formats && filteredCustomFoodtypesIcon[0].picture.formats.thumbnail) {
                          foodtype.icon.formats.thumbnail.url = filteredCustomFoodtypesIcon[0].picture.formats.thumbnail.url
                        }
                      }
                    }
                  }
                  return (
                    <div key={'foodtype-' + foodtype.id + '-' + index}>
                      <div 
                        style={{ backgroundImage: `url(${foodtype.icon.url})` }} 
                        id={"foodtype-" + foodtype.id}
                      >
                        <span className={s.propertiesLabelListViewFoodtypes}>
                          {foodtype.name_i18n[this.props.locale]}
                        </span>
                      </div>
                    </div>
                  );
                })
              }
            </div>
        );
    }
}

export default Properties;
