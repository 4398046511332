import React from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  Tooltip,
  ButtonGroup,
  Button
} from 'reactstrap';

import { FormattedMessage } from 'react-intl'; 
import { navigateToNewPage } from '../../actions/navigation';
import $ from 'jquery';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { connect } from 'react-redux';
import queryString from 'query-string';
//import Section from './components/Section/Section';
import Banner from './components/Banner/Banner';
import { GAEvent } from '../../components/Tracking/Tracking';
import PriceTable from '../../components/PriceTable/PriceTable';
import AddongroupItemsTable from '../../components/AddongroupItemsTable/AddongroupItemsTable';
//import Description from './components/Description/Description';
//import mock from './mock';
import Formsy from 'formsy-react';

import { getMenuItemDetailsFromServer } from '../../actions/menuitems';
import { addProduct } from '../../actions/cart';

import s from './MenuItem.module.scss';


import { /*FormattedMessage,*/ injectIntl } from 'react-intl'; 
import ProgressIndicator from '../../components/ProgressIndicator';

// flag to indicate a call to the server is being done
let isAFetchFromApiInProgress = false;

let isScrollToTopRequired = false;

class MenuItem extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      tooltipRefreshDataFromServer: [],
      submitButtonEnabled: false
    }

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.refreshMenuItemDetailsFromServer = this.refreshMenuItemDetailsFromServer.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.enableSubmitButton = this.enableSubmitButton.bind(this);
    this.disableSubmitButton = this.disableSubmitButton.bind(this);
    this.addMenuItemToCart = this.addMenuItemToCart.bind(this);
    this.animateMenuItemToCart = this.animateMenuItemToCart.bind(this);
  }
  
  componentDidMount() {
    this._isMounted = true;

    isScrollToTopRequired = true;

    this.dispatchPageNavigationToHeader();
    
    // retrieve data from server if needed
    //console.log('did mount');
    if (this.props.restaurantInfo)
      this.refreshMenuItemDetailsFromServer();

    const restaurantCollectionId = queryString.parse(this.props.location.search).rcid;

    if(!restaurantCollectionId) {
      // remove localstorage item if exists
      // Remove localStorage items with a pattern in their name
      Object.keys(localStorage).forEach(key => {
        if (key.startsWith('restaurantCollection-slug-')) {
          localStorage.removeItem(key);
        }
      });
    } else {
      localStorage.setItem(`restaurantCollection-slug-${restaurantCollectionId}`, restaurantCollectionId);
    }
  }

  dispatchPageNavigationToHeader(strTitleToDisplay = '') {
    const { dispatch, intl } = this.props;
    const params = this.props.match.params;
    // const menucategoryId = this.props.match.params.menucategoryid;
    const menugroupId = this.props.match.params.menugroupid;
    const paramsInQueryString = queryString.parse(this.props.location.search);
    const currentRestaurant =  this.props.restaurantMenuItemDetails ? this.props.restaurantMenuItemDetails.restaurant : null;
    const currentMenu = currentRestaurant ? currentRestaurant.menus[0] : null;
    const currentMenuCategory = currentMenu ? currentMenu.menucategories[0] : null;
    let currentMenuGroup;

    // if we are in menu group mode
    if(currentMenuCategory && currentMenuCategory.menugroups && menugroupId) {
      currentMenuGroup = currentMenuCategory.menugroups[0];
    }

    const restaurantCollectionId = queryString.parse(this.props.location.search).rcid;
    const queryStringParamMode = queryString.parse(this.props.location.search).mode
    const queryStringParamLocationNumber = queryString.parse(this.props.location.search).locationnumber
    const queryStringParamGroupCode = queryString.parse(this.props.location.search).groupcode
    const deliveryMethod = this.props.match.params.deliverymethod;

    if(restaurantCollectionId) {
      dispatch(navigateToNewPage(
        currentMenuCategory && strTitleToDisplay === ''? 
          (currentMenuGroup? currentMenuGroup.name_i18n[intl.locale] : currentMenuCategory.name_i18n[intl.locale]) : strTitleToDisplay,
        `/app/${deliveryMethod}/restaurant/${params.restaurantid}/menu/${params.menuid}#${paramsInQueryString.hashkey}?${menugroupId ? 
          'menugroupid=' + menugroupId : ''/*'menucategoryid=' + menucategoryId*/ }&ts=${new Date().getTime()}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}${queryStringParamLocationNumber ? '&locationnumber=' + queryStringParamLocationNumber : ''}&rcid=${restaurantCollectionId}`));  
    } else {
      dispatch(navigateToNewPage(
        currentMenuCategory && strTitleToDisplay === ''? 
          (currentMenuGroup? currentMenuGroup.name_i18n[intl.locale] : currentMenuCategory.name_i18n[intl.locale]) : strTitleToDisplay,
        `/app/${deliveryMethod}/restaurant/${params.restaurantid}/menu/${params.menuid}#${paramsInQueryString.hashkey}?${menugroupId ? 'menugroupid=' + menugroupId : ''/*'menucategoryid=' + menucategoryId*/ }&ts=${new Date().getTime()}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}${queryStringParamLocationNumber ? '&locationnumber=' + queryStringParamLocationNumber : ''}`));  
    }
   }

  componentWillUnmount() {
    this._isMounted = false;

    isScrollToTopRequired = false;
    // put the flag back to false so when the component will be loaded again we will
    // be able to call the server
    isAFetchFromApiInProgress = false;
  }

  toggleTooltip(id, field) {
    const newState = [...this.state[field]];
    newState.fill(false);

    if (!this.state[field][id]) {
      newState[id] = true;
    }

    this._isMounted && this.setState({
      [field]: newState,
    });
  }
  
  refreshMenuItemDetailsFromServer() {
    const { dispatch, restaurantInfo } = this.props;
    // console.log('calling  refreshMenusFromServer => ', restaurantInfo)
    // we put the flag that a call to the server is being done
    const deliveryMethod = this.props.match.params.deliverymethod;
    if(restaurantInfo && !this.props.isFetching) {
      //console.log('calling server to get menus');
      const restaurantId = restaurantInfo.slug;
      const listOfMatchingDeliveryMethods = restaurantInfo.deliverymethods.filter(dm => dm.code === deliveryMethod)
      let currentDeliveryMethod 
      if(listOfMatchingDeliveryMethods.length > 0) {
        currentDeliveryMethod = listOfMatchingDeliveryMethods[0]
        // console.log('currentDeliveryMethod => ', currentDeliveryMethod)
        const menuId = this.props.match.params.menuid;
        const menucategoryId = this.props.match.params.menucategoryid;
        const menugroupId = this.props.match.params.menugroupid;
        const menuitemsgroupId = this.props.match.params.menuitemsgroupid;
        const orderedMenuItemId = this.props.match.params.menuitemid;
        isAFetchFromApiInProgress = true;
        dispatch(getMenuItemDetailsFromServer(restaurantId, menuId, menucategoryId, menugroupId, menuitemsgroupId, orderedMenuItemId, currentDeliveryMethod));
      }
    }
  }

  scrollToTop() {
    //console.log(elementId);
    const el = document.getElementById('breadcrumb-menuitem-details');
    if (el) {
      el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }
  }

  componentDidUpdate(prevProps) {
    if(isScrollToTopRequired) {
      this.scrollToTop();
      isScrollToTopRequired = false;
    }

    const { restaurantMenuItemDetails, restaurantInfo } = this.props;
    // console.log('menu items this.props => ', this.props);
    if(restaurantInfo && !restaurantMenuItemDetails && !isAFetchFromApiInProgress) {
        //console.log('calling server 1');
        isAFetchFromApiInProgress = false;
        this.refreshMenuItemDetailsFromServer();
    }

    const newOrderedMenuItemId = prevProps.match.params.menuitemid;
    const newMenuGroupId = prevProps.match.params.menugroupid;
    const restaurantIdInURL = prevProps.match.params.restaurantid;
    const menuitemsgroupId = prevProps.match.params.menuitemsgroupid;

    let currentOrderedMenuItemId;
    // we check if we are loading a new menu item to refresh the data from the server
    if(restaurantMenuItemDetails && restaurantMenuItemDetails.restaurant) {
      //console.log('this.props.restaurantMenuItemDetails in didUpdate => ', this.props.restaurantMenuItemDetails);
      const currentRestaurant =  restaurantMenuItemDetails.restaurant;
      const currentMenu = currentRestaurant.menus[0];
      const currentMenuCategory = currentMenu.menucategories[0];
      
      if(currentMenuCategory && currentMenuCategory.menugroups && newMenuGroupId) {
        // if we are in menu items group mode
        if(menuitemsgroupId) {
          // menu items group mode
          currentOrderedMenuItemId = currentMenuCategory.menugroups[0].menuitemgroups[0].orderedmenuitems[0].id;
        } else {
          // me group mode
          currentOrderedMenuItemId = currentMenuCategory.menugroups[0].orderedmenuitems[0].id;
        }
      } else {
        // in menu category mode
        // in menu category mode
        if(currentMenuCategory && currentMenuCategory.orderedmenuitems)
          currentOrderedMenuItemId = currentMenuCategory.orderedmenuitems[0].id;
      }
      //console.log('newOrderedMenuItemId => ', newOrderedMenuItemId);
      //console.log('currentOrderedMenuItemId => ', currentOrderedMenuItemId);
      if(restaurantInfo && newOrderedMenuItemId !== currentOrderedMenuItemId &&
        !this.props.isFetching &&
        !prevProps.isFetching &&
        !isAFetchFromApiInProgress) {
        //console.log('new menu item loaded!!');
        isAFetchFromApiInProgress = false;
        this.refreshMenuItemDetailsFromServer();
      }
    }

    if(!this.props.isFetching) {
      // dispatch back button url and page title name
      this.dispatchPageNavigationToHeader();
    } else {
      this.dispatchPageNavigationToHeader('...');
    }


    //console.log('restaurantIdInURL didUpdate => ', restaurantIdInURL);
    // in case the restaurant id is not defined in the url
    if(restaurantIdInURL === "undefined" || restaurantIdInURL === undefined) {
      //console.log('wer are redirecting');
      this.props.history.push('/error');
    }

  }

  enableSubmitButton() {
    //console.log('enabled =>', this.refs.formMenuItem.getModel());
    this.setState({ submitButtonEnabled: true })
  }

  disableSubmitButton() {
    //console.log('disabled =>', this.refs.formMenuItem.getModel());
    this.setState({ submitButtonEnabled: false });
  }

  animateMenuItemToCart(menuItemToAddToCart) {
    const {locale} = this.props.intl;
    // get the elements from the dom
    const cart = $('#alacarteCartButton');
    const item = $('#checkoutButton');
    //console.log('cart => ', cart);
    //console.log('item => ', item);
    const imageWidth = item[0].scrollWidth;
    const imageHeight = item[0].scrollHeight;
    //const imageWidth = item.innerWidth();
    //const imageHeight = item.innerHeight();
    //console.log('w => ', imageWidth);
    //console.log('h => ', imageHeight);
    const cartTopOffset = cart.offset().top - item.offset().top;
    const cartLeftOffset = cart.offset().left - item.offset().left;
    //console.log('Top Offset => ', cartTopOffset);
    //console.log('Left Offset => ', cartLeftOffset);
    const flyingImg = $('<button class="btn btn-primary ' +
                          s.bFlyingImg+'" style="height: '+imageHeight +'px; width: '+ imageWidth+'px;' +
                          ' border: solid 1px #eabf00 !important;">' + menuItemToAddToCart.name_i18n[locale] + '</button>');
    // add flying img to parent of checkout button
    item.parent().append(flyingImg);
    // animate it
    flyingImg.animate({
      bottom: (cartTopOffset * -1) + 10,
      left: cartLeftOffset + 10,
      width: 40,
      height: 40,
      opacity: 0.2,
    }, {
      duration: 700,
      easing: "linear",
      complete: function(){
        flyingImg.remove();
      }
    });
    // change styles attributes
    flyingImg.attr('style', 'font-size: 12px !important; height: '+imageHeight +'px; width: '+ imageWidth+'px;' + 
                            ' border: 1px solid #eabf00 !important;');
  }

  addMenuItemToCart(model) {
    //console.log('model => ', model);
    //console.log('flattened array of addongroupitems => ', JSON.stringify(model.listOfSelectedAddongroupsItems.flat()));

    const { dispatch } = this.props;
    // dispatch the add to cart action
    if(model.listOfSelectedAddongroupsItems) {
      model.menuitemWithSelectedSize.listOfSelectedAddongroupsItems = model.listOfSelectedAddongroupsItems.flat();
    }
    else {
      model.menuitemWithSelectedSize.listOfSelectedAddongroupsItems = [];
    }
    model.menuitemWithSelectedSize.currencyNumberOfDecimals = this.props.restaurantMenuItemDetails && this.props.restaurantMenuItemDetails.restaurant ? this.props.restaurantMenuItemDetails.restaurant.city.country.currency.numberOfDecimals : 2;
    dispatch(addProduct(model.menuitemWithSelectedSize));
    this.animateMenuItemToCart(model.menuitemWithSelectedSize);

    GAEvent("FRONTEND", "Add menu item to cart", "MENUITEM_DETAILS_PAGE_ADD_MENU_ITEM_TO_CART");
  }

  render() {
    const menuId = this.props.match.params.menuid;
    const menucategoryId = this.props.match.params.menucategoryid;
    const menugroupId = this.props.match.params.menugroupid;
    const restaurantId = this.props.match.params.restaurantid;
    const menuitemsgroupId = this.props.match.params.menuitemsgroupid;
    let currentRestaurant, currentMenu, currentMenuCategory, currentMenuGroup, currentMenuItem, currencyForRestaurant;
    const restaurantCollectionId = queryString.parse(this.props.location.search).rcid;
    const queryStringParamMode = queryString.parse(this.props.location.search).mode;
    const queryStringParamLocationNumber = queryString.parse(this.props.location.search).locationnumber;
    const queryStringParamGroupCode = queryString.parse(this.props.location.search).groupcode;
    
    //const isMenuGroupMode = menugroupId && !menuitemsgroupId;
    const isMenuItemsGroupMode = menugroupId && menuitemsgroupId;
    const {locale} = this.props.intl;

    if(this.props.restaurantMenuItemDetails &&
      this.props.restaurantMenuItemDetails.restaurant) {
      //console.log('this.props.restaurantMenuItemDetails in render => ', this.props.restaurantMenuItemDetails);
      currentRestaurant =  this.props.restaurantMenuItemDetails.restaurant;
      //console.log('current Restaurant => ', currentRestaurant)
      currentMenu = currentRestaurant.menus[0];
      currentMenuCategory = currentMenu.menucategories[0];
      currencyForRestaurant = currentRestaurant.city.country.currency;

      // if we are in menu group mode
      if(currentMenuCategory && currentMenuCategory.menugroups && menugroupId) {
        currentMenuGroup = currentMenuCategory.menugroups[0];
        // if in menuitem groups mode
        if (menuitemsgroupId) {
          currentMenuItem = currentMenuCategory.menugroups[0].menuitemgroups[0].orderedmenuitems[0].menuitem;
        } else {
          // menu group mode
          currentMenuItem = currentMenuCategory.menugroups[0].orderedmenuitems[0].menuitem;
        }
      } else {
        // in menu category mode
        if(currentMenuCategory && currentMenuCategory.orderedmenuitems)
          currentMenuItem = currentMenuCategory.orderedmenuitems[0].menuitem;
      }

      if (currentMenuItem)
        currentMenuItem.currencyFormat = currencyForRestaurant.symbol;
    }
    //const {restaurant} = this.props.restaurantMenuItemDetails ? this.props.restaurantMenuItemDetails : null;
    //console.log('restaurant in render => ', restaurant);
    //const menuitemId = this.props.match.params.menuitemid;
    //console.log('currentMenuItem => ', currentMenuItem);
    if (currentMenuItem) {
      currentMenuItem.menuid = menuId;
    }

    const url = this.props.location.search;
    const params = queryString.parse(url);
    const hashkey = params.hashkey;
    const deliveryMethod = this.props.match.params.deliverymethod;
    let deliveryMethodObj = null
    const restaurantInfo = this.props.restaurantInfo;
    //console.log('menuitem details restaurantInfo => ', restaurantInfo)
    if (restaurantInfo) {
      const { deliverymethods } = restaurantInfo;
      const filteredDeliveryMethods = deliverymethods.filter(dm => dm.code === deliveryMethod);
      if (filteredDeliveryMethods.length > 0) {
        deliveryMethodObj = filteredDeliveryMethods[0];
      }
    }

    return (
      <div className={s.productDetails}>
        {this.props.isFetching ? <ProgressIndicator />: null}
        {
          // discount on collect at kiosk orders
        deliveryMethodObj && deliveryMethodObj.code.includes('collectatkiosk') && this.props.restaurantMenuItemDetails &&
        this.props.restaurantMenuItemDetails.restaurant &&
         this.props.restaurantMenuItemDetails.restaurant.discountOnCollectAtKiosk &&
         this.props.restaurantMenuItemDetails.restaurant.discountOnCollectAtKiosk > 0 ?
          (
            <div className={`${s.coupon}`}>
              <div className={`${s.couponContainer}`}>
                  {this.props.restaurantMenuItemDetails.restaurant.discountOnCollectAtKiosk}%&nbsp;
                  <FormattedMessage id="Menuitem.Details.Page.Dineout.Order.Discount.On.Order.Text" 
                                  defaultMessage="off your total purchase" />
              </div>
            </div>
          )
        :
          // discount on dine out orders
          deliveryMethodObj && !deliveryMethodObj.isDineIn && this.props.restaurantMenuItemDetails &&
          this.props.restaurantMenuItemDetails.restaurant &&
          this.props.restaurantMenuItemDetails.restaurant.discountOnDineOutOrders &&
          this.props.restaurantMenuItemDetails.restaurant.discountOnDineOutOrders > 0 ?
            (
              <div className={`${s.coupon}`}>
                <div className={`${s.couponContainer}`}>
                    {this.props.restaurantMenuItemDetails.restaurant.discountOnDineOutOrders}%&nbsp;
                    <FormattedMessage id="Menuitem.Details.Page.Dineout.Order.Discount.On.Order.Text" 
                                    defaultMessage="off your total purchase" />
                </div>
              </div>
            )
          : null
        }
        <Row id='breadcrumb-menuitem-details'>
          <Col lg={9} md={9} sm={7} xs={9} className="d-sm-down-none">
            <Breadcrumb style={{marginTop: 7}}>
              <BreadcrumbItem>{currentRestaurant ? currentRestaurant.name : '............'}</BreadcrumbItem>
              <BreadcrumbItem
                style={locale === 'ar' ? {paddingLeft: 0} : {}}>
                {locale === 'ar' ? (<span>&nbsp;&nbsp;</span>) : ''}
                {<Link style={{color: 'black', textDecoration: 'underline'}} 
                to={
                  restaurantCollectionId ?
                  `/app/${deliveryMethod}/restaurant/${restaurantId}/menus?rcid=${restaurantCollectionId}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}${queryStringParamLocationNumber ? '&locationnumber=' + queryStringParamLocationNumber : ''}`
                  :
                  queryStringParamLocationNumber ? 
                  `/app/${deliveryMethod}/restaurant/${restaurantId}/menus?locationnumber=${queryStringParamLocationNumber}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}` :
                  `/app/${deliveryMethod}/restaurant/${restaurantId}/menus${queryStringParamMode ? '?mode=' + queryStringParamMode : '?'}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`
                }>
                {currentMenu? currentMenu.userLabel_i18n[locale] : '............'}
                </Link>}
              </BreadcrumbItem>
              <BreadcrumbItem>
              {locale === 'ar' ? (<span>&nbsp;&nbsp;</span>) : ''}
              {
                !this.props.isFetching ? <Link style={{color: 'black', textDecoration: 'underline'}} 
                to={
                  restaurantCollectionId ?
                  `/app/${deliveryMethod}/restaurant/${restaurantId}/menu/${menuId}#${hashkey}?${menugroupId ? 'menugroupid=' + menugroupId : 'menucategoryid=' + menucategoryId }&ts=${new Date().getTime()}&rcid=${restaurantCollectionId}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamLocationNumber ? '&locationnumber=' + queryStringParamLocationNumber : ''}`
                  :
                  `/app/${deliveryMethod}/restaurant/${restaurantId}/menu/${menuId}#${hashkey}?${menugroupId ? 'menugroupid=' + menugroupId : 'menucategoryid=' + menucategoryId }&ts=${new Date().getTime()}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamLocationNumber ? '&locationnumber=' + queryStringParamLocationNumber : ''}`
                }>{currentMenuCategory ? (currentMenuGroup? currentMenuGroup.name_i18n[locale] : currentMenuCategory.name_i18n[locale]) : null}</Link> : "............" 
              }
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col lg={3} md={3} sm={5} xs={3} className={locale === 'ar' ? "d-sm-down-none" : "d-sm-down-none text-right"}>
            <span>
              <ButtonGroup id="buttonRefreshDataFromServer">
                <Button onClick={() => {
                  isAFetchFromApiInProgress = true;
                  this.refreshMenuItemDetailsFromServer();
                  GAEvent("FRONTEND", "Refresh data from server", "MENUITEM_DETAILS_PAGE_REFRESH_DATA_FROM_SERVER");
                }} color="default"><i className="la la-refresh"></i></Button>
              </ButtonGroup>
              <Tooltip placement="top" isOpen={this.state.tooltipRefreshDataFromServer[0]} toggle={() => this.toggleTooltip(0, 'tooltipRefreshDataFromServer')} 
                target={"buttonRefreshDataFromServer"}>
                <FormattedMessage id="MenuItem.details.button.refresh.data.from.server" defaultMessage="Refresh dish information" />
              </Tooltip>
            </span>
          </Col>
        </Row>
        {
          currentMenuItem && !this.props.isFetching ? 
            <Banner 
              customAllergenIcons={
                this.props.restaurantMenuItemDetails &&
                this.props.restaurantMenuItemDetails.restaurant ? 
                  this.props.restaurantMenuItemDetails.restaurant.customAllergenIcons : null
              }   
              customFoodtypeIcons={
                this.props.restaurantMenuItemDetails &&
                this.props.restaurantMenuItemDetails.restaurant ? 
                  this.props.restaurantMenuItemDetails.restaurant.customFoodtypeIcons : null
              }        
              menuItem={currentMenuItem} locale={locale} /> 
              : null
        }
        <Formsy ref="formMenuItem"
                onValidSubmit={this.addMenuItemToCart} 
                onValid={this.enableSubmitButton} 
                onInvalid={this.disableSubmitButton}>
          {/* Menu item sizes */}
          {currentMenuItem && !this.props.isFetching ? 
            <PriceTable name="menuitemWithSelectedSize"
                        currentDeliveryMethod={deliveryMethod}
                        required
                        menuitem={currentMenuItem}
                        isShowPrice= {!isMenuItemsGroupMode} 
                        currency={currencyForRestaurant} 
                        locale={locale} /> : null}
          {/* Add on groups */}
          {
            currentMenuItem && !this.props.isFetching &&
            currentMenuItem.addongroups.length > 0 ? 
              currentMenuItem.addongroups.map((addongroup, index) => {
                if (addongroup.addonitems && addongroup.addonitems.length > 0) {
                  // console.log(addongroup);
                  return (
                    <AddongroupItemsTable 
                      key={"addongroupitemstablecell-" + addongroup.id}
                      name={"listOfSelectedAddongroupsItems[" + index + "]"}
                      required={ addongroup.isMandatory ? true : false }
                      addongroup={addongroup}
                      currency={currencyForRestaurant}
                      locale={locale}
                      customAllergenIcons={
                        this.props.restaurantMenuItemDetails &&
                        this.props.restaurantMenuItemDetails.restaurant ? 
                          this.props.restaurantMenuItemDetails.restaurant.customAllergenIcons : null
                      }   
                      customFoodtypeIcons={
                        this.props.restaurantMenuItemDetails &&
                        this.props.restaurantMenuItemDetails.restaurant ? 
                          this.props.restaurantMenuItemDetails.restaurant.customFoodtypeIcons : null
                      }  
                    />
                  );
                } else {
                  return "";
                }
              })
            : null
          }
          {/* Add to cart button */}
          {
            deliveryMethodObj && 
            deliveryMethodObj.code !== 'viewonly' &&
            deliveryMethodObj.code !== 'spaviewonly' &&
            deliveryMethodObj.code !== 'servicerequestvo' &&
            queryStringParamMode !== 'website' ? (
              <div>
                <Button id="checkoutButton"
                        type="submit"
                        //style={{display: this.state.submitButtonEnabled ? 'block' : 'none'}}
                        disabled={!this.state.submitButtonEnabled}
                        color="primary">
                  {
                    this.state.submitButtonEnabled ?
                    <FormattedMessage id="MenuItem.details.button.add.to.cart.label" defaultMessage="Add to cart" />
                    : 
                    <FormattedMessage id="MenuItem.details.button.select.options.label" defaultMessage="Select options" />
                  }
                </Button>
              </div>
            ) : null
          }
        </Formsy>
      </div >
    );
  }
}

function mapStateToProps(store) {
  return {
    isFetching: store.menuitem.isFetching,
    errorMessage: store.menuitem.errorMessage,
    restaurantMenuItemDetails: store.menuitem.restaurantMenuItemDetails,
    restaurantInfo: store.restaurant.restaurantInfo,
    isFetchingRestaurantInfo: store.restaurant.isFetching
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(MenuItem)));
