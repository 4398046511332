import React from 'react';
import PropTypes from 'prop-types';
//`import { Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getMenusFromServer } from '../../actions/menu';
import { navigateToNewPage } from '../../actions/navigation';
import {
  Button,
  ButtonGroup,
  Breadcrumb,
  BreadcrumbItem,
  Badge,
  Tooltip,
  Row,
  Col
} from 'reactstrap';
import {
  loadCart
} from "../../actions/cart";
import queryString from 'query-string';
import { FormattedMessage, injectIntl } from 'react-intl'; 
import ProgressIndicator from '../../components/ProgressIndicator';
import { GAEvent } from "../../components/Tracking/Tracking";

import CarouselMenu from '../../components/CarouselMenu/CarouselMenu';

import s from './Menus.module.scss';

var moment = require('moment-timezone');

// Not best practice but this flag has been added to avoid a double call to the server
// looking at the isFetching property connected to the reducer which is not fast enougth
// changing value when a call to the backend is sent in the didupdate function and end
// up with another useless call to the server before the isFetching property is updated
// by default this flag is at false indicating that no call has been made to the server
// as soon as a call is made then it is put as true and only back to false when the
// component is unmounted
let isAFetchFromApiInProgress = false;

let isScrollToTopRequired = false;

class Menus extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      tooltipRefreshDataFromServer: [false],
    };

    this.refreshMenusFromServer = this.refreshMenusFromServer.bind(this);
    this.getActiveAndServedNowMenus = this.getActiveAndServedNowMenus.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.dispatchPageNavigationToHeader = this.dispatchPageNavigationToHeader.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    isScrollToTopRequired = true;

    this.dispatchPageNavigationToHeader();
    //console.log('this.props.restaurantWithMenus in componentDidMount => ', this.props.restaurantWithMenus);
    //console.log('this.props.isFetching in componentDidMount => ', this.props.isFetching);
    //console.log('isAFetchFromApiInProgress => ', isAFetchFromApiInProgress);
    if(this.props.restaurantInfo && this.props.restaurantWithMenus === undefined && !this.props.isFetching && !isAFetchFromApiInProgress) {
      this.refreshMenusFromServer();
    }

    const restaurantCollectionId = queryString.parse(this.props.location.search).rcid;

    if(!restaurantCollectionId) {
      // Remove localStorage items with a pattern in their name
      Object.keys(localStorage).forEach(key => {
        if (key.startsWith('restaurantCollection-slug-')) {
          localStorage.removeItem(key);
        }
      });
    } else {
      localStorage.setItem(`restaurantCollection-slug-${restaurantCollectionId}`, restaurantCollectionId);
    }
  }

  dispatchPageNavigationToHeader() {
    // dispatch back button url and page title name
    const { dispatch, intl } = this.props;
    /*dispatch(navigateToNewPage(
      this.props.intl.formatMessage(
      { id: "Menus.page.title.for.header.bar", 
        defaultMessage: 'Menus'}), 
    '/app/restaurants'));*/
    const restaurantCollectionId = queryString.parse(this.props.location.search).rcid;
    const queryStringParamMode = queryString.parse(this.props.location.search).mode;
    const queryStringParamLocationNumber = queryString.parse(this.props.location.search).locationnumber;
    const queryStringParamGroupCode = queryString.parse(this.props.location.search).groupcode;
    const deliveryMethod = this.props.match.params.deliverymethod;
    const pageTitle = deliveryMethod === 'servicerequest' ? 'Services' : 'Menus'
    const idForHeaderTitleTranslation = 
      deliveryMethod === 'servicerequest' ? "Menus.page.title.services.for.header.bar" : "Menus.page.title.for.header.bar"
    if(restaurantCollectionId) {
      if (queryStringParamLocationNumber) {
        dispatch(navigateToNewPage(
          intl.formatMessage(
            { id: idForHeaderTitleTranslation, 
              defaultMessage: pageTitle}),  
        `/app/dineout/restaurantcollection/${restaurantCollectionId}${queryStringParamLocationNumber ? '?locationnumber=' + queryStringParamLocationNumber : ''}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`));
      } else {
        dispatch(navigateToNewPage(
          intl.formatMessage(
            { id: idForHeaderTitleTranslation, 
              defaultMessage: pageTitle}), 
        `/app/dineout/restaurantcollection/${restaurantCollectionId}${queryStringParamMode ? '?mode=' + queryStringParamMode : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`));
      }
    } else {
      dispatch(navigateToNewPage(
        intl.formatMessage(
        { id: idForHeaderTitleTranslation, 
          defaultMessage: pageTitle}), 
      ''));
    }
  }

  componentDidUpdate(prevProps) {

    if(isScrollToTopRequired) {
      this.scrollToTop();
      isScrollToTopRequired = false;
    }

    this.dispatchPageNavigationToHeader();

    // console.log('isAFetchFromApiInProgress => ', isAFetchFromApiInProgress)

    if(this.props.restaurantWithMenus === undefined &&
      prevProps.restaurantWithMenus === undefined &&
       !this.props.isFetching && !isAFetchFromApiInProgress) {
        //console.log('calling server to get menus from component did update');
        //console.log('çalling server 1');
        // isAFetchFromApiInProgress = false;
        this.refreshMenusFromServer();
       }

    // console.log('this.props.match.params.restaurantid => ', this.props.match.params.restaurantid)
    // console.log('prevProps.restaurantWithMenus.id => ', prevProps.restaurantWithMenus ? prevProps.restaurantWithMenus.slug : null)

    // if we are loading another restaurant - checking id of the current one
    // mentioned in url and the previously stored one in the restaurantWithMenus
    if(prevProps.restaurantWithMenus && 
      this.props.match.params.restaurantid !== prevProps.restaurantWithMenus.slug &&
      !this.props.isFetching && !isAFetchFromApiInProgress) {
      //console.log('çalling server 2');
      // isAFetchFromApiInProgress = false;
      this.refreshMenusFromServer();
    }

    /* console.log('this.props.restaurantWithMenus => ', this.props.restaurantWithMenus)
    console.log('this.props.isFetchingRestaurantInfo => ', this.props.isFetchingRestaurantInfo) */
    // console.log('this.props.restaurantInfo => ', this.props.restaurantInfo)

    // console.log('prevPros.restaurantInfo => ', prevProps.restaurantInfo)
    if(this.props.restaurantInfo && this.props.restaurantWithMenus === undefined && !this.props.isFetching && !isAFetchFromApiInProgress) {
      // isAFetchFromApiInProgress = false;
      //console.log('çalling server 3');
      this.refreshMenusFromServer();
    }

    if(this.props.restaurantInfo && !prevProps.restaurantInfo && !this.props.isFetching) {
      // isAFetchFromApiInProgress = false;
      //console.log('çalling server 4');
      this.refreshMenusFromServer();
    }

    if(prevProps.restaurantInfo && this.props.restaurantInfo &&
      prevProps.restaurantInfo.id !== this.props.restaurantInfo.id && !this.props.isFetching) {
        // isAFetchFromApiInProgress = false;
        //console.log('çalling server 5');
        this.refreshMenusFromServer();
    }

    if(this.props.websocketmessage && !prevProps.websocketmessage && !this.props.isFetching) {
      // console.log('call 1 - this.props.websocketmessage => ', this.props.websocketmessage);
      isAFetchFromApiInProgress = false;
      this.refreshMenusFromServer();
    }

    if(prevProps.websocketmessage && this.props.websocketmessage &&
      prevProps.websocketmessage.uuid !== this.props.websocketmessage.uuid && !this.props.isFetching) {
        // console.log('call 2 - this.props.websocketmessage => ', this.props.websocketmessage);
        isAFetchFromApiInProgress = false;
        this.refreshMenusFromServer();
      }
  }

  componentWillUnmount() {
    isAFetchFromApiInProgress = false;
    this._isMounted = false;
    isScrollToTopRequired = false;
  }

  scrollToTop() {
    //console.log(elementId);
    const el = document.getElementById('breadcrumb-menus-list');
    if (el) {
      el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }
  }

  refreshMenusFromServer() {
    const { dispatch, restaurantInfo } = this.props;
    // console.log('calling  refreshMenusFromServer => ', restaurantInfo)
    // we put the flag that a call to the server is being done
    const deliveryMethod = this.props.match.params.deliverymethod;
    if(restaurantInfo && !this.props.isFetching && !isAFetchFromApiInProgress) {
      isAFetchFromApiInProgress = true;
      const restaurantId  = restaurantInfo.slug;
      const listOfMatchingDeliveryMethods = restaurantInfo.deliverymethods.filter(dm => dm.code === deliveryMethod)
      let currentDeliveryMethod 
      if(listOfMatchingDeliveryMethods.length > 0) {
        currentDeliveryMethod = listOfMatchingDeliveryMethods[0]
        // console.log('currentDeliveryMethod => ', currentDeliveryMethod)
        //console.log('refreshing list of menus 1 => ', restaurantId)
        const restaurantSlugInURL = this.props.match.params.restaurantid
        // we check if they are different as this might happen that we are calling the menus
        // when the call for the restaurant info has not finished yet so we can always
        // rely on the restaurant slug in the url
        if (restaurantId !== restaurantSlugInURL) {
          dispatch(getMenusFromServer(restaurantSlugInURL, currentDeliveryMethod));
        } else {
          dispatch(getMenusFromServer(restaurantId, currentDeliveryMethod));
        }
      }
    }
  }

  toggleTooltip(id, field) {
    const newState = [...this.state[field]];
    newState.fill(false);

    if (!this.state[field][id]) {
      newState[id] = true;
    }

    this._isMounted && this.setState({
      [field]: newState,
    });
  }

  getActiveAndServedNowMenus() {
    const arrayOfMenus = this.props.restaurantWithMenus ? this.props.restaurantWithMenus.menus : null;
    // console.log('arrayOfMenus => ', arrayOfMenus)
    if (arrayOfMenus) {
      const restaurantTimezone = this.props.restaurantWithMenus ? this.props.restaurantWithMenus.city.momentJsTimezoneCode : null;
      // console.log('restaurantTimezone => ', restaurantTimezone)
        // only show active menus and also only menus available now (their availability
        // timeframe crosses with now)
        const currentLocationHour = parseInt(moment().tz(restaurantTimezone).format('HH'), 10);
        const currentLocationMinutes =  parseInt(moment().tz(restaurantTimezone).format('mm'), 10);
      
      return arrayOfMenus.filter((menu) => {
        const { isAlwaysVisible, availabilityTimeslots } = menu

        if (isAlwaysVisible)
         return menu.isActive;

        let isCurrentMenuAvailableNow = false;

        for (const availabilityTimeslot of availabilityTimeslots) {
          const menuStarttimeHour = availabilityTimeslot.startTimeInHours;
          const menuStarttimeMinutes = availabilityTimeslot.startTimeInMinutes;
          const menuEndtimeHour = availabilityTimeslot.endTimeInHours;
          const menuEndtimeMinutes = availabilityTimeslot.endTimeInMinutes;
          const { isAvailabilityGoingOverNextDay } = availabilityTimeslot
          /*console.log('availabilityTimeslot => ', availabilityTimeslot)
          console.log('getActiveAndServedNowMenus menu is going to next day => ', menu);
          console.log('menuStarttimeHour => ', menuStarttimeHour)
          console.log('menuStarttimeMinutes => ', menuStarttimeMinutes)
          console.log('menuEndtimeHour => ', menuEndtimeHour)
          console.log('menuEndtimeMinutes => ', menuEndtimeMinutes)
          console.log('currentLocationHour => ', currentLocationHour)
          console.log('currentLocationMinutes => ', currentLocationMinutes)*/
          // if we are in availability over the next day mode
          if (isAvailabilityGoingOverNextDay && !isAlwaysVisible) {
            // if we are in the usual mode like from 14:30 to 06:00+1
            // but it is 01:30 then all goes as usual
            if (currentLocationHour < menuEndtimeHour && currentLocationHour < menuStarttimeHour) {
              isCurrentMenuAvailableNow = true;
              break;
            } else {
              if (currentLocationHour === menuEndtimeHour && currentLocationMinutes <= menuEndtimeMinutes) {
                isCurrentMenuAvailableNow = true;
                break;
              } else {
                // if it is 22:00 then startime in bigger than end time
                if(currentLocationHour > menuStarttimeHour && currentLocationHour > menuEndtimeHour) {
                  isCurrentMenuAvailableNow = true;
                  break;
                } else {
                  if (currentLocationHour === menuStarttimeHour &&
                    currentLocationMinutes >= menuStarttimeMinutes && currentLocationHour > menuEndtimeHour) {
                    isCurrentMenuAvailableNow = true;
                    break;
                  }
                }   
              }
            }
          } else {
            if (!isAlwaysVisible) {
              console.log(currentLocationHour + ':'+ currentLocationMinutes);
              if(currentLocationHour > menuStarttimeHour && 
                (currentLocationHour < menuEndtimeHour || (currentLocationHour === menuEndtimeHour && currentLocationMinutes <= menuEndtimeMinutes))) {
                isCurrentMenuAvailableNow = true;
                break;
              } else {
                if (currentLocationHour === menuStarttimeHour &&
                  currentLocationMinutes >= menuStarttimeMinutes && 
                  (currentLocationHour < menuEndtimeHour || (currentLocationHour === menuEndtimeHour && currentLocationMinutes <= menuEndtimeMinutes))) {
                  isCurrentMenuAvailableNow = true;
                  break;
                }
              }
            }
          }
        }
        return menu.isActive && isCurrentMenuAvailableNow
      });
    } else {
      return null;
    }
  }

  render() {

    const name = this.props.restaurantWithMenus ? this.props.restaurantWithMenus.name : undefined;
    const restaurantId = this.props.match.params.restaurantid;
    const menusThatAreActiveAndThatAreServedNow = this.getActiveAndServedNowMenus();
    const restaurantCollectionId = queryString.parse(this.props.location.search).rcid;
    const deliveryMethod = this.props.match.params.deliverymethod;
    let deliveryMethodObj = null
    const lastOrder = JSON.parse(localStorage.getItem(`lastOrder-${deliveryMethod}-${restaurantId}`));
    const { dispatch, intl } = this.props;
    const { locale } = intl
    const { restaurantInfo } = this.props;
    let restaurantInfoSlug = null
    const queryStringParamMode = queryString.parse(this.props.location.search).mode
    const queryStringParamLocationNumber = queryString.parse(this.props.location.search).locationnumber
    const queryStringParamGroupCode = queryString.parse(this.props.location.search).groupcode
    if (restaurantInfo) {
      restaurantInfoSlug = restaurantInfo.slug;
      const { deliverymethods } = restaurantInfo;
      const filteredDeliveryMethods = deliverymethods.filter(dm => dm.code === deliveryMethod);
      if (filteredDeliveryMethods.length > 0) {
        deliveryMethodObj = filteredDeliveryMethods[0];
      }
    }

    return (
      <div className={s.root}>
        <Row id='breadcrumb-menus-list'>
          <Col xs={8} className="d-sm-down-none">
            <Breadcrumb>
              <BreadcrumbItem>{this.props.isFetching ? '............' : name}</BreadcrumbItem>
              <BreadcrumbItem active>
                {locale === 'ar' ? (<span>&nbsp;&nbsp;</span>) : ''}
                <FormattedMessage id="Menus.Breadcrumbitem.Menus" defaultMessage="Menus" />&nbsp;&nbsp;
                {menusThatAreActiveAndThatAreServedNow && menusThatAreActiveAndThatAreServedNow.length > 0 ? <Badge color="primary">{menusThatAreActiveAndThatAreServedNow.length}</Badge> : null}
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col className={locale === 'ar' ? "d-sm-down-none" : "d-sm-down-none text-right"}>
            <div className={s.galleryControls} style={locale === 'ar' ? {display: 'block', position: 'initial'} : {}}>
              <ButtonGroup id="buttonRefreshDataFromServer">
                <Button onClick={() => {
                  this.refreshMenusFromServer(true);
                  GAEvent("FRONTEND", "Refresh data from server", "MENUS_PAGE_REFRESH_DATA_FROM_SERVER");
                }} color="default"><i className="la la-refresh"></i></Button>
              </ButtonGroup>
              <Tooltip placement="top" isOpen={this.state.tooltipRefreshDataFromServer[0]} toggle={() => this.toggleTooltip(0, 'tooltipRefreshDataFromServer')} 
                target={"buttonRefreshDataFromServer"}>
                <FormattedMessage id="Menus.button.refresh.data.from.server" defaultMessage="Refresh list of menus" />
              </Tooltip>
            </div>
          </Col>
        </Row>
        {this.props.isFetching ? <ProgressIndicator /> : null}
        {menusThatAreActiveAndThatAreServedNow && menusThatAreActiveAndThatAreServedNow.length === 0 ?
          <span><FormattedMessage id="Menus.Page.No.Menus.Available" 
                              defaultMessage="This outlet is currently closed" />.</span> 
        : null}
        {
          // discount on collect at kiosk orders
          deliveryMethodObj && deliveryMethodObj.code.includes('collectatkiosk') && this.props.restaurantWithMenus &&
          this.props.restaurantWithMenus.discountOnCollectAtKiosk &&
          this.props.restaurantWithMenus.menus.length > 0 &&
          this.props.restaurantWithMenus.discountOnCollectAtKiosk > 0 ?
            (
              <div className={`${s.coupon}`}>
                <div className={`${s.couponContainer}`}>
                    {this.props.restaurantWithMenus.discountOnCollectAtKiosk}%&nbsp;
                    <FormattedMessage id="Menus.Page.Dineout.Order.Discount.On.Order.Text" 
                                    defaultMessage="off your total purchase" />
                </div>
              </div>
            )
        :
          // discount on dine out orders
          deliveryMethodObj && !deliveryMethodObj.isDineIn && this.props.restaurantWithMenus &&
          this.props.restaurantWithMenus.discountOnDineOutOrders &&
          this.props.restaurantWithMenus.menus.length > 0 &&
          this.props.restaurantWithMenus.discountOnDineOutOrders > 0 ?
          (
            <div className={`${s.coupon}`}>
              <div className={`${s.couponContainer}`}>
                  {this.props.restaurantWithMenus.discountOnDineOutOrders}%&nbsp;
                  <FormattedMessage id="Menus.Page.Dineout.Order.Discount.On.Order.Text" 
                                  defaultMessage="off your total purchase" />
              </div>
            </div>
          ) : null
         }
        {
          // there is a last order and it has not expired
          lastOrder && new Date(lastOrder.expirationDate) > new Date() && 
          this.props.restaurantWithMenus && this.props.restaurantWithMenus.menus.length > 0 && 
          this.props.restaurantWithMenus.isRepeatLastOrderAllowed ? (
            <div 
              className={`${s.buttonRepeatLastOrder}`}
              onClick={() => {
              dispatch(loadCart(lastOrder.cartProducts));
              GAEvent("FRONTEND", "Repeat your order", "MENUS_PAGE_REPEAT_YOUR_ORDER");
            }}>
              <FormattedMessage id="Menus.Page.Repeat.Last.Order.Button.Label" 
                              defaultMessage="Repeat last order" />
            </div>
          ) : null
        }
        <div className={s.gallery}>
          {
            restaurantInfoSlug === restaurantId && menusThatAreActiveAndThatAreServedNow && menusThatAreActiveAndThatAreServedNow.length > 0 ?
            menusThatAreActiveAndThatAreServedNow.map((menu, index) => {
              const key = menu.userLabel_i18n[locale] + index;
              return (
                <div key={key} className={`${s.picture} card`}>
                 <a href={
                    restaurantCollectionId ?
                    `#/app/${deliveryMethod}/restaurant/${restaurantId}/menu/${menu.id}?rcid=${restaurantCollectionId}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamLocationNumber ? '&locationnumber=' + queryStringParamLocationNumber : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`
                    :
                    queryStringParamLocationNumber ? 
                    `#/app/${deliveryMethod}/restaurant/${restaurantId}/menu/${menu.id}?locationnumber=${queryStringParamLocationNumber}${queryStringParamMode ? '?mode=' + queryStringParamMode : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}` :
                    `#/app/${deliveryMethod}/restaurant/${restaurantId}/menu/${menu.id}${queryStringParamMode ? '?mode=' + queryStringParamMode : '?'}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`
                   }>
                    <CarouselMenu menu={menu}/>
                    <div className={s.description}>
                      <h5 className="mt-0 mb-xs fw-semi-bold" style={{textAlign: locale === 'ar' ? 'right' : 'left'}}>
                        {menu.userLabel_i18n[locale]}
                      </h5>
                      {
                        menu.isDisplayAvailabilityTimes ? (
                          menu.availabilityDescription_i18n ? (
                            <span className="text-muted">
                              <span 
                                className="fw-semi-bold"
                                dangerouslySetInnerHTML={{__html: menu.availabilityDescription_i18n[this.props.intl.locale]}}>
                              </span>
                            </span>
                          ) : (
                            <span className="text-muted"><FormattedMessage id="Menus.Menu.Card.Available.From" defaultMessage="Available from" /><span className="fw-semi-bold"> {menu.startTimeInHours}:{menu.startTimeInMinutes === 0 ? "00" : menu.startTimeInMinutes} <FormattedMessage id="Menus.Menu.Card.Available.To" defaultMessage="to" /> {menu.endTimeInHours}:{menu.endTimeInMinutes === 0 ? "00" : menu.endTimeInMinutes} </span></span>
                          )
                        ) : null
                      }
                    </div>
                  </a>
                </div>
              );
            }) : null
          }
        </div>
      </div>);
  } // enf of render
} // end of class

function mapStateToProps(store) {
  return {
    isFetching: store.menu.isFetching,
    errorMessage: store.menu.errorMessage,
    restaurantWithMenus: store.menu.restaurantWithMenus,
    restaurantInfo: store.restaurant.restaurantInfo,
    isFetchingRestaurantInfo: store.restaurant.isFetching,
    websocketmessage: store.websocket.messageObject
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(Menus)));
