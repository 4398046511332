exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/**\n * Customized version of bootstrap using variables from _variables.scss.\n * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.\n */\n/*\n * Typography\n * ======================================================================== */\n/**\n * Custom application mixins available through out the app\n */\n/*html, body {\n    max-width: 100%;\n    overflow-x: hidden;\n    -webkit-overflow-scrolling: touch;\n}*/\n.Layout_root__KuT-s {\n  height: 100%;\n  position: relative;\n  left: 0; }\n\n.Layout_wrap__3Y4cM {\n  position: relative;\n  min-height: 100vh;\n  display: flex;\n  margin-left: 0px;\n  flex-direction: column;\n  left: 0;\n  right: 0;\n  transition: left 0.3s ease-in-out; }\n\n.Layout_content__3nMgS {\n  position: relative;\n  flex-grow: 1;\n  padding-top: 75px !important;\n  padding: 40px 40px 60px;\n  background-color: #f9fbfd; }\n  @media (max-width: 767.98px) {\n    .Layout_content__3nMgS {\n      padding: 20px 15px; } }\n\n.Layout_contentFooter__3etPF {\n  position: absolute;\n  bottom: -20px;\n  right: 10px;\n  color: #798892;\n  text-align: center;\n  width: 100%; }\n", ""]);

// exports
exports.locals = {
	"root": "Layout_root__KuT-s",
	"wrap": "Layout_wrap__3Y4cM",
	"content": "Layout_content__3nMgS",
	"contentFooter": "Layout_contentFooter__3etPF"
};