import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import { connect } from "react-redux";
import {
  addProduct,
  updateCart,
  removeProduct,
  loadCart,
  resetCart,
  submitOrderToKitchen,
  addNoteToProduct,
} from "../../actions/cart";
import CartProduct from "./components/CartProduct";
import CheckoutPopup from "./components/CheckoutPopup";
import TaxRate from "./components/TaxRate";
import queryString from 'query-string';

import s from "./FloatCart.module.scss";
import { FormattedMessage, injectIntl } from "react-intl";
import DeleteAllIcon from "./assets/delete-all-icon-black.png";

import ProgressIndicator from "../../components/ProgressIndicator";
import HeaderMessage from "./components/HeaderMessage/HeaderMessage"
import { GAEvent } from "../../components/Tracking/Tracking";

import { showSuccessMessage } from "../../components/ToastMessenger";
var md5 = require("md5");
const { v4: uuidv4 } = require('uuid');

const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const round_up = (x,nd) => {
  /*var rup=Math.pow(10,nd);
  var rdwn=Math.pow(10,-nd); // Or you can just use 1/rup
  const result = (Math.round(x*rup)*rdwn).toFixed(nd)*/
  const result = (Math.ceil(x * 100) / 100).toFixed(nd);
  return result
}

class FloatCart extends Component {
  static propTypes = {
    loadCart: PropTypes.func.isRequired,
    updateCart: PropTypes.func.isRequired,
    cartProducts: PropTypes.array.isRequired,
    newProduct: PropTypes.object,
    removeProduct: PropTypes.func,
    productToRemove: PropTypes.object,
    appDeliveryMethod: PropTypes.string.isRequired,
    isCommentAllowedAtCheckout: PropTypes.bool.isRequired
  };

  state = {
    isOpen: false,
    glowMeFlag: false,
    restaurantCurrency: "",
    showCheckoutPopup: false,
    isVatIncludedInPrice: undefined
  };

  componentDidMount() {
    const { appDeliveryMethod } = this.props
    const restaurantSlug = this.retrieveRestaurantIdFromUrl()
    // Check if we're in restaurant collection mode
    const urlParams = new URLSearchParams(window.location.hash.split('?')[1] || '');
    const rcid = urlParams.get('rcid');
    //console.log('rcid => ', rcid);
    let cartname;
    if (rcid) {
      // Get the restaurantCollection-type from localStorage
      const restaurantCollectionType = localStorage.getItem(`restaurantCollection-type-${rcid}`);

      // Determine the cart name based on the restaurantCollection-type
      if (restaurantCollectionType === 'marketplace_combined_checkouts') {
        cartname = `cart-${rcid}`;
      } else {
        cartname = `cart-${rcid}-${appDeliveryMethod}-${restaurantSlug}`;
      }
    } else {
      // If not in restaurant collection mode, use the original cart name
      cartname = `cart-${appDeliveryMethod}-${restaurantSlug}`;
    }
    const cartInLocalStorage = JSON.parse(localStorage.getItem(cartname));
    //console.log('cartInLocalStorage => ', cartInLocalStorage);
    const today = new Date();
    if (
      cartInLocalStorage &&
      cartInLocalStorage.cartProducts &&
      cartInLocalStorage.cartProducts.length > 0 &&
      new Date(cartInLocalStorage.expirationDate) > today
    ) {
      //console.log('loading cart content from local storage');
      this.props.loadCart(cartInLocalStorage.cartProducts);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.newProduct) {
      this.addProduct(nextProps.newProduct);
      // if the number of items in the cart changed we then reset the state
      // of the glow me flag to false after the animation is done so we can
      // reinitiate the animation every time we add or remove an item from the cart
      this.setState({ glowMeFlag: true }, () => {
        setTimeout(() => {
          this.setState({ glowMeFlag: false });
        }, 800);
      });
    }

    //console.log('nextProps.productToUpdateWithNote => ', nextProps.productToUpdateWithNote);
    //console.log('this.props.productToUpdateWithNote => ', this.props.productToUpdateWithNote);

    if (
      nextProps.productToUpdateWithNote &&
      this.props.productToUpdateWithNote
    ) {
      //console.log(' here 1 ');
      if (
        nextProps.productToUpdateWithNote.id !==
        this.props.productToUpdateWithNote.id
      ) {
        //console.log(' here 2 ');
        this.addNoteToProduct(nextProps.productToUpdateWithNote);
      } else {
        if (
          nextProps.productToUpdateWithNote.note !==
          this.props.productToUpdateWithNote.note
        ) {
          //console.log(' here 3 ');
          this.addNoteToProduct(nextProps.productToUpdateWithNote);
        }
      }
    } else {
      // if this is the first product to which we add a note
      if (
        nextProps.productToUpdateWithNote &&
        !this.props.productToUpdateWithNote
      ) {
        //console.log(' here 0 ');
        this.addNoteToProduct(nextProps.productToUpdateWithNote);
      }
    }

    // console.log('this.props.postedOrder out => ', JSON.stringify(this.props.postedOrder))
    // console.log('nextProps.postedOrder out => ', JSON.stringify(nextProps.postedOrder))

    // if we have successfully posted an order
    if (
      (!this.props.postedOrder && nextProps.postedOrder) ||
      (this.props.postedOrder &&
        nextProps.postedOrder &&
        this.props.postedOrder.id !== nextProps.postedOrder.id)
      || (nextProps.postedOrder && nextProps.postedOrder.urlToPayment !== "" && this.props.postedOrder.uuid !== nextProps.postedOrder.uuid)  
    ) {
      let deliveryMethodsMatchingAppDeliveryMethod
      if (nextProps.restaurantInfo && nextProps.restaurantInfo.deliverymethods)
        deliveryMethodsMatchingAppDeliveryMethod = nextProps.restaurantInfo.deliverymethods.filter(dm => dm.code === nextProps.appDeliveryMethod)
      else {
        if (nextProps.restaurantMenuItems && nextProps.restaurantMenuItems.restaurant && nextProps.restaurantMenuItems.restaurant.deliverymethods)
          deliveryMethodsMatchingAppDeliveryMethod = nextProps.restaurantMenuItems.restaurant.deliverymethods.filter(dm => dm.code === nextProps.appDeliveryMethod)
          else {
            if (nextProps.restaurantWithMenus && nextProps.restaurantWithMenus.deliverymethods)
              deliveryMethodsMatchingAppDeliveryMethod = nextProps.restaurantWithMenus.deliverymethods.filter(dm => dm.code === nextProps.appDeliveryMethod)
          }
      }
      let currentDeliveryMethod
      if (deliveryMethodsMatchingAppDeliveryMethod && deliveryMethodsMatchingAppDeliveryMethod.length > 0)
        currentDeliveryMethod = deliveryMethodsMatchingAppDeliveryMethod[0]

      // console.log('nextProps.postedOrder in => ', JSON.stringify(nextProps.postedOrder))
      // console.log('currentDeliveryMethod in => ', JSON.stringify(currentDeliveryMethod))
      if ((currentDeliveryMethod && currentDeliveryMethod.isDineIn && !currentDeliveryMethod.isWithOnlinePayment) ||
        (currentDeliveryMethod && !currentDeliveryMethod.isDineIn && !currentDeliveryMethod.isCollectAtLocation && !currentDeliveryMethod.isWithOnlinePayment) ||
        currentDeliveryMethod.code.includes('massageatspa') ||
        currentDeliveryMethod.code.includes('servicerequest') ||
        (nextProps.postedOrder.paymentInformation && nextProps.postedOrder.paymentInformation.method)) {
          // show a success message
          if (currentDeliveryMethod.code.includes('massageatspa') || 
              currentDeliveryMethod.code.includes('servicerequest')) {
            showSuccessMessage(
              this.props.intl.formatMessage({
                id: "FloatCart.message.inquiry.posted.successfully",
                defaultMessage:
                  "Your inquiry has been successfully submitted.",
              })
            );
          } else {
            showSuccessMessage(
              this.props.intl.formatMessage({
                id: "FloatCart.message.order.posted.successfully",
                defaultMessage:
                  "Your order has been successfully sent to the kitchen.",
              })
            );
          }
        //console.log('nextProps.postedOrder => ', nextProps.postedOrder)
        // we check if the order needs an invoice generation
        if (nextProps.postedOrder.isInvoiceGenerationEnabled) {
          //console.log('nextProps.postedOrder => ', nextProps.postedOrder);
          const getUrl = window.location;
          const baseUrl = getUrl.protocol + "//" + getUrl.host;
          const redirectUrl = `${baseUrl}/#/app/${nextProps.postedOrder.type}/restaurant/${nextProps.postedOrder.restaurant.slug}/orderstatus/${nextProps.postedOrder.uuid}`;
          window.location.replace(redirectUrl);
        }
        // get the new connection id to place a new order
        const { websocket } = this.props
        websocket.send(JSON.stringify({'message': uuidv4()}));
      } else {
          window.location.replace(nextProps.postedOrder.urlToPayment);
      }

      // and close the cart that has been emptied
      //alert('close cart');
      this.closeFloatCart();
    }

    // in case we loaded the cart from the local storage when the component
    // did mount then we need to update the cart totals
    //console.log('componentWillReceiveProps => ', nextProps);
    if (
      nextProps.cartProducts.length !== nextProps.cartTotal.productQuantity &&
      nextProps.cartTotal.productQuantity === 0
    ) {
      const { appDeliveryMethod } = nextProps
      const restaurantSlug = this.retrieveRestaurantIdFromUrl()
      this.props.updateCart(
        nextProps.cartProducts,
        appDeliveryMethod,
        this.state.isVatIncludedInPrice,
        this.state.vatTaxRates,
        this.state.listOfTaxRatesOtherThanVAT,
        restaurantSlug);
    }

    // if we are resetting the cart
    if (
      nextProps.cartProducts.length === 0 &&
      nextProps.cartTotal.productQuantity > 0
    ) {
      const { appDeliveryMethod } = nextProps
      const restaurantSlug = this.retrieveRestaurantIdFromUrl()
      this.props.updateCart(
        nextProps.cartProducts,
        appDeliveryMethod,
        this.state.isVatIncludedInPrice,
        this.state.vatTaxRates,
        this.state.listOfTaxRatesOtherThanVAT,
        restaurantSlug);
    }

    if(this.props.appDeliveryMethod !== nextProps.appDeliveryMethod) {
      const restaurantSlug = this.retrieveRestaurantIdFromUrl()
      const urlParams = new URLSearchParams(window.location.hash.split('?')[1] || '');
      const rcid = urlParams.get('rcid');
      // get the restaurantCollection-type from localStorage
      const restaurantCollectionType = localStorage.getItem(`restaurantCollection-type-${rcid}`);
      // Check if the previous delivery method is not 'dineout'
      // as dineout is a delivery method that is used for restaurant collection orders
      // and we also reset the cart when we have a restaurant collection that is not combined
      if (this.props.appDeliveryMethod !== 'dineout' || 
        (this.props.appDeliveryMethod === 'dineout' && restaurantCollectionType !== 'marketplace_combined_checkouts')
      ) {
        //console.log("resetting cart floatcart => ", nextProps.appDeliveryMethod, this.props.appDeliveryMethod);
        this.props.resetCart(nextProps.appDeliveryMethod, restaurantSlug);
      } else {
        console.log("Not resetting cart - coming from restaurant collection page");
      }
    }

    //console.log(nextProps);

    if (
      nextProps.restaurantMenuItems &&
      nextProps.restaurantMenuItems.restaurant
    ) {
      if (
        nextProps.restaurantMenuItems.restaurant.city.country.currency.symbol
      ) {
        this.setState({
          restaurantCurrency:
            nextProps.restaurantMenuItems.restaurant.city.country.currency
              .symbol,
        });
      } else {
        if (
          this.props.restaurantMenuItems.restaurant.city.country.currency.symbol
        ) {
          this.setState({
            restaurantCurrency: this.props.restaurantMenuItems.restaurant.city
              .country.currency.symbol,
          });
        }
      }
    } else {
      if (
        nextProps.restaurantWithMenus
      ) {
        if (
          nextProps.restaurantWithMenus.city.country.currency.symbol
        ) {
          this.setState({
            restaurantCurrency:
              nextProps.restaurantWithMenus.city.country.currency
                .symbol
          });
        } else {
          if (
            this.props.restaurantWithMenus.city.country.currency
              .symbol
          ) {
            this.setState({
              restaurantCurrency: this.props.restaurantWithMenus.city
                .country.currency.symbol
            });
          }
        }
      }
    }

    if (nextProps.productToRemove !== this.props.productToRemove) {
      this.removeProduct(nextProps.productToRemove);
    }

    // console.log('this.props.restaurantWithMenus => ', this.props.restaurantWithMenus)

    if (this.props.restaurantWithMenus && this.props.restaurantWithMenus.taxRates && !this.state.isVatIncludedInPrice) {
      const listOfTaxRates = this.props.restaurantWithMenus.taxRates;
      const listOfVatAndActiveTaxRates = listOfTaxRates.filter((taxRate) => taxRate.isActive && taxRate.type === 'vat')
      const listOfTaxRatesOtherThanVAT = listOfTaxRates.filter((taxRate) => taxRate.isActive && taxRate.type !== 'vat')  
      if (listOfVatAndActiveTaxRates && listOfVatAndActiveTaxRates.length > 0) {
        this.setState({
          isVatIncludedInPrice: listOfVatAndActiveTaxRates[0].isIncludedInPrices,
          vatTaxRates: listOfVatAndActiveTaxRates,
          listOfTaxRatesOtherThanVAT
        })
      }
    } else {
      if (this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant && !this.state.isVatIncludedInPrice) {
        const listOfTaxRates = this.props.restaurantMenuItems.restaurant.taxRates;
        const listOfVatAndActiveTaxRates = listOfTaxRates.filter((taxRate) => taxRate.isActive && taxRate.type === 'vat')
        const listOfTaxRatesOtherThanVAT = listOfTaxRates.filter((taxRate) => taxRate.isActive && taxRate.type !== 'vat')  
        if (listOfVatAndActiveTaxRates && listOfVatAndActiveTaxRates.length > 0) {
          this.setState({
            isVatIncludedInPrice: listOfVatAndActiveTaxRates[0].isIncludedInPrices,
            vatTaxRates: listOfVatAndActiveTaxRates,
            listOfTaxRatesOtherThanVAT
          }) 
        }
      } else {
        if (this.props.restaurantInfo && !this.state.isVatIncludedInPrice) {
          const listOfTaxRates = this.props.restaurantInfo.taxRates;
          const listOfVatAndActiveTaxRates = listOfTaxRates.filter((taxRate) => taxRate.isActive && taxRate.type === 'vat')
          const listOfTaxRatesOtherThanVAT = listOfTaxRates.filter((taxRate) => taxRate.isActive && taxRate.type !== 'vat')  
          if (listOfVatAndActiveTaxRates && listOfVatAndActiveTaxRates.length > 0) {
            this.setState({
              isVatIncludedInPrice: listOfVatAndActiveTaxRates[0].isIncludedInPrices,
              vatTaxRates: listOfVatAndActiveTaxRates,
              listOfTaxRatesOtherThanVAT
            }) 
          }
        }
      }
    }
    /* if (nextProps.postedOrder !== this.props.postedOrder) {
      console.log("nextProps.postedOrder => ", nextProps.postedOrder);
    } */
  }

  openFloatCart = () => {
    this.setState({ isOpen: true });
    GAEvent("FRONTEND", "Open float cart", "FLOATCART_OPEN");
  };

  closeFloatCart = () => {
    this.setState({ isOpen: false });
    GAEvent("FRONTEND", "Close float cart", "FLOATCART_CLOSE");
  };

  addNoteToProduct = (productToAddNoteTo) => {
    const { cartProducts, updateCart } = this.props;

    cartProducts.forEach((cp) => {
      if (cp.id === productToAddNoteTo.id) {
        // we need to check if this is a simple product with one size and no addons
        if (
          cp.menuitemsizes &&
          cp.menuitemsizes.length === 1 &&
          productToAddNoteTo.menuitemsizes.length === 1
        ) {
          // we need to check now the selected addon groups if any
          // no addon groups were selected
          if (
            cp.listOfSelectedAddongroupsItems.length === 0 &&
            productToAddNoteTo.listOfSelectedAddongroupsItems.length === 0
          ) {
            cp.note = productToAddNoteTo.note;
          } else {
            if (
              JSON.stringify(cp.listOfSelectedAddongroupsItems) ===
              JSON.stringify(productToAddNoteTo.listOfSelectedAddongroupsItems)
            ) {
              cp.note = productToAddNoteTo.note;
            }
          }
        } else {
          if (
            cp.selectedmenuitemsize &&
            cp.selectedmenuitemsize.id ===
              productToAddNoteTo.selectedmenuitemsize.id
          ) {
            // we need to check now the selected addon groups if any
            // no addon groups were selected
            if (
              cp.listOfSelectedAddongroupsItems.length === 0 &&
              productToAddNoteTo.listOfSelectedAddongroupsItems.length === 0
            ) {
              cp.note = productToAddNoteTo.note;
            } else {
              if (
                JSON.stringify(cp.listOfSelectedAddongroupsItems) ===
                JSON.stringify(
                  productToAddNoteTo.listOfSelectedAddongroupsItems
                )
              ) {
                cp.note = productToAddNoteTo.note;
              }
            }
          } else {
            // adding a note to a menu group
            // we need to check if this is a simple product with one size and no addons
            if (
              cp.listOfSelectedMenuItemsForMenuGroup &&
              productToAddNoteTo.listOfSelectedMenuItemsForMenuGroup &&
              cp.listOfSelectedMenuItemsForMenuGroup.length ===
                productToAddNoteTo.listOfSelectedMenuItemsForMenuGroup.length
            ) {
              // we need to check if we have the same selected menu items for
              // each menu items group
              if (
                cp.listOfSelectedMenuItemsForMenuGroupMD5 ===
                productToAddNoteTo.listOfSelectedMenuItemsForMenuGroupMD5
              ) {
                // this is a shortcut as backend is expecting a note for each
                // selected menu item but for now we will only allow to add
                // a note to the full combo menu and not per menu item as it is
                // easier but in the future we should implement a note per
                // selected menu item within a menu group
                // TODO IMPLEMENT MULTIPLE NOTES FOR COMBO MENU
                //console.log('here we are => ', productToAddNoteTo);
                cp.note = productToAddNoteTo.note;
                cp.listOfSelectedMenuItemsForMenuGroup = productToAddNoteTo.listOfSelectedMenuItemsForMenuGroup.map(
                  (menuItem, index) => {
                    if (
                      index ===
                      productToAddNoteTo.listOfSelectedMenuItemsForMenuGroup
                        .length -
                        1
                    ) {
                      // only the last menu item in the list of selected menu items
                      // will have the user note
                      menuItem.note = productToAddNoteTo.note;
                    } else {
                      menuItem.note = "";
                    }
                    // return the menuitem with the note information
                    return menuItem;
                  }
                );
              }
            }
          }
        }
      }
    });

    GAEvent("FRONTEND", "Add note to product", "FLOATCART_ADD_NOTE_TO_PRODUCT");
    const { appDeliveryMethod } = this.props
    const restaurantSlug = this.retrieveRestaurantIdFromUrl()
    updateCart(cartProducts,
      appDeliveryMethod,
      this.state.isVatIncludedInPrice,
      this.state.vatTaxRates,
      this.state.listOfTaxRatesOtherThanVAT,
      restaurantSlug);
  };

  addProduct = (product) => {
    const { cartProducts, updateCart } = this.props;
    const restaurantSlug = this.retrieveRestaurantIdFromUrl()
    //console.log('clone cart products => ', [...cartProducts]);
    let productAlreadyInCart = false;
    //console.log('product to add to floatcart => ', product);
    // if we have a normal product with different size or addons
    if (!product.listOfSelectedMenuItemsForMenuGroup) {
      // if the product we are adding does not have any quantity field
      // if we are incrementing a product within the floatcart then
      // the product will have a quantity and we should not change it
      if (!product.quantity) product.quantity = 1;

      if (!product.currencyFormat)
        product.currencyFormat = this.state.restaurantCurrency;

      cartProducts.forEach((cp) => {
        if (cp.id === product.id) {
          // we need to check if this is a simple product with one size and no addons
          if (
            cp.menuitemsizes.length === 1 &&
            product.menuitemsizes.length === 1
          ) {
            // we need to check now the selected addon groups if any
            // no addon groups were selected
            if (
              cp.listOfSelectedAddongroupsItems.length === 0 &&
              product.listOfSelectedAddongroupsItems.length === 0
            ) {
              cp.quantity = cp.quantity + product.quantity;
              productAlreadyInCart = true;
            } else {
              // filter new element that is being added by checking if it has addongroups without any addonitems selected
              // which could happen in the case of optional addongroups
              product.listOfSelectedAddongroupsItems = [
                ...product.listOfSelectedAddongroupsItems.filter(
                  (addongroup) => {
                    return addongroup && addongroup.selectedAddons.length > 0;
                  }
                ),
              ];

              //console.table(product.listOfSelectedAddongroupsItems);

              if (
                JSON.stringify(cp.listOfSelectedAddongroupsItems) ===
                JSON.stringify(product.listOfSelectedAddongroupsItems)
              ) {
                cp.quantity = cp.quantity + product.quantity;
                productAlreadyInCart = true;
              }
            }
          } else {
            // product had multiple sizes and one was selected
            if (
              cp.selectedmenuitemsize.id === product.selectedmenuitemsize.id
            ) {
              // we need to check now the selected addon groups if any
              // no addon groups were selected
              if (
                cp.listOfSelectedAddongroupsItems.length === 0 &&
                product.listOfSelectedAddongroupsItems.length === 0
              ) {
                cp.quantity = cp.quantity + product.quantity;
                productAlreadyInCart = true;
              } else {
                // filter new element that is being added by checking if it has addongroups without any addonitems selected
                // which could happen in the case of optional addongroups
                product.listOfSelectedAddongroupsItems = [
                  ...product.listOfSelectedAddongroupsItems.filter(
                    (addongroup) => {
                      return addongroup && addongroup.selectedAddons.length > 0;
                    }
                  ),
                ];
                if (
                  JSON.stringify(cp.listOfSelectedAddongroupsItems) ===
                  JSON.stringify(product.listOfSelectedAddongroupsItems)
                ) {
                  cp.quantity = cp.quantity + product.quantity;
                  productAlreadyInCart = true;
                }
              }
            }
          }
        }
      });

      //console.log('addProduct => ', cartProducts);
      product.restaurantSlug = restaurantSlug;
      product.deliveryMethodCode = this.retrieveDeliveryMethodFromUrl();
      if (!productAlreadyInCart) {
        // removing empty addongroups like optional addongroups
        product.listOfSelectedAddongroupsItems = [
          ...product.listOfSelectedAddongroupsItems.filter((addongroup) => {
            return addongroup && addongroup.selectedAddons.length > 0;
          }),
        ];
        cartProducts.push(Object.assign({}, product));
      }
      const { appDeliveryMethod } = this.props
      
      updateCart(cartProducts,
        appDeliveryMethod,
        this.state.isVatIncludedInPrice,
        this.state.vatTaxRates,
        this.state.listOfTaxRatesOtherThanVAT,
        restaurantSlug);
    } else {
      // if we are adding a combo menu (menu group)
      //console.log('adding a menugroup to cart -  still needs to be implemented');
      //console.log('md5 of product to add => ', md5(JSON.stringify(product.listOfSelectedMenuItemsForMenuGroup)));
      product.quantity = 1;
      if (!product.currencyFormat)
        product.currencyFormat = this.state.restaurantCurrency;

      //console.log('menu group to add => ', product);

      cartProducts.forEach((cp) => {
        // we are adding the same menugroup
        if (cp.id === product.id) {
          // we need to check if this is a simple product with one size and no addons
          if (
            cp.listOfSelectedMenuItemsForMenuGroup.length ===
            product.listOfSelectedMenuItemsForMenuGroup.length
          ) {
            // we need to check if we have the same selected menu items for
            // each menu items group
            //console.log('MD5 1 => ', cp.listOfSelectedMenuItemsForMenuGroupMD5);
            //console.log('current 1 => ', cp.listOfSelectedMenuItemsForMenuGroup);

            if (
              cp.listOfSelectedMenuItemsForMenuGroupMD5 ===
              md5(JSON.stringify(product.listOfSelectedMenuItemsForMenuGroup))
            ) {
              cp.quantity = cp.quantity + product.quantity;
              productAlreadyInCart = true;
            }
          }
        }
      });

      //console.log('addProduct => ', cartProducts);

      if (!productAlreadyInCart) {
        if (product.listOfSelectedMenuItemsForMenuGroup) {
          product.listOfSelectedMenuItemsForMenuGroupMD5 = md5(
            JSON.stringify(product.listOfSelectedMenuItemsForMenuGroup)
          );
          cartProducts.push(Object.assign({}, product));
        } else {
          cartProducts.push(Object.assign({}, product));
        }
      }
      const { appDeliveryMethod } = this.props
      const restaurantSlug = this.retrieveRestaurantIdFromUrl()
      updateCart(cartProducts,
        appDeliveryMethod,
        this.state.isVatIncludedInPrice,
        this.state.vatTaxRates,
        this.state.listOfTaxRatesOtherThanVAT,
        restaurantSlug);
    }

    GAEvent("FRONTEND", "add product to cart", "FLOATCART_ADD_PRODUCT_TO_CART");
  };

  removeProduct = (product) => {
    const { cartProducts, updateCart } = this.props;

    //console.log(product.indexOfProductInCart);

    const index = cartProducts.findIndex((p) => {
      let result = false;
      //console.log(index);
      //console.log('product in cart => ', JSON.stringify(p.listOfSelectedAddongroupsItems));
      //console.log('product to delete from cart => ', JSON.stringify(product.listOfSelectedAddongroupsItems));
      // if single size product
      if (
        p.menuitemsizes &&
        p.menuitemsizes.length === 1 &&
        product.menuitemsizes &&
        product.menuitemsizes.length === 1
      ) {
        // if no list of addongroup items selected
        if (
          p.listOfSelectedAddongroupsItems.length === 0 &&
          product.listOfSelectedAddongroupsItems.length === 0
        ) {
          return p.id === product.id;
        } else {
          if (
            JSON.stringify(p.listOfSelectedAddongroupsItems) ===
            JSON.stringify(product.listOfSelectedAddongroupsItems)
          ) {
            result = p.id === product.id;
          }
        }
      } else {
        // if multiple sizes product
        if (p.selectedmenuitemsize && product.selectedmenuitemsize) {
          // check if same size is selected
          if (p.selectedmenuitemsize.id === product.selectedmenuitemsize.id) {
            // we check if they have addon group items
            if (
              p.listOfSelectedAddongroupsItems.length === 0 &&
              product.listOfSelectedAddongroupsItems.length === 0
            ) {
              return p.id === product.id;
            } else {
              // we check if the selected addons are the same
              if (
                JSON.stringify(p.listOfSelectedAddongroupsItems) ===
                JSON.stringify(product.listOfSelectedAddongroupsItems)
              ) {
                return p.id === product.id;
              } else {
                // product does not match
                return false;
              }
            }
          } else {
            return false;
          }
        } else {
          // the product to delete is a menu group
          if (product.listOfSelectedMenuItemsForMenuGroup) {
            if (p.id === product.id) {
              // we need to check if this is a simple product with one size and no addons
              if (
                p.listOfSelectedMenuItemsForMenuGroup.length ===
                product.listOfSelectedMenuItemsForMenuGroup.length
              ) {
                // we need to check if we have the same selected menu items for
                // each menu items group
                if (
                  p.listOfSelectedMenuItemsForMenuGroupMD5 ===
                  product.listOfSelectedMenuItemsForMenuGroupMD5
                ) {
                  //console.log('combo product to remove => ', product);
                  //console.log('current product => ', p);
                  result = true;
                }
              }
            }
          }
        }
      }

      return result;
    });

    // if we found the product and that it is not a quantity decrement then
    // we fully remove it from the cart
    if (index >= 0 && !product.isDecrementQuantity) {
      cartProducts.splice(index, 1);
      const { appDeliveryMethod } = this.props
      const restaurantSlug = this.retrieveRestaurantIdFromUrl()
      updateCart(cartProducts,
        appDeliveryMethod,
        this.state.isVatIncludedInPrice,
        this.state.listOfTaxRatesOtherThanVAT,
        restaurantSlug);
    } else {
      // user wants to decrement the quantity of this product
      if (index >= 0 && product.isDecrementQuantity) {
        cartProducts[index].quantity = cartProducts[index].quantity - 1;
        const { appDeliveryMethod } = this.props
        const restaurantSlug = this.retrieveRestaurantIdFromUrl()
        updateCart(cartProducts,
          appDeliveryMethod,
          this.state.isVatIncludedInPrice,
          this.state.vatTaxRates,
          this.state.listOfTaxRatesOtherThanVAT,
          restaurantSlug);
      }
    }
  };

  emptyCart = () => {
    const { cartProducts, appDeliveryMethod } = this.props;
    // if there are product to checkout in the cart
    if (cartProducts.length > 0) {
      if (
        window.confirm(
          this.props.intl.formatMessage({
            id: "Floatcart.confirmation.message.empty.cart",
            defaultMessage: "Are you sure you want to empty your cart?",
          })
        )
      ) {
        const restaurantSlug = this.retrieveRestaurantIdFromUrl()
        this.props.resetCart(appDeliveryMethod, restaurantSlug);
        this.closeFloatCart();
        GAEvent("FRONTEND", "Empty full cart", "FLOATCART_EMPTY_FULL_CART");
      }
    }
  };

  proceedToCheckout = () => {
    const { cartProducts } = this.props;
    // if there are product to checkout in the cart
    if (cartProducts.length > 0) {
      this.setState({
        showCheckoutPopup: true,
      });
    }
  };

  onCloseModalCheckout = () => {
    this.setState({
      showCheckoutPopup: false,
    });
  };

  retrieveRestaurantIdFromUrl() {
    // in case we use a deeplink and we have items in the cart then
    // we need to extract the restaurant id from the url
    const urlHash = window.location.hash;
    const indexOfRestaurant = urlHash.indexOf("/restaurant/");
    // console.log('indexOfRestaurant => ', indexOfRestaurant)
    const indexOfMenus = urlHash.indexOf("/menu");
    // console.log('indexOfMenus => ', indexOfMenus)
    const extractRestaurantInformation = urlHash.substring(
      indexOfRestaurant,
      indexOfMenus
    );
    // console.log('extractRestaurantInformation => ', extractRestaurantInformation)
    return extractRestaurantInformation.replace("/restaurant/", "");
  }

  retrieveDeliveryMethodFromUrl() {
    const urlHash = window.location.hash;
    const match = urlHash.match(/\/app\/([^/]+)/);
    return match ? match[1] : null;
  }

  sendOrderToKitchen = (
    clientFullName,
    tableLabel,
    phoneNumber,
    orderType,
    selectedCollectionTimeslot,
    selectedPaymentMethod,
    emailInput,
    firstnameInput,
    lastnameInput,
    groupCodeForOrderInput
  ) => {
    const {
      submitOrderToKitchen,
      restaurantWithMenus,
      restaurantMenuItems,
      restaurantInfo,
      cartProducts,
    } = this.props;

    // console.log('restaurantWithMenus => ', restaurantWithMenus);
    // console.log('restaurantMenuItems => ', restaurantMenuItems);
    GAEvent(
      "FRONTEND",
      "Send order to kitchen",
      "FLOATCART_CHECKOUT_SEND_ORDER_TO_KITCHEN"
    );
    // if there are product to checkout in the cart
    if (cartProducts.length > 0) {
      const order = {};
      if (restaurantWithMenus)
        order.restaurant = restaurantWithMenus.id;
      else {
        if (restaurantMenuItems && restaurantMenuItems.restaurant)
          order.restaurant = restaurantMenuItems.restaurant.id;
        else {
          if (restaurantMenuItems && restaurantMenuItems.restaurant)
            order.restaurant = restaurantInfo.id;
        }
      }
      // console.log('order.restaurant in sendOrderToKitchen => ', order.restaurant)
      order.customerPhoneNumberForNotification = phoneNumber;
      order.tableLabel = tableLabel;
      order.customerEmailForReceipt = emailInput;
      order.type = orderType;
      order.customerFirstname = firstnameInput;
      order.customerLastname = lastnameInput;
      order.groupCodeForOrder = groupCodeForOrderInput;

      let deliveryMethodsMatchingAppDeliveryMethod
      if (this.props.restaurantInfo && this.props.restaurantInfo.deliverymethods)
        deliveryMethodsMatchingAppDeliveryMethod = this.props.restaurantInfo.deliverymethods.filter(dm => dm.code === this.props.appDeliveryMethod)
      else {
        if (this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant && this.props.restaurantMenuItems.restaurant.deliverymethods)
          deliveryMethodsMatchingAppDeliveryMethod = this.props.restaurantMenuItems.restaurant.deliverymethods.filter(dm => dm.code === this.props.appDeliveryMethod)
          else {
            if (this.props.restaurantWithMenus && this.props.restaurantWithMenus.deliverymethods)
              deliveryMethodsMatchingAppDeliveryMethod = this.props.restaurantWithMenus.deliverymethods.filter(dm => dm.code === this.props.appDeliveryMethod)
          }
      }
      let currentDeliveryMethod
      if (deliveryMethodsMatchingAppDeliveryMethod && deliveryMethodsMatchingAppDeliveryMethod.length > 0)
        currentDeliveryMethod = deliveryMethodsMatchingAppDeliveryMethod[0]

      order.isDineInOrder = currentDeliveryMethod ? currentDeliveryMethod.isDineIn : null

      order.orderContent = [];
      // add a fake menu item to the order so that we can set the restaurant slug
      // in case we are in restaurant collection mode
      /*order.orderContent.push({
        menuitemId: '938384',
        restaurantSlug: 'somos-peru',
        size: {
          menuitemSizeId: '123456',
          quantity: 1
        },
        menuId: 123456,
        addonsgroups: [],
        note: ''
      });*/

      cartProducts.forEach((menuItem) => {
        // if not a menu combo
        if (!menuItem.listOfSelectedMenuItemsForMenuGroup) {
          const orderItem = {};
          orderItem.menuitemId = menuItem.id;
          orderItem.size = {};
          orderItem.menuId = Number(menuItem.menuid);
          orderItem.restaurantSlug = menuItem.restaurantSlug;
          orderItem.deliveryMethodCode = menuItem.deliveryMethodCode;
          // check menu item with one size or multiple sizes
          if (menuItem.menuitemsizes.length === 1)
            orderItem.size.menuitemSizeId = menuItem.menuitemsizes[0].id;
          else orderItem.size.menuitemSizeId = menuItem.selectedmenuitemsize.id;

          orderItem.size.quantity = menuItem.quantity;
          orderItem.addonsgroups = [];
          orderItem.note = menuItem.note ? menuItem.note : "";

          //console.log('menuItem => ', menuItem);

          // manage addons for a normal menu item
          if (menuItem.listOfSelectedAddongroupsItems.length > 0) {
            // add addongroups to the menuItem that will be pushed in the orderContent
            const tempArrayAddonGroups = menuItem.listOfSelectedAddongroupsItems
              .filter((addongroup) => addongroup.addongroupsId) // remove empty addon groups
              .map((addongroup) => {
                // build the addonitems array
                let selectedAddonsArray = [];

                if (addongroup.selectedAddons) {
                  const tempArrayAddonGroupItems = addongroup.selectedAddons.map(
                    (addonitem) => {
                      return {
                        addonitemId: addonitem.addonitemId,
                      };
                    }
                  );
                  selectedAddonsArray = [...tempArrayAddonGroupItems];
                }

                // add the addon items to the addon group
                return {
                  addongroupsId: addongroup.addongroupsId,
                  selectedAddons: selectedAddonsArray,
                };
              });

            orderItem.addonsgroups = [...tempArrayAddonGroups];
          }

          order.orderContent.push(orderItem);
        } else {
          // if we have a combo menu
          const ComboMenuItem = {};
          ComboMenuItem.menugroupid = menuItem.id;
          ComboMenuItem.quantity = menuItem.quantity;
          ComboMenuItem.selectedMenuItems = [];
          ComboMenuItem.restaurantSlug = menuItem.restaurantSlug;
          ComboMenuItem.deliveryMethodCode = menuItem.deliveryMethodCode;
          const tempArraySelectedComboMenuItems = menuItem.listOfSelectedMenuItemsForMenuGroup.map(
            (selMenuItem, index) => {
              const tempMenuItemObj = {};
              tempMenuItemObj.menuitemId = selMenuItem.id;
              tempMenuItemObj.menuitemsgroupid = selMenuItem.menuitemgroup.id;
              tempMenuItemObj.size = {};
              tempMenuItemObj.size.menuitemSizeId =
                selMenuItem.selectedmenuitemsize.id;
              tempMenuItemObj.size.quantity = 1;
              tempMenuItemObj.addonsgroups = [];
              // if the user selected some addons
              if (
                selMenuItem.selectedAddons &&
                selMenuItem.selectedAddons.length > 0
              ) {
                const groupByAddongroupid = groupBy('addongroupid');
                const groupedByAddongroups = groupByAddongroupid(selMenuItem.selectedAddons);
                // console.log("groupedByAddongroups => ", groupedByAddongroups);
                const arrayOfAddongroupsIds = Object.keys(groupedByAddongroups);
                for (let index = 0; index < arrayOfAddongroupsIds.length; index++) {
                  const tempSelectedAddons = groupedByAddongroups[arrayOfAddongroupsIds[index]].map(
                    (selectedAddon) => {
                      return {
                        addonitemId: selectedAddon.addonitemId,
                      };
                    }
                  );
                  // add addon group to menu item object
                  tempMenuItemObj.addonsgroups.push({
                    addongroupsId: arrayOfAddongroupsIds[index],
                    selectedAddons: tempSelectedAddons
                  });
                }
              }

              //console.log('selMenuItem => ', selMenuItem.name, selMenuItem.note);

              //tempMenuItemObj.note = selMenuItem.note ? selMenuItem.note : "";
              // add the note to the last element of the combo menu so that the
              // backend can display the note as an overall combo menu note and
              // not only a one item note. This will have to be changed later
              if (
                index ===
                  menuItem.listOfSelectedMenuItemsForMenuGroup.length - 1 &&
                menuItem.note !== ""
              ) {
                tempMenuItemObj.note = menuItem.note;
              }

              return tempMenuItemObj;
            }
          );

          ComboMenuItem.selectedMenuItems = tempArraySelectedComboMenuItems;

          order.orderContent.push(ComboMenuItem);
        }
      });

      // not ideal but it works for now
      let restaurantCollectionSlug = null;
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.startsWith('restaurantCollection-slug-')) {
          restaurantCollectionSlug = localStorage.getItem(key);
          break;
        }
      }

      if (restaurantCollectionSlug) {
        order.restaurantCollectionSlug = restaurantCollectionSlug
      }

      // adding client selected locale to order so that
      // in case of payment we can send the client to a payment
      // page with his locale
      //console.log('this.props.intl => ', this.props.intl);
      order.localeCode = this.props.intl.locale;
      order.extraInformation = {
        selectedCollectionTimeslot,
      };

      // if the payment method is not an online payment method then
      // we need to set our order as is so that the backend will
      // manage it properly and not trigger the payment gateway
      if(!selectedPaymentMethod.isOnlinePaymentMethod) {
        order.paymentInformation = {
          statusCode: "open",
          method: selectedPaymentMethod.code 
        }
      }

      //console.log('order to submit => ', order);
      // submit order to kitchen
      submitOrderToKitchen(order, this.props.appDeliveryMethod);
      // close floatcart when order is sent to kitchen
      this.onCloseModalCheckout();
      // go back list of menus page
      //console.log(this.props);
      let restaurantId;
      if (
        restaurantMenuItems &&
        restaurantMenuItems.restaurant
      )
        restaurantId = restaurantMenuItems.restaurant ? restaurantMenuItems.restaurant.slug : null;
      else {
        if (restaurantWithMenus)
          restaurantId = restaurantWithMenus.slug;
        else
          if (restaurantInfo)
            restaurantId = restaurantInfo.slug;
          else 
            restaurantId = this.retrieveRestaurantIdFromUrl()
      }
      
      const queryStringParamMode = queryString.parse(this.props.location.search).mode
      const queryStringParamGroupCode = queryString.parse(this.props.location.search).groupcode
      if(restaurantCollectionSlug) {
        this.props.history.push(
          `/app/${this.props.appDeliveryMethod}/restaurant/${restaurantId}/menus?rcid=${restaurantCollectionSlug}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`
        );
      } else {
        this.props.history.push(
          `/app/${this.props.appDeliveryMethod}/restaurant/${restaurantId}/menus${queryStringParamMode ? '?mode=' + queryStringParamMode : '?'}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`
        );
      }
    }
  };

  render() {
    const {
      cartTotal,
      cartProducts,
      removeProduct,
      addNoteToProduct,
      addProduct,
      isCommentAllowedAtCheckout
    } = this.props;

    let restaurantDeliveryMethods;
    // we check if the current restaurant has only enabled the digital menu offering
    // and not the order management which in this case we won't show the checkout button
    let isOnlyDigitalMenuEnabledForCurrentRestaurant;
    let isVatIncludedInPrice = this.state.isVatIncludedInPrice;
    let listOfTaxRatesOtherThanVAT = this.state.listOfTaxRatesOtherThanVAT;
    let restaurantSlug = ""
    if (this.props.restaurantInfo) {
      //console.log("resInfo => ", this.props.restaurantInfo)
      restaurantSlug = this.props.restaurantInfo.slug;
    }
    if (this.props.restaurantInfo && this.props.restaurantInfo.deliverymethods) {
      restaurantDeliveryMethods = [
        ...this.props.restaurantInfo.deliverymethods,
      ];
      isOnlyDigitalMenuEnabledForCurrentRestaurant = this.props
        .restaurantInfo.isDigitalMenuOnlyForDineIn;
      } else {
      if (
        this.props.restaurantMenuItems &&
        this.props.restaurantMenuItems.restaurant &&
        this.props.restaurantMenuItems.restaurant.deliverymethods
      ) {
        restaurantDeliveryMethods = [
          ...this.props.restaurantMenuItems.restaurant.deliverymethods,
        ];
        isOnlyDigitalMenuEnabledForCurrentRestaurant = this.props
          .restaurantMenuItems.restaurant.isDigitalMenuOnlyForDineIn;
        } else {
        if (this.props.restaurantWithMenus &&
          this.props.restaurantWithMenus.deliverymethods) {
          restaurantDeliveryMethods = [
            ...this.props.restaurantWithMenus.deliverymethods,
          ];
          isOnlyDigitalMenuEnabledForCurrentRestaurant = this.props
            .restaurantWithMenus.isDigitalMenuOnlyForDineIn;
          }
      }
    }

    // console.log('Floatcart this.props.restaurantInfo => ', this.props.restaurantInfo)
    // console.log('FloatCart restaurantDeliveryMethods => ', restaurantDeliveryMethods)
    //console.log('render products => ', products);

    let classes = [s.floatCart];

    if (!!this.state.isOpen) {
      classes.push(s.floatCartOpen);
    }

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const todayDate = new Date();
    const dayName = days[todayDate.getDay()];

    const { appDeliveryMethod } = this.props


    let deliveryMethodsMatchingCurrentRestaurantMode
    if (restaurantDeliveryMethods && restaurantDeliveryMethods.length > 0)
      deliveryMethodsMatchingCurrentRestaurantMode = restaurantDeliveryMethods.filter(dm => dm.code === appDeliveryMethod)

    let currentDeliveryMethodObj

    if (deliveryMethodsMatchingCurrentRestaurantMode && deliveryMethodsMatchingCurrentRestaurantMode.length > 0)
      currentDeliveryMethodObj = deliveryMethodsMatchingCurrentRestaurantMode[0]

    const products = cartProducts.map((p, index) => {
      return (
        <CartProduct
          outletSlug={restaurantSlug}
          currentDeliveryMethod={appDeliveryMethod}
          product={p}
          removeProduct={removeProduct}
          addProduct={addProduct}
          addNoteToProduct={addNoteToProduct}
          isCommentAllowedAtCheckout={isCommentAllowedAtCheckout}
          key={"FloatCart-CartProduct-" + p.id + "-" + index}
        />
      );
    });

    const currencyNumberOfDecimals = cartProducts && cartProducts.length > 0 ? cartProducts[0].currencyNumberOfDecimals : 2;


    let discountAmount = 0;

    if (currentDeliveryMethodObj && currentDeliveryMethodObj.code.includes('collectatkiosk') && this.props.restaurantWithMenus &&
      this.props.restaurantWithMenus.discountOnCollectAtKiosk &&
      this.props.restaurantWithMenus.discountOnCollectAtKiosk > 0) {
        discountAmount = this.props.restaurantWithMenus.discountOnCollectAtKiosk;
    } else {
      if (currentDeliveryMethodObj && !currentDeliveryMethodObj.isDineIn && this.props.restaurantWithMenus &&
        this.props.restaurantWithMenus.discountOnDineOutOrders &&
        this.props.restaurantWithMenus.discountOnDineOutOrders > 0) {
          discountAmount = this.props.restaurantWithMenus.discountOnDineOutOrders;
      }
    }
      
    if (currentDeliveryMethodObj && currentDeliveryMethodObj.code.includes('collectatkiosk') && !this.props.restaurantWithMenus &&
      this.props.restaurantMenuItems &&
      this.props.restaurantMenuItems.restaurant &&
      this.props.restaurantMenuItems.restaurant.discountOnCollectAtKiosk &&
      this.props.restaurantMenuItems.restaurant.discountOnCollectAtKiosk > 0) {
        discountAmount =  this.props.restaurantMenuItems.restaurant.discountOnCollectAtKiosk;
    } else {
      if (currentDeliveryMethodObj && !currentDeliveryMethodObj.isDineIn && !this.props.restaurantWithMenus &&
        this.props.restaurantMenuItems &&
        this.props.restaurantMenuItems.restaurant &&
        this.props.restaurantMenuItems.restaurant.discountOnDineOutOrders &&
        this.props.restaurantMenuItems.restaurant.discountOnDineOutOrders > 0) {
          discountAmount = this.props.restaurantMenuItems.restaurant.discountOnDineOutOrders;
      }
    }
    
    if (currentDeliveryMethodObj && currentDeliveryMethodObj.code.includes('collectatkiosk') && !this.props.restaurantWithMenus && !this.props.restaurantMenuItems &&
      this.props.restaurantInfo &&
      this.props.restaurantInfo.discountOnCollectAtKiosk &&
      this.props.restaurantInfo.discountOnCollectAtKiosk > 0) {
      discountAmount = this.props.restaurantInfo.discountOnCollectAtKiosk;
    } else {
      if (currentDeliveryMethodObj && !currentDeliveryMethodObj.isDineIn && !this.props.restaurantWithMenus && !this.props.restaurantMenuItems &&
        this.props.restaurantInfo &&
        this.props.restaurantInfo.discountOnDineOutOrders &&
        this.props.restaurantInfo.discountOnDineOutOrders > 0) {
        discountAmount = this.props.restaurantInfo.discountOnDineOutOrders;
      }
    }
    
    const { intl } = this.props
    const { locale } = intl

    return (
      <div className={classes.join(" ")} style={
        locale === 'ar' && this.state.isOpen ? {left: 0, right: 'auto', transition: 'left 0.2s'} : (
          locale === 'ar' && !this.state.isOpen ? 
            {left: '-450px', right: 'auto', transition: 'left 0.2s' } : 
            {}
        )
      }>
        {/* If cart open, show close (x) button */}
        {this.state.isOpen && (
          <div
            onClick={() => this.closeFloatCart()}
            className={locale === 'ar' ? s.floatCartCloseBtnRTL : s.floatCartCloseBtn}
          >
            X
          </div>
        )}
        <div
          onClick={() => this.emptyCart()}
          className={s.floatCartDeleteAllBtn}
          style={locale === 'ar' ? {left: 0,  right: 'auto'} : {}}
        >
          <img
            className={s.floatCartDeleteAllButtonImage}
            src={DeleteAllIcon}
            alt=""
          />
        </div>

        {/* If cart is closed, show bag with quantity of product and open cart action */}
        {!this.state.isOpen && (
          <span
            id="alacarteCartButton"
            onClick={() => this.openFloatCart()}
            className={
              s.bag +
              " " +
              s.bagFloatCartClosed +
              (this.state.glowMeFlag ? " " + s.glowMe : "")
            }
            style={locale === 'ar' ? {position: 'fixed', left: '0px'} : {}}
          >
            <span
              className={s.bagQuantity + " " + s.bagFloatCartClosedQuantity}
            >
              {cartTotal.productQuantity}
            </span>
          </span>
        )}
        { // we only display the checkout page when we are in the order management mode enabled for the restaurant
          restaurantDeliveryMethods ? (
          <CheckoutPopup
            queryStringSearch={this.props.location.search}
            restaurantCountryCode={this.props.restaurantInfo && this.props.restaurantInfo.city && 
              this.props.restaurantInfo.city.country && this.props.restaurantInfo.city.country.cioc ? 
              this.props.restaurantInfo.city.country.cioc.toLowerCase()
              : "nl"}
            deliveryMethods={restaurantDeliveryMethods}
            restaurantPaymentMethods={this.props.restaurantInfo
              ? this.props.restaurantInfo.paymentMethods
              : []}
            mobileAppDeliveryMethod={this.props.appDeliveryMethod}
            mobileAppDeliveryMethodConfiguration={this.props.restaurantInfo.deliveryMethodsConfiguration}
            collectTimeslots={
              this.props.restaurantInfo &&
              this.props.restaurantInfo.collectTimeslotsForWeek &&
              this.props.restaurantInfo.collectTimeslotsForWeek.isActive &&
              this.props.restaurantInfo.collectTimeslotsForWeek[dayName]
                ? this.props.restaurantInfo.collectTimeslotsForWeek[dayName].timeslots
                : []
            }
            takeawayTimeslots={
              this.props.restaurantInfo &&
              this.props.restaurantInfo.takeawayTimeslotsForWeek &&
              this.props.restaurantInfo.takeawayTimeslotsForWeek.isActive &&
              this.props.restaurantInfo.takeawayTimeslotsForWeek[dayName]
                ? this.props.restaurantInfo.takeawayTimeslotsForWeek[dayName].timeslots
                : []
            }
            showPopup={this.state.showCheckoutPopup}
            onClose={this.onCloseModalCheckout}
            onSubmit={(extendedOrderInformation) => {
              this.sendOrderToKitchen(
                extendedOrderInformation.clientFullName,
                extendedOrderInformation.tableLabel,
                extendedOrderInformation.phoneNumber,
                extendedOrderInformation.orderType,
                extendedOrderInformation.selectedCollectionTimeslot,
                extendedOrderInformation.selectedPaymentMethod,
                extendedOrderInformation.emailInput,
                extendedOrderInformation.firstnameInput,
                extendedOrderInformation.lastnameInput,
                extendedOrderInformation.groupCodeForOrderInput
              );
            }}
          />
        ) : null}
        <div className={s.floatCartContent}>
          <div className={s.floatCartHeader}>
            {
              locale === 'ar' ? (
                <span>
                  <span className={s.floatCartHeaderHeaderTitle}>
                    <FormattedMessage
                      id="Floatcart.header.title"
                      defaultMessage="Bag"
                    />
                  </span>
                  <span className={s.bag}>
                    <span className={s.bagQuantity}>{cartTotal.productQuantity}</span>
                  </span>
                </span>
              ) : (
                <span>
                  <span className={s.bag}>
                    <span className={s.bagQuantity}>{cartTotal.productQuantity}</span>
                  </span>
                  <span className={s.floatCartHeaderHeaderTitle}>
                    <FormattedMessage
                      id="Floatcart.header.title"
                      defaultMessage="Bag"
                    />
                  </span>
                </span>
              )
            }
          </div>
          {this.props.isPostingOrder ? <ProgressIndicator /> : null}
          {
            this.props.restaurantInfo && this.props.restaurantInfo.isDisplayTakeawayMessageForDineIn &&
            currentDeliveryMethodObj && currentDeliveryMethodObj.isDineIn ?
            (
              <HeaderMessage />
            ) : null
          }
          <div className={s.floatCartShelfContainer}>
            {products}
            {!products.length && (
              <p className={s.floatCartShelfContainerEmpty}>
                <FormattedMessage
                  id="Floatcart.empty.cart.message"
                  defaultMessage="Add some products in the bag"
                />{" "}
                <br />
                🙂
              </p>
            )}
          </div>
          <div className={s.floatCartFooter}>
            <TaxRate 
              cartTotal={cartTotal}
              listOfVatTaxRates={this.state.vatTaxRates}
              listOfNonVatTaxRates={listOfTaxRatesOtherThanVAT}
              isVatIncludedInPrice={isVatIncludedInPrice} />
            <div 
              className={s.floatCartFooterSub}
              style={locale === 'ar' ? {textAlign: 'right'} : {}}>
              <FormattedMessage
                id="Floatcart.subtotal.label"
                defaultMessage="SUBTOTAL"
              />
            </div>
            <div className={s.floatCartFooterSubPrice}>
              <p 
                className={s.floatCartFooterSubPriceVal}
                style={locale === 'ar' ? {textAlign: 'end'} : {}}>
                {
                  this.state.restaurantCurrency
                    ? 
                      <span className={discountAmount > 0 && cartTotal.totalPrice > 0 ? s.discountOnTotalPrice : null}>{this.state.restaurantCurrency}&nbsp;</span>
                      : cartProducts.length > 0
                      ? 
                        <span className={discountAmount > 0 && cartTotal.totalPrice > 0 ? s.discountOnTotalPrice : null}>{cartProducts[0].currencyFormat}&nbsp;</span>
                        : ""
                }
                {
                  cartTotal.totalPriceWithBeforeVatTaxes > 0 ? 
                    round_up(cartTotal.totalPriceWithBeforeVatTaxes, 2)
                  : null
                }
                {
                  cartTotal.totalPriceWithBeforeVatTaxes === 0 && discountAmount > 0 && cartTotal.totalPrice > 0 ?
                  <span className={s.discountOnTotalPrice}>{
                    currencyNumberOfDecimals <= 2 ?
                    parseFloat(round_up(parseFloat(cartTotal.totalPrice), currencyNumberOfDecimals)) :
                    round_up(parseFloat(cartTotal.totalPrice), currencyNumberOfDecimals)
                  }</span> : 
                    cartTotal.totalPriceWithBeforeVatTaxes === 0 && currencyNumberOfDecimals <= 2 ?
                    parseFloat(round_up(parseFloat(cartTotal.totalPrice), currencyNumberOfDecimals)) :
                    cartTotal.totalPriceWithBeforeVatTaxes === 0 ?
                    round_up(parseFloat(cartTotal.totalPrice), currencyNumberOfDecimals) : null
                }
                {
                  discountAmount > 0 && cartTotal.totalPrice > 0 ?
                  <span className={s.discountSpace}> -> </span> : null
                }
                {
                  discountAmount > 0 && cartTotal.totalPrice > 0 ?
                    `${
                      this.state.restaurantCurrency
                        ? this.state.restaurantCurrency
                        : cartProducts.length > 0
                        ?
                          cartProducts[0].currencyFormat
                        : ""
                      } ${
                        round_up((cartTotal.totalPrice - round_up((cartTotal.totalPrice * (discountAmount / 100)), 2) + Number.EPSILON) * 100, 2) / 100
                      }` 
                     : null
                }
              </p>
            </div>
            {isOnlyDigitalMenuEnabledForCurrentRestaurant && currentDeliveryMethodObj && currentDeliveryMethodObj.isDineIn ? (
              <div className={s.floatCartFooterCallWaiterLabel}>
              <FormattedMessage
                id="Floatcart.callwaiter.label"
                defaultMessage="Call the waiter when you are ready"
              />
            </div>
            ) : (
              <div
              onClick={() => this.proceedToCheckout()}
              className={s.floatCartFooterBuyBtn}
              >
                <FormattedMessage
                  id="Floatcart.checkout.button.label"
                  defaultMessage="Checkout"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cartProducts: state.cart.cartProducts,
  newProduct: state.cart.productToAdd,
  productToRemove: state.cart.productToRemove,
  cartTotal: state.cart.data,
  restaurantWithMenus: state.menu.restaurantWithMenus,
  restaurantMenuItems: state.menuitem.restaurantMenuItems,
  isPostingOrder: state.cart.isPostingOrder,
  postedOrder: state.cart.postedOrder,
  productToUpdateWithNote: state.cart.productToUpdateWithNote,
  restaurantInfo: state.restaurant.restaurantInfo,
  websocket: state.websocket.socket
});

export default withRouter(
  injectIntl(
    connect(mapStateToProps, {
      addProduct,
      loadCart,
      updateCart,
      removeProduct,
      resetCart,
      submitOrderToKitchen,
      addNoteToProduct,
    })(FloatCart)
  )
);
