import axios from 'axios';
//import runtimeEnv from '@mars/heroku-js-runtime-env';

export async function getRestaurantInfo(restaurantSlug) {
    // console.log('getRestaurantInfo', restaurantSlug);
    if (!restaurantSlug || restaurantSlug === 'undefined' ) {
      return {};
    } else {
      // endpoint path
      const apiEndpointPath = '/graphql';
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const todayDate = new Date();
      const dayName = days[todayDate.getDay()];

      const graphqlQuery= `
      query getRestaurantInformation {
        restaurantsForPublic(slug: "${restaurantSlug}") {
          id
          slug
          name
          outletLogoForCustomerApp { url formats }
          customAllergenIcons { icon { url formats } allergen { id } }
          customFoodtypeIcons { picture { url formats } foodtype { id } }
          isCommentAllowedAtCheckout
          isDigitalMenuOnlyForDineIn
          isDisplayTakeawayMessageForDineIn
          discountOnDineOutOrders
          discountOnCollectAtKiosk
          isRepeatLastOrderAllowed
          deliveryMethodsConfiguration {
            deliverymethod {
              code
            } 
            customerEmailForOrder
            customerPhoneForOrder
            groupCodeForOrder
            customerFullnameForOrder
            isActive
            locationNumberLabelTranslations
            overwriteLocationNumberLabel
          }
          taxRates {
            isActive
            isIncludedInPrices
            description_i18n
            type
            taxValue
            taxValueType
          }
          defaultLanguageForCustomerApp {
            id
            localeCode
            imageURL
            alt
            label
          }
          paymentMethods {
            code
            isOnlinePaymentMethod
          }
          companyRegistrationNumber
          collectTimeslotsForWeek {
            isActive
            ${dayName} {
              timeslots {
                id
                label
                fromTimeHours
                fromTimeMinutes
                toTimeHours
                toTimeMinutes
              }
            }
          }
          takeawayTimeslotsForWeek {
            isActive
            ${dayName} {
              timeslots {
                id
                label
                fromTimeHours
                fromTimeMinutes
                toTimeHours
                toTimeMinutes
              }
            }
          }
          languagepacks {
            id
          }
          deliverymethods {
            code
            label
            isDineIn
            isWithOnlinePayment
            description_i18n
            isCollectAtLocation
          }
          city {
            name
            timezone {
              utc
            }
            country {
              cioc
              currency {
                symbol
                numberOfDecimals
              }
            }
          }
        }
      }       
      `
      // init runtime env var
      //const env = runtimeEnv();
      // Request API.
      return await axios
      .post( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
      '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
      ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath + '?restaurantId=' + restaurantSlug, 
            {
              "query": graphqlQuery
            }
          )
      .then(response => {
          // Handle success.
          // console.log('menu axios response', response);
          if(response.data.errors) return response.data;

          return response.data.data.restaurantsForPublic[0];
      });
    }
}

export async function getListOfRestaurantsFromServer() {
    // endpoint path
    const apiEndpointPath = '/graphql';
    // init runtime env var
    //const env = runtimeEnv();
    // Request API.
    return await axios
    .post( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
    '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
     ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath, 
           {
            "query": "query getListOfOrdersForDayForRestaurant {  \n  restaurantsForPublic\n  { \n    id\n    name\n  slug\n   streetNameAndNumber\n  isDisplayTakeawayMessageForDineIn\n deliveryMethodsConfiguration { deliverymethod { code } outletLogoForCustomerApp { url formats } customerEmailForOrder customerPhoneForOrder groupCodeForOrder customerFullnameForOrder locationNumberLabelTranslations overwriteLocationNumberLabel isActive }\n  isRepeatLastOrderAllowed\n  discountOnDineOutOrders\n  discountOnCollectAtKiosk\n  city {      \n      name \n    }\n    cuisinetypes {\n      name\n      name_i18n\n    }\n  taxRates {\n  isActive\n  isIncludedInPrices\n  description_i18n\n type\n taxValue  taxValueType }\n   backgroundPicture {\n      url\n    }\n  }\n}"
           }
        )
    .then(response => {
        // Handle success.
        //console.log('menu axios response', response);
        if(response.data.errors) return response.data;

        return response.data.data.restaurants;
    });
}

export async function getRestaurantCollectionDetails(restaurantCollectionSlug) {

    const graphqlQuery= `
        query getRestaurantCollectionDetails {
            restaurantCollectionsForPublic(slug: "${restaurantCollectionSlug}") {
            name_i18n
            isActive
            languagepacks {
                id
            }
            type
            displayMode
            outletLogoForCustomerApp { url formats }
            groupOfRestaurants {
                restaurant {
                    slug
                    isActive
                    isRepeatLastOrderAllowed
                    isCommentAllowedAtCheckout
                    outletLogoForCustomerApp { url formats }
                    deliveryMethodsConfiguration {
                      deliverymethod {
                        code
                      } 
                      customerEmailForOrder
                      customerPhoneForOrder
                      groupCodeForOrder
                      customerFullnameForOrder
                      isActive
                      locationNumberLabelTranslations
                      overwriteLocationNumberLabel
                    }
                    cuisinetypes {
                        name_i18n
                    }
                    backgroundPicture {
                        url
                    }
                }
                deliverymethod {
                    code
                }
                isActive
                description_i18n
                picture {
                  url
                }
            }
            promotionBanner {
              id
              isActive
              promotionDescription_i18n
              displayUntil
              isOpenLinkInNewWindow
              urlToOpen
            }
        }
    }`
    // endpoint path
    const apiEndpointPath = '/graphql';
    // init runtime env var
    //const env = runtimeEnv();
    // Request API.
    return await axios
    .post( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
    '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
     ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath + '?restaurantColId=' + restaurantCollectionSlug, 
           {
               "query": graphqlQuery
           }
        )
    .then(response => {
        // Handle success.
        //console.log('menu axios response', response);
        if(response.data.errors) return response.data;

        if (response.data.data.restaurantCollectionsForPublic[0].isActive)
            return response.data.data.restaurantCollectionsForPublic[0];
        else
            return response.data = {errors: ['restaurant collection disabled']};
    });
}