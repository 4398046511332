import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl'; 

const Thumb = props => {
  const { locale } = props.intl
  return (
    <div className={props.classes}
      style={locale === 'ar' ? {
        position: 'absolute',
        right: '7px'
      } : {}}>
      <img 
        src={props.src}
        alt={props.alt} 
        title={props.title}
        style={locale === 'ar' ? {
          width: '48px',
          height: '48px'
        } : {}}
        />
    </div>
  );
};

Thumb.propTypes = {
  alt: PropTypes.string,
  title: PropTypes.string,
  classes: PropTypes.string,
  src: PropTypes.string.isRequired
};

export default injectIntl(Thumb);
