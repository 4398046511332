import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import s from "./TaxRate.module.scss";

const round_up = (x,nd) => {
  /*var rup=Math.pow(10,nd);
  var rdwn=Math.pow(10,-nd); // Or you can just use 1/rup
  const result = (Math.round(x*rup)*rdwn).toFixed(nd)*/
  const result = (Math.ceil(x * 100) / 100).toFixed(nd);
  // console.log("round_up => ", x, result)
  return result
}

class TaxRate extends Component {
  static propTypes = {
    listOfNonVatTaxRates: PropTypes.array,
    vatTaxRate: PropTypes.object,
    isVatIncludedInPrice: PropTypes.bool,
    cartTotal: PropTypes.object.isRequired
  };

  state = {};

  render() {
    let { listOfNonVatTaxRates, isVatIncludedInPrice, cartTotal, listOfVatTaxRates } = this.props
    const { locale } = this.props.intl;
    let vatDescriptionI18n = ""
    if (listOfVatTaxRates) {
        vatDescriptionI18n = listOfVatTaxRates[0].description_i18n[locale];
    }

    const { totalNetPriceOfCart } = cartTotal;

    //console.log("taxrate comp totalNetPriceOfCart => ", totalNetPriceOfCart)

    /* console.log('isVatIncludedInPrice => ', isVatIncludedInPrice);
    console.log('listOfVatTaxRates => ', listOfVatTaxRates);
    console.log('listOfNonVatTaxRates => ', listOfNonVatTaxRates) */
    let allTaxes = []
    
    if (listOfVatTaxRates) {
      allTaxes = listOfVatTaxRates.concat(listOfNonVatTaxRates.filter(tax => tax.isActive))
    }

    // we are going to check if we have taxes that needs to be applied before VAT
    let allTaxesBeforeVAT1 = []
    let firstTaxToApplyBeforeVAT = {}
    firstTaxToApplyBeforeVAT.taxAmount = 0
    if (listOfNonVatTaxRates && listOfNonVatTaxRates.length > 0) {
      allTaxesBeforeVAT1 = listOfNonVatTaxRates.filter((taxRate) => taxRate.type.includes('other_apply_before_vat_1'))
      if (allTaxesBeforeVAT1.length > 0) {
        const currentFirstTax = allTaxesBeforeVAT1[0]
        firstTaxToApplyBeforeVAT.taxAmount = currentFirstTax.taxValueType === 'percent' ? 
          parseFloat(round_up(((((parseFloat(totalNetPriceOfCart) * currentFirstTax.taxValue / 100) + Number.EPSILON) * 100) / 100), 2)) :
          parseFloat(round_up(((currentFirstTax.taxValue + Number.EPSILON) * 100) / 100, 2))
        firstTaxToApplyBeforeVAT.description_i18n = currentFirstTax.description_i18n
      }
    } 

    let allTaxesBeforeVAT2 = []
    let secondTaxToApplyBeforeVAT = {}
    secondTaxToApplyBeforeVAT.taxAmount = 0
    if (listOfNonVatTaxRates && listOfNonVatTaxRates.length > 0) {
      allTaxesBeforeVAT2 = listOfNonVatTaxRates.filter((taxRate) => taxRate.type.includes('other_apply_before_vat_2'))
      if (allTaxesBeforeVAT2.length > 0) {
        const currentSecondTax = allTaxesBeforeVAT2[0]
        secondTaxToApplyBeforeVAT.taxAmount = currentSecondTax.taxValueType === 'percent' ? 
          parseFloat(round_up(((((parseFloat(totalNetPriceOfCart) + firstTaxToApplyBeforeVAT.taxAmount ) * currentSecondTax.taxValue / 100) + Number.EPSILON) * 100) / 100, 2)) :
          parseFloat(round_up(((currentSecondTax.taxValue + Number.EPSILON) * 100) / 100, 2))
        secondTaxToApplyBeforeVAT.description_i18n = currentSecondTax.description_i18n
      }
    }

    if (listOfNonVatTaxRates && listOfNonVatTaxRates.length > 0) {
      listOfNonVatTaxRates = listOfNonVatTaxRates.filter((taxRate) => !taxRate.type.includes('apply_before_vat'))
    }

    let vatValueIncludingBeforeTaxRates = parseFloat(round_up(cartTotal.totalVat, 2));
    if (listOfVatTaxRates && (allTaxesBeforeVAT2.length > 0 || allTaxesBeforeVAT1.length > 0)) {
      vatValueIncludingBeforeTaxRates = round_up((parseFloat(totalNetPriceOfCart) + firstTaxToApplyBeforeVAT.taxAmount  + secondTaxToApplyBeforeVAT.taxAmount) * (listOfVatTaxRates[0].taxValue / 100), 2);
    }
    
    // console.log("cartTotal => ", cartTotal)

    // TODO take into account extra taxes like room service

    /* let totalNetAmount = 0
    // if the vat is not included in the price then we need to calculate it
    if (!isVatIncludedInPrice) {
        totalNetAmount = cartTotal.totalPrice - cartTotal.totalVat
    } */ 

    return cartTotal.currencyFormat ? (
        <div 
            style={locale === 'ar' ? {textAlign: 'right'} : {}}>
            {
              allTaxesBeforeVAT1 && allTaxesBeforeVAT1.length > 0 ? (
                <div>
                    <div
                      className={s.taxRateSub}
                      style={locale === 'ar' ? {textAlign: 'right'} : {}}>
                      <span style={{textAlign: 'left'}}>
                        {firstTaxToApplyBeforeVAT.description_i18n[locale]}
                      </span>
                    </div>
                    <div
                      className={s.taxRateSubPrice}>
                        <p 
                            className={s.taxRateSubPriceVal}
                            style={locale === 'ar' ? {textAlign: 'end'} : {}}>
                              {cartTotal.currencyFormat}&nbsp;{firstTaxToApplyBeforeVAT.taxAmount}
                        </p>
                    </div>
                </div>
              ) : null
            }
            {
                allTaxesBeforeVAT2 && allTaxesBeforeVAT2.length > 0 ? (
                  <div>
                      <div
                        className={s.taxRateSub}
                        style={locale === 'ar' ? {textAlign: 'right'} : {}}>
                        <span style={{textAlign: 'left'}}>
                          {secondTaxToApplyBeforeVAT.description_i18n[locale]}
                        </span>
                      </div>
                      <div
                        className={s.taxRateSubPrice}>
                          <p 
                              className={s.taxRateSubPriceVal}
                              style={locale === 'ar' ? {textAlign: 'end'} : {}}>
                                {cartTotal.currencyFormat}&nbsp;{secondTaxToApplyBeforeVAT.taxAmount}
                          </p>
                      </div>
                  </div>
                ) : null
            }
            {
                !isVatIncludedInPrice && listOfVatTaxRates ? (    
                      <div>
                        <div key={`vat-rate-cart-1}`}
                            className={s.taxRateSub}
                            style={locale === 'ar' ? {textAlign: 'right'} : {}}>
                            <span style={{textAlign: 'left'}}>
                            {vatDescriptionI18n}&nbsp;{/*listOfVatTaxRates[0] ? 
                              (
                                listOfVatTaxRates[0].taxValueType === 'percent' ? 
                                  listOfVatTaxRates[0].taxValue + '%' 
                                  : cartTotal.currencyFormat + ' ' + listOfVatTaxRates[0].taxValue
                              ) 
                              : null*/
                            }
                            </span>
                        </div>
                        <div key={`vat-subprice-rate-cart-1}`}
                          className={s.taxRateSubPrice}>
                            <p 
                                className={s.taxRateSubPriceVal}
                                style={locale === 'ar' ? {textAlign: 'end'} : {}}>
                                    {cartTotal.currencyFormat}&nbsp;{vatValueIncludingBeforeTaxRates ? parseFloat(vatValueIncludingBeforeTaxRates).toFixed(2) : '0.00'}
                                    {/*cartTotal.currencyFormat}&nbsp;{(Math.round((cartTotal.totalVat + Number.EPSILON) * 100) / 100).toFixed(2)*/}
                            </p>
                        </div>
                        {
                          listOfNonVatTaxRates.filter(tax => tax.isActive).map((otherTax, index) => {
                            return (
                              <div key={`tax-rate-cart-${index}`}>
                                <div
                                  className={s.taxRateSub}
                                  style={locale === 'ar' ? {textAlign: 'right'} : {}}>
                                  <span style={{textAlign: 'left'}}>
                                    {otherTax.description_i18n[locale]}
                                  </span>
                                </div>
                                <div
                                  className={s.taxRateSubPrice}>
                                    <p 
                                        className={s.taxRateSubPriceVal}
                                        style={locale === 'ar' ? {textAlign: 'end'} : {}}>
                                          {
                                            otherTax.taxValueType === 'percent' ?
                                              cartTotal.currencyFormat + ' ' + round_up(((((cartTotal.totalPrice - cartTotal.totalVat - cartTotal.totalPriceBeforeOtherActiveNonIncludedTaxRates) * otherTax.taxValue /100) + Number.EPSILON) * 100) / 100, 2) : (
                                              cartTotal.currencyFormat + ' ' + round_up(((otherTax.taxValue + Number.EPSILON) * 100) / 100, 2)
                                            )
                                          }
                                    </p>
                                </div>
                              </div>
                            )
                          })
                        }
                    </div>
                ) : (
                      // multiple VAT rates we show 1 message
                      isVatIncludedInPrice && listOfVatTaxRates && listOfVatTaxRates.length > 1 && (!listOfNonVatTaxRates || listOfNonVatTaxRates.length === 0) ? (
                        <div style={{fontSize: '12px'}}>
                            {vatDescriptionI18n}&nbsp;
                            <FormattedMessage
                                id="Floatcart.taxRate.vat.message"
                                defaultMessage="included in prices"
                            />
                        </div>
                      ) : (
                        // one VAT rate and other tax than VAT then we show a list of applied taxes
                        isVatIncludedInPrice && listOfVatTaxRates && listOfVatTaxRates.length === 1 && listOfNonVatTaxRates && listOfNonVatTaxRates.length > 0? (
                          allTaxes.map((tax, index) => {
                            if (tax.isActive) {
                              return (
                                <div key={`tax-rate-cart-${index}`} style={{fontSize: '12px'}}>
                                  {tax.description_i18n[locale]}&nbsp;
                                  <FormattedMessage
                                      id="Floatcart.taxRate.vat.and.other.taxes.includedinprice"
                                      defaultMessage="included in price"
                                  />
                                  &nbsp;({
                                    tax.taxValueType === 'percent' ? 
                                    tax.taxValue + '%' 
                                    : cartTotal.currencyFormat + ' ' + round_up(((tax.taxValue + Number.EPSILON) * 100) / 100, 2)
                                  })
                                </div>
                              )
                            } else {
                              return null
                            }
                          })
                        ) : isVatIncludedInPrice && listOfVatTaxRates && listOfVatTaxRates.length > 1 && listOfNonVatTaxRates && listOfNonVatTaxRates.length > 0 ? (
                          listOfNonVatTaxRates.map((tax, index) => {
                            if (tax.isIncludedInPrices && tax.isActive) {
                              return (
                                <div key={`tax-rate-cart-${index}`} style={{fontSize: '12px'}}>
                                  {tax.description_i18n[locale]}&nbsp;
                                  <FormattedMessage
                                      id="Floatcart.taxRate.vat.and.other.taxes.includedinprice"
                                      defaultMessage="included in price"
                                  />
                                  &nbsp;({
                                    tax.taxValueType === 'percent' ? 
                                    tax.taxValue + '%' 
                                    : cartTotal.currencyFormat + ' ' + round_up(((tax.taxValue + Number.EPSILON) * 100) / 100, 2)
                                  })
                                </div>
                              )
                            } else {
                              return (
                                <div key={`tax-rate-cart-${index}`}>
                                  <div
                                    className={s.taxRateSub}
                                    style={locale === 'ar' ? {textAlign: 'right'} : {}}>
                                    <span style={{textAlign: 'left'}}>
                                      {tax.description_i18n[locale]}
                                    </span>
                                  </div>
                                  <div
                                    className={s.taxRateSubPrice}>
                                      <p 
                                          className={s.taxRateSubPriceVal}
                                          style={locale === 'ar' ? {textAlign: 'end'} : {}}>
                                              {cartTotal.currencyFormat}&nbsp;{round_up(((tax.taxValue + Number.EPSILON) * 100) / 100, 2)}
                                      </p>
                                  </div>
                                </div>
                              )
                            }
                          }).concat(["return 1 vat tax"].map(() => {
                            return (
                              <div key={`vat-tax-rate-cart-1`} style={{fontSize: '12px'}}>
                                {vatDescriptionI18n}&nbsp;
                                <FormattedMessage
                                    id="Floatcart.taxRate.vat.message"
                                    defaultMessage="included in prices"
                                />
                              </div>
                              )
                          }))
                          ) : (
                          <div style={{fontSize: '12px'}}>
                              {vatDescriptionI18n}&nbsp;
                              <FormattedMessage
                                  id="Floatcart.taxRate.vat.message"
                                  defaultMessage="included in prices"
                              />
                          </div>
                        )
                      )
                )
            }
        </div>
    ) : null;
  }
}

export default injectIntl(TaxRate);

