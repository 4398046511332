exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Properties_propertiesForListView__zcZ-h {\n  display: contents;\n  position: absolute;\n  padding-top: 10px; }\n  .Properties_propertiesForListView__zcZ-h div {\n    width: 35px;\n    height: 20px;\n    text-align: center; }\n    .Properties_propertiesForListView__zcZ-h div div {\n      border: none;\n      background-size: contain;\n      background-position: center;\n      background-repeat: no-repeat; }\n\n.Properties_propertiesLabelListViewFoodtypes__3POvj {\n  position: relative;\n  bottom: -11px;\n  font-size: 0.5rem;\n  color: black;\n  white-space: nowrap; }\n\n.Properties_propertiesLabelListViewAllergens__23wa6 {\n  position: relative;\n  top: -9px;\n  font-size: 0.5rem;\n  color: black;\n  white-space: nowrap; }\n", ""]);

// exports
exports.locals = {
	"propertiesForListView": "Properties_propertiesForListView__zcZ-h",
	"propertiesLabelListViewFoodtypes": "Properties_propertiesLabelListViewFoodtypes__3POvj",
	"propertiesLabelListViewAllergens": "Properties_propertiesLabelListViewAllergens__23wa6"
};