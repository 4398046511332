import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';

import Lightbox from 'react-images';

import LazyBackground from '../LazyBackground';

import s from './CarouselMenuItem.module.scss';

import fullscreenButton from '../../images/carousel/fullscreen.svg';


class CarouselMenuItem extends Component {

  static propTypes = {
    menuItem: PropTypes.object.isRequired,
    keyForId: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    isLightboxEnabled: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
       activeIndex: 0,
       theme: {
        arrow: {
          ':focus': {
            outline: 0,
          },
        },
        close: {
          ':focus': {
            outline: 0,
          },
        },
      },
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoImage = this.gotoImage.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.handleClickImage = this.handleClickImage.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    const { menuItem } = this.props;
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === menuItem.pictures.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    const { menuItem } = this.props;
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? menuItem.pictures.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  openLightbox(event) {
    event.preventDefault();
    this.setState({
      currentImage: 0,
      lightboxIsOpen: true,
    });
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }

  gotoImage(index) {
    this.setState({
      currentImage: index,
    });
  }

  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  handleClickImage() {
    if (this.state.currentImage === this.props.menuItem.pictures.length - 1) {
      this.closeLightbox()
    } else {
      this.gotoNext();
    }
  }

  render() {
    const { activeIndex } = this.state;

    const { menuItem } = this.props;

    // console.log('menuItem => ', menuItem)

    const slides = menuItem.pictures.map((picture, index) => {

      const stylingPicture = {};
      stylingPicture.backgroundImage = `url(${picture.formats && picture.formats.medium ? picture.formats.medium.url : picture.url})`;
      stylingPicture.height = this.props.height;
      //if (this.props.isLightboxEnabled) 
      //  stylingPicture.cursor = "pointer";

      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={picture.url + index}
        >
            <div className={s.productCardPhoto + " figure-img" + (menuItem.isActive?"":" disableWithGrayscale")}
                style={stylingPicture}>
            </div>
        </CarouselItem>
      );
    });

    const carouselIndicatorsItems = menuItem.pictures.map((picture) => {
        return {
            src: picture.url
        };
    });

    const arrayWithSelectedImage = menuItem ? menuItem.pictures.map((pic) => {return {src: pic.url}}): undefined;

    const fullScreenComponent = (
      <div className={s.fullscreenButtonContainer}>
        <button className={s.fullscreenButton} onClick={e => this.openLightbox(e)}>
          <span className={s.fullscreenButtonIcon}
            style={{backgroundImage: `url(${fullscreenButton})`}}>
          </span>
        </button>
        {
          menuItem && !this.props.isFetching ?
          <Lightbox
            currentImage={this.state.currentImage}
            images={arrayWithSelectedImage}
            isOpen={this.state.lightboxIsOpen}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            onClose={this.closeLightbox}
            onClickImage={this.handleClickImage}
            onClickThumbnail={this.gotoImage}
            backdropClosesModal
            enableKeyboardInput
            theme={this.state.theme}
          /> : null
        }
      </div>
    );

    return (
        menuItem.pictures.length > 1 ?
            <div id={'carouselMenuItem-' + this.props.keyForId} className={s.carouselContainer}>
              <Carousel
                  activeIndex={activeIndex}
                  next={this.next}
                  previous={this.previous}
                  slide
                  interval={false}
              >
                  <CarouselIndicators items={carouselIndicatorsItems} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                  {slides}
                  <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                  <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
              </Carousel>
              {this.props.isLightboxEnabled ? fullScreenComponent : null}
            </div>
            :
            (
                menuItem.pictures.length === 1 ?
                    <div id={'carouselMenuItem-' + this.props.keyForId} className={s.productCardContainer}>
                      <LazyBackground
                          height={'150px'}
                          className={s.productCardPhoto + " figure-img" + (menuItem.isActive?"":" disableWithGrayscale")}
                          src={menuItem.pictures[0].formats && menuItem.pictures[0].formats.medium ? menuItem.pictures[0].formats.medium.url : menuItem.pictures[0].url}
                          style={{ height: this.props.height}}>
                      </LazyBackground>
                      {this.props.isLightboxEnabled ? fullScreenComponent : null}
                    </div>
                    :
                    null
            )
    );
  }
}

CarouselMenuItem.defaultProps = {
  isLightboxEnabled: false
}


export default CarouselMenuItem;