import { combineReducers } from 'redux';
import navigation from './navigation';
import alerts from './alerts';
import locale from './locale';
import websocket from './websocket';
import eventSource from './eventSource';
import menu from './menu';
import menuitem from './menuitem';
import restaurant from './restaurant';
import cart from './cart';


export default combineReducers({
  alerts,
  navigation,
  locale,
  websocket,
  eventSource,
  menu,
  menuitem,
  restaurant,
  cart
});
