import React from "react";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
} from "reactstrap";

import "./DropdownCollectionTimeslots.module.scss";
import { injectIntl, FormattedMessage } from "react-intl";

class DropdownCollectionTimeslots extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.select = this.select.bind(this);
    this.state = {
      dropdownOpen: false,
      value: "",
      validTimeslots: [],
    };
  }

  componentDidMount() {
    if (this.props.collectTimeslots) {
      const validTimeslots = this.getValidTimeslotsForTheDay();
      // console.log('didmount validTimeslots => ', validTimeslots);
      // if there are valid timeslots
      if (validTimeslots.length > 0) {
        this.setState({
          value: validTimeslots[0].label,
          validTimeslots,
        });
        // by default select the first timeslot available in the list
        this.props.onChange(validTimeslots[0]);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.collectTimeslots !== nextProps.collectTimeslots) {
      const validTimeslots = this.getValidTimeslotsForTheDay();
      // console.log('willreceive validTimeslots => ', validTimeslots);
      if (validTimeslots.length > 0) {
        this.setState({
          value: validTimeslots[0].label,
          validTimeslots,
        });
        this.props.onChange(validTimeslots[0]);
      }
    }
  }

  // this function will only return the timeslots that are still valid for the day
  getValidTimeslotsForTheDay() {
    const currentDatetime = new Date();
    const currentTimeHours = currentDatetime.getHours();
    const currentTimeMinutes = currentDatetime.getMinutes();
    const { intl } = this.props;
    // console.log('dropdowncollectiontimeslot this.props.takeawayEnabled => ', this.props.takeawayEnabled);
    // console.log('dropdowncollectiontimeslot intl => ', intl);
    let resArray = [];
    if (this.props.takeawayEnabled) {
      // console.log('this.props.takeawayTimeslots => ', this.props.takeawayTimeslots)
      // we check if any of the takeaway timeslots are within the availability timeslots configured in the back office
      const tempArray = this.props.takeawayTimeslots.filter((timeslotObject) => {
        // console.log('timeslot Object => ', timeslotObject)
        // console.log('currentTimeHours => ', currentTimeHours)
        if (timeslotObject.toTimeHours > currentTimeHours && currentTimeHours > timeslotObject.fromTimeHours ) {
          return true;
        }
        if (timeslotObject.toTimeHours === currentTimeHours && currentTimeHours > timeslotObject.fromTimeHours ) {
          if (timeslotObject.toTimeMinutes > currentTimeMinutes) {
            return true;
          }
        }
        if (timeslotObject.fromTimeHours === currentTimeHours && timeslotObject.toTimeHours === currentTimeHours) {
          if (timeslotObject.toTimeMinutes > currentTimeMinutes && currentTimeMinutes > timeslotObject.fromTimeMinutes) {
            return true;
          }
          return false;
        } else {
          if (timeslotObject.fromTimeHours === currentTimeHours && timeslotObject.toTimeHours > currentTimeHours) {
            if (currentTimeMinutes > timeslotObject.fromTimeMinutes) {
              return true;
            }
            return false;
          }
        }
  
        return false;
      });

      // console.log('tempArray takeaway => ', tempArray )

      // if there is a timeslot that is available
      if (tempArray.length > 0) {
        resArray.push({
          label: intl.formatMessage({
            id: "DropdownCollectionTimeslots.takeaway.option.label",
            defaultMessage: "Take away right now",
          }),
          id: 'takeaway'
        });
      }
    } else {
      // merge the available timeslots with the takeway mode  
      resArray = resArray.concat(this.props.collectTimeslots.filter((timeslotObject) => {
        if (timeslotObject.fromTimeHours > currentTimeHours) {
          return true;
        }
        if (timeslotObject.fromTimeHours === currentTimeHours) {
          if (timeslotObject.fromTimeMinutes > currentTimeMinutes) {
            return true;
          }
          return false;
        }

        return false;
      }));

      // sort array of timeslots
      resArray.sort((timeslotA, timeslotB) => {
        if (timeslotA.fromTimeHours < timeslotB.fromTimeHours)
          return -1;
        if (timeslotA.fromTimeHours > timeslotB.fromTimeHours)
          return 1;
        if (timeslotA.fromTimeHours === timeslotB.fromTimeHours) {
          if(timeslotA.fromTimeMinutes < timeslotB.fromTimeMinutes) {
            return -1;
          } else {
            if(timeslotA.fromTimeMinutes > timeslotB.fromTimeMinutes) {
              return 1;
            } else {
              if(timeslotA.fromTimeMinutes === timeslotB.fromTimeMinutes) {
                return 0;
              }
            }
          }
        }

        return 0;
      });
    }

    return resArray;
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  select(selectedCollectionTimeslot) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      value: selectedCollectionTimeslot.label,
    });
    this.props.onChange(selectedCollectionTimeslot);
  }

  render() {
    let componentToDisplay = {};
    if (this.state.validTimeslots && this.state.validTimeslots.length > 0) {
      let listOfTimeSlotsInDropdownItems = this.state.validTimeslots.map((validTimeslot) => {
        return (
          <DropdownItem
            key={validTimeslot.id}
            onClick={() => {
              this.select(validTimeslot);
            }}
          >
            {validTimeslot.label}
          </DropdownItem>
        );
      });

      componentToDisplay = (
        <Dropdown isOpen={this.state.dropdownOpen} size="lg" toggle={this.toggle}>
          <DropdownToggle caret color="white">
            {this.state.value}
          </DropdownToggle>
          <DropdownMenu>
            {listOfTimeSlotsInDropdownItems}
          </DropdownMenu>
        </Dropdown>
      );
    } else {
      componentToDisplay = this.props.takeawayEnabled ? (
        <span>
          <FormattedMessage
              id="DropdownCollectionTimeslots.NoCollectionTimeSlotAvailable.takeaway.Message"
              defaultMessage="Take away not available"
          />
        </span>
      ) : (
        <span>
          <FormattedMessage
              id="DropdownCollectionTimeslots.NoCollectionTimeSlotAvailable.clickandcollect.Message"
              defaultMessage="Click & Collect not available"
          />
        </span>
      );
    }

    return (componentToDisplay);
  }
}
export default injectIntl(DropdownCollectionTimeslots);
