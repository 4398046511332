import { MENUITEMS_REQUEST , MENUITEMS_SUCCESS, MENUITEMS_FAILURE,
         MENUITEM_DETAILS_REQUEST , MENUITEM_DETAILS_SUCCESS, MENUITEM_DETAILS_FAILURE} 
         from '../actions/menuitems';

// The menu reducer.
export default function menuitem(state = {
  isFetching: false,
  restaurantMenuItems: {},
  updatedMenuItem: {},
  menuitemDetails: {},
  errorMessage: ''
}, action) {
  switch (action.type) {
    case MENUITEMS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: ''
      });
    case MENUITEMS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        restaurantMenuItems: action.restaurantMenuItems,
        errorMessage: '',
      });
    case MENUITEMS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.message,
      });
    case MENUITEM_DETAILS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        errorMessage: ''
      });
    case MENUITEM_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        restaurantMenuItemDetails: action.restaurantMenuItemDetails,
        errorMessage: '',
      });
    case MENUITEM_DETAILS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.message,
      });
    default:
      return state;
  }
}
