import React from 'react';
import PropTypes from 'prop-types';
import {
  /*Input,
  Label,*/
  Badge
} from 'reactstrap';
import { withFormsy } from 'formsy-react';

import PrepTimeClock from '../PrepTimeClock/PrepTimeClock';

import {
  BootstrapTable,
  TableHeaderColumn,
} from 'react-bootstrap-table';

import {FormattedMessage, injectIntl} from 'react-intl';

import Widget from '../Widget/Widget';
import s from './PriceTable.modules.scss';
import PopupInfoMenuItemSize from '../PopupInfoMenuItemSize/PopupInfoMenuItemSize';

class PriceTable extends React.Component {

  static propTypes = {
    menuitem: PropTypes.object.isRequired,
    currency: PropTypes.object.isRequired,
    menugroupidforinput: PropTypes.string,
    menuid: PropTypes.string,
    isShowPrice: PropTypes.bool,
    isHidePriceColumn: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    isShowInWidget: PropTypes.bool,
    currentDeliveryMethod: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      menuitemSizes: this.props.menuitem.menuitemsizes,
      currencyForMenuitemSize: this.props.currency,
      locale: this.props.locale,
      showModalPopup: false,
      clickedMenuItemSize: {},
      selectedmenuitemsize: null
    };

    this.displayModalPopup = this.displayModalPopup.bind(this);
    this.sizeNameFormatter = this.sizeNameFormatter.bind(this);
    this.hideModalPopup = this.hideModalPopup.bind(this);
    this.priceFormatter = this.priceFormatter.bind(this);
    this.inputFormatter = this.inputFormatter.bind(this);
    this.changeValue = this.changeValue.bind(this);
  }

  componentDidMount() {

    this.props.menuitem.menuitemsizes.forEach(menuitemsize => {
      // if there is a menu item size that should be preselected
      // and that we did not set any value yet then we do it once
      if(!this.state.selectedmenuitemsize && menuitemsize.isPreselected) {
        this.setState({
          selectedmenuitemsize: menuitemsize
        });
        const valueToReturn = Object.assign({}, this.props.menuitem);
        valueToReturn.selectedmenuitemsize = menuitemsize;
        this.props.setValue(valueToReturn);
      }
    });
    
  }

  componentDidUpdate(prevProps) {
    if(prevProps.menuitem.id !== this.props.menuitem.id) {
      this.setState({
        menuitemSizes: this.props.menuitem.menuitemsizes,
        currencyForMenuitemSize: this.props.currency,
        locale: this.props.locale,
        selectedmenuitemsize: null
      });

      //console.log('this.state.selectedmenuitemsize => ', this.state.selectedmenuitemsize);

      this.props.menuitem.menuitemsizes.forEach(menuitemsize => {
        // if there is a menu item size that should be preselected
        // and that we did not set any value yet then we do it once
        if(!this.state.selectedmenuitemsize && menuitemsize.isPreselected) {
          this.setState({
            selectedmenuitemsize: menuitemsize
          });
          const valueToReturn = Object.assign({}, this.props.menuitem);
          valueToReturn.selectedmenuitemsize = menuitemsize;
          this.props.setValue(valueToReturn);
        }
      });
    }

    // if we are in combo mode then menus are switched by tabs
    // and if they have the same menuitems then they do not get updated
    // that is why we use the menu id to make sure we detect a change between
    // combo menus
    if(prevProps.menuid !== '' && prevProps.menuid !== this.props.menuid) {
      this.setState({
        menuitemSizes: this.props.menuitem.menuitemsizes,
        currencyForMenuitemSize: this.props.currency,
        locale: this.props.locale,
        selectedmenuitemsize: null
      });

      //console.log('this.state.selectedmenuitemsize => ', this.state.selectedmenuitemsize);

      this.props.menuitem.menuitemsizes.forEach(menuitemsize => {
        // if there is a menu item size that should be preselected
        // and that we did not set any value yet then we do it once
        if(!this.state.selectedmenuitemsize && menuitemsize.isPreselected) {
          this.setState({
            selectedmenuitemsize: menuitemsize
          });
          const valueToReturn = Object.assign({}, this.props.menuitem);
          valueToReturn.selectedmenuitemsize = menuitemsize;
          this.props.setValue(valueToReturn);
        }
      });
    }
  }

  displayModalPopup(menuItemSize) {
    //console.log('menuItemSize => ', menuItemSize);

    this.setState({
      showModalPopup: true,
      clickedMenuItemSize: menuItemSize
    });
  }

  hideModalPopup() {
    this.setState({
      showModalPopup: false,
      clickedMenuItemSize: {}
    });
  }

  sizeNameFormatter(cell, row) {
    let sizeNameUIComponent = (
      <div className="buttonLinkToDetailsPopup" onClick={() => this.displayModalPopup(row)}>
        {row.name_i18n[this.state.locale]}&nbsp;
        <i className="no-display-md fa fa-external-link" aria-hidden="true"></i>
      </div>
    );

    if (this.props.currentDeliveryMethod.includes('massageatspa') ||
        this.props.currentDeliveryMethod.includes('servicerequest')) {
      sizeNameUIComponent = (
        <div className="buttonLinkToDetailsPopup">
          {row.name_i18n[this.state.locale]}
        </div>
      )
    }

    return sizeNameUIComponent
  }

  priceFormatter(cell) {
    let price = cell
    if (price > 0) {
      if (this.state.currencyForMenuitemSize.numberOfDecimals <= 2) {
        price = parseFloat(price.toFixed(this.state.currencyForMenuitemSize.numberOfDecimals))
      } else {
        price = price.toFixed(this.state.currencyForMenuitemSize.numberOfDecimals)
      }
    }
    return (
      this.props.isShowPrice ?
      <Badge color="primary" className="priceMenuItem">
        {this.state.currencyForMenuitemSize.symbol} {price}
      </Badge>
      : 
      <Badge color="secondary" className="priceMenuItem">
        N/A
      </Badge>
    );
  };

  changeValue(event) {
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    const valueToReturn = Object.assign({}, this.props.menuitem);
    valueToReturn.selectedmenuitemsize = JSON.parse(event.currentTarget.value);
    //console.log('valueToReturn => ', valueToReturn);
    this.setState({
      selectedmenuitemsize: Object.assign({}, valueToReturn.selectedmenuitemsize)
    }, () => this.props.setValue(valueToReturn));
  }

  inputFormatter(cell, row) {

    const {menugroupidforinput} = this.props;

    //console.log('menugroupidforinput => ', menugroupidforinput);
    //console.log('selectedmenuitemsize => ', this.state.selectedmenuitemsize);
    //console.log('row => ', row);
    //console.log('checked => ', this.state.selectedmenuitemsize && (row.id === this.state.selectedmenuitemsize.id));

    return (
      <input type="radio"
            className="option-input radio"
             id={row.id} 
             name={menugroupidforinput !== '' ? "dishsize-" + menugroupidforinput : "dishsize"}
             value={JSON.stringify(row)} 
             checked={this.state.selectedmenuitemsize && (row.id === this.state.selectedmenuitemsize.id) ? true : false}
             onChange={this.changeValue}
             ></input>
    );
  }


  render() {
    const options = {
      sizePerPage: 10,
      paginationSize: 3,
    };

    const {menuitemSizes, 
          currencyForMenuitemSize, 
          showModalPopup,
          clickedMenuItemSize} = this.state;

    const {isHidePriceColumn, intl, currentDeliveryMethod, menuitem} = this.props;
    const { locale } = intl;

    function prepTimeFormatter(cell, row) {
      return (
        <div>
          {
            cell && cell > 0 ? <PrepTimeClock idForComponent={"priceTableRow-"+ row.id} timeToDisplay={cell} /> : <span>N/A</span>
          }
        </div>
      );
    }

    function caloriesFormatter(cell, row) {
      return (
        <div>
          {
            cell && cell > 0 ? cell : <span>N/A</span>
          }
        </div>
      );
    }

    console.log('currentDeliveryMethod => ', currentDeliveryMethod)

    const bootstrapTable = (
      <BootstrapTable data={menuitemSizes}
                          version="4" 
                          options={options} 
                          tableContainerClass={`table-striped table-hover ${s.bootstrapTable}`}
                          onClickCell={this.displayModalPopup}>
            <TableHeaderColumn className="d-none" columnClassName="d-none" dataField="id" isKey>
              <span className="fs-sm">ID</span>
            </TableHeaderColumn>
            <TableHeaderColumn className="colTenPercentWidth headerRadioButton"
                               columnClassName="colTenPercentWidth radiobutton"
                               dataField="name"
                               dataFormat={this.inputFormatter.bind(this)}>
            </TableHeaderColumn>
            <TableHeaderColumn dataField="name" 
                               className={locale === 'ar' ? 'colThirtyPercentWidth rtl' : 'colThirtyPercentWidth'}
                               columnClassName={locale === 'ar' ? 'colThirtyPercentWidth rtl' : 'colThirtyPercentWidth'}
                               dataFormat={this.sizeNameFormatter.bind(this)}>
              <span className="fs-sm">
                {
                  (
                    currentDeliveryMethod.includes('massageatspa') ||
                    currentDeliveryMethod.includes('spaviewonly') ||
                    currentDeliveryMethod.includes('servicerequest')
                  ) &&
                  this.props.menuitem.menuitemType === "spa_treatment" ? (
                  <FormattedMessage id="PriceTable.BootstrapTable.TableHeaderColumn.duration.label"
                                                defaultMessage="Duration" />
                  ) : (
                    currentDeliveryMethod.includes('massageatspa') ||
                    currentDeliveryMethod.includes('spaviewonly') ||
                    currentDeliveryMethod.includes('servicerequest')
                    ) &&
                  (this.props.menuitem.menuitemType === "spa_service" || this.props.menuitem.menuitemType === "service_request") ? (
                  <FormattedMessage id="PriceTable.BootstrapTable.TableHeaderColumn.description.label"
                                                defaultMessage="Description" />
                  ) : (
                    <FormattedMessage id="PriceTable.BootstrapTable.TableHeaderColumn.size.label"
                                                defaultMessage="Size" />
                  )
                }
              </span>
            </TableHeaderColumn>
            {
              !isHidePriceColumn ?
              <TableHeaderColumn dataField="price" 
                                className="colTwentyPercentWidth priceField"
                                columnClassName="colTwentyPercentWidth priceField"
                                dataFormat={this.priceFormatter.bind(this)}>
                <span className="fs-sm">
                  <FormattedMessage id="PriceTable.BootstrapTable.TableHeaderColumn.price.label"
                                                    defaultMessage="Price" />
                </span>
              </TableHeaderColumn>
              :null
            }
            {
              !currentDeliveryMethod.includes('massageatspa') && !currentDeliveryMethod.includes('servicerequest') ? (
                <TableHeaderColumn dataField="calories"
                  dataFormat={caloriesFormatter}
                  className="d-none d-md-table-cell colTwentyFivePercentWidth caloriesField"
                  columnClassName="d-none d-md-table-cell colTwentyFivePercentWidth caloriesField">
                <span className="fs-sm">
                <FormattedMessage id="PriceTable.BootstrapTable.TableHeaderColumn.calories.label"
                                                defaultMessage="Calories" />
                </span>
                </TableHeaderColumn>
              ) : null
            }
            {
              !currentDeliveryMethod.includes('massageatspa') && !currentDeliveryMethod.includes('servicerequest') ? (
                <TableHeaderColumn dataField="preparationTimeInMinutes" 
                          className="d-none d-md-table-cell colThirtyPercentWidth prepTimeField"
                          columnClassName="d-none d-md-table-cell colThirtyPercentWidth prepTimeField"
                          dataFormat={prepTimeFormatter}>
                <span className="fs-sm">
                <FormattedMessage id="PriceTable.BootstrapTable.TableHeaderColumn.preparationtime.label"
                                                defaultMessage="Prep. Time" />
                </span>
                </TableHeaderColumn>
              ) : null
            }
          </BootstrapTable>
    );

    return (
      <div>
        {
          this.props.isShowInWidget ?
          <Widget title={
                        (
                          currentDeliveryMethod.includes('massageatspa') ||
                          currentDeliveryMethod.includes('spaviewonly')
                        ) && menuitem.menuitemType === "spa_treatment" ? (
                          <h4>
                            <span className="fw-semi-bold">
                              <FormattedMessage id="PriceTable.widget.title.available.treament.durations"
                                                defaultMessage="Available treatment durations" />
                            </span>
                          </h4>
                        ) : 
                        (
                          (
                            currentDeliveryMethod.includes('massageatspa') ||
                            currentDeliveryMethod.includes('spaviewonly') ||
                            currentDeliveryMethod.includes('servicerequest')
                          )
                          && (menuitem.menuitemType === "spa_service" ||  menuitem.menuitemType === "service_request")? (
                          <h4>
                            <span className="fw-semi-bold">
                              <FormattedMessage id="PriceTable.widget.title.available.services"
                                                defaultMessage="Available services" />
                            </span>
                          </h4>
                          ) : (
                            <h4>
                              <span className="fw-semi-bold">
                                <FormattedMessage id="PriceTable.widget.title.available.dish.sizes"
                                                  defaultMessage="Available dish sizes" />
                              </span>
                            </h4>
                          )
                        )
                      } 
                collapse>
            {bootstrapTable}
          </Widget>
          :
          bootstrapTable
        }
        {
          showModalPopup ?
            <PopupInfoMenuItemSize 
                isModalHideDishOpen={showModalPopup} 
                menuItemSize={clickedMenuItemSize}
                currencyForMenuItemSize={currencyForMenuitemSize}
                locale={this.state.locale}
                isShowPrice={this.props.isShowPrice}
                onModalClosed={this.hideModalPopup} /> : null
        }
      </div>
    );
  }

}

PriceTable.defaultProps = {
  isShowPrice: true,
  isHidePriceColumn: false,
  isShowInWidget: true,
  menugroupidforinput: '',
  menuid: ''
};

export default withFormsy(injectIntl(PriceTable));
