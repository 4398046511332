import { getListOfRestaurantsFromServer, getRestaurantCollectionDetails, getRestaurantInfo } from '../api/restaurant.js';

export const RESTAURANTS_REQUEST = 'RESTAURANTS_REQUEST';
export const RESTAURANTS_SUCCESS = 'RESTAURANTS_SUCCESS';
export const RESTAURANTS_FAILURE = 'RESTAURANTS_FAILURE';

export const RESTAURANTCOLLECTION_REQUEST = 'RESTAURANTCOLLECTION_REQUEST';
export const RESTAURANTCOLLECTION_SUCCESS = 'RESTAURANTCOLLECTION_SUCCESS';
export const RESTAURANTCOLLECTION_FAILURE = 'RESTAURANTCOLLECTION_FAILURE';

export const RESTAURANT_INFO_REQUEST = 'RESTAURANT_INFO_REQUEST';
export const RESTAURANT_INFO_SUCCESS = 'RESTAURANT_INFO_SUCCESS';
export const RESTAURANT_INFO_FAILURE = 'RESTAURANT_INFO_FAILURE';

function requestRestaurants() {
  return {
    type: RESTAURANTS_REQUEST,
    isFetching: true
  };
}

function receiveRestaurants(listOfRestaurants) {
  return {
    type: RESTAURANTS_SUCCESS,
    isFetching: false,
    listOfRestaurants
  };
}

function restaurantsError(message) {
  return {
    type: RESTAURANTS_FAILURE,
    isFetching: false,
    message,
  };
}

function requestRestaurantInfo() {
  return {
    type: RESTAURANT_INFO_REQUEST,
    isFetching: true
  };
}

function receiveRestaurantInfo(restaurantInfo) {
  return {
    type: RESTAURANT_INFO_SUCCESS,
    isFetching: false,
    restaurantInfo
  };
}

function restaurantInfoError(message) {
  return {
    type: RESTAURANT_INFO_FAILURE,
    isFetching: false,
    message,
  };
}

function requestRestaurantCollection() {
  return {
    type: RESTAURANTCOLLECTION_REQUEST,
    isFetching: true
  };
}

function receiveRestaurantCollection(restaurantCollection) {
  return {
    type: RESTAURANTCOLLECTION_SUCCESS,
    isFetching: false,
    restaurantCollection
  };
}

function restaurantCollectionError(message) {
  return {
    type: RESTAURANTCOLLECTION_FAILURE,
    isFetching: false,
    message,
  };
}

export function getRestaurantInformation(restaurantId) {
  if (!restaurantId || restaurantId === 'undefined') return (dispatch) => dispatch(restaurantInfoError('No restaurantId provided'));
  return (dispatch) => {
    dispatch(requestRestaurantInfo());
    getRestaurantInfo(restaurantId)
        .then(restaurantInfo => {
          //console.log('in getMenusFromServer restaurantWithMenus  => ' + JSON.stringify(restaurantWithMenus));
          //localStorage.setItem('arrayOfMenus', arrayOfMenus);
          // Dispatch the success action with fake user
          if(restaurantInfo.errors) return dispatch(restaurantInfoError(restaurantInfo.errors[0].message));

          dispatch(receiveRestaurantInfo(restaurantInfo));
        })
        .catch(error => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          if (error.response && error.response.data) {
            return dispatch(restaurantInfoError(error.response.data.message));
          }
          return dispatch(restaurantInfoError(error.toString()));
        })
  };
}

// Logs the user out
export function actionGetRestaurantsFromServer() {
  return (dispatch) => {
    dispatch(requestRestaurants());
    getListOfRestaurantsFromServer()
        .then(listOfRestaurants => {
          //console.log('in getMenusFromServer restaurantWithMenus  => ' + JSON.stringify(restaurantWithMenus));
          //localStorage.setItem('arrayOfMenus', arrayOfMenus);
          // Dispatch the success action with fake user
          if(listOfRestaurants.errors) return dispatch(restaurantsError(listOfRestaurants.errors[0].message));

          dispatch(receiveRestaurants(listOfRestaurants));
        })
        .catch(error => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          if (error.response && error.response.data) {
            return dispatch(restaurantsError(error.response.data.message));
          }
          return dispatch(restaurantsError(error.toString()));
        })
  };
}

export function actionGetRestaurantCollectionFromServer(restaurantCollectionId) {
  return (dispatch) => {
    dispatch(requestRestaurantCollection());
    getRestaurantCollectionDetails(restaurantCollectionId)
        .then(restaurantCollectionObject => {
          //console.log('in getMenusFromServer restaurantWithMenus  => ' + JSON.stringify(restaurantWithMenus));
          //localStorage.setItem('arrayOfMenus', arrayOfMenus);
          // Dispatch the success action with fake user
          if(restaurantCollectionObject.errors) return dispatch(restaurantCollectionError(restaurantCollectionObject.errors[0].message));

          dispatch(receiveRestaurantCollection(restaurantCollectionObject));
        })
        .catch(error => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          if (error.response && error.response.data) {
            return dispatch(restaurantCollectionError(error.response.data.message));
          }
          return dispatch(restaurantCollectionError(error.toString()));
        })
  };
}