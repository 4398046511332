import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import s from "./HeaderMessage.module.scss";

class HeaderMessage extends Component {
  static propTypes = {};

  state = {};

  render() {
    const { locale } = this.props.intl;
    return (
        <div 
            className={s.headerMessageSection}
            style={locale === 'ar' ? {textAlign: 'right'} : {}}>
            <div style={{color: 'black', fontSize: '12px'}}>
                <FormattedMessage
                    id="Floatcart.headermessage.message"
                    defaultMessage="For any dish that is for take away please do indicate it in the 'Leave a note to kitchen' section"
                />
            </div>
        </div>
    );
  }
}

export default injectIntl(HeaderMessage);

