import { SOCKET_CONNECTION_INIT,
  SOCKET_CONNECTION_SUCCESS,
  SOCKET_CONNECTION_ERROR,
  SOCKET_CONNECTION_CLOSED,
  SOCKET_ON_MESSAGE_RECEIVED } from '../actions/websocket';

const initialState = {
  connected: false,
  readyState: null,
  socket: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SOCKET_CONNECTION_INIT:
      return Object.assign({}, state, {
        connected: false,
        socket: action.socket,
      });

    case SOCKET_CONNECTION_SUCCESS:
      return Object.assign({}, state, {
        connected: true,
      });

    case SOCKET_CONNECTION_ERROR:
      return Object.assign({}, state, {
        connected: false,
        socket: null,
        errorMessage: action.message
      });

    case SOCKET_CONNECTION_CLOSED:
      return Object.assign({}, state, {
        connected: false,
        socket: action.socket
      });

    case SOCKET_ON_MESSAGE_RECEIVED:
      return Object.assign({}, state, {
        connected: true,
        messageObject: action.messageObject,
      });

    default:
      return state;
  }
}