exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/**\n * Customized version of bootstrap using variables from _variables.scss.\n * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.\n */\n/*\n * Typography\n * ======================================================================== */\n/**\n * Custom application mixins available through out the app\n */\n/*html, body {\n    max-width: 100%;\n    overflow-x: hidden;\n    -webkit-overflow-scrolling: touch;\n}*/\n.HeaderMessage_headerMessageSection__2KQO_ {\n  margin-right: 15px;\n  margin-left: 15px;\n  margin-bottom: 10px; }\n  .HeaderMessage_headerMessageSection__2KQO_ div span {\n    font-size: 1.1rem;\n    color: crimson; }\n", ""]);

// exports
exports.locals = {
	"headerMessageSection": "HeaderMessage_headerMessageSection__2KQO_"
};