import React, { Component } from 'react';

import loadingGif from './loadingImage.gif';

class LazyBackground extends Component {
    state = { src: null };
  
    componentDidMount() {
      this._isMounted = true;
      const { src } = this.props;
  
      const imageLoader = new Image();
  
      imageLoader.onload = () => {
        if (this._isMounted)
            this.setState({ src });
      };

      imageLoader.src = src;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
  
    render() {
        const { height } = this.props;
  
        let componentToReturn;

        if(this.state.src) {
            componentToReturn = (
                <div {...this.props} 
                    style={{ backgroundImage: `url(${this.state.src})` }} />
            );
        } else {
            componentToReturn = (
                <div {...this.props} 
                    style={this.props.height ? {height: height} : {}}>
                    <div style={{ 
                            backgroundImage: `url(${loadingGif})`,
                            width: '45px',
                            height: '45px',
                            position: 'relative',
                            top: '38%',
                            left: '42%'
                        }} />
                </div>
            );
        }

      return componentToReturn;
    }
}

export default LazyBackground;