import { getListOfMenuItemsFromServerForRestaurantMenu, getMenuItemDetailsViaMenuCategory,
         getMenuItemDetailsViaMenuGroup, getMenuItemDetailsViaMenuitemsGroup } from '../api/menuitem.js';

export const MENUITEMS_REQUEST = 'MENUITEMS_REQUEST';
export const MENUITEMS_SUCCESS = 'MENUITEMS_SUCCESS';
export const MENUITEMS_FAILURE = 'MENUITEMS_FAILURE';

export const MENUITEM_DETAILS_REQUEST = 'MENUITEM_DETAILS_REQUEST';
export const MENUITEM_DETAILS_SUCCESS = 'MENUITEM_DETAILS_SUCCESS';
export const MENUITEM_DETAILS_FAILURE = 'MENUITEM_DETAILS_FAILURE';

function requestMenuItems() {
  return {
    type: MENUITEMS_REQUEST,
    isFetching: true
  };
}

function receiveMenuItems(restaurantMenuItems) {
  return {
    type: MENUITEMS_SUCCESS,
    isFetching: false,
    restaurantMenuItems
  };
}

function menuItemsError(message) {
  return {
    type: MENUITEMS_FAILURE,
    isFetching: false,
    message,
  };
}

function requestMenuItemDetails() {
  return {
    type: MENUITEM_DETAILS_REQUEST,
    isFetching: true
  };
}

function receiveMenuItemDetails(restaurantMenuItemDetails) {
  return {
    type: MENUITEM_DETAILS_SUCCESS,
    isFetching: false,
    restaurantMenuItemDetails
  };
}

function menuItemDetailsError(message) {
  return {
    type: MENUITEM_DETAILS_FAILURE,
    isFetching: false,
    message,
  };
}

// Get a list of menus items within a specific menu
export function getMenuItemsFromServer(restaurantId, menuId, deliveryMethod) {
  if (!restaurantId || restaurantId === 'undefined') return (dispatch) => dispatch(menuItemsError('No restaurantId provided'));
  return (dispatch) => {
    dispatch(requestMenuItems());
    getListOfMenuItemsFromServerForRestaurantMenu(restaurantId, menuId, deliveryMethod)
        .then(restaurantWithMenuItems => {
          // console.log('in getMenuItemsFromServer restaurantWithMenuItems  => ' + JSON.stringify(restaurantWithMenuItems));
          //localStorage.setItem('arrayOfMenus', arrayOfMenus);
          // Dispatch the success action with fake user
          if(restaurantWithMenuItems.errors) return dispatch(menuItemsError(restaurantWithMenuItems.errors[0].message));

          dispatch(receiveMenuItems(restaurantWithMenuItems));
        })
        .catch(error => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          if (error.response && error.response.data) {
            return dispatch(menuItemsError(error.response.data.message));
          }
          return dispatch(menuItemsError(error.toString()));
        })
  };
}

// Get a menu item details for a specific menu, menu category and menu group when applicable
export function getMenuItemDetailsFromServer(restaurantId, menuId, menuCategoryId, menuGroupid = null, menuitemsGroupid = null, orderedMenuItemId, deliveryMethod) {
  if (restaurantId === undefined) return (dispatch) => dispatch(menuItemDetailsError('No restaurantId provided'));
  return (dispatch) => {
    dispatch(requestMenuItemDetails());
    // menu group mode
    if(menuGroupid && !menuitemsGroupid) {
      getMenuItemDetailsViaMenuGroup(restaurantId, menuId, menuCategoryId, menuGroupid, orderedMenuItemId, deliveryMethod)
      .then(restaurantWithMenuItemDetails => {
        //console.log('in getMenuItemDetailsFromServer menugroup mode restaurantWithMenuItemDetails  => ' + JSON.stringify(restaurantWithMenuItemDetails));
        //localStorage.setItem('arrayOfMenus', arrayOfMenus);
        // Dispatch the success action with fake user
        if(restaurantWithMenuItemDetails.errors) return dispatch(menuItemDetailsError(restaurantWithMenuItemDetails.errors[0].message));

        dispatch(receiveMenuItemDetails(restaurantWithMenuItemDetails));
      })
      .catch(error => {
        //console.log('An error occurred inLoginUser: ', error.response.data.message);
        if (error.response && error.response.data) {
          return dispatch(menuItemDetailsError(error.response.data.message));
        }
        return dispatch(menuItemDetailsError(error.toString()));
      })
    } else {
      // menu item group mode
      if(menuGroupid && menuitemsGroupid) {
        getMenuItemDetailsViaMenuitemsGroup(restaurantId, menuId, menuCategoryId, menuGroupid, menuitemsGroupid, orderedMenuItemId, deliveryMethod)
        .then(restaurantWithMenuItemDetails => {
          //console.log('in getMenuItemDetailsFromServer menugroup mode restaurantWithMenuItemDetails  => ' + JSON.stringify(restaurantWithMenuItemDetails));
          //localStorage.setItem('arrayOfMenus', arrayOfMenus);
          // Dispatch the success action with fake user
          if(restaurantWithMenuItemDetails.errors) return dispatch(menuItemDetailsError(restaurantWithMenuItemDetails.errors[0].message));
  
          dispatch(receiveMenuItemDetails(restaurantWithMenuItemDetails));
        })
        .catch(error => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          if (error.response && error.response.data) {
            return dispatch(menuItemDetailsError(error.response.data.message));
          }
          return dispatch(menuItemDetailsError(error.toString()));
        })
      } else {
        // normal menu mode
        getMenuItemDetailsViaMenuCategory(restaurantId, menuId, menuCategoryId, orderedMenuItemId, deliveryMethod)
            .then(restaurantWithMenuItemDetails => {
              //console.log('in getMenuItemDetailsFromServer menucategory mode restaurantWithMenuItemDetails  => ' + JSON.stringify(restaurantWithMenuItemDetails));
              //localStorage.setItem('arrayOfMenus', arrayOfMenus);
              // Dispatch the success action with fake user
              if(restaurantWithMenuItemDetails.errors) return dispatch(menuItemDetailsError(restaurantWithMenuItemDetails.errors[0].message));

              dispatch(receiveMenuItemDetails(restaurantWithMenuItemDetails));
            })
            .catch(error => {
              //console.log('An error occurred inLoginUser: ', error.response.data.message);
              if (error.response && error.response.data) {
                return dispatch(menuItemDetailsError(error.response.data.message));
              }
              return dispatch(menuItemDetailsError(error.toString()));
            })
        }
      }
  };
}