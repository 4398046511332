exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/**\n * Customized version of bootstrap using variables from _variables.scss.\n * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.\n */\n/*\n * Typography\n * ======================================================================== */\n/**\n * Custom application mixins available through out the app\n */\n/*html, body {\n    max-width: 100%;\n    overflow-x: hidden;\n    -webkit-overflow-scrolling: touch;\n}*/\n.TaxRate_taxRateSub__3q7oc,\n.TaxRate_taxRateSubPrice__2OiTe {\n  color: white;\n  vertical-align: middle;\n  display: inline-block;\n  width: 60%;\n  font-size: 14px;\n  font-weight: 600; }\n\n.TaxRate_taxRateSub__3q7oc {\n  width: 40%;\n  text-align: left; }\n\n.TaxRate_taxRateSubPriceVal__vDal5 {\n  margin: 0;\n  color: white;\n  font-size: 18px;\n  text-align: right; }\n", ""]);

// exports
exports.locals = {
	"taxRateSub": "TaxRate_taxRateSub__3q7oc",
	"taxRateSubPrice": "TaxRate_taxRateSubPrice__2OiTe",
	"taxRateSubPriceVal": "TaxRate_taxRateSubPriceVal__vDal5"
};