import axios from 'axios';
//import runtimeEnv from '@mars/heroku-js-runtime-env';

export async function getListOfMenusFromServerForRestaurant (restaurantSlug, deliveryMethod) {
    //console.log('getListOfMenusFromServerForRestaurant => ', restaurantSlug);
    if (!restaurantSlug  || restaurantSlug === 'undefined') {
        return [];
    } else {
        // console.log('deliveryMethod => ', deliveryMethod);
        const isInDineInMode = deliveryMethod.isDineIn;
        // endpoint path
        const apiEndpointPath = '/graphql';
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const todayDate = new Date();
        const dayName = days[todayDate.getDay()];
        const graphqlQuery = `
        query GetListOfMenusForRestaurant {
            restaurantsForPublic(slug: "${restaurantSlug}") {
                id
                slug
                name
                outletLogoForCustomerApp { url formats }
                isCommentAllowedAtCheckout
                isDisplayTakeawayMessageForDineIn
                isDigitalMenuOnlyForDineIn
                discountOnDineOutOrders
                discountOnCollectAtKiosk
                isRepeatLastOrderAllowed
                deliveryMethodsConfiguration {
                    deliverymethod {
                        code
                    }
                    customerEmailForOrder
                    customerPhoneForOrder
                    groupCodeForOrder
                    customerFullnameForOrder
                    isActive
                    locationNumberLabelTranslations
                    overwriteLocationNumberLabel
                }
                taxRates {
                    isActive
                    isIncludedInPrices
                    description_i18n
                    type
                    taxValue
                    taxValueType
                }
                defaultLanguageForCustomerApp {
                    id
                    localeCode
                    imageURL
                    alt
                    label
                }
                paymentMethods {
                    code
                    isOnlinePaymentMethod
                }
                companyRegistrationNumber
                collectTimeslotsForWeek {
                    isActive
                    ${dayName} {
                        timeslots {
                            id
                            label
                            fromTimeHours
                            fromTimeMinutes
                            toTimeHours
                            toTimeMinutes
                        }
                    }
                }
                takeawayTimeslotsForWeek {
                    isActive
                    ${dayName} {
                        timeslots {
                            id
                            label
                            fromTimeHours
                            fromTimeMinutes
                            toTimeHours
                            toTimeMinutes
                        }
                    }
                }
                languagepacks {
                    id
                }
                deliverymethods {
                    code
                    label
                    isDineIn
                    isWithOnlinePayment
                    description_i18n
                    isCollectAtLocation
                }
                city {
                    name
                    momentJsTimezoneCode
                    timezone {
                        utc
                    }
                    country {
                        cioc
                        currency {
                            symbol
                            numberOfDecimals
                        }
                    }
                }
                menus(sort: "displayOrder:asc", where: { isActive: true }) {
                    id
                    userLabel
                    startTimeInHours
                    startTimeInMinutes
                    availabilityTimeslots
                    endTimeInHours
                    endTimeInMinutes
                    userLabel_i18n
                    availabilityDescription_i18n
                    isDisplayAvailabilityTimes
                    isAvailabilityGoingOverNextDay
                    isAlwaysVisible
                    themePicture {
                        url
                        formats
                    }
                    weekdaysAvailableOn (where: { code: "${dayName.toLowerCase()}" }) {
                        code
                    }
                    deliverymethods (where: { code: "${deliveryMethod.code}" }) {
                        code
                        label
                        isDineIn
                        isWithOnlinePayment
                        description_i18n
                        isCollectAtLocation
                    }
                    isActive
                    menucategories {
                        id
                        orderedmenuitems {
                            id
                        }
                        menugroups(where: { isActive: true, ${(isInDineInMode ? "isAvailableForDineIn" : "isAvailableForDineOut")}: true }) {
                            id
                        }
                    }
                }
            }
        }
        `
        // init runtime env var
        //const env = runtimeEnv();
        // Request API.
        // Only return active menus
        //  menucategories {\n    id\n    orderedmenuitems {\n    id\n   }\n   menugroups (where: {isActive: true, isAvailableForDineIn: true}) {\n   id\n   }\n   }\n
        return await axios
        .post( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
            '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
            ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath  + '?restaurantId=' + restaurantSlug, 
            {
                "query": graphqlQuery
            }
            )
        .then(response => {
            // Handle success.
            if(response.data.errors) return response.data;

            // Check if restaurantsForPublic exists and has at least one item
            if (!response.data.data.restaurantsForPublic || 
                !Array.isArray(response.data.data.restaurantsForPublic) || 
                response.data.data.restaurantsForPublic.length === 0) {
                // Return an empty restaurant object with an empty menus array
                return { menus: [] };
            }

            const foundRestaurant = response.data.data.restaurantsForPublic[0];
            
            // Check if foundRestaurant exists before accessing its properties
            if (!foundRestaurant) {
                // Return an empty restaurant object with an empty menus array
                return { menus: [] };
            }
            
            // Initialize menus array if it doesn't exist
            if (!foundRestaurant.menus) {
                foundRestaurant.menus = [];
            }
            
            // Filter menus to only include valid ones
            if (foundRestaurant.menus && foundRestaurant.menus.length > 0) {
                foundRestaurant.menus = foundRestaurant.menus.filter((menu) => {
                    // Check if menu has required properties
                    if (!menu || !menu.deliverymethods || !menu.menucategories || !menu.weekdaysAvailableOn) {
                        return false;
                    }
                    
                    const { deliverymethods, menucategories, weekdaysAvailableOn } = menu;

                    // Check if menucategories is an array
                    if (!Array.isArray(menucategories)) {
                        return false;
                    }

                    const filteredMenuCategories = menucategories.filter((menuCategory) => {
                        // Check if menuCategory has required properties
                        if (!menuCategory || !menuCategory.menugroups || !menuCategory.orderedmenuitems) {
                            return false;
                        }
                        
                        return menuCategory.menugroups.length > 0 || menuCategory.orderedmenuitems.length > 0;
                    });

                    // if we have non empty menu categories then we can keep the menu
                    return filteredMenuCategories.length > 0 && 
                           Array.isArray(deliverymethods) && deliverymethods.length > 0 && 
                           Array.isArray(weekdaysAvailableOn) && weekdaysAvailableOn.length > 0;
                });
            }

            // Return the restaurant with all its menus, even if none are currently available
            return foundRestaurant;
        })
        .catch(error => {
            console.error("Error fetching menus for restaurant:", error.message);
            return { menus: [] }; // Return an object with empty menus array on error
        });
    }
}

// Update the getRestaurantAvailability function to properly check menu availability
export async function getRestaurantAvailability(restaurantSlug, deliveryMethodCode) {
  try {
    // Create a delivery method object as expected by getListOfMenusFromServerForRestaurant
    const deliveryMethod = {
      code: deliveryMethodCode,
      // Set isDineIn based on the delivery method code
      isDineIn: deliveryMethodCode === 'dinein'
    };
    
    // Use the existing API to get menus for this restaurant and delivery method
    let response = await getListOfMenusFromServerForRestaurant(restaurantSlug, deliveryMethod);
    
    // Check if response is valid
    if (!response) {
      return false;
    }
    
    // Check if response is an error object
    if (response.errors) {
      return false;
    }
    
    // Handle different response structures
    // Some responses might be wrapped in a data property
    if (response.data && response.data.restaurantsForPublic && response.data.restaurantsForPublic[0]) {
      response = response.data.restaurantsForPublic[0];
    }
    
    // Check if response has menus property
    if (!response.menus) {
      return false;
    }
    
    // Check if menus is an array
    if (!Array.isArray(response.menus)) {
      return false;
    }
    
    // If there are no menus at all, the restaurant is closed
    if (response.menus.length === 0) {
      return false;
    }
    
    // Now we can safely check menu availability
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    const currentTimeInMinutes = currentHour * 60 + currentMinute;
    
    // Check if any menu is available now
    return response.menus.some(menu => {
      // Skip invalid menus
      if (!menu || typeof menu !== 'object') {
        return false;
      }
      
      // Check if the menu is active
      if (!menu.isActive) {
        return false;
      }
      
      // If the menu is always visible, it's available
      if (menu.isAlwaysVisible) {
        return true;
      }
      
      // Make sure all required properties exist
      if (typeof menu.startTimeInHours !== 'number' || 
          typeof menu.startTimeInMinutes !== 'number' ||
          typeof menu.endTimeInHours !== 'number' ||
          typeof menu.endTimeInMinutes !== 'number') {
        return false;
      }
      
      // Check if the current time is within the menu's availability window
      const startTimeInMinutes = menu.startTimeInHours * 60 + menu.startTimeInMinutes;
      const endTimeInMinutes = menu.endTimeInHours * 60 + menu.endTimeInMinutes;
      
      if (menu.isAvailabilityGoingOverNextDay) {
        // Handle menus that go past midnight
        if (currentTimeInMinutes >= startTimeInMinutes || currentTimeInMinutes <= endTimeInMinutes) {
          return true;
        }
      } else {
        // Normal case: start time < end time
        if (currentTimeInMinutes >= startTimeInMinutes && currentTimeInMinutes <= endTimeInMinutes) {
          return true;
        }
      }
      
      return false;
    });
  } catch (error) {
    console.error("Error checking restaurant availability:", error.message);
    return false;
  }
}