exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Clearfix_clearfix__L0ngN {\n  clear: both; }\n", ""]);

// exports
exports.locals = {
	"clearfix": "Clearfix_clearfix__L0ngN"
};