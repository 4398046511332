import { ADD_ITEM_TO_CART, REMOVE_ITEM_FROM_CART, LOAD_CART, UPDATE_CART, RESET_CART,
         POSTING_ORDER, ORDER_POSTED_SUCCESS, ORDER_POSTED_ERROR, ADD_NOTE_TO_ITEM} from '../actions/cart';

export default function cart(state = {
  cartProducts: [],
  data: {
    productQuantity: 0,
    totalPrice: 0,
  }
}, action) {
  switch (action.type) {
    case ADD_ITEM_TO_CART:
      return {
        ...state,
        productToAdd: action.productToAdd
      };
    case REMOVE_ITEM_FROM_CART:
      return {
        ...state,
        productToRemove: action.productToRemove
      };
    case LOAD_CART:
      return {
        ...state,
        cartProducts: [...action.products]
      };
    case RESET_CART:
        return {
          ...state,
          cartProducts: []
        };
    case UPDATE_CART:
      return {
        ...state,
        productToAdd: null,
        data: action.data
      };
    case POSTING_ORDER:
      return {
        ...state,
        isPostingOrder: true,
        errorMessage: ''
      };
    case ORDER_POSTED_SUCCESS:
      return {
        ...state,
        isPostingOrder: false,
        postedOrder: action.postedOrder,
        errorMessage: '',
      };
    case ORDER_POSTED_ERROR:
      return {
        ...state,
        isPostingOrder: false,
        errorMessage: action.message,
      };
    case ADD_NOTE_TO_ITEM:
        return {
          ...state,
          productToUpdateWithNote: action.productToUpdateWithNote,
        };
    default:
      return state;
  }
}
