import "./register-files";
import PDFDocument from "pdfkit";
import blobStream from "blob-stream";

const createInvoice = async (invoicesToPrint, callback, intl) => {
  // console.log("intl => ", intl);
  const pageHeight = 700;
  let doc = new PDFDocument({ size: [400, pageHeight], margin: 30 });
  for(let index = 0; index < invoicesToPrint.length; index++) {
    const invoice = invoicesToPrint[index];
    const { restaurant } = invoice;
    const response = await fetch(
      restaurant.logoUrl ? restaurant.logoUrl : "/apple-touch-icon.png"
    );
    const data = await response.blob();
    const base64String = await convertBlobToBase64(data);
    var img = new Buffer(
      base64String
        .replace("data:image/jpeg;base64,", "")
        .replace("data:image/png;base64,", "")
        .replace("data:image/jpg;base64,", ""),
      "base64"
    );

    doc
      .image(img, 36, 45, { width: restaurant.logoUrl ? 55 : 50 })
      .fillColor("#444444")
      .fontSize(10)
      .font("Courier-Bold")
      .text(restaurant.companyName, 120, 50, { align: "right" })
      .font("Courier")
      .text(restaurant.address1, 120, 65, { align: "right" })
      .text(restaurant.address2, 120, 80, { align: "right" })
      .text(restaurant.phoneNumber, 120, 95, { align: "right" })
      .text(intl.formatMessage({
        id: "PdfReceipt.companycocnumber.label",
        defaultMessage: "CRN",
      }) + ": " + restaurant.companyCoCNumber, 120, 110, { align: "right" })
      .moveDown();

      // generateHeader(doc);
      generateCustomerInformation(doc, invoice, intl);
      generateInvoiceTable(doc, invoice, intl);
      if (index < invoicesToPrint.length - 1) {
        doc.addPage();
      }
  } // end of loop
  generateFooter(doc, intl, invoicesToPrint.length);
  // pipe the document to a blob
  const stream = doc.pipe(blobStream());
  doc.end();
  stream.on("finish", function () {
    // or get a blob URL for display in the browser
    const blobUrl = stream.toBlobURL("application/pdf");
    // console.log("url pdfinvoice => ", url);
    callback(blobUrl);
  });
};

function convertBlobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
}

function generateCustomerInformation(doc, invoice, intl) {
  doc
    .fillColor("#444444")
    .fontSize(15)
    .text(
      intl.formatMessage({
        id: "PdfReceipt.receipt.title",
        defaultMessage: "Receipt",
      }),
      28,
      108,
      {
        align: "center",
        width: 67, // this parameter might have to be increased now based on Receipt word at the longest but in other languages can be longer
      }
    );

  generateHr(doc, 140);

  const customerInformationTop = 155;

  doc
    .fontSize(10)
    .text(
      intl.formatMessage({
        id: "PdfReceipt.ordernumber.label",
        defaultMessage: "Order Number",
      }) + ":",
      30,
      customerInformationTop
    )
    .font("Courier-Bold")
    .text(invoice.invoice_nr, 160, customerInformationTop)
    .font("Courier")
    .text(
      intl.formatMessage({
        id: "PdfReceipt.orderdate.label",
        defaultMessage: "Order Date",
      }) + ":",
      30,
      customerInformationTop + 15
    )
    .text(formatDate(invoice.orderDatetime), 160, customerInformationTop + 15)
    .text(
      intl.formatMessage({
        id: "PdfReceipt.paidamount.label",
        defaultMessage: invoice.orderPaymentStatus === "paid" ? "Paid amount" : "Amount to pay",
      }) + ":",
      30,
      customerInformationTop + 30
    )
    .font("Courier")
    .text(formatCurrency(invoice.currencySymbol, invoice.paid), 160, customerInformationTop + 30)

    /*.font("Helvetica-Bold")
    .text(invoice.shipping.name, 300, customerInformationTop)
    .font("Helvetica")
    .text(invoice.shipping.address, 300, customerInformationTop + 15)
    .text(
      invoice.shipping.city +
        ", " +
        invoice.shipping.state +
        ", " +
        invoice.shipping.country,
      300,
      customerInformationTop + 30
    )*/
    .moveDown();

  generateHr(doc, 207);
}

function generateInvoiceTableHeader(doc, invoiceTableTop, intl) {
  doc.font("Courier-Bold");
  generateTableRow(
    doc,
    invoiceTableTop,
    intl.formatMessage({
      id: "PdfReceipt.table.column.item.label",
      defaultMessage: "Item",
    }),
    intl.formatMessage({
      id: "PdfReceipt.table.column.unitcost.label",
      defaultMessage: "Unit Cost",
    }),
    intl.formatMessage({
      id: "PdfReceipt.table.column.quantity.label",
      defaultMessage: "Qty",
    }),
    intl.formatMessage({
      id: "PdfReceipt.table.column.total.label",
      defaultMessage: "Total",
    })
  );
  generateHr(doc, invoiceTableTop + 20);
  doc.font("Courier");
}

function generateInvoiceTable(doc, invoice, intl) {
  let i;
  // first page invoice table padding value
  let invoiceTableTop = 245;

  generateInvoiceTableHeader(doc, invoiceTableTop, intl);

  const totalPriceBeforeDiscount = invoice.totalPriceBeforeDiscount;
  const totalPriceAfterDiscount = invoice.subtotal;
  const isDiscountAppliedToThisInvoice = (totalPriceBeforeDiscount - totalPriceAfterDiscount > 0);
  const discountValue = totalPriceBeforeDiscount - totalPriceAfterDiscount;

  
  
  // max number of items per page
  let maxNumberOfItemsPerPage = 12;
  let itemIndex = 0;
  for (i = 0; i < invoice.items.length; i++) {
    itemIndex = i % maxNumberOfItemsPerPage;
    const item = invoice.items[i];

    // add new page when we have 
    if (i > 0 && itemIndex === 0) {
      doc.addPage();
      // second page or more invoice table header top spacing
      invoiceTableTop = 20;
      generateInvoiceTableHeader(doc, invoiceTableTop, intl);
    }

    const position = invoiceTableTop + (itemIndex + 1) * 30;
    // console.log(i, itemIndex, position, item.description,formatCurrency(invoice.currencySymbol, item.amount),item.quantity);

    generateTableRow(
      doc,
      position,
      item.description,
      formatCurrency(invoice.currencySymbol, item.amount),
      item.quantity,
      formatCurrency(invoice.currencySymbol, item.amount * item.quantity)
    );

    generateHr(doc, position + 20);
  }
  // increment itemIndex so we take into account the last menu item added in the menu items table
  itemIndex++;

  if (isDiscountAppliedToThisInvoice) {
    const totalPricePosition = invoiceTableTop + (i + 1) * 30;
    generateTableRow(
      doc,
      totalPricePosition,
      "",
      "",
      intl.formatMessage({
        id: "PdfReceipt.totalprice.label",
        defaultMessage: "Total",
      }),
      formatCurrency(invoice.currencySymbol, invoice.totalPriceBeforeDiscount)
    );

    const discountValuePosition = invoiceTableTop + (i + 1) * 36;
    generateTableRow(
      doc,
      discountValuePosition,
      "",
      "",
      intl.formatMessage({
        id: "PdfReceipt.discountvalue.label",
        defaultMessage: "Discount value",
      }),
      "-" + formatCurrency(invoice.currencySymbol, discountValue)
    );
  }

  const subtotalPosition = isDiscountAppliedToThisInvoice ? invoiceTableTop + (itemIndex + 1) * 42 : invoiceTableTop + (itemIndex + 1) * 30;
  // console.log("Subtotal", subtotalPosition, invoice.subtotal);
  generateTotalRow(
    doc,
    subtotalPosition,
    intl.formatMessage({
      id: "PdfReceipt.subtotal.label",
      defaultMessage: "Subtotal",
    }),
    formatCurrency(invoice.currencySymbol, invoice.subtotal)
  );

  const paidToDatePosition = subtotalPosition + 20;
  generateTotalRow(
    doc,
    paidToDatePosition,
    intl.formatMessage({
      id: "PdfReceipt.paidtodate.label",
      defaultMessage: "Paid To Date",
    }),
    formatCurrency(invoice.currencySymbol, invoice.orderPaymentStatus === "paid" ? invoice.paid : 0.00)
  );

  const duePosition = paidToDatePosition + 20;
  doc.font("Courier-Bold");
  generateTotalRow(
    doc,
    duePosition,
    intl.formatMessage({
      id: "PdfReceipt.balancedue.label",
      defaultMessage: "Balance Due",
    }),
    formatCurrency(invoice.currencySymbol, invoice.orderPaymentStatus === "paid" ? invoice.subtotal - invoice.paid : invoice.subtotal)
  );
  doc.font("Courier");
}

function generateFooter(doc, intl) {
  doc.fontSize(10).text(intl.formatMessage({
    id: "PdfReceipt.footer.thankyouforyourorder.text",
    defaultMessage: "Thank you for your order",
  }), 30, 640, {
    align: "center",
    width: 335,
  });
  doc.fontSize(8).text(intl.formatMessage({
    id: "PdfReceipt.footer.poweredby.text",
    defaultMessage: "Powered by Alacarte",
  }), 30, 660, {
    align: "center",
    width: 335,
  });
}

function generateTableRow(doc, y, description, unitCost, quantity, lineTotal) {
  doc
    .fontSize(10)
    .text(description, 30, y, { width: 160 })
    .text(unitCost, 180, y, { width: 70, align: "right" })
    .text(quantity, 220, y, { width: 70, align: "right" })
    .text(lineTotal, 0, y, { align: "right" });
}

function generateHr(doc, y) {
  doc.strokeColor("#aaaaaa").lineWidth(1).moveTo(30, y).lineTo(370, y).stroke();
}

function generateTotalRow(doc, y, description, lineTotal) {
  doc
    .fontSize(10)
    .text(description, 170, y, { width: 120, align: "right" })
    .text(lineTotal, 0, y, { align: "right" });
}

function formatCurrency(currencySymbol, cents) {
  return currencySymbol + (cents / 100).toFixed(2);
}

function formatDate(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  var formattedDay = ("0" + day).slice(-2);
  var formattedMonth = ("0" + month).slice(-2);
  var formattedHours = ("0" + hours).slice(-2);
  var formattedHMinutes = ("0" + minutes).slice(-2);

  return (
    year +
    "/" +
    formattedMonth +
    "/" +
    formattedDay +
    " - " +
    formattedHours +
    ":" +
    formattedHMinutes
  );
}

export default createInvoice;
