import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Breadcrumb,
  BreadcrumbItem,
  Badge,
  Row,
  Col,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import $ from 'jquery';

import queryString from 'query-string'

import Allergens from '../../components/Allergens/Allergens';
import { GAEvent } from "../../components/Tracking/Tracking";

import { showSuccessMessage } from '../../components/ToastMessenger';
import { navigateToNewPage } from '../../actions/navigation';

import { getMenuItemsFromServer } from '../../actions/menuitems';
import { addProduct } from '../../actions/cart';

import FilterElement from './components/FilterElement/FilterElement';
//import RealPictureRibbon from './components/RealPictureRibbon/RealPictureRibbon';
import RealPictureText from '../../components/RealPictureText/RealPictureText';
import CarouselMenuItem from '../../components/CarouselMenuItem/CarouselMenuItem';
//import ProductCard from './components/ProductCard/ProductCard';
import MobileModal from './components/MobileModal/MobileModal';

import AddToCartIcon from './assets/add-to-bag-icon.png';
//import MoreDetailsIcon from './assets/more-details.png';


import { FormattedMessage, injectIntl } from 'react-intl'; 
import ProgressIndicator from '../../components/ProgressIndicator';
import MenuitemgroupsGallery from './components/MenuitemgroupsGallery';
import Formsy from 'formsy-react';
import LazyLoad from 'react-lazyload';

import s from './Menuitems.module.scss';

import chefHat from '../../images/chef/chef-hat.svg';

//import PullToRefresh from 'pulltorefreshjs';


// Not best practice but this flag has been added to avoid a double call to the server
// looking at the isFetching property connected to the reducer which is not fast enougth
// changing value when a call to the backend is sent in the didupdate function and end
// up with another useless call to the server before the isFetching property is updated
// by default this flag is at false indicating that no call has been made to the server
// as soon as a call is made then it is put as true and only back to false when the
// component is unmounted
let isAFetchFromApiInProgress = false;

let isScrollToTopRequired = false;

class Menuitems extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    const {intl} = this.props;

    this.pulltorefresh = null;

    this._isMounted = false;
    this.activeMenuCategory = 'all';
    this.activeMenuCategoryId = null;

    const allString = intl.formatMessage({ id: "all.message.for.filters", defaultMessage: 'All'});
    const foodCategoriesFilterLabel = intl.formatMessage({ id: "MenuItems.filter.food.category", defaultMessage: 'Food Categories'});
    const foodTypesFilterLabel = intl.formatMessage({ id: "MenuItems.filter.food.type", defaultMessage: 'Food Types'});

    this.state = {
      activeMenuCategoryIdInState: null,
      tooltipsRecommendedByTheChef: [],
      tooltipRefreshDataFromServer: [false],
      popoversMenuItemSize: [],
      tooltipMenuCategory: [],
      foodCategoriesFilterLabel,
      foodTypesFilterLabel,
      filtersData: [],
      allString,
      foodTypeFilter: allString,
      foodCategoryFilter: allString,
      activeFilter: allString,
      isModalActive: false,
      modalId: null,
      order: null,
      submitMenuGroupButtonEnabled: false
    };

    this.refreshMenuItemsFromServer = this.refreshMenuItemsFromServer.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.filterMenuItemsByMenuCategory = this.filterMenuItemsByMenuCategory.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.filterMenuItemsBasedOnSelectedFilters = this.filterMenuItemsBasedOnSelectedFilters.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.retrieveListOfMenuCategories = this.retrieveListOfMenuCategories.bind(this);
    this.retrieveMenuItemsFilters = this.retrieveMenuItemsFilters.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.addMenuItemToCart = this.addMenuItemToCart.bind(this);
    this.animateMenuItemToCart = this.animateMenuItemToCart.bind(this);
    this.scrollToMenuItem = this.scrollToMenuItem.bind(this);
    this.dispatchPageNavigationToHeader = this.dispatchPageNavigationToHeader.bind(this);
    this.enableMenuGroupSubmitButton = this.enableMenuGroupSubmitButton.bind(this);
    this.disableMenuGroupSubmitButton = this.disableMenuGroupSubmitButton.bind(this);
    this.submitMenuGroup = this.submitMenuGroup.bind(this);
    this.animateMenuGroupToCart = this.animateMenuGroupToCart.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  componentDidMount() {
    // adding an event listener on touchmove for mobile scrolling to activate or deactivate
    // the pull to refresh
    //document.addEventListener("touchmove", this.checkIfElementIsIntheViewport, false);
    this._isMounted = true;
    //const {intl} = this.props;
    // by default if in menu category mode we select the 'all' tab
    this.activeMenuCategory = 'all';
    // get the query parameters
    let params = '';
    if(this.props.location.search !== '') {
      params = queryString.parse(this.props.location.search);
      isScrollToTopRequired = false;
    } else {
      if(this.props.location.hash !== '') {
        const withoutHash = this.props.location.hash.substring(this.props.location.hash.indexOf('?'));
        params = queryString.parse(withoutHash);
        isScrollToTopRequired = false;
      } else {
        isScrollToTopRequired = true;
      }
    }
    // if deeplink with query parameters then we select the right
    // menu category or menu group directly instead of pointing to
    // the first in the list
    this.activeMenuCategoryId = params.menucategoryid || params.menugroupid;
    if(this.activeMenuCategoryId === undefined)
      this.activeMenuCategoryId = null;

    // dispatch back button url and page title name
    this.dispatchPageNavigationToHeader();

    if (this.props.restaurantInfo && this.props.restaurantMenuItems.restaurant === undefined && !this.props.isFetching && !isAFetchFromApiInProgress) {
      //console.log('calling server to get menu items in componentDidMount');
      this.refreshMenuItemsFromServer(this.props.match.params.menuid);
    }

    const restaurantCollectionId = queryString.parse(this.props.location.search).rcid;

    if(!restaurantCollectionId) {
      // remove localstorage item if exists
      // Remove localStorage items with a pattern in their name
      Object.keys(localStorage).forEach(key => {
        if (key.startsWith('restaurantCollection-slug-')) {
          localStorage.removeItem(key);
        }
      });
    } else {
      localStorage.setItem(`restaurantCollection-slug-${restaurantCollectionId}`, restaurantCollectionId);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //console.log('nextProps => ', nextProps);
    let params = '';
    if(nextProps.location.search !== '') {
      params = queryString.parse(nextProps.location.search);
    } else {
      if(nextProps.location.hash !== '') {
        const withoutHash = nextProps.location.hash.substring(nextProps.location.hash.indexOf('?'));
        params = queryString.parse(withoutHash);
      }
    }
    //console.log('derived state params url => ', params);
    const nextActiveMenuCategoryId = params.menucategoryid || params.menugroupid;
    // if we have a paremeter in query string
    if(nextActiveMenuCategoryId !== undefined) {
      // if we had an active menu category in the state
      if(nextActiveMenuCategoryId !== prevState.activeMenuCategoryIdInState) {
        //console.log('prevState.activeMenuCategoryIdInState => ', prevState.activeMenuCategoryIdInState);
        //console.log('nextActiveMenuCategoryId => ', nextActiveMenuCategoryId);
        // then we re render to display the menu items of the new menu category or menu group
        //console.log('we have moved to another item');
        return {
          activeMenuCategoryIdInState: nextActiveMenuCategoryId
        };
      }
    } else {
      return {
        activeMenuCategoryIdInState: null
      };
    }

    return null;
  }

  dispatchPageNavigationToHeader(strTitleToDisplay = '') {
    const { dispatch, intl } = this.props;
    const params = this.props.match.params;
    const restaurantCollectionId = queryString.parse(this.props.location.search).rcid;
    const queryStringParamMode = queryString.parse(this.props.location.search).mode
    const queryStringParamLocationNumber = queryString.parse(this.props.location.search).locationnumber
    const queryStringParamGroupCode = queryString.parse(this.props.location.search).groupcode
    const deliveryMethod = this.props.match.params.deliverymethod;

    if(restaurantCollectionId) {
      dispatch(navigateToNewPage(
        this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant && strTitleToDisplay === '' ?
         this.props.restaurantMenuItems.restaurant.menus[0].userLabel_i18n[intl.locale] : strTitleToDisplay, 
      `/app/${deliveryMethod}/restaurant/${params.restaurantid}/menus?rcid=${restaurantCollectionId}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamLocationNumber ? '&locationnumber=' + queryStringParamLocationNumber : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`));
    } else {
      if (queryStringParamLocationNumber) {
        dispatch(navigateToNewPage(
          this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant && strTitleToDisplay === '' ?
           this.props.restaurantMenuItems.restaurant.menus[0].userLabel_i18n[intl.locale] : strTitleToDisplay, 
        `/app/${deliveryMethod}/restaurant/${params.restaurantid}/menus?locationnumber=${queryStringParamLocationNumber}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`));
      } else {
        dispatch(navigateToNewPage(
          this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant && strTitleToDisplay === '' ?
           this.props.restaurantMenuItems.restaurant.menus[0].userLabel_i18n[intl.locale] : strTitleToDisplay, 
        `/app/${deliveryMethod}/restaurant/${params.restaurantid}/menus${queryStringParamMode ? '?mode=' + queryStringParamMode : '?'}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`));
      }
    }
  }

  componentDidUpdate(prevProps) {
    if(isScrollToTopRequired) {
      this.scrollToTop();
      isScrollToTopRequired = false;
    }
    //console.log('menu items newProps => ', newProps);
    let params = '';
    if(this.props.location.search !== '') {
      params = queryString.parse(this.props.location.search);
    } else {
      if(this.props.location.hash !== '') {
        const withoutHash = this.props.location.hash.substring(this.props.location.hash.indexOf('?'));
        params = queryString.parse(withoutHash);
      }
    }

    let previousParams = '';
    if(prevProps.location.search !== '') {
      previousParams = queryString.parse(prevProps.location.search);
    } else {
      if(this.props.location.hash !== '') {
        const withoutHash = prevProps.location.hash.substring(prevProps.location.hash.indexOf('?'));
        previousParams = queryString.parse(withoutHash);
      }
    }

    let currentMenuCategoryId = params.menucategoryid || params.menugroupid;
    let previousMenuCategoryId = previousParams.menucategoryid || previousParams.menugroupid || this.activeMenuCategoryId;
    const currentLoadedURLTimestamp = params.ts;
    const currentTimeStamp = new Date().getTime();
    const isUserGoingBackInBrowserHistory = currentLoadedURLTimestamp ? (currentTimeStamp - currentLoadedURLTimestamp) > 0 : false;
    //console.log('isUserGoingBackInBrowserHistory => ', isUserGoingBackInBrowserHistory);
    // as the one in the state would have become the new category menu id or menu group id
    // as it would have been updated in getDerivedStateFromProps
    //console.log('this.state.activeMenuCategoryIdInState => ', this.state.activeMenuCategoryIdInState);
    //console.log('this.activeMenuCategoryId => ', this.activeMenuCategoryId);
    if(this.activeMenuCategoryId === undefined)
      this.activeMenuCategoryId = null;
    if(currentMenuCategoryId === undefined)
      currentMenuCategoryId = null;
    //console.log('this.activeMenuCategoryId #22 => ', this.activeMenuCategoryId);
    //console.log('this.state.activeMenuCategoryIdInState #22 => ', this.state.activeMenuCategoryIdInState);
    //console.log('previousMenuCategoryId => ', previousMenuCategoryId);
    // if we just loaded the page and we are going back in history the 
    // state stays undefined and then we refresh data from the server
    if(this.props.restaurantInfo && isUserGoingBackInBrowserHistory &&
      this.state.activeMenuCategoryIdInState &&
      this.activeMenuCategoryId !== this.state.activeMenuCategoryIdInState &&
      !this.props.isFetching && !prevProps.isFetching) {
      //console.log('reloading history');
      this.activeMenuCategoryId = this.state.activeMenuCategoryIdInState;
      isAFetchFromApiInProgress = false;
      this.refreshMenuItemsFromServer(this.props.match.params.menuid);
    } else {
      if(this.props.restaurantInfo && !this.state.activeMenuCategoryIdInState &&
        this.activeMenuCategoryId && isUserGoingBackInBrowserHistory &&
        !this.props.isFetching && !prevProps.isFetching) {
        //console.log('this.activeMenuCategoryId ###ZZZ => ', this.activeMenuCategoryId);
        this.activeMenuCategoryId = null;
        isAFetchFromApiInProgress = false;
        this.refreshMenuItemsFromServer(this.props.match.params.menuid);
        //this.forceUpdate();
      }
    }

    //console.log('currentMenuCategoryId => ', currentMenuCategoryId);
    //console.log('previousMenuCategoryId => ', previousMenuCategoryId);

    if(currentMenuCategoryId && previousMenuCategoryId &&
      currentMenuCategoryId !== previousMenuCategoryId) {
      //console.log(this.activeMenuCategoryId);
      //console.log('resetting menugroup submit button', this.refs.formMenuGroup.getModel());
      // reset form menu group values as we switched combo menu
      // we check if the model has been reset so we do not reset it at every render
      //console.log('this.refs.formMenuGroup.getModel() => ', this.refs.formMenuGroup.getModel());
      if(this.refs.formMenuGroup && 
        !this.refs.formMenuGroup.getModel().listOfSelectedMenuItemsForMenuGroup
          .every((currentValue) => {
            //console.log(currentValue === undefined);
            return currentValue === undefined;
          })) {
        // console.log('reseting formsy form');
        this.refs.formMenuGroup.reset();
      }
    }

    //console.log('menu items this.props => ', this.props);
    if(this.props.restaurantInfo && this.props.restaurantMenuItems.restaurant === undefined &&
      prevProps.restaurantMenuItems.restaurant === undefined &&
       !this.props.isFetching && !prevProps.isFetching && !isAFetchFromApiInProgress) {
        //console.log('calling server 1');
        isAFetchFromApiInProgress = false;
        this.refreshMenuItemsFromServer(this.props.match.params.menuid);
    }

    // when a hash is positioned in the url then it means we want to jump to an anchor on a
    // specific menu item
    if (this.props.location.hash && this.props.location.hash.includes('#menuitem-link-')) {
      // when the selected category is in the state and in the active menu category
      // which means we have now selected the right tab then we scroll to the menu
      // item in this tab
      //console.log('this.state.activeMenuCategoryIdInState => ', this.state.activeMenuCategoryIdInState);
      //console.log('this.activeMenuCategoryId => ', this.activeMenuCategoryId);
      if(this.state.activeMenuCategoryIdInState === this.activeMenuCategoryId) {
        //console.log('scroll to menu item => ', this.props.location.hash);
        this.scrollToMenuItem();
      }
    }

    //console.log('this.props.match.params.id => ', this.props.match.params.id);
    //console.log('newProps.match.params.id => ', newProps.match.params.id);
    // console.log('this.props.isFetching => ', this.props.isFetching);
    // if we are loading another menu then we call the server to get this new menu
    // dishes
    //console.log('menus newProps', newProps);
    //console.log('menus this.props', this.props);
    //console.log('isAFetchFromApiInProgress => ', isAFetchFromApiInProgress);
    if (this.props.restaurantInfo && this.props.restaurantMenuItems.restaurant !== undefined &&
      this.props.restaurantMenuItems.restaurant !== null &&
      this.props.restaurantMenuItems.restaurant.menus[0].id !== prevProps.match.params.menuid &&
      !this.props.isFetching && !prevProps.isFetching && !isAFetchFromApiInProgress) {
      //console.log('####calling server to get menu items from component did update');
      //console.log('calling server 2');
      isAFetchFromApiInProgress = false;
      this.refreshMenuItemsFromServer(prevProps.match.params.menuid);
    }

    if(!this.props.isFetching) {
      // dispatch back button url and page title name
      this.dispatchPageNavigationToHeader();
    } else {
      this.dispatchPageNavigationToHeader('...');
    }

    //console.log('newProps.updatedMenuItem => ', newProps.updatedMenuItem);
    //console.log('this.props.updatedMenuItem => ', this.props.updatedMenuItem);
    // if we did update a menu item (which means the previously updated one has a different id than the new one or
    // if we are updating the same one as the previous one then its isActive state should be different)
    if (this.props.restaurantInfo && this.props.updatedMenuItem.id && 
      (prevProps.updatedMenuItem.id !== this.props.updatedMenuItem.id || prevProps.updatedMenuItem.isActive !== this.props.updatedMenuItem.isActive) ) {
      showSuccessMessage(this.props.updatedMenuItem.name + " " + this.props.intl.formatMessage({ id: "MenuItems.message.menuitem.isactive.update.success", defaultMessage: 'successfully updated'}));
      //console.log('calling server 3');
      isAFetchFromApiInProgress = false;
      this.refreshMenuItemsFromServer(prevProps.match.params.menuid);
    }


    if(this.props.websocketmessage && !prevProps.websocketmessage && !this.props.isFetching) {
      // console.log('call 1 - this.props.websocketmessage => ', this.props.websocketmessage);
      isAFetchFromApiInProgress = false;
      this.refreshMenuItemsFromServer(prevProps.match.params.menuid);
    }

    if(prevProps.websocketmessage && this.props.websocketmessage &&
      prevProps.websocketmessage.uuid !== this.props.websocketmessage.uuid && !this.props.isFetching) {
        // console.log('call 2 - this.props.websocketmessage => ', this.props.websocketmessage);
        isAFetchFromApiInProgress = false;
        this.refreshMenuItemsFromServer(prevProps.match.params.menuid);
    }
  }

  componentWillUnmount() {
    // put the flag back to false so when the component will be loaded again we will
    // be able to call the server
    isAFetchFromApiInProgress = false;
    this._isMounted = false;
    this.activeMenuCategory = 'all';
    this.activeMenuCategoryId = null;
    isScrollToTopRequired = false;
    // removing the event listener on touchmove
    //document.removeEventListener("touchmove", this.checkIfElementIsIntheViewport, false);
    // Don't forget to destroy all instances on unmout
    // or you will get some glitches.
    //this.pulltorefresh.destroy();
  }

  scrollToTop() {
    //console.log(elementId);
    const el = document.getElementById('breadcrumb-menuitems-list');
    if (el) {
      el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }
  }

  scrollToMenuItem() {
    // get the element id to which we want to jump
    const elementId = this.props.location.hash.substring(
      this.props.location.hash.indexOf('#menuitem-link-') + 1,
      this.props.location.hash.indexOf('?')
    );
    //console.log(elementId);
    const el = document.getElementById(elementId);
    //console.log('el => ', el);
    if (el) {
      el.scrollIntoView({behavior: "auto", block: "end", inline: "nearest"});
      // remove the hash anchor from the url
      const windowLocationHash = window.location.hash;
      window.location.hash = windowLocationHash.replace('#' + elementId, '');
    }
  }

  // list of menu categories or menu groups
  retrieveListOfMenuCategories() {
    let menuCategories = [];
    const {locale} = this.props.intl;

    //console.log('this.activeMenuCategory (retrieveListOfMenuCategories) => ', this.activeMenuCategory);

    // if we are in menugroup mode
    if(this.props.restaurantMenuItems &&
      this.props.restaurantMenuItems.restaurant &&
      this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length === 1 &&
      this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length === 0) {
      menuCategories =  this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].menugroups;
      // select the first menu group 
      //console.log('we are in menugroup mode @@@@@@@@@@@@@@@@');
      if(this.activeMenuCategoryId === null && menuCategories.length > 0) {
        let params = '';
        if(this.props.location.search !== '') {
          params = queryString.parse(this.props.location.search);
        } else {
          if(this.props.location.hash !== '') {
            const withoutHash = this.props.location.hash.substring(this.props.location.hash.indexOf('?'));
            params = queryString.parse(withoutHash);
          }
        }
        const currentMenuGroupIdInURL = params.menugroupid;
        this.activeMenuCategoryId = currentMenuGroupIdInURL || menuCategories[0].id;
        if(this.activeMenuCategoryId) {
          const filteredArrayOfMenugroups = menuCategories.filter((mg) => mg.id === this.activeMenuCategoryId);
          if(filteredArrayOfMenugroups.length > 0)
            this.activeMenuCategory = filteredArrayOfMenugroups[0].name_i18n[locale];
        }
        else
          this.activeMenuCategory = menuCategories[0].name_i18n[locale];
        //console.log('this.activeMenuCategory ## 1 => ', this.activeMenuCategory);
      } else {
        //console.log('this.activeMenuCategoryId (retrieveListOfMenuCategories) => ', this.activeMenuCategoryId);
        //console.log('menuCategories ## 1.5 => ', menuCategories); 
        const filteredArrayOfMenugroups = menuCategories.filter((mg) => mg.id === this.activeMenuCategoryId);
        if(filteredArrayOfMenugroups.length > 0) {
          this.activeMenuCategoryId = filteredArrayOfMenugroups[0].id;
          this.activeMenuCategory = filteredArrayOfMenugroups[0].name_i18n[locale];
        }
        //console.log('this.activeMenuCategory ## 1.5 => ', this.activeMenuCategory);
      }
    } else {
      // menu categories mode
      //console.log('we are in menu category mode $$$$$$$$$$$$$');
      // when we switch from menugroup to menu categories the only way to know that
      // we need to reset the menuActiveCategory to all is to check if we have
      // an active menu category that is a menu group when being in menu category mode
      // this means that we can reset the active menu category to all
      if(this.props.restaurantMenuItems && 
        this.props.restaurantMenuItems.restaurant && 
        this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length >= 1 &&
        this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length > 0) {
        const arrayFilteredMenuCategories = this.props.restaurantMenuItems.restaurant.menus[0].menucategories.filter((mc) => mc.id === this.activeMenuCategoryId);
        
        //console.log('arrayFilteredMenuCategories =>', arrayFilteredMenuCategories);
        if(arrayFilteredMenuCategories.length === 0) {
          let params = '';
          if(this.props.location.search !== '') {
            params = queryString.parse(this.props.location.search);
          } else {
            if(this.props.location.hash !== '') {
              const withoutHash = this.props.location.hash.substring(this.props.location.hash.indexOf('?'));
              params = queryString.parse(withoutHash);
            }
          }
          const currentMenuCategoryIdInURL = params.menucategoryid;
          this.activeMenuCategoryId = currentMenuCategoryIdInURL;
          if(this.activeMenuCategoryId) {
            const arrayFilteredMenuCategories = this.props.restaurantMenuItems.restaurant.menus[0].menucategories.filter((mc) => mc.id === this.activeMenuCategoryId);
            if(arrayFilteredMenuCategories.length > 0)
              this.activeMenuCategory = arrayFilteredMenuCategories[0].name_i18n[locale];
          }
          else {
            // if we only have one menu category then it is preselected
            if(arrayFilteredMenuCategories.length > 0) {
              this.activeMenuCategoryId = arrayFilteredMenuCategories[0].id;
              this.activeMenuCategory = arrayFilteredMenuCategories[0].name_i18n[locale];
            }
            else
              this.activeMenuCategory = 'all';
          }
          //console.log('this.activeMenuCategory ## 2 => ', this.activeMenuCategory);
        } else {
          this.activeMenuCategory = arrayFilteredMenuCategories[0].name_i18n[locale];
        }

        menuCategories =  this.props.restaurantMenuItems.restaurant.menus[0].menucategories;
      }
    }
    //console.log('menuCategoriesFilter =>', menuCategoriesFilter);
    const menuCategoriesFilter = menuCategories.map(mc => {return {id: mc.id, name:mc.name_i18n[locale]}});

    return menuCategoriesFilter;
  }

  retrieveMenuItemsFilters() {
    //console.log('parseDataFromServer => ', this.props.restaurantMenuItems);
    const {intl} = this.props;
    const {locale} = intl;

    let allMenuItems = [];
    // if we have only one category this means we are now in a bundle menus offer
    // in menu group mode with ordered menu items in it
    if (this.props.restaurantMenuItems && 
      this.props.restaurantMenuItems.restaurant && 
      this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length === 1 &&
      this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length === 0) {
      const selectedMenugroupMenuItems = this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].menugroups.filter((mg) => mg.id === this.activeMenuCategoryId);
      //console.log('selectedMenugroupMenuItems => ', selectedMenugroupMenuItems);
      if(selectedMenugroupMenuItems.length > 0) {
        allMenuItems = selectedMenugroupMenuItems[0].orderedmenuitems;
        allMenuItems = allMenuItems
        .filter(orderedMenuItem => orderedMenuItem.menuitem.isActive)
        .map((orderedMenuItem) => {
          orderedMenuItem.menuitem.orderedmenuitemId =  orderedMenuItem.id;
          orderedMenuItem.menuitem.menucategory =  orderedMenuItem.menucategory;
          // we flag this menu item as a menuitem that does not belongs to a menuitem group
          orderedMenuItem.menuitem.isMenuItemForGroup = false;
          return orderedMenuItem.menuitem;
        });

        // array that will contain all the menu items of a menu group
        let tempMenuitemsGroupMenuItems = [];
        // we are menuitems group mode
        if(selectedMenugroupMenuItems[0].menuitemgroups.length > 0) {
          tempMenuitemsGroupMenuItems = [...new Set([].concat(...selectedMenugroupMenuItems[0].menuitemgroups.map((mig) => mig.orderedmenuitems)))];
          //console.log('tempMenuitemsGroupMenuItems => ', tempMenuitemsGroupMenuItems);
          // if all menuitems group in the menu group have ordered menu items
          if(tempMenuitemsGroupMenuItems.length > 0) {
            // we map the array to only return menu items
            tempMenuitemsGroupMenuItems = tempMenuitemsGroupMenuItems
            .filter(orderedMenuItem => orderedMenuItem.menuitem.isActive)
            .map((orderedMenuItem) => {
              orderedMenuItem.menuitem.orderedmenuitemId =  orderedMenuItem.id;
              // we flag this menu item as a menuitem that belongs to a menuitem group
              orderedMenuItem.menuitem.isMenuItemForGroup = true;
              return orderedMenuItem.menuitem;
            });
            allMenuItems = [...new Set(allMenuItems.concat(tempMenuitemsGroupMenuItems))];
            //console.log('allMenuItems => ', allMenuItems);
          }
        }
      }
      //console.log('allMenuItems => ', allMenuItems);
    } else {
      // Normal menu mode
      if (this.props.restaurantMenuItems && 
        this.props.restaurantMenuItems.restaurant && 
        this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length >= 1 &&
        this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length > 0) {
          allMenuItems = this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant ? 
            [...new Set([].concat(...this.props.restaurantMenuItems.restaurant.menus[0].menucategories.map((mc) => mc.orderedmenuitems)))] : [];
            allMenuItems = allMenuItems
            .filter(orderedMenuItem => orderedMenuItem.menuitem.isActive)
            .map((orderedMenuItem) => {
              orderedMenuItem.menuitem.orderedmenuitemId =  orderedMenuItem.id;
              orderedMenuItem.menuitem.menucategory =  orderedMenuItem.menucategory;
              return orderedMenuItem.menuitem;
            });
      }
    } // end else of menugroup mode
    
    let filtersData = [];

    if(allMenuItems.length > 0) {
      const allString = intl.formatMessage({ id: "all.message.for.filters", defaultMessage: 'All'});
      const foodCategoriesFilterLabel = intl.formatMessage({ id: "MenuItems.filter.food.category", defaultMessage: 'Food Categories'});
      const foodTypesFilterLabel = intl.formatMessage({ id: "MenuItems.filter.food.type", defaultMessage: 'Food Types'});
      const filterTitle = intl.formatMessage({ id: "MenuItems.filtersData.title", defaultMessage: 'Filter'});

      // listing all food categories and removing duplicates
      const foodCategories = [...new Set(allMenuItems.map((menuItem) => menuItem.foodcategory ? menuItem.foodcategory.namePlural_i18n[locale] : "Other"))];
      //console.log('foodCategories => ', foodCategories);

      // getting all food type children arrays
      const foodTypes =  [...new Set([].concat(...allMenuItems.map((menuitem) => menuitem.foodtypes)))];
      //console.log('foodTypes => ', foodTypes);

      // removing duplicate foodtype objects
      const seen = {};
      const filteredFoodTypes = foodTypes.filter((foodTypeObj) => {
        return seen.hasOwnProperty(foodTypeObj.name_i18n) ? false : (seen[foodTypeObj.name_i18n[locale]] = true);
      });
      // from object to string
      const finalFilteredFoodType = [...new Set(filteredFoodTypes.map((filteredFoodTypeObj) => filteredFoodTypeObj.name_i18n[locale]))];
      //console.log('finalFilteredFoodType => ', finalFilteredFoodType);
  
      filtersData = [{
        title: filterTitle,
        data: [{
          id: 0,
          label: foodCategoriesFilterLabel,
          options: [allString].concat(foodCategories),
          },
          {
            id: 1,
            label: foodTypesFilterLabel,
            options: [allString].concat(finalFilteredFoodType),
          },
        ],
      },
      ];
    }

    return filtersData;
  }

  refreshMenuItemsFromServer(pMenuId) {
    const { dispatch, restaurantInfo } = this.props;
    // console.log('calling  refreshMenusFromServer => ', restaurantInfo)
    // we put the flag that a call to the server is being done
    const deliveryMethod = this.props.match.params.deliverymethod;
    if(restaurantInfo && !this.props.isFetching) {
      //console.log('calling server to get menus');
      // get the menu id from the url id parameter
      const menuId = pMenuId;
      // resetting the list of filtered menu items as we are loading new data
      this._isMounted && this.setState({allFilteredMenuItems : null});
      isAFetchFromApiInProgress = true;
      const restaurantId  = restaurantInfo.slug;
      const listOfMatchingDeliveryMethods = restaurantInfo.deliverymethods.filter(dm => dm.code === deliveryMethod)
      let currentDeliveryMethod 
      if(listOfMatchingDeliveryMethods.length > 0) {
        currentDeliveryMethod = listOfMatchingDeliveryMethods[0]
        // console.log('currentDeliveryMethod => ', currentDeliveryMethod)
        dispatch(getMenuItemsFromServer(restaurantId, menuId, currentDeliveryMethod));
      }
    }
  }

  updateFilter(filterName, currentOption) {
    //console.log('filterName => ', filterName);
    //console.log('updateFoodTypeFilter => ', currentOption);
    const selectedOption = currentOption ? currentOption : this.state.allString;
    const { foodTypesFilterLabel, foodCategoriesFilterLabel } = this.state;
    // set the state
    switch(filterName) {
      case foodTypesFilterLabel: this._isMounted && this.setState({foodTypeFilter: selectedOption});break;
      case foodCategoriesFilterLabel: this._isMounted && this.setState({foodCategoryFilter: selectedOption});break;
      default: break;
    }
  }

  filterMenuItemsBasedOnSelectedFilters() {
    const allStr = this.state.allString;

    let allMenuItems = [];

    const {locale} = this.props.intl;

    //console.log("this.activeMenuCategory (filterMenuItemsBasedOnSelectedFilters) => ", this.activeMenuCategory);
    
    if(this.state.allFilteredMenuItems) {
      allMenuItems = this.state.allFilteredMenuItems;
    } else {
      if(this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant) {
        if(this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length === 1 &&
          this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length === 0) {
          const selectedMenugroupMenuItems = this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].menugroups.filter((mg) => mg.id === this.activeMenuCategoryId);
          //console.log('selectedMenugroupMenuItems => ', selectedMenugroupMenuItems);
          if(selectedMenugroupMenuItems.length > 0) {
            allMenuItems = selectedMenugroupMenuItems[0].orderedmenuitems;
            allMenuItems = allMenuItems
            .filter(orderedMenuItem => orderedMenuItem.menuitem.isActive)
            .map((orderedMenuItem) => {
              orderedMenuItem.menuitem.orderedmenuitemId =  orderedMenuItem.id;
              orderedMenuItem.menuitem.menucategory = orderedMenuItem.menucategory;
              // we flag this menu item as a menuitem that does not belongs to a menuitem group
              orderedMenuItem.menuitem.isMenuItemForGroup = false;
              //console.log('orderedMenuItem.menuitem.menucategory 1 => ', orderedMenuItem.menuitem.menucategory);
              return orderedMenuItem.menuitem;
            });

            // array that will contain all the menu items of a menu group
            let tempMenuitemsGroupMenuItems = [];
            // we are menuitems group mode
            if(selectedMenugroupMenuItems[0].menuitemgroups.length > 0) {
              tempMenuitemsGroupMenuItems = [...new Set([].concat(...selectedMenugroupMenuItems[0].menuitemgroups.map((mig) => mig.orderedmenuitems)))];
              //console.log('tempMenuitemsGroupMenuItems => ', tempMenuitemsGroupMenuItems);
              // if all menuitems group in the menu group have ordered menu items
              if(tempMenuitemsGroupMenuItems.length > 0) {
                // we map the array to only return menu items
                tempMenuitemsGroupMenuItems = tempMenuitemsGroupMenuItems
                .filter(orderedMenuItem => orderedMenuItem.menuitem.isActive)
                .map((orderedMenuItem) => {
                  orderedMenuItem.menuitem.orderedmenuitemId =  orderedMenuItem.id;
                  // we flag this menu item as a menuitem that belongs to a menuitem group
                  orderedMenuItem.menuitem.isMenuItemForGroup = true;
                  return orderedMenuItem.menuitem;
                });
                allMenuItems = [...new Set(allMenuItems.concat(tempMenuitemsGroupMenuItems))];
                //console.log('allMenuItems => ', allMenuItems);
              }
            }
          }
          //console.log('allMenuItems => ', allMenuItems);
        } else {
          // Normal menu mode
          if(this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length >= 1 &&
            this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length > 0) {
            allMenuItems = [...new Set([].concat(...this.props.restaurantMenuItems.restaurant.menus[0].menucategories.map((mc) => {
              // adding the menu category name to the first ordered menu item of a menu category so it can be used
              // as a title when the all filter is selected to visually distinguish the menu categories when
              // scrolling
              if(this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length > 1 && mc.orderedmenuitems && mc.orderedmenuitems.length > 0)
                mc.orderedmenuitems[0].categoryName_i18n = mc.name_i18n;
              return mc.orderedmenuitems;
            })))];
            allMenuItems = allMenuItems
            .filter(orderedMenuItem => orderedMenuItem.menuitem.isActive)
            .map((orderedMenuItem) => {
              orderedMenuItem.menuitem.orderedmenuitemId = orderedMenuItem.id;
              orderedMenuItem.menuitem.menucategory = orderedMenuItem.menucategory;
              if(orderedMenuItem.categoryName_i18n)
                orderedMenuItem.menuitem.categoryName_i18n = orderedMenuItem.categoryName_i18n;
              //console.log('orderedMenuItem.menuitem.menucategory 2 => ', orderedMenuItem.menuitem.menucategory);
              return orderedMenuItem.menuitem;
            });

            // filter the list of menu items based on the selected menu category
            if (this.activeMenuCategory !== "all") {
              allMenuItems =  allMenuItems.filter((menuitem) => {
                //const group = child.groups.find(item => item === type);
                //const group = child.groups.find(item => item === type);
                const group = menuitem.menucategory.name_i18n[locale] === this.activeMenuCategory;
                
                return !!group;
              });
            }
          }
        }
      }
    }
    
    if(this.state.foodTypeFilter === allStr &&
      this.state.foodCategoryFilter === allStr &&
      this.state.activeFilter === allStr) {
        //console.log('on est la sans filtre');
       return allMenuItems;
    } else {
      //console.log('on est la avec filtre');
      let filteredData = allMenuItems;
      // filter on foodType
      if (this.state.foodTypeFilter !== allStr) {
        filteredData = filteredData.filter((item) => {
          const foundFoodType = item.foodtypes.length > 0 ? item.foodtypes.find(foodType => foodType.name_i18n[locale] === this.state.foodTypeFilter) : null;
          return !!foundFoodType;
        })
      }
      
      // filter on food category
      if (this.state.foodCategoryFilter !== allStr) {
        filteredData = filteredData.filter((item) => {
          const foundFoodCategory = item.foodcategory ? item.foodcategory.namePlural_i18n[locale] === this.state.foodCategoryFilter : null;
          return !!foundFoodCategory;
        })
      }

      GAEvent("FRONTEND", "Filter menu items based on selected filter", "MENUITEMS_PAGE_FILTER_MENUITEMS");

      return filteredData;
    }
  }

  openModal(id) {
    this._isMounted && this.setState({ isModalActive: true, modalId: id });
  }

  closeModal = () => {
    this._isMounted && this.setState({ isModalActive: false, modalId: null });
  }

  // Menu category or menu group
  filterMenuItemsByMenuCategory(categoryId, categoryName) {
    let allMenuItems = null;
    //console.log('filterMenuItemsByMenuCategory categoryName => ', categoryName);
    //console.log('filterMenuItemsByMenuCategory this.activeMenuCategoryId => ', this.activeMenuCategoryId);
    // if we have only one category this means we are now in a bundle menus offer (=> menugroup)
    if (this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length === 1 &&
      this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length === 0) {
      const selectedMenugroupMenuItems = this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].menugroups.filter((mg) => mg.id === categoryId);
      //console.log('selectedMenugroupMenuItems => ', selectedMenugroupMenuItems);
      if (selectedMenugroupMenuItems.length > 0) {
        allMenuItems = selectedMenugroupMenuItems[0].orderedmenuitems;
        allMenuItems = allMenuItems
        .filter(orderedMenuItem => orderedMenuItem.menuitem.isActive)
        .map((orderedMenuItem) => {
          orderedMenuItem.menuitem.orderedmenuitemId =  orderedMenuItem.id;
          // we flag this menu item as a menuitem that does not belongs to a menuitem group
          orderedMenuItem.menuitem.isMenuItemForGroup = false;
          return orderedMenuItem.menuitem;
        });

        // array that will contain all the menu items of a menu group
        let tempMenuitemsGroupMenuItems = [];
        // we are menuitems group mode
        if(selectedMenugroupMenuItems[0].menuitemgroups.length > 0) {
          tempMenuitemsGroupMenuItems = [...new Set([].concat(...selectedMenugroupMenuItems[0].menuitemgroups.map((mig) => mig.orderedmenuitems)))];
          //console.log('tempMenuitemsGroupMenuItems 2 => ', tempMenuitemsGroupMenuItems);
          // if all menuitems group in the menu group have ordered menu items
          if(tempMenuitemsGroupMenuItems.length > 0) {
            // we map the array to only return menu items
            tempMenuitemsGroupMenuItems = tempMenuitemsGroupMenuItems
            .filter(orderedMenuItem => orderedMenuItem.menuitem.isActive)
            .map((orderedMenuItem) => {
              orderedMenuItem.menuitem.orderedmenuitemId =  orderedMenuItem.id;
              // we flag this menu item as a menuitem that belongs to a menuitem group
              orderedMenuItem.menuitem.isMenuItemForGroup = true;
              return orderedMenuItem.menuitem;
            });
            allMenuItems = [...new Set(allMenuItems.concat(tempMenuitemsGroupMenuItems))];
            //console.log('allMenuItems 2 => ', allMenuItems);
          }
        }

        this.activeMenuCategory = categoryName;
        this.activeMenuCategoryId = categoryId; 
        //console.log("this.activeMenuCategory (menugroup)=> ", this.activeMenuCategory);
        //console.log('allMenuItems => ', allMenuItems);
        const queryStringParamMode = queryString.parse(this.props.location.search).mode
        const queryStringParamLocationNumber = queryString.parse(this.props.location.search).locationnumber
        const queryStringParamGroupCode = queryString.parse(this.props.location.search).groupcode
        if(this.activeMenuCategoryId) {
          // check if the current menugroupid in the url is different than
          // the one stored in the component activemenucategoryid var
          let params = '';
          if(this.props.location.search !== '') {
            params = queryString.parse(this.props.location.search);
          } else {
            if(this.props.location.hash !== '') {
              const withoutHash = this.props.location.hash.substring(this.props.location.hash.indexOf('?'));
              params = queryString.parse(withoutHash);
            }
          }
          const currentMenuGroupIdInURL = params.menugroupid;
          if(this.activeMenuCategoryId !== currentMenuGroupIdInURL){
            let urlToPush = `?menugroupid=${this.activeMenuCategoryId}&ts=${new Date().getTime()}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamLocationNumber ? '&locationnumber=' + queryStringParamLocationNumber : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`;
            const restaurantCollectionId = queryString.parse(this.props.location.search).rcid;
            if(restaurantCollectionId)
              urlToPush += `&rcid=${restaurantCollectionId}`;
            this.props.history.push(urlToPush);
          }
        } else {
          let urlToPush = `?ts=${new Date().getTime()}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamLocationNumber ? '&locationnumber=' + queryStringParamLocationNumber : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`;
          const restaurantCollectionId = queryString.parse(this.props.location.search).rcid;
          if(restaurantCollectionId)
            urlToPush += `&rcid=${restaurantCollectionId}`;
          this.props.history.push(urlToPush);
        }

        this._isMounted && this.setState({
          allFilteredMenuItems: allMenuItems,
          activeMenuCategoryIdInState: this.activeMenuCategoryId
        });
      } 
    } else {
      // we are in normal menu mode
      allMenuItems = [...new Set([].concat(...this.props.restaurantMenuItems.restaurant.menus[0].menucategories.map((mc) => {
        // adding the menu category name to the first ordered menu item of a menu category so it can be used
        // as a title when the all filter is selected to visually distinguish the menu categories when
        // scrolling
        if(this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length > 1 && mc.orderedmenuitems && mc.orderedmenuitems.length > 0)
          mc.orderedmenuitems[0].categoryName_i18n = mc.name_i18n;
        return mc.orderedmenuitems;
      })))];
      allMenuItems = allMenuItems
      .filter(orderedMenuItem => orderedMenuItem.menuitem.isActive)
      .map((orderedMenuItem) => {
        orderedMenuItem.menuitem.orderedmenuitemId = orderedMenuItem.id;
        if(orderedMenuItem.categoryName_i18n)
          orderedMenuItem.menuitem.categoryName_i18n = orderedMenuItem.categoryName_i18n;
        return orderedMenuItem.menuitem;
      });

      // filter the list of menu items based on the selected menu category
      const allFilteredMenuItems = categoryName === 'all' ? allMenuItems : allMenuItems.filter((child) => {
        //const group = child.groups.find(item => item === type);
        const {locale} = this.props.intl;
        const group = child.menucategory ?  child.menucategory.name_i18n[locale] === categoryName : false;
        return !!group;
      });

      this.activeMenuCategory = categoryName;
      this.activeMenuCategoryId = categoryId; 
      //console.log("this.activeMenuCategory (menucategory) => ", this.activeMenuCategory);
      const queryStringParamMode = queryString.parse(this.props.location.search).mode
      const queryStringParamLocationNumber = queryString.parse(this.props.location.search).locationnumber
      const queryStringParamGroupCode = queryString.parse(this.props.location.search).groupcode
      if(this.activeMenuCategoryId) {
        // check if the current menugroupid in the url is different than
        // the one stored in the component activemenucategoryid var
        let params = '';
        if(this.props.location.search !== '') {
          params = queryString.parse(this.props.location.search);
        } else {
          if(this.props.location.hash !== '') {
            const withoutHash = this.props.location.hash.substring(this.props.location.hash.indexOf('?'));
            params = queryString.parse(withoutHash);
          }
        }
        const currentMenuCategoryIdInURL = params.menucategoryid;
        if(this.activeMenuCategoryId !== currentMenuCategoryIdInURL){
          let urlToPush = `?menucategoryid=${this.activeMenuCategoryId}&ts=${new Date().getTime()}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamLocationNumber ? '&locationnumber=' + queryStringParamLocationNumber : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`;
          const restaurantCollectionId = queryString.parse(this.props.location.search).rcid;
          if(restaurantCollectionId)
              urlToPush += `&rcid=${restaurantCollectionId}`;
          this.props.history.push(urlToPush);
        }
      } else {
        let urlToPush = `?ts=${new Date().getTime()}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamLocationNumber ? '&locationnumber=' + queryStringParamLocationNumber : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`;
          const restaurantCollectionId = queryString.parse(this.props.location.search).rcid;
          if(restaurantCollectionId)
            urlToPush += `&rcid=${restaurantCollectionId}`;
        this.props.history.push(urlToPush);
      }

      this._isMounted && this.setState({
        allFilteredMenuItems,
        activeMenuCategoryIdInState: this.activeMenuCategoryId
      });
    }

    GAEvent("FRONTEND", "Filter menu items by menu category", "MENUITEMS_PAGE_FILTER_MENUITEMS_BY_MENU_CATEGORY");
  }

  toggleTooltip(id, field) {
    const newState = [...this.state[field]];
    newState.fill(false);

    if (!this.state[field][id]) {
      newState[id] = true;
    }

    this._isMounted && this.setState({
      [field]: newState,
    });
  }

  animateMenuItemToCart(itemElementId, menuItemToAddToCart) {
      // get the elements from the dom
      const cart = $('#alacarteCartButton');
      const item = $('#carouselMenuItem-' + itemElementId);
      const imageWidth = item.children()[0].scrollWidth;
      const imageHeight = item.children()[0].scrollHeight;
      //const imageWidth = item.innerWidth();
      //const imageHeight = item.innerHeight();
      //console.log('w => ', imageWidth);
      //console.log('h => ', imageHeight);
      const cartTopOffset = cart.offset().top - item.offset().top;
      const cartLeftOffset = cart.offset().left - item.offset().left;
      //console.log('Top Offset => ', cartTopOffset);
      //console.log('Left Offset => ', cartLeftOffset);
      const flyingImg = $('<div class="'+s.productCardPhoto+
                            ' '+s.bFlyingImg+'" style="background-image: url(&quot;'+
                            menuItemToAddToCart.pictures[0].url+'&quot;); height: '+
                            imageHeight+'px; width: '+
                            imageWidth+'px; "></div>');
      // animate the flying image to move towards the cart
      flyingImg.animate({
        top: cartTopOffset + 20,
        left: cartLeftOffset + 50,
        width: 10,
        height: 10,
        opacity: 0.2,
      }, {
        duration: 700,
        easing: "linear",
        complete: function(){
          flyingImg.remove();
        }
      });

      // add the flying image to the carousel item
      item.append(flyingImg);
  }
  
  addMenuItemToCart(itemElementId, menuItemToAddToCart, menuItemDetailsURL = '') {
    const { dispatch } = this.props;
    menuItemToAddToCart.currencyNumberOfDecimals = this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant ? this.props.restaurantMenuItems.restaurant.city.country.currency.numberOfDecimals : 2;
    // if we have only one size for the item that we want to add then we just
    // add it to the cart, if we have multiple sizes then we have to display
    // them to the user for selecting which one he would like to add to the cart
    if(menuItemToAddToCart.menuitemsizes.length === 1 && menuItemToAddToCart.addongroups.length === 0) {
      this.animateMenuItemToCart(itemElementId, menuItemToAddToCart);
      menuItemToAddToCart.listOfSelectedAddongroupsItems = [];
      // dispatch the add to cart action
      dispatch(addProduct(menuItemToAddToCart));
    } else {
      // we check if a menuitem with multiple sizes already
      // has a selected menu item size then we add it to the cart
      if(menuItemToAddToCart.selectedmenuitemsize) {
        this.animateMenuItemToCart(itemElementId, menuItemToAddToCart);
        menuItemToAddToCart.listOfSelectedAddongroupsItems = [];
        // dispatch the add to cart action
        dispatch(addProduct(menuItemToAddToCart));
      } else {
        // if the menu item has either multiple sizes or required add-ons then
        // we go to its details page
        if(menuItemDetailsURL !== '') {
          const urlToGoTo = menuItemDetailsURL.replace('#', ''); // removing the initial # from the url sent
          //console.log('urlToGoTo => ', urlToGoTo);
          this.props.history.push(urlToGoTo);
        }
      }
    }

    GAEvent("FRONTEND", "Add menu item to cart", "MENUITEMS_PAGE_ADD_MENU_ITEM_TO_CART");
  }

  enableMenuGroupSubmitButton() {
    // console.log('enabled =>', this.refs.formMenuGroup.getModel());
    this.setState({ submitMenuGroupButtonEnabled: true })
  }

  disableMenuGroupSubmitButton() {
    // console.log('disabled =>', this.refs.formMenuGroup.getModel());
    this.setState({ submitMenuGroupButtonEnabled: false });
  }

  animateMenuGroupToCart() {
    const {locale} = this.props.intl;
    const menuGroupObj = 
      this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].menugroups.filter((mg) => mg.id === this.activeMenuCategoryId)[0];
    // get the elements from the dom
    const cart = $('#alacarteCartButton');
    const item = $('#checkoutButton');
    //console.log('cart => ', cart);
    //console.log('item => ', item);
    const imageWidth = item[0].scrollWidth;
    const imageHeight = item[0].scrollHeight;
    //const imageWidth = item.innerWidth();
    //const imageHeight = item.innerHeight();
    //console.log('w => ', imageWidth);
    //console.log('h => ', imageHeight);
    const cartTopOffset = cart.offset().top - item.offset().top;
    const cartLeftOffset = cart.offset().left - item.offset().left;
    //console.log('Top Offset => ', cartTopOffset);
    //console.log('Left Offset => ', cartLeftOffset);
    const flyingImg = $('<button class="btn btn-primary ' +
                          s.bFlyingImgMenuItemGroup +'" style="height: '+imageHeight +'px; width: '+ imageWidth+'px;' +
                          ' border: solid 1px #eabf00 !important;">' + menuGroupObj.name_i18n[locale] + '</button>');
    // add flying img to parent of checkout button
    item.parent().append(flyingImg);
    // animate it
    flyingImg.animate({
      bottom: (cartTopOffset * -1) + 10,
      left: cartLeftOffset + 10,
      width: 40,
      height: 40,
      opacity: 0.2,
    }, {
      duration: 700,
      easing: "linear",
      complete: function(){
        flyingImg.remove();
      }
    });
    // change styles attributes
    flyingImg.attr('style', 'font-size: 12px !important; height: '+imageHeight +'px; width: '+ imageWidth+'px;' + 
                            ' border: 1px solid #eabf00 !important;');
  }

  submitMenuGroup(model) {
    const {dispatch} = this.props;
    // console.log('model => ', model);
    const menuGroupObj = 
      this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].menugroups.filter((mg) => mg.id === this.activeMenuCategoryId)[0];
    //console.log('menuGroupObj => ', menuGroupObj);
    const selectedmenuitemgroupsWithItems = model;
    menuGroupObj.listOfSelectedMenuItemsForMenuGroup = selectedmenuitemgroupsWithItems.listOfSelectedMenuItemsForMenuGroup;
    menuGroupObj.currencyNumberOfDecimals = this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant ? this.props.restaurantMenuItems.restaurant.city.country.currency.numberOfDecimals : 2;
    this.animateMenuGroupToCart();
    //console.log('menuGroupObj => ', menuGroupObj);
    dispatch(addProduct(menuGroupObj));

    GAEvent("FRONTEND", "Submit menu group", "MENUITEMS_PAGE_SUBMIT_MENU_GROUP");
  }

  render() {
    const { menuid } = this.props.match.params;
    const {locale} = this.props.intl;
    const menuCategories = this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant ? this.props.restaurantMenuItems.restaurant.menus[0].menucategories : null;
    const filterForMenuCategories = this.retrieveListOfMenuCategories();
    const menuItemsArray = this.filterMenuItemsBasedOnSelectedFilters();
    const { isModalActive, modalId } = this.state;
    const filtersForMenuItems = this.retrieveMenuItemsFilters();
    const currencySymbol = this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant ? this.props.restaurantMenuItems.restaurant.city.country.currency.symbol : "";
    const currencyNumberOfDecimals = this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant ? this.props.restaurantMenuItems.restaurant.city.country.currency.numberOfDecimals : 2;
    //console.log(currencySymbol);
    const menuName = this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant ? this.props.restaurantMenuItems.restaurant.menus[0].userLabel_i18n[locale] : "";
    const { restaurant } = this.props.restaurantMenuItems;
    const restaurantId = this.props.match.params.restaurantid;
    const deliveryMethod = this.props.match.params.deliverymethod;
    const queryStringParamMode = queryString.parse(this.props.location.search).mode
    const queryStringParamLocationNumber = queryString.parse(this.props.location.search).locationnumber
    const queryStringParamGroupCode = queryString.parse(this.props.location.search).groupcode
    let deliveryMethodObj = null
    const restaurantInfo = this.props.restaurantInfo;
    if (restaurantInfo) {
      const { deliverymethods } = restaurantInfo;
      const filteredDeliveryMethods = deliverymethods.filter(dm => dm.code === deliveryMethod);
      if (filteredDeliveryMethods.length > 0) {
        deliveryMethodObj = filteredDeliveryMethods[0];
      }
    }
    // console.log('restaurantInfo => ', restaurantInfo)
    // console.log('this.props.restaurantMenuItems => ', this.props.restaurantMenuItems)
    const areWeLoadingANewMenu = this.props.restaurantMenuItems &&
                                 this.props.restaurantMenuItems.restaurant ? this.props.restaurantMenuItems.restaurant.menus[0].id !== this.props.match.params.menuid : false;
    // if we have a menu group then this variable will retrieve the description of the menu group
    const menuGroupObj = this.props.restaurantMenuItems &&
                                          this.props.restaurantMenuItems.restaurant &&
                                          this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length === 1 &&
                                          this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length === 0 &&
                                          this.activeMenuCategoryId ? 
                                            this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].menugroups.filter((mg) => mg.id === this.activeMenuCategoryId)[0]
                                             : null;
    //console.log('menuGroupObj => ', menuGroupObj);
    // if we have a menu group with menuitems groups
    const menuitemsgroupArray = menuGroupObj ? menuGroupObj.menuitemgroups : null;
    //console.log('menuitemsgroupArray => ', menuitemsgroupArray);
    // get the menu category id for the bundled menus
    const menuCategoryIdForMenuGroup = this.props.restaurantMenuItems &&
                                        this.props.restaurantMenuItems.restaurant &&
                                        this.props.restaurantMenuItems.restaurant.menus[0].menucategories.length === 1 &&
                                        this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length === 0 ? this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].id : null;
    //console.log("this.activeMenuCategory => ", this.activeMenuCategory);
    const restaurantCollectionId = queryString.parse(this.props.location.search).rcid;

    return (
      <div id='menuitemspage-body-root' className={s.root}>
        <Row id='breadcrumb-menuitems-list'>
          <Breadcrumb className="d-sm-down-none">
            <BreadcrumbItem>{restaurant? restaurant.name : '............'}</BreadcrumbItem>
            <BreadcrumbItem 
              style={locale === 'ar' ? {paddingLeft: 0} : {}}
              >{!areWeLoadingANewMenu ? <Link style={{color: 'black', textDecoration: 'underline'}}
              to={
                restaurantCollectionId ?
                `/app/${deliveryMethod}/restaurant/${restaurantId}/menus?rcid=${restaurantCollectionId}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamLocationNumber ? '&locationnumber=' + queryStringParamLocationNumber : ''}`
                :
                queryStringParamLocationNumber ?
                `/app/${deliveryMethod}/restaurant/${restaurantId}/menus?locationnumber=${queryStringParamLocationNumber}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}` :
                `/app/${deliveryMethod}/restaurant/${restaurantId}/menus${queryStringParamMode ? '?mode=' + queryStringParamMode : '?'}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`
              } >
                {locale === 'ar' ? (<span>&nbsp;&nbsp;</span>) : ''}
                {menuName}</Link> : "............" }</BreadcrumbItem>
            {
              this.activeMenuCategoryId && menuGroupObj === null ?
              <BreadcrumbItem active={this.activeMenuCategoryId === null}>
                  <a href="/" 
                    style={{color: 'black', textDecoration: 'underline'}}
                    onClick={(e) => {
                        e.preventDefault();
                        this.filterMenuItemsByMenuCategory(null, 'all')
                    }}>
                    <FormattedMessage id="MenuItems.Breadcrumbitem.MenusItems" defaultMessage="Menus Items" />
                  </a>
                  {locale === 'ar' ? '' : (<span>&nbsp;&nbsp;</span>)}
                <Badge color="primary">{menuItemsArray && !areWeLoadingANewMenu ? menuItemsArray.length : null}</Badge>
              </BreadcrumbItem>
              :
              <BreadcrumbItem active>
                  {locale === 'ar' ? (<span>&nbsp;&nbsp;</span>) : ''}
                  {this.activeMenuCategory}
                  &nbsp;&nbsp;
                  {
                    // we do not want to display the count elements badge in combo menus
                    menuGroupObj === null ? 
                    <Badge color="primary">{menuItemsArray && !areWeLoadingANewMenu ? menuItemsArray.length : null}</Badge> : null
                  }
                  
              </BreadcrumbItem>
            }
          </Breadcrumb>
        </Row>
        {this.props.isFetching ? <ProgressIndicator />: null}
        {
          // discount on collect at kiosk orders
          deliveryMethodObj && deliveryMethodObj.code.includes('collectatkiosk') && this.props.restaurantMenuItems &&
          this.props.restaurantMenuItems.restaurant &&
          this.props.restaurantMenuItems.restaurant.discountOnCollectAtKiosk &&
          this.props.restaurantMenuItems.restaurant.discountOnCollectAtKiosk > 0 ?
            (
              <div className={`${s.coupon}`}>
                <div className={`${s.couponContainer}`}>
                    {this.props.restaurantMenuItems.restaurant.discountOnCollectAtKiosk}%&nbsp;
                    <FormattedMessage id="Menuitems.Page.Dineout.Order.Discount.On.Order.Text" 
                                    defaultMessage="off your total purchase" />
                </div>
              </div>
            )
        : 
          // discount on dine out orders
          deliveryMethodObj && !deliveryMethodObj.isDineIn && this.props.restaurantMenuItems &&
          this.props.restaurantMenuItems.restaurant &&
          this.props.restaurantMenuItems.restaurant.discountOnDineOutOrders &&
          this.props.restaurantMenuItems.restaurant.discountOnDineOutOrders > 0 ?
            (
              <div className={`${s.coupon}`}>
                <div className={`${s.couponContainer}`}>
                    {this.props.restaurantMenuItems.restaurant.discountOnDineOutOrders}%&nbsp;
                    <FormattedMessage id="Menuitems.Page.Dineout.Order.Discount.On.Order.Text" 
                                    defaultMessage="off your total purchase" />
                </div>
              </div>
            )
          : null
        }
        {menuCategories && menuCategories.length > 0 && !isModalActive && !areWeLoadingANewMenu ?
        <Row style={{marginBottom: "10px"}}>
              <Col className={s.colHorizontalScrollbar} lg={9} md={9} sm={12} xs={12}>
                <ButtonToolbar className={locale === 'ar' ? s.buttonToolbarHorizontalScrollingLTR : s.buttonToolbarHorizontalScrolling}>
                  {
                    //###############################################################
                    // only for menu categories ('all' tab)
                    //###############################################################
                    !areWeLoadingANewMenu && menuGroupObj === null && filterForMenuCategories.length > 1?
                    <ButtonGroup>
                          <Button
                            color="default"
                            onClick={() => this.filterMenuItemsByMenuCategory(null, 'all')} active={this.activeMenuCategoryId === null || this.activeMenuCategoryId === undefined}>{this.state.allString}</Button>
                    </ButtonGroup>
                    : null
                  }
                {
                  //###############################################################
                  // for every menu category or menu group (top tabs)
                  //###############################################################
                  filterForMenuCategories.length > 1 ?
                  filterForMenuCategories.map((menuCategoryObj, index) => {
                    const menuCategoryStr = menuCategoryObj.name;
                    //console.log("menuCategoryStr => ", menuCategoryStr);
                    //console.log("menuCategoryObj => ", menuCategoryObj);
                    const key = menuCategoryStr + index;
                    // we want to limit the visible text in the button group to xx characters
                    // for the sake of a simple, consistent and clean UI
                    //if (menuCategoryStr.length > 15) {
                    return (
                      <ButtonGroup key={key}>
                        <Button
                          color="default"
                          onClick={() => this.filterMenuItemsByMenuCategory(menuCategoryObj.id, menuCategoryStr)} active={this.activeMenuCategoryId === menuCategoryObj.id}>
                            {menuCategoryStr}
                          </Button>
                      </ButtonGroup>
                    )
                  }) : null
                }
                </ButtonToolbar>
              </Col>
              <Col lg={3} md={3} className={locale === 'ar' ? "d-sm-down-none" : "d-sm-down-none text-right"}>
                <span>
                  <ButtonGroup id="buttonRefreshDataFromServer">
                    <Button onClick={() => {
                      this.refreshMenuItemsFromServer(this.props.match.params.menuid, true);
                      GAEvent("FRONTEND", "Refresh data from server", "MENUITEMS_PAGE_REFRESH_DATA_FROM_SERVER");
                    }} color="default"><i className="la la-refresh"></i></Button>
                  </ButtonGroup>
                  <Tooltip placement="top" isOpen={this.state.tooltipRefreshDataFromServer[0]} toggle={() => this.toggleTooltip(0, 'tooltipRefreshDataFromServer')} 
                    target={"buttonRefreshDataFromServer"}>
                    <FormattedMessage id="MenuItems.button.refresh.data.from.server" defaultMessage="Refresh list of dishes" />
                  </Tooltip>
                </span>
              </Col>
        </Row> : null}
        {
          //###############################################################
          // for every menugroup object
          //###############################################################
          this.activeMenuCategoryId && !areWeLoadingANewMenu && menuGroupObj ?
          <Row>
            <Col>
              <div className={s.jumbotronContainer + " jumbotron"} style={{
                    backgroundImage: 'url('+
                    menuGroupObj.picture && menuGroupObj.picture.formats && menuGroupObj.picture.formats.medium ? menuGroupObj.picture.formats.medium.url : menuGroupObj.picture.url 
                    +')'
              }}>
                <h3 className={s.titleMenuGroupCard}>{menuGroupObj.name_i18n[locale]}</h3>
                <p className={s.subtitleMenuGroupCard}>
                  {menuGroupObj.description_i18n[locale]}
                </p>
                <p className="lead">
                  <Badge color="primary" style={{fontSize: '22px'}}>{restaurant.city.country.currency.symbol} {currencyNumberOfDecimals <= 2 ? parseFloat(menuGroupObj.price.toFixed(currencyNumberOfDecimals)) : menuGroupObj.price.toFixed(currencyNumberOfDecimals)}</Badge>
                </p>
                {
                  /*<p className="lead">
                    <Button color="primary">Learn More</Button>
                  </p>*/
                }
              </div>
            </Col>
          </Row> : null
        }
        {menuCategories && menuCategories.length === 0  && !isModalActive && !areWeLoadingANewMenu ?<span>&nbsp;<FormattedMessage id="MenuItems.Page.No.MenuItems.Available" defaultMessage="No menu items available for this menu" />.</span> : null}
        {
          //###############################################################
          // For every filter (category, type, visibility)
          //###############################################################
          menuCategories && menuCategories.length > 0 && !isModalActive && !areWeLoadingANewMenu ? 
          <div className={s.productsListFilters}>
                {filtersForMenuItems.map(item =>
                  (typeof item.data[0] === 'string'
                    ? <FilterElement
                       defaultLabel={item.title}
                       onChange={(currentOption) => {this.updateFilter(item.title,currentOption)}} options={item.data} key={item.id} />
                    : item.data.map(i =>
                      <FilterElement
                        defaultLabel={i.label}
                        onChange={(currentOption) => {this.updateFilter(i.label,currentOption)}} options={i.options} key={i.id} />)),
                )}
          </div> : null
        }
        {
          deliveryMethodObj && !deliveryMethodObj.code.includes('massageatspa') &&
          !deliveryMethodObj.code.includes('servicerequest') &&
          menuCategories && menuCategories.length > 0 && !isModalActive && !areWeLoadingANewMenu ? 
          <div className={s.mobileFilterButtons}>
            <button
              className="btn btn-transparent btn-lg"
              onClick={() => this.openModal(0)}
            >
              <FormattedMessage id="MenuItems.filtersData.title" defaultMessage="Filter" />
              {
                (this.state.foodTypeFilter !== this.state.allString ||
                this.state.foodCategoryFilter !== this.state.allString ||
                this.state.activeFilter !== this.state.allString) ?
                <span className={s.filterIsActive}></span> : null
              }
              <i className="fa fa-2x fa-angle-down" />
            </button>
          </div> : null
        }
        <div className={s.gallery}>
          {
            //###############################################################
            // for every Menu Item
            //###############################################################
            menuItemsArray && menuItemsArray.length > 0 && !areWeLoadingANewMenu ?
            // we only display the menu items that are not part of a menu item group as a gallery
            // the others will be displayed in widget of menuitem groups
            menuItemsArray.filter(menuitem => !menuitem.isMenuItemForGroup).map((menuItemObj, index) => {
              //console.log('menuItemObj => ', menuItemObj);

              let menuItemDetailsURL = '';

              const key = menuItemObj.id + '-' + index;
              menuItemObj.menuid = menuid

              // determine menu item details page url based if we are in category menu mode or menu group mode
              if(restaurant && (restaurant.menus[0].menucategories.length >= 1 &&
                this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length > 0)) {
                menuItemDetailsURL = `#/app/${deliveryMethod}/restaurant/${restaurantId}/menu/${restaurant.menus[0].id}/menucategory/${menuItemObj.menucategory.id}/menuitem/${menuItemObj.orderedmenuitemId}?hashkey=menuitem-link-${key}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamLocationNumber ? '&locationnumber=' + queryStringParamLocationNumber : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`;
                // if we are in restaurant collection mode
                if(restaurantCollectionId)
                  menuItemDetailsURL += `&rcid=${restaurantCollectionId}`;
              } else {
                menuItemDetailsURL = `#/app/${deliveryMethod}/restaurant/${restaurantId}/menu/${restaurant.menus[0].id}/menucategory/${menuCategoryIdForMenuGroup}/menugroup/${this.activeMenuCategoryId}/menuitem/${menuItemObj.orderedmenuitemId}`;
                // if we are in restaurant collection mode
                if(restaurantCollectionId)
                  menuItemDetailsURL += `?rcid=${restaurantCollectionId}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamLocationNumber ? '&locationnumber=' + queryStringParamLocationNumber : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`;
                else {
                  if (queryStringParamLocationNumber) {
                    menuItemDetailsURL += `?locationnumber=${queryStringParamLocationNumber}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`;
                  } else {
                    menuItemDetailsURL += `${queryStringParamMode ? '?mode=' + queryStringParamMode : '?'}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`;
                  }
                }
              }

              return (
                <div key={key}>
                  {
                    menuItemObj.categoryName_i18n?
                      <span className={s.menuCategoryStickyHeader}
                        style={ locale === 'ar' ? {
                          textAlign: 'initial',
                          bottom: '3px'
                          } : {}}>
                            {menuItemObj.categoryName_i18n[locale]}
                      </span>
                    : null
                  }
                  <LazyLoad offset={50}>
                  <div id={'menuitem-link-' + key} className={`${s.picture} card`}>
                    <a href={menuItemDetailsURL} className={s.hrefOnMenuItem}>
                      <CarouselMenuItem keyForId={key} menuItem={menuItemObj} height={"320px"}/>
                      {
                        menuItemObj.isRecommendedByTheChef ? 
                        <div>
                          <div className={s.label + ' bg-danger'} id={"ribbon-recommendedByTheChef-" + index}>
                            <img width="15px" src={chefHat} alt="" />
                          </div>
                          <Tooltip placement="top" isOpen={this.state.tooltipsRecommendedByTheChef[index]} toggle={() => this.toggleTooltip(index, 'tooltipsRecommendedByTheChef')} 
                          target={"ribbon-recommendedByTheChef-" + index}>
                            <FormattedMessage id="MenuItems.MenuItem.ribbon.recommandedByTheChef" defaultMessage="Recommended by the Chef" />
                          </Tooltip>
                        </div>
                        : null
                      }
                      {/*<RealPictureRibbon isRealPicture = {menuItemObj.isRealPictures} />*/}
                      <div 
                        className={s.description}
                        style={locale === 'ar' ? {textAlign: 'initial'} : {}}>
                        <h6 className="mt-0 mb-xs fw-semi-bold">
                          {menuItemObj.name_i18n[locale]}
                          {
                            deliveryMethodObj && !deliveryMethodObj.code.includes('massageatspa') &&
                            !deliveryMethodObj.code.includes('servicerequest') &&
                            menuItemObj.menuitemsizes.length === 1 &&
                            menuItemObj.menuitemsizes[0].preparationTimeInMinutes &&
                            menuItemObj.menuitemsizes[0].preparationTimeInMinutes > 0 ?
                            <span className="text-muted" style={{fontSize: '10px'}}>
                              &nbsp;
                              (
                                <FormattedMessage id="MenuItems.MenuItem.Card.footer.label.preptime" defaultMessage="prep. time" />&nbsp;
                                {menuItemObj.menuitemsizes[0].preparationTimeInMinutes}&nbsp;
                                <FormattedMessage id="MenuItems.MenuItem.Card.footer.label.minutes" defaultMessage="min" />
                              )
                            </span>
                            :
                            (
                              deliveryMethodObj && 
                              (
                                deliveryMethodObj.code.includes('massageatspa') ||
                                deliveryMethodObj.code.includes('spaviewonly')
                              ) &&
                              menuItemObj.menuitemType === "spa_treatment" &&
                              menuItemObj.menuitemsizes.length === 1 ? (
                                <span className="text-muted" style={{fontSize: '10px'}}>
                                &nbsp;
                                (
                                  <FormattedMessage id="MenuItems.MenuItem.Card.footer.label.treatmenttime" defaultMessage="treatment time" />&nbsp;
                                    {menuItemObj.menuitemsizes[0].name_i18n[locale]}
                                )
                                </span>
                              ) : null
                            )
                          }
                        </h6>
                        <RealPictureText isRealPicture = {menuItemObj.isRealPictures} />
                      </div>
                    </a>
                    <div className={s.footer} style={
                      ((!menuItemObj.allergens) || (menuItemObj.allergens && menuItemObj.allergens.length === 0)) &&
                      ((!menuItemObj.foodtypes) || (menuItemObj.foodtypes && menuItemObj.foodtypes.length === 0)) ? {
                        height: "60px"
                      }:{}
                    }>
                      <hr style={{marginBottom: '7px'}}/>
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        {
                          (menuItemObj.allergens && menuItemObj.allergens.length > 0) ||
                          (menuItemObj.foodtypes && menuItemObj.foodtypes.length > 0) ?
                          <Allergens 
                            customFoodtypesIcon={
                              this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant ? 
                                this.props.restaurantMenuItems.restaurant.customFoodtypeIcons
                                  : null
                            }
                            customAllergensIcon={
                              this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant ? 
                                this.props.restaurantMenuItems.restaurant.customAllergenIcons
                                  : null
                              }
                            listOfAllergens={menuItemObj.allergens}
                            islistview={true}
                            locale={locale}
                            isMixedWithFoodTypes={menuItemObj.foodtypes && menuItemObj.foodtypes.length > 0}
                            listOfFoodTypes={menuItemObj.foodtypes && menuItemObj.foodtypes.length > 0 ? menuItemObj.foodtypes : null}
                            />
                          : <span></span>
                        }
                        {
                          // if we have a menu that is a menu group
                          restaurant && (restaurant.menus[0].menucategories.length >= 1 &&
                            this.props.restaurantMenuItems.restaurant.menus[0].menucategories[0].orderedmenuitems.length > 0) ? 
                            (
                              menuItemObj.menuitemsizes.length > 1 ?
                              <span className="fw-semi-bold"
                                style={
                                  locale === 'ar' ? {    
                                  position: "absolute",
                                  left: "55px"
                                } : {    
                                  position: "absolute",
                                  right: "55px"
                                }
                              }>
                                <FormattedMessage id="MenuItems.MenuItem.Card.label.price.from" defaultMessage="From" /> {currencySymbol} {currencyNumberOfDecimals <=2 ? parseFloat(menuItemObj.menuitemsizes[0].price.toFixed(currencyNumberOfDecimals)) : menuItemObj.menuitemsizes[0].price.toFixed(currencyNumberOfDecimals)}
                              </span>
                              : (
                                  menuItemObj.menuitemsizes.length === 1 ?
                                    (
                                      locale === 'ar' && menuItemObj.menuitemsizes[0].price > 0? (
                                        <span
                                          style={{
                                              position: "absolute",
                                              left: "55px"
                                          }}
                                          className="fw-semi-bold">
                                          { /*
                                            menuItemObj.menuitemsizes[0].calories ? 
                                              (
                                                <span style={{fontSize: '10px'}}>
                                                  ({menuItemObj.menuitemsizes[0].calories} <FormattedMessage id="MenuItems.MenuItem.Card.footer.label.calories" defaultMessage="calories" />)
                                                </span>)
                                              : null
                                              */
                                          }
                                          <span>{currencySymbol} {currencyNumberOfDecimals <=2 ? parseFloat(menuItemObj.menuitemsizes[0].price.toFixed(currencyNumberOfDecimals)) : menuItemObj.menuitemsizes[0].price.toFixed(currencyNumberOfDecimals)}</span>
                                        </span>
                                      ) : (
                                        menuItemObj.menuitemsizes[0].price > 0 ? (
                                          <span
                                            style={
                                              ((!menuItemObj.allergens) || (menuItemObj.allergens && menuItemObj.allergens.length === 0)) &&
                                              ((!menuItemObj.foodtypes) || (menuItemObj.foodtypes && menuItemObj.foodtypes.length === 0)) ? {
                                                position: "absolute",
                                                right: "55px"
                                              } : {
                                                position: "absolute",
                                                right: "55px"
                                            }}
                                            className="fw-semi-bold">      
                                            {currencySymbol} {currencyNumberOfDecimals <=2 ? parseFloat(menuItemObj.menuitemsizes[0].price.toFixed(currencyNumberOfDecimals)) : menuItemObj.menuitemsizes[0].price.toFixed(currencyNumberOfDecimals)}
                                            {
                                              /*
                                              menuItemObj.menuitemsizes[0].calories ? 
                                                (
                                                  <span style={{fontSize: '10px'}}>
                                                    ({menuItemObj.menuitemsizes[0].calories} <FormattedMessage id="MenuItems.MenuItem.Card.footer.label.calories" defaultMessage="calories" />)
                                                  </span>)
                                                : null
                                              */
                                            }
                                          </span>
                                        ) : null
                                      )
                                    )
                                  :
                                    <span className="btn-danger"
                                      style={
                                        locale === 'ar' ? {    
                                        position: "absolute",
                                        left: "55px"
                                      } : {    
                                        position: "absolute",
                                        right: "55px"
                                      }
                                    }>
                                      &nbsp;
                                      <FormattedMessage id="MenuItems.MenuItem.Card.label.price.not.available" defaultMessage="Price is not available" />
                                      &nbsp;
                                    </span>
                                )
                          ) : 
                            <span className="btn-gray"
                              style={
                                locale === 'ar' ? {
                                position: "absolute",
                                left: "55px"
                              } : {    
                                position: "absolute",
                                right: "55px"
                              }
                            }>
                                &nbsp;
                                <FormattedMessage id="MenuItems.MenuItem.Card.label.price.not.applicable" defaultMessage="Price is not applicable" />
                                &nbsp;
                            </span>
                        }
                        {
                          //###############################################################
                          // for every menuitem obj determine the menucategory or menugroup url
                          //###############################################################
                          restaurant && (menuItemObj.menuitemsizes.length === 1 &&  menuItemObj.addongroups.length === 0) 
                          && deliveryMethodObj &&
                          deliveryMethodObj.code !== 'viewonly' && 
                          deliveryMethodObj.code !== 'spaviewonly' &&
                          deliveryMethodObj.code !== 'servicerequestvo' &&
                          queryStringParamMode !== 'website' ?
                            <div>
                              <Button id={`buttonMenuCategoryMode-addMenuItemToCart-${key}`}
                                color="success"
                                className={s.addToCartButton}
                                onClick={() => this.addMenuItemToCart(key, menuItemObj)}>
                                  <img 
                                    src={AddToCartIcon}
                                    className={s.imageAddToCartButton}
                                    style={
                                      locale === 'ar' ? {    
                                        left: "10px"
                                    } : {}}
                                    alt=' ' />
                              </Button>
                            </div> :
                            (
                              deliveryMethodObj &&
                              deliveryMethodObj.code !== 'viewonly' &&
                              deliveryMethodObj.code !== 'spaviewonly' &&
                              deliveryMethodObj.code !== 'servicerequestvo' &&
                              queryStringParamMode !== 'website' ? (
                                <div>
                                  <Button id={`buttonMenuCategoryMode-addMenuItemToCart-${key}`}
                                    color="primary"
                                    className={s.addToCartButton}
                                    onClick={() => this.addMenuItemToCart(key, menuItemObj, menuItemDetailsURL)}>
                                      <img
                                        src={AddToCartIcon}
                                        className={s.imageAddToCartButton}
                                        style={
                                          locale === 'ar' ? {    
                                            left: "10px"
                                        } : {}}
                                        alt=' ' />
                                  </Button>
                                </div>
                              ) : null
                            )
                        }
                      </div>
                    </div>
                  </div>
                  </LazyLoad>
                </div> // added for menu categories title when available
              );
            }): null // end of menucategory.map
          }
        </div>
        {
          menuitemsgroupArray && menuitemsgroupArray.length > 0 &&
          !areWeLoadingANewMenu &&
          menuItemsArray && menuItemsArray.length > 0 ?
          <Formsy onValidSubmit={this.submitMenuGroup} 
                  ref="formMenuGroup"
                  onValid={this.enableMenuGroupSubmitButton} 
                  onInvalid={this.disableMenuGroupSubmitButton}>
            {
              menuitemsgroupArray.map((menuitemgroup, index) => {
              
              return (<MenuitemgroupsGallery
                              key={'MenuitemgroupsGallery-' + menuitemgroup + '-' + index}
                              name={"listOfSelectedMenuItemsForMenuGroup["+index+"]"}
                              required={menuitemgroup.isMandatory ? true : false}
                              menuitemgroup={menuitemgroup}
                              restaurantId={restaurantId}
                              customAllergensIcon={this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant ? this.props.restaurantMenuItems.restaurant.customAllergenIcons : null}
                              customFoodtypesIcon={this.props.restaurantMenuItems && this.props.restaurantMenuItems.restaurant ? this.props.restaurantMenuItems.restaurant.customFoodtypeIcons : null}
                              restaurantCurrency={currencySymbol}
                              currencyNumberOfDecimals={currencyNumberOfDecimals}
                              menuId={restaurant.menus[0].id}
                              menuCategoryIdForMenuGroup={menuCategoryIdForMenuGroup}
                              activeMenuCategoryId={this.activeMenuCategoryId}
                              filteredMenuitems={menuItemsArray.filter(menuitem => menuitem.isMenuItemForGroup)}
                              locale={locale} />); 
              })
            }
            {/* Add to cart button */}
            {
              deliveryMethodObj &&
              deliveryMethodObj.code !== 'viewonly' &&
              deliveryMethodObj.code !== 'spaviewonly' &&
              deliveryMethodObj.code !=='servicerequestvo' &&
              queryStringParamMode !== 'website' ? (
                <div>
                  <Button id="checkoutButton"
                          type="submit"
                          //style={{display: this.state.submitButtonEnabled ? 'block' : 'none'}}
                          disabled={!this.state.submitMenuGroupButtonEnabled}
                          color="primary">
                    {
                      this.state.submitMenuGroupButtonEnabled ?
                      <FormattedMessage id="MenuItems.menugroups.button.add.to.cart.label" defaultMessage="Add to cart" />
                      : 
                      <FormattedMessage id="MenuItems.menugroups.button.select.options.label" defaultMessage="Select options" />
                    }
                  </Button>
                </div>
              ) : null
            }
          </Formsy>
          : null
        }
        {
          menuCategories && menuCategories.length > 0 && 
          menuItemsArray && menuItemsArray.length === 0 && 
          !areWeLoadingANewMenu ?
            <span>&nbsp;
              <FormattedMessage id="MenuItems.Page.No.FilteredMenuItems.Available" 
                                defaultMessage="No menu items available for the selected filters" />.</span> 
            : null
        }
        {
          filtersForMenuItems.length > 0 ?
          <MobileModal active={isModalActive && modalId === 0} 
                    data={filtersForMenuItems[0]} 
                    close={this.closeModal}
                    onSelectedValue={this.updateFilter} />
          :null
        }
        <Modal size="lg" isOpen={this.state.isModalHideDishOpen} toggle={this.toggleModalHideDish}>
          <ModalHeader toggle={this.toggleModalHideDish}>
            <FormattedMessage id="MenuItems.Modal.hide.dish.header" defaultMessage="Hide dish confirmation" />
          </ModalHeader>
          <ModalBody className="bg-white">
            {this.state.selectedDishToHideOrShow? <b>{this.state.selectedDishToHideOrShow.name_i18n[locale]}</b>: null}<br /><br />
            <span style={{color:"red"}}>
              <FormattedMessage id="MenuItems.Modal.hide.dish.body" defaultMessage="This action will remove the above dish from the menu. This means that your customers won't be able to see it in the menu and therefore it won't be available for ordering." />
            </span>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModalHideDish}>
              <FormattedMessage id="MenuItems.Modal.hide.dish.footer.button.close" defaultMessage="Close" />
            </Button>
            <Button color="primary" onClick={this.hideDishInMenu}>
              <FormattedMessage id="MenuItems.Modal.hide.dish.footer.button.confirm" defaultMessage="Confirm" />
            </Button>
          </ModalFooter>
        </Modal>
        <Modal size="lg" isOpen={this.state.isModalShowDishOpen} toggle={this.toggleModalShowDish}>
          <ModalHeader toggle={this.toggleModalShowDish}>
            <FormattedMessage id="MenuItems.Modal.show.dish.header" defaultMessage="Show dish confirmation" />
          </ModalHeader>
          <ModalBody className="bg-white">
            {this.state.selectedDishToHideOrShow? <b>{this.state.selectedDishToHideOrShow.name_i18n[locale]}</b>: null}<br /><br />
            <span style={{color:"blue"}}>
              <FormattedMessage id="MenuItems.Modal.show.dish.body" defaultMessage="This action will publish the above dish in your menu. This means that your customers will be able to see it in the menu and therefore it will be available for ordering." />
            </span>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModalShowDish}>
              <FormattedMessage id="MenuItems.Modal.show.dish.footer.button.close" defaultMessage="Close" />
            </Button>
            <Button color="primary" onClick={this.showDishInMenu}>
              <FormattedMessage id="MenuItems.Modal.show.dish.footer.button.confirm" defaultMessage="Confirm" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>);
  } // enf of render
} // end of class

function mapStateToProps(store) {
  return {
    isFetching: store.menuitem.isFetching,
    errorMessage: store.menuitem.errorMessage,
    restaurantMenuItems: store.menuitem.restaurantMenuItems,
    updatedMenuItem: store.menuitem.updatedMenuItem,
    restaurantInfo: store.restaurant.restaurantInfo,
    isFetchingRestaurantInfo: store.restaurant.isFetching,
    websocketmessage: store.websocket.messageObject
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(Menuitems)));
