import { getListOfMenusFromServerForRestaurant } from '../api/menu.js';

export const MENUS_REQUEST = 'MENUS_REQUEST';
export const MENUS_SUCCESS = 'MENUS_SUCCESS';
export const MENUS_FAILURE = 'MENUS_FAILURE';

function requestMenus() {
  return {
    type: MENUS_REQUEST,
    isFetching: true
  };
}

function receiveMenus(restaurantMenus) {
  return {
    type: MENUS_SUCCESS,
    isFetching: false,
    restaurantWithMenus: restaurantMenus
  };
}

function menusError(message) {
  return {
    type: MENUS_FAILURE,
    isFetching: false,
    message,
  };
}

// Logs the user out
export function getMenusFromServer(restaurantId, deliveryMethod) {
  if (!restaurantId || restaurantId === 'undefined') {
    return (dispatch) => {
      dispatch(menusError('Error: No restaurantId provided'));
    };
  }
  return (dispatch) => {
    dispatch(requestMenus());
    getListOfMenusFromServerForRestaurant(restaurantId, deliveryMethod)
        .then(restaurantWithMenus => {
          //console.log('in getMenusFromServer restaurantWithMenus  => ' + JSON.stringify(restaurantWithMenus));
          //localStorage.setItem('arrayOfMenus', arrayOfMenus);
          // Dispatch the success action with fake user
          if(restaurantWithMenus.errors) return dispatch(menusError(restaurantWithMenus.errors[0].message));

          dispatch(receiveMenus(restaurantWithMenus));
        })
        .catch(error => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          if (error.response && error.response.data) {
            return dispatch(menusError(error.response.data.message));
          }
          return dispatch(menusError(error.toString()));
        })
  };
}