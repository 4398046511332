import ReactGA from "react-ga4";
//import runtimeEnv from '@mars/heroku-js-runtime-env';

export const initGA = () => {
  // init runtime env var
  //const env = runtimeEnv();
  const ga_id = /*env.REACT_APP_GOOGLE_ANALYTICS_ID || */process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
  if(ga_id) {
    const isNotProduction = process.env.NODE_ENV !== "production"
    // console.log("isNotProduction => ", isNotProduction)
    // console.log('ga_id => ', ga_id);       
    ReactGA.initialize(ga_id, {
      testMode: isNotProduction,
      gaOptions: {
        debug_mode: isNotProduction,
      },
      gtagOptions: {
        debug_mode: isNotProduction,
      }
    });
    console.log('ga successfully initialized');
    return true;
  } else {
    return false;
  }
}

export const PageView = ({deliveryMethodCode, restaurantSlug}) => {
    ReactGA.set({ dimension1: restaurantSlug })
    ReactGA.set({ dimension2: deliveryMethodCode })
    ReactGA.gtag("set", "user_properties", {
      dimension1: restaurantSlug,
      dimension2: deliveryMethodCode
    });
    ReactGA.send({ 
      hitType: 'pageview', 
      page: window.location.pathname + window.location.search
    })
    // console.log('ga page view dimensions => ', deliveryMethodCode, restaurantSlug)
}

/**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
export const GAEvent = (category, action, label) => {
  ReactGA.event({ category: category, action: action, label: label });
};