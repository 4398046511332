import React from 'react';
import PropTypes from 'prop-types';
import { navigateToNewPage } from '../../actions/navigation';
import { connect } from 'react-redux';
import {
  Row,
  Col
} from 'reactstrap';

import { FormattedMessage, injectIntl } from 'react-intl'; 

import Image1 from './assets/1.png';
import Image2 from './assets/2.png';
import Image3 from './assets/3.png';
import Image4 from './assets/4.png';

import s from './Landing.module.scss';

class Landing extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    // dispatch back button url and page title name
    const { dispatch } = this.props;
    dispatch(navigateToNewPage());
  }

  componentDidUpdate(prevProps) {
    // dispatch back button url and page title name
    const { dispatch } = this.props;
    dispatch(navigateToNewPage());
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {

    return (
      <div className={s.root}>
        {/*----- 1 -----*/}
        <Row>
          <Col>
            <img src={Image1} alt='' />
          </Col>
        </Row>
        <Row className={s.tutorialText}>
          <Col className={s.colTutorialNumber} xs={1}>
            <span className={s.tutorialNumber}>1.</span>
          </Col>
          <Col xs={11}>
            <span className={s.explanationText}>
              <FormattedMessage id="Landing.tutorial.step.1.explanation.text" 
                defaultMessage="Open your phone's Camera. Tap the Camera app icon, which resembles a black camera on a grey background." />
            </span>
          </Col>
        </Row>
        {/*----- 2 -----*/}
        <Row>
          <Col>
            <img src={Image2} alt='' />
          </Col>
        </Row>
        <Row className={s.tutorialText}>
          <Col className={s.colTutorialNumber} xs={1}>
            <span className={s.tutorialNumber}>2.</span>
          </Col>
          <Col xs={11}>
            <span className={s.explanationText}>
              <FormattedMessage id="Landing.tutorial.step.2.explanation.text" 
                defaultMessage="Point the camera at the QR code. Your phone should focus in on the QR code after a brief moment." />
            </span>
          </Col>
        </Row>
        {/*----- 3 -----*/}
        <Row>
          <Col>
            <img src={Image3} alt='' />
          </Col>
        </Row>
        <Row className={s.tutorialText}>
          <Col className={s.colTutorialNumber} xs={1}>
            <span className={s.tutorialNumber}>3.</span>
          </Col>
          <Col xs={11}>
            <span className={s.explanationText}>
              <FormattedMessage id="Landing.tutorial.step.3.explanation.text" 
                defaultMessage="Make sure the QR code is centered on your phone's screen. All four edges of the QR code should be on your phone's screen." />
            </span>
          </Col>
        </Row>
        {/*----- 4 -----*/}
        <Row>
          <Col>
            <img src={Image4} alt='' />
          </Col>
        </Row>
        <Row className={s.tutorialText}>
          <Col className={s.colTutorialNumber} xs={1}>
            <span className={s.tutorialNumber}>4.</span>
          </Col>
          <Col xs={11}>
            <span className={s.explanationText}>
              <FormattedMessage id="Landing.tutorial.step.4.explanation.text" 
                defaultMessage="Open the QR code's content. Tap the browser notification that appears at the top of the screen to open the QR code's webpage or other information." />
            </span>
          </Col>
        </Row>

      </div>);
  } // enf of render
} // end of class

function mapStateToProps(store) {
  return {};
}

export default injectIntl(connect(mapStateToProps)(Landing));
