import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable */
import s from './RealPictureText.module.scss';
import { FormattedMessage } from 'react-intl'; 
/* eslint-enable */
const RealPictureText = ({ isRealPicture }) => (
  !isRealPicture ? 
  <div className={s.realpictureText}>
    <span>
      *<FormattedMessage id="MenuItems.MenuItem.NotRealPictureText.label" defaultMessage="Displayed photo is for illustration only" />
    </span>
  </div>
  :
  <div style={{fontSize: "0.6em"}}>&nbsp;</div>
);

RealPictureText.propTypes = {
  isRealPicture: PropTypes.bool,
};

RealPictureText.defaultProps = {
  isRealPicture: false
};

export default RealPictureText;
