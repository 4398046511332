import React from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/dist/style.css";
import InputValidation from "../../../InputValidation";
import Formsy from "formsy-react";
import { FormattedMessage, injectIntl } from "react-intl";
import DropdownCollectionTimeslots from "../../../DropdownCollectionTimeslots/DropdownCollectionTimeslots";
import queryString from 'query-string';

import s from "./CheckoutPopup.module.scss";

const REQUIRED = 'required'
const OPTIONAL = 'optional'

class CheckoutPopup extends React.Component {
  static propTypes = {
    showPopup: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    restaurantPaymentMethods: PropTypes.array.isRequired,
    deliveryMethods: PropTypes.array.isRequired,
    mobileAppDeliveryMethod: PropTypes.string.isRequired,
    mobileAppDeliveryMethodConfiguration: PropTypes.array.isRequired,
    collectTimeslots: PropTypes.array,
    takeawayTimeslots: PropTypes.array,
    restaurantCountryCode: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    const locationNumber = localStorage.getItem('locationnumber')
    const groupcode = localStorage.getItem('groupcode')
    this.state = {
      phone: "",
      tablelabel: locationNumber ? locationNumber : "",
      tablelabelExtensionForCollecAtX: null,
      submitButtonEnabled: false,
      orderType: this.props.mobileAppDeliveryMethod,
      selectedCollectionTimeslot: {},
      selectedPaymentMethod: {
        code: "onlinepayment",
        isOnlinePaymentMethod: true,
      },
      emailInput: "",
      firstnameInput: "",
      lastnameInput: "",
      groupCodeForOrderInput: groupcode ? groupcode : "",
    };

    this.handleOnChangePhone = this.handleOnChangePhone.bind(this);
    this.enableSubmitButton = this.enableSubmitButton.bind(this);
    this.disableSubmitButton = this.disableSubmitButton.bind(this);
    this.handleOnChangeTableLabel = this.handleOnChangeTableLabel.bind(this);
    this.handleOnChangeTableLabelExtensionForCollectAtX = this.handleOnChangeTableLabelExtensionForCollectAtX.bind(this);
    this.getLocallyStoredOrderExtInfo = this.getLocallyStoredOrderExtInfo.bind(
      this
    );
    this.saveOrderExtInfoLocally = this.saveOrderExtInfoLocally.bind(this);
    this.submitOrderExtendedInfo = this.submitOrderExtendedInfo.bind(this);
    this.collectionTimeslotchanged = this.collectionTimeslotchanged.bind(this);
    this.handleOnChangePaymentMethod = this.handleOnChangePaymentMethod.bind(this);
    this.handleOnChangeEmailInput = this.handleOnChangeEmailInput.bind(this);
    this.handleOnChangeFirstnameInput = this.handleOnChangeFirstnameInput.bind(this);
    this.handleOnChangeLastnameInput = this.handleOnChangeLastnameInput.bind(this);
    this.handleOnChangeGroupCodeForOrderInput = this.handleOnChangeGroupCodeForOrderInput.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Only load when popup is newly shown
    if (this.props.showPopup && !prevProps.showPopup) {
      this.loadOrderExtendedInfo();
    }
  }

  loadOrderExtendedInfo() {
    const localstorageOrderExtInfo = this.getLocallyStoredOrderExtInfo();
    console.log("localstorageOrderExtInfo => ", localstorageOrderExtInfo);

    if (localstorageOrderExtInfo) {
      const now = new Date();
      const isExpirationDateStillValid =
        new Date(localstorageOrderExtInfo.expirationDate) > now;

      this.setState({
        orderType: localstorageOrderExtInfo.orderType,
        phone: localstorageOrderExtInfo.phoneNumber,
        emailInput: localstorageOrderExtInfo.emailInput,
        firstnameInput: localstorageOrderExtInfo.firstnameInput,
        lastnameInput: localstorageOrderExtInfo.lastnameInput,
        groupCodeForOrderInput: localStorage.getItem('groupcode') ? localStorage.getItem('groupcode') : 
        (isExpirationDateStillValid
          ? localstorageOrderExtInfo.groupCodeForOrderInput
          : ""),
        tablelabel: localStorage.getItem('locationnumber') ? localStorage.getItem('locationnumber') : 
        (isExpirationDateStillValid
          ? localstorageOrderExtInfo.tableLabel
          : ""),
        tablelabelExtensionForCollecAtX: isExpirationDateStillValid
          ? localstorageOrderExtInfo.tablelabelExtensionForCollecAtX : null
      });
    }
  }

  componentDidMount() {
    // Initial load
    if (this.props.showPopup) {
      this.loadOrderExtendedInfo();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.mobileAppDeliveryMethod !== prevState.orderType) {
      return {
        orderType: nextProps.mobileAppDeliveryMethod
      }
    }

    return null;
  }

  getLocallyStoredOrderExtInfo() {
    const { mobileAppDeliveryMethod } = this.props
    return JSON.parse(localStorage.getItem(`orderExtendedInformation-${mobileAppDeliveryMethod}`));
  }

  saveOrderExtInfoLocally() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const orderExtendedInfoToSaveLocallyInJSON = {
      phoneNumber: this.state.phone,
      clientFullName: "",
      tableLabel: this.state.tablelabel,
      tablelabelExtensionForCollecAtX: this.state.tablelabelExtensionForCollecAtX,
      expirationDate: tomorrow,
      orderType: this.state.orderType,
      selectedCollectionTimeslot: this.state.selectedCollectionTimeslot,
      selectedPaymentMethod: this.state.selectedPaymentMethod,
      emailInput: this.state.emailInput,
      firstnameInput: this.state.firstnameInput,
      lastnameInput: this.state.lastnameInput,
      groupCodeForOrderInput: this.state.groupCodeForOrderInput
    };
    const orderExtendedInfoToSaveLocallyInStringFormat = JSON.stringify(
      orderExtendedInfoToSaveLocallyInJSON
    );
    const { mobileAppDeliveryMethod } = this.props
    localStorage.setItem(
      `orderExtendedInformation-${mobileAppDeliveryMethod}`,
      orderExtendedInfoToSaveLocallyInStringFormat
    );

    return orderExtendedInfoToSaveLocallyInJSON;
  }

  handleOnChangePhone(value) {
    this.setState({ phone: value });
  }

  handleOnChangeTableLabel(value) {
    this.setState({ tablelabel: value });
  }

  handleOnChangeTableLabelExtensionForCollectAtX(value) {
    this.setState({ tablelabelExtensionForCollecAtX: value })
  }

  handleOnChangeEmailInput(value) {
    this.setState({ emailInput: value });
  }

  handleOnChangeFirstnameInput(value) {
    this.setState({ firstnameInput: value });
  }

  handleOnChangeLastnameInput(value) {
    this.setState({ lastnameInput: value });
  }

  handleOnChangeGroupCodeForOrderInput(value) {
    this.setState({ groupCodeForOrderInput: value });
  }

  handleOnChangePaymentMethod(value) {
    // console.log("selected payment method => ", JSON.parse(value));
    this.setState({ selectedPaymentMethod: { ...JSON.parse(value) } });
  }

  enableSubmitButton() {
    this.setState({ submitButtonEnabled: true });
  }

  disableSubmitButton() {
    this.setState({ submitButtonEnabled: false });
  }

  submitOrderExtendedInfo() {
    // this.disableSubmitButton();
    const extendedOrderInformation = this.saveOrderExtInfoLocally();
    const mobileAppDeliveryMethod = this.props.mobileAppDeliveryMethod;
    if (mobileAppDeliveryMethod === 'collectatbar' ||
      mobileAppDeliveryMethod === 'collectatbarnp' ||
      mobileAppDeliveryMethod === 'collectatkiosk' ||
      mobileAppDeliveryMethod === 'collectatkioskwop') {
      // if table number if not a number then takeway
      if (isNaN(extendedOrderInformation.tablelabelExtensionForCollecAtX)) {
        extendedOrderInformation.tableLabel += ` (${extendedOrderInformation.tablelabelExtensionForCollecAtX})`
      } else {
        if (extendedOrderInformation.tablelabelExtensionForCollecAtX) {
          extendedOrderInformation.tableLabel += ` (table: ${extendedOrderInformation.tablelabelExtensionForCollecAtX})`
        }
      }
    }
    this.props.onSubmit(extendedOrderInformation);
  }

  collectionTimeslotchanged(selectedCollectionTimeslot) {
    // console.log('selected collection timeslot => ', selectedCollectionTimeslot);
    this.setState({
      selectedCollectionTimeslot,
    });
  }

  render() {
    const { onClose, showPopup, restaurantPaymentMethods, intl, deliveryMethods, mobileAppDeliveryMethodConfiguration } = this.props;
    const mobileAppDeliveryMethod = this.props.mobileAppDeliveryMethod;
    const queryStringParamLocationNumber = queryString.parse(this.props.queryStringSearch).locationnumber;
    const queryStringParamGroupCode = queryString.parse(this.props.queryStringSearch).groupcode;
    // console.log("this.props.queryStringSearch => ", this.props.queryStringSearch);
    // console.log("queryStringParamLocationNumber => ", queryStringParamLocationNumber);
    // console.log("queryStringParamGroupCode => ", queryStringParamGroupCode);
    const onlinePaymentMethods = restaurantPaymentMethods.filter(
      (pm) => pm.isOnlinePaymentMethod
    );
    const nonOnlinePaymentMethods = restaurantPaymentMethods.filter(
      (pm) => !pm.isOnlinePaymentMethod
    );

    let overwriteLocationNumberLabel, locationNumberLabelTranslations = null;
    // console.log('deliveryMethodsConfiguration => ', mobileAppDeliveryMethodConfiguration)
    let filteredDeliveryMethodsConfiguration = []
    if (mobileAppDeliveryMethodConfiguration) {
      filteredDeliveryMethodsConfiguration = mobileAppDeliveryMethodConfiguration.filter((config) => config.deliverymethod.code === mobileAppDeliveryMethod)
      if (filteredDeliveryMethodsConfiguration.length > 0) {
        overwriteLocationNumberLabel = filteredDeliveryMethodsConfiguration[0].overwriteLocationNumberLabel;
        locationNumberLabelTranslations = filteredDeliveryMethodsConfiguration[0].locationNumberLabelTranslations;
      }
    }
    //console.log('overwriteLocationNumberLabel => ', overwriteLocationNumberLabel);
    //console.log('locationNumberLabelTranslations => ', locationNumberLabelTranslations);
    // console.log('deliveryMethods => ', deliveryMethods)
    const deliveryMethodsForMobileAppDeliveryMethod = deliveryMethods.filter(dm => dm.code === mobileAppDeliveryMethod);
    let currentDeliveryMethod
    if (deliveryMethodsForMobileAppDeliveryMethod.length > 0)
      currentDeliveryMethod = deliveryMethodsForMobileAppDeliveryMethod[0]
    const { locale } = intl

    /* console.log("this.props.deliveryMethods => ", this.props.deliveryMethods);
    console.log('currentDeliveryMethod => ', currentDeliveryMethod)
    console.log("mobileAppDeliveryMethod => ", mobileAppDeliveryMethod); */
    /* console.log("this.state.selectedPaymentMethod => ", this.state.selectedPaymentMethod.id);
    console.log("this.state.selectedCollectionTimeslot.id => ", this.state.selectedCollectionTimeslot);
    console.log("this.state.!selectedCollectionTimeslot => ", !this.state.selectedCollectionTimeslot.id);
    console.log("this.state.!submitButtonEnabled => ", !this.state.submitButtonEnabled);
    console.log("this.state.!this.state.selectedPaymentMethod.code => ", !this.state.selectedPaymentMethod.code); */
    let isSubmitButtonDisabled = true;
    if (currentDeliveryMethod && currentDeliveryMethod.isDineIn) {
      isSubmitButtonDisabled = !this.state.submitButtonEnabled;
    } else {
      if (currentDeliveryMethod &&
        !currentDeliveryMethod.isDineIn &&
        !currentDeliveryMethod.isCollectAtLocation &&
        !currentDeliveryMethod.isWithOnlinePayment ) {
          isSubmitButtonDisabled = false
      }
      else {
        if (currentDeliveryMethod && currentDeliveryMethod.code && 
          (currentDeliveryMethod.code.includes('massageatspa') || currentDeliveryMethod.code.includes('servicerequest'))) {
          isSubmitButtonDisabled = !this.state.submitButtonEnabled;
        } else {
          if (currentDeliveryMethod &&
            !currentDeliveryMethod.isDineIn &&
            !currentDeliveryMethod.isCollectAtLocation &&
            currentDeliveryMethod.isWithOnlinePayment ) {
              isSubmitButtonDisabled = !this.state.submitButtonEnabled;
          } else {
            if (currentDeliveryMethod) {
              isSubmitButtonDisabled = !(this.state.submitButtonEnabled);
            }
          }
        }
      }
    }
    
    return (
      <Modal isOpen={showPopup} className={s.root}>
        <ModalHeader 
          style={locale === 'ar' ? {textAlign: 'center', display: 'initial'} : {}}>
          <FormattedMessage
            id="CheckoutPopup.ModalHeader.ConfirmCheckout"
            defaultMessage="Confirm Checkout"
          />
        </ModalHeader>
        <ModalBody className="bg-white">
          <Formsy
            onValidSubmit={this.submitOrderExtendedInfo}
            onValid={this.enableSubmitButton}
            onInvalid={this.disableSubmitButton}
            style={locale === 'ar' ? {textAlign: 'right'} : {}}
          >
            <fieldset>
              {
                filteredDeliveryMethodsConfiguration.length > 0 &&
                (
                  filteredDeliveryMethodsConfiguration[0].customerPhoneForOrder === REQUIRED || 
                  filteredDeliveryMethodsConfiguration[0].customerPhoneForOrder === OPTIONAL
                ) ? (
                <FormGroup row>
                  <Label md="12" xs="12" for="mask-int-phone">
                    <FormattedMessage
                      id="CheckoutPopup.phonenumber.label"
                      defaultMessage="Enter your phone number"
                    />
                    <span className={s.helpPhoneNumber}>
                      {
                        currentDeliveryMethod.code.includes('massageatspa') ? (
                          <FormattedMessage
                            id="CheckoutPopup.phonenumber.massageatspa.label.helptext"
                            defaultMessage="your phone number is required so that the outlet can call you back to book the treatment"
                          />
                        ) :(
                          <FormattedMessage
                            id="CheckoutPopup.phonenumber.label.helptext"
                            defaultMessage="your phone number is only used in case the restaurant wants to contact you about your order"
                          />
                        )
                      }
                    </span>                    
                    {
                      currentDeliveryMethod.isDineIn && currentDeliveryMethod.isCollectAtLocation ? (
                      <div className={s.helpPhoneNumberCollect}>
                        <FormattedMessage
                          id="CheckoutPopup.phonenumber.collectatlocation.helptext"
                          defaultMessage="In case you did not pickup your order after receiving an email notification, we will call you to let you know that the order is ready to be collected"
                        />
                      </div>
                      ) : null
                    }
                  </Label>
                  <Col xs="12">
                    <InputValidation
                      type="text"
                      id="phoneNumberInput"
                      name="phoneNumberInput"
                      value={this.state.phone}
                      required={filteredDeliveryMethodsConfiguration[0].customerPhoneForOrder === REQUIRED}
                      isHidden={true}
                    />
                    <ReactPhoneInput
                      id="mask-int-phone"
                      defaultCountry={this.props.restaurantCountryCode}
                      value={this.state.phone}
                      onChange={this.handleOnChangePhone}
                    />
                  </Col>
                </FormGroup>
                ) : null
              }
              {
                filteredDeliveryMethodsConfiguration.length > 0 &&
                (
                  filteredDeliveryMethodsConfiguration[0].customerEmailForOrder === REQUIRED || 
                  filteredDeliveryMethodsConfiguration[0].customerEmailForOrder === OPTIONAL
                ) ? (
                <FormGroup row>
                  <Label md="12" xs="12" for="emailInput">
                      <FormattedMessage
                        id="CheckoutPopup.input.email.label"
                        defaultMessage="Enter your email address"
                      />
                    <span className={s.helpPhoneNumber}>
                      <FormattedMessage
                        id="CheckoutPopup.email.label.helptext"
                        defaultMessage="your email is used to send you real-time email notifications about your order"
                      />
                    </span>
                    {
                        currentDeliveryMethod && currentDeliveryMethod.isDineIn && currentDeliveryMethod.isCollectAtLocation ? (
                        <div className={s.helpPhoneNumberCollect}>
                          <FormattedMessage
                            id="CheckoutPopup.email.collectatlocation.helptext"
                            defaultMessage="Please do provide an email address on which we can send you a notification when the order is ready to be collected"
                          />
                        </div>
                        ) : (null)
                    }
                  </Label>
                  <Col xs="12">
                    <InputValidation
                      type="text"
                      id="emailInput"
                      name="emailInput"
                      value={this.state.emailInput}
                      setValue={this.handleOnChangeEmailInput}
                      validations="isEmail"
                      required={filteredDeliveryMethodsConfiguration[0].customerEmailForOrder === REQUIRED}
                    />
                  </Col>
                </FormGroup> 
                ) : null
              }
              {
                filteredDeliveryMethodsConfiguration.length > 0 &&
                (
                  filteredDeliveryMethodsConfiguration[0].groupCodeForOrder === REQUIRED || 
                  filteredDeliveryMethodsConfiguration[0].groupCodeForOrder === OPTIONAL
                ) ? (
                <FormGroup row>
                  <Label md="12" xs="12" for="groupCodeInput">
                    <FormattedMessage
                      id="CheckoutPopup.groupcodefororder.label"
                      defaultMessage="Enter your code"
                    />                  
                  </Label>
                  <Col xs="12">
                    <InputValidation
                      type="text"
                      id="groupCodeInput"
                      name="groupCodeInput"
                      value={this.state.groupCodeForOrderInput}
                      setValue={this.handleOnChangeGroupCodeForOrderInput}
                      required={filteredDeliveryMethodsConfiguration[0].groupCodeForOrder === REQUIRED}
                      disabled={queryStringParamGroupCode ? true : false}
                    />
                  </Col>
                </FormGroup>
                ) : null
              }
              {
                mobileAppDeliveryMethod === "collectatbar" ||
                mobileAppDeliveryMethod === "collectatbarnp" ||
                mobileAppDeliveryMethod === "collectatkiosk" ? (
                  <FormGroup row>
                    <Label md="12" xs="12" for="tableLabelInputCollectAtX">
                      {
                        overwriteLocationNumberLabel ? (
                          locationNumberLabelTranslations[locale]
                        ) : (
                          <FormattedMessage
                            id="CheckoutPopup.tablelabel.collectatx.deliverymethods.label"
                            defaultMessage="Enter your table number"
                          />
                        )
                      }
                      <div className={s.helpTableNumberCollect}>
                        <FormattedMessage
                          id="CheckoutPopup.tablenumber.collectatx.helptext"
                          defaultMessage="We need your table number so we can find you in case you did not pickup your order after receiving an email notification. Please enter 'Takeaway' in case you want to takeout your order."
                        />
                      </div>
                    </Label>
                    <Col xs="12">
                      <InputValidation
                        type="number"
                        id="tableLabelInputCollectAtX"
                        name="tableLabelInputCollectAtX"
                        value={this.state.tablelabelExtensionForCollecAtX}
                        setValue={this.handleOnChangeTableLabelExtensionForCollectAtX}
                        validations="isNumeric"
                        required
                      />
                      {
                        mobileAppDeliveryMethod === "collectatbar" ||
                        mobileAppDeliveryMethod === "collectatbarnp" ? 
                          <div className={s.helpCollectAtBar18plus}>
                              <FormattedMessage
                                id="CheckoutPopup.tablenumber.collectatbar.18plus.helptext"
                                defaultMessage="Please note, that you should be 18 years and older to order. If you are under 18, your order will not be refunded."
                              />
                            <img id="nix18image" alt="nix18" />
                          </div>
                        : null
                      }
                    </Col>
                  </FormGroup>
                ) : null
              }
              {
                filteredDeliveryMethodsConfiguration.length > 0 &&
                (
                  filteredDeliveryMethodsConfiguration[0].customerFullnameForOrder === REQUIRED || 
                  filteredDeliveryMethodsConfiguration[0].customerFullnameForOrder === OPTIONAL
                ) ? (
                  <div>
                  <FormGroup row>
                    <Label md="12" xs="12" for="firsnameInput">
                        <FormattedMessage
                          id="CheckoutPopup.input.firstname.label"
                          defaultMessage="Enter your firstname"
                        />
                    </Label>
                    <Col xs="12">
                      <InputValidation
                        type="text"
                        id="firsnameInput"
                        name="firsnameInput"
                        value={this.state.firstnameInput}
                        setValue={this.handleOnChangeFirstnameInput}
                        required={filteredDeliveryMethodsConfiguration[0].customerFullnameForOrder === REQUIRED}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="12" xs="12" for="lastnameInput">
                        <FormattedMessage
                          id="CheckoutPopup.input.lastname.label"
                          defaultMessage="Enter your lastname"
                        />
                    </Label>
                    <Col xs="12">
                      <InputValidation
                        type="text"
                        id="lastnameInput"
                        name="lastnameInput"
                        value={this.state.lastnameInput}
                        setValue={this.handleOnChangeLastnameInput}
                        required={filteredDeliveryMethodsConfiguration[0].customerFullnameForOrder === REQUIRED}
                      />
                    </Col>
                  </FormGroup>
                </div>
                ) : null
              }
              <FormGroup row>
                <Label md="12" xs="12" for="tableLabelInput">
                  {
                    // we have more than one delivery method and now assuming
                    // it is dine in and collect then we display the right message
                    mobileAppDeliveryMethod === "dinein" ? (
                      overwriteLocationNumberLabel ? (
                        locationNumberLabelTranslations[locale]
                      ) : (
                        <FormattedMessage
                          id="CheckoutPopup.tablelabel.dinein.deliverymethods.label"
                          defaultMessage="Enter your table number"
                        />
                      )
                    ) : (
                        mobileAppDeliveryMethod === "roomservice" || mobileAppDeliveryMethod.includes('massageatspawithroom') ? (
                          <span>
                            <FormattedMessage
                              id="CheckoutPopup.tablelabel.roomservice.deliverymethods.label"
                              defaultMessage="Enter your room number"
                            />
                            {
                              !queryStringParamLocationNumber ? (
                                <div className={s.helpRoomNumberRoomService}>
                                {
                                  mobileAppDeliveryMethod.includes('massageatspa') ? (
                                  <FormattedMessage
                                    id="CheckoutPopup.roomnumber.massageatspa.helptext"
                                    defaultMessage="Please enter your room number for the inquiry to be processed"/>
                                  ) : (
                                    <FormattedMessage
                                      id="CheckoutPopup.roomnumber.roomservice.helptext"
                                      defaultMessage="Please enter your room number for the order to be processed and delivered to your room"/>
                                  )
                                }
                                </div>
                              ) : null
                            }
                          </span>
                        ) : ( 
                            mobileAppDeliveryMethod === "delivertoofficewop" ||
                            mobileAppDeliveryMethod === "deliverytoseat" ||
                            mobileAppDeliveryMethod === "deliverytolocation" ? (
                              overwriteLocationNumberLabel ? (
                                locationNumberLabelTranslations[locale]
                              ) : (
                              <span>
                                <FormattedMessage
                                  id="CheckoutPopup.tablelabel.deliverytolocation.deliverymethods.label"
                                  defaultMessage="Enter your delivery location"
                                />
                              </span>
                              )
                            ) : 
                              // massage at spa
                              mobileAppDeliveryMethod.includes('massageatspa') ? (
                                <span>
                                  <FormattedMessage
                                    id="CheckoutPopup.tablelabel.massageatspa.deliverymethods.label"
                                    defaultMessage="Enter your full name"
                                  />
                                </span>
                              ) : // service request
                                mobileAppDeliveryMethod.includes('servicerequest') ? (
                                  <span>
                                    <FormattedMessage
                                      id="CheckoutPopup.tablelabel.servicerequest.deliverymethods.label"
                                      defaultMessage="Fill in your location"
                                    />
                                  </span>
                                ) : (
                                // here we are assuming it is collect
                                <FormattedMessage
                                  id="CheckoutPopup.tablelabel.collect.deliverymethods.label"
                                  defaultMessage="Enter your name for the order"
                                />
                                )
                        )
                    )
                  }
                </Label>
                <Col xs="12">
                  <InputValidation
                    // type={currentDeliveryMethod && currentDeliveryMethod.isDineIn && !currentDeliveryMethod.isCollectAtLocation ? "number" : "text"}
                    type="text"
                    id="tableLabelInput"
                    name="tableLabelInput"
                    value={this.state.tablelabel}
                    setValue={this.handleOnChangeTableLabel}
                    required
                    disabled={queryStringParamLocationNumber ? true : false}
                  />
                </Col>
              </FormGroup>
              {
                // if the restaurant offers non online payment methods (cash or restaurant voucher for example)
                // and we are in click and collect mode then we show the different payment methods
                mobileAppDeliveryMethod !== "dinein" &&
                nonOnlinePaymentMethods.length > 0 ? (
                  <FormGroup>
                    <legend
                      style={{
                        fontSize: "1.2rem",
                        color: "#495057",
                        borderBottom: "none",
                        marginBottom: "5px",
                      }}
                    >
                      <FormattedMessage
                        id="CheckoutPopup.form.paymentmethod.section.label"
                        defaultMessage="Select your payment method"
                      />
                    </legend>
                    <FormGroup row>
                      {nonOnlinePaymentMethods.map((pm, index) => {
                        return (
                          <Col xs={12} sm={6} key={pm.code + index}>
                            <FormGroup
                              check
                              row
                              style={{
                                paddingLeft: "0",
                              }}
                            >
                              <Label
                                check
                                style={{
                                  border: "2px solid #798892",
                                  borderRadius: "5px",
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  width: "220px",
                                  backgroundColor:
                                    this.state.selectedPaymentMethod.code ===
                                    pm.code
                                      ? "gold"
                                      : "white",
                                  height: "50px",
                                }}
                              >
                                <InputValidation
                                  type="radio"
                                  id={`radio-${pm.code + index}`}
                                  name="radio1"
                                  style={{ display: "contents" }}
                                  value={JSON.stringify(pm)}
                                  setValue={this.handleOnChangePaymentMethod}
                                  required
                                />
                                <div
                                  style={{
                                    marginTop: "10px",
                                  }}
                                >
                                  {pm.code === "cash" ? (
                                    <FormattedMessage
                                      id="CheckoutPopup.form.paymentmethod.cash.button.label"
                                      defaultMessage="Cash"
                                    />
                                  ) : pm.code === "ticketresto" ? (
                                    <FormattedMessage
                                      id="CheckoutPopup.form.paymentmethod.ticketresto.button.label"
                                      defaultMessage="Restaurant voucher"
                                    />
                                  ) : pm.code === "roompost" ? (
                                    <FormattedMessage
                                      id="CheckoutPopup.form.paymentmethod.roompost.button.label"
                                      defaultMessage="Room Post"
                                    />
                                  ) : pm.code === "creditcard" ? (
                                    <FormattedMessage
                                      id="CheckoutPopup.form.paymentmethod.creditcard.button.label"
                                      defaultMessage="Credit Card"
                                    />
                                  ) : null}
                                </div>
                              </Label>
                            </FormGroup>
                          </Col>
                        );
                      })}
                      {
                         onlinePaymentMethods.length > 0 ? (
                          <Col xs={12} sm={6}>
                            <FormGroup
                              check
                              row
                              style={{
                                paddingLeft: "0",
                              }}
                            >
                              <Label
                                check
                                style={{
                                  border: "2px solid #798892",
                                  borderRadius: "5px",
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  width: "220px",
                                  backgroundColor:
                                    this.state.selectedPaymentMethod.code ===
                                    "onlinepayment"
                                      ? "gold"
                                      : "white",
                                  height: "50px",
                                }}
                              >
                                <InputValidation
                                  type="radio"
                                  id="radio3"
                                  name="radio1"
                                  style={{ display: "contents" }}
                                  value={JSON.stringify({
                                    code: "onlinepayment",
                                    isOnlinePaymentMethod: true,
                                  })}
                                  setValue={this.handleOnChangePaymentMethod}
                                  required
                                />
                                <div
                                  style={{
                                    marginTop: "10px",
                                  }}
                                >
                                  <FormattedMessage
                                    id="CheckoutPopup.form.paymentmethod.onlinepayment.button.label"
                                    defaultMessage="Online payment"
                                  />
                                </div>
                              </Label>
                            </FormGroup>
                          </Col>
                         ) : null
                      }
                    </FormGroup>
                  </FormGroup>
                ) : null
              }
              {
                mobileAppDeliveryMethod === "collect" || 
                mobileAppDeliveryMethod === "takeaway" || 
                mobileAppDeliveryMethod === "collectwop" ? (
                <FormGroup row>
                  <Label md="12" xs="12" for="collection-timeslot-select">
                    <FormattedMessage
                      id="CheckoutPopup.tablelabel.collect.collectiontimeslots.label"
                      defaultMessage="Select your pickup time slot"
                    />
                  </Label>
                  <Col xs="12">
                    <DropdownCollectionTimeslots
                      takeawayEnabled={
                        mobileAppDeliveryMethod === "takeaway"
                      }
                      onChange={this.collectionTimeslotchanged}
                      collectTimeslots={this.props.collectTimeslots}
                      takeawayTimeslots={this.props.takeawayTimeslots}
                    />
                  </Col>
                </FormGroup>
              ) : null}
            </fieldset>
            <div className="form-action">
              <Row>
                <Col xs={3}>
                  <Button
                    type="button"
                    color="default"
                    onClick={() => onClose()}
                    className="btn-rounded"
                  >
                    <FormattedMessage
                      id="CheckoutPopup.form.cancel.button.label"
                      defaultMessage="Cancel"
                    />
                  </Button>
                </Col>
                <Col xs={9}>
                  <Button
                    id="checkoutPopupSubmitButton"
                    type="submit"
                    color="danger"
                    disabled={ isSubmitButtonDisabled }
                    className="btn-rounded float-right"
                  >
                    {
                      mobileAppDeliveryMethod.includes('massageatspa') ||
                      mobileAppDeliveryMethod.includes('servicerequest') ? (
                      <FormattedMessage
                        id="CheckoutPopup.form.submit.button.inquiry.label"
                        defaultMessage="Send inquiry"
                      />
                      ) :
                    (currentDeliveryMethod && currentDeliveryMethod.isDineIn && !currentDeliveryMethod.isWithOnlinePayment) ||
                      (currentDeliveryMethod && !currentDeliveryMethod.isDineIn && !currentDeliveryMethod.isWithOnlinePayment && !currentDeliveryMethod.isCollectAtLocation) ? (
                      <FormattedMessage
                        id="CheckoutPopup.form.submit.button.label"
                        defaultMessage="Submit Order"
                      />
                    ) : (
                          this.state.selectedPaymentMethod.code &&
                          !this.state.selectedPaymentMethod
                            .isOnlinePaymentMethod ? (
                          <FormattedMessage
                            id="CheckoutPopup.form.submit.payment.method.not.online.button.label"
                            defaultMessage="Submit Order"
                          />
                        ) : (
                              this.state.selectedCollectionTimeslot.id || (currentDeliveryMethod && currentDeliveryMethod.isDineIn && currentDeliveryMethod.isWithOnlinePayment) ? (
                              <FormattedMessage
                                id="CheckoutPopup.form.submit.payment.button.label"
                                defaultMessage="Payment"
                              />
                            ) : (
                                currentDeliveryMethod && !currentDeliveryMethod.isDineIn && currentDeliveryMethod.isWithOnlinePayment ? (
                                <FormattedMessage
                                  id="CheckoutPopup.form.submit.payment.button.label"
                                  defaultMessage="Payment"
                                />
                                ) :
                                <FormattedMessage
                                  id="CheckoutPopup.form.submit.payment.notavailable.button.label"
                                  defaultMessage="Not available"
                                />
                              )
                            )
                    )}
                  </Button>
                </Col>
              </Row>
            </div>
          </Formsy>
        </ModalBody>
        <div style={{textAlign: 'center', fontSize: '12px', marginBottom: '15px'}}>
          <a
            href='https://www.getalacarte.com/terms-conditions-privacy-policy'
            rel='noopener noreferrer'
            target='_blank'>
            <FormattedMessage
              id="CheckoutPopup.terms.and.conditions.label"
              defaultMessage="Terms & Conditions and Privacy Policy"
            />
          </a>
        </div>
      </Modal>
    );
  }
}

export default injectIntl(CheckoutPopup);
