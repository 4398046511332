import React from 'react';
import PropTypes from 'prop-types';
import Widget from '../../../../components/Widget';
import { withRouter } from 'react-router-dom';
import {
  Tooltip,
} from 'reactstrap';

import {FormattedMessage} from 'react-intl';

import './MenuitemgroupsGallery.modules.scss';
import RealPictureText from '../../../../components/RealPictureText/RealPictureText';
import CarouselMenuItem from '../../../../components/CarouselMenuItem/CarouselMenuItem';
import chefHat from '../../../../images/chef/chef-hat.svg';
import Allergens from '../../../../components/Allergens/Allergens';

import $ from 'jquery';

import { withFormsy } from 'formsy-react';
import PriceTableWithoutFormsy from '../../../../components/PriceTable/PriceTableWithoutFormsy';
import AddongroupItemsTableWithoutFormsy from '../../../../components/AddongroupItemsTable/AddongroupItemsTableWithoutFormsy';

class MenuitemgroupsGallery extends React.Component {

  static propTypes = {
    menuitemgroup: PropTypes.object.isRequired,
    filteredMenuitems: PropTypes.array.isRequired,
    restaurantId: PropTypes.string.isRequired,
    customAllergensIcon: PropTypes.array,
    customFoodtypesIcon: PropTypes.array,
    restaurantCurrency: PropTypes.string.isRequired,
    currencyNumberOfDecimals: PropTypes.number.isRequired,
    menuId: PropTypes.string.isRequired,
    menuCategoryIdForMenuGroup: PropTypes.string.isRequired,
    activeMenuCategoryId: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      locale: this.props.locale,
      tooltipsRecommendedByTheChef: [],
      menuitemgroup: this.props.menuitemgroup,
      filteredMenuitems: this.props.filteredMenuitems,
      selectedMenuitemgroupsize: null
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.setValueForMenuitemgroup = this.setValueForMenuitemgroup.bind(this);
    this.setValueForMenuitemgroupAddons = this.setValueForMenuitemgroupAddons.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    // if we loaded a new set of menuitem groups
    // prevProps.filteredMenuitems !== this.props.filteredMenuitems
    if(prevProps.activeMenuCategoryId !== this.props.activeMenuCategoryId) {
      if (this.state.selectedMenuitemgroupsize) {
        //console.log('state selectedMenuitemgroupsize => ', this.state.selectedMenuitemgroupsize);
        this.setState({
          menuitemgroup: this.props.menuitemgroup,
          filteredMenuitems: this.props.filteredMenuitems,
          locale: this.props.locale,
          selectedMenuitemgroupsize: null
        });
      } else {
        this.setState({
          menuitemgroup: this.props.menuitemgroup,
          filteredMenuitems: this.props.filteredMenuitems,
          locale: this.props.locale
        });
      }
    }
  }

  setValueForMenuitemgroup(value) {
    const selectedMenuitemgroupsize = value;
    // if we currently have a value (which could be the addon that has
    // been selected before the size of a dish) then we check
    // if the current size selected for the dish is the one that belongs
    // to that addon and if this is the case then we merge both objects
    //console.log(selectedMenuitemgroupsize);
    const currentValue = this.props.getValue();
    if(!currentValue || !Object.keys(currentValue).length) {
      // adding menu item group to selected menu item size
      if(selectedMenuitemgroupsize) {
        selectedMenuitemgroupsize.menuitemgroup = this.state.menuitemgroup;
        // store the selected menu item size in a menu group in the component state
        this.setState({
          selectedMenuitemgroupsize
        });
        //console.log('here 1 => ', selectedMenuitemgroupsize);
        this.props.setValue(selectedMenuitemgroupsize);
      }
    } else {
      // the selected addon is for the selected menu item
      if(selectedMenuitemgroupsize && selectedMenuitemgroupsize.id === currentValue.menuitemId) {
        const mergedValueSizeAndAddons = Object.assign(currentValue, selectedMenuitemgroupsize);
        selectedMenuitemgroupsize.menuitemgroup = this.state.menuitemgroup;
        // store the selected menu item size in a menu group in the component state
        this.setState({
          selectedMenuitemgroupsize
        });
        //console.log('here 2 => ', mergedValueSizeAndAddons);
        this.props.setValue(mergedValueSizeAndAddons);
      } else {
        // the selected addon does not belong to the selected menu
        // so it is overwritten and all addons value are resetted
        const currentValueClone = Object.assign({}, currentValue);
        
        if(currentValueClone.menuitemsgroupid) {
          //console.log('currentValueClone => ', currentValueClone);
          $('input[name=addongroup-' + currentValueClone.menuitemsgroupid + "-" + currentValueClone.selectedAddonGroup.id + ']').prop('checked', false);
        }
          
        if(selectedMenuitemgroupsize) {
          selectedMenuitemgroupsize.menuitemgroup = this.state.menuitemgroup;
          // store the selected menu item size in a menu group in the component state
          this.setState({
            selectedMenuitemgroupsize
          });
          //console.log('here 3 => ', selectedMenuitemgroupsize);
          this.props.setValue(selectedMenuitemgroupsize);
        }
      }
    }
  }

  setValueForMenuitemgroupAddons(value) {
    const selectedMenuitemgroupaddons = value;
    let currentValue = Object.assign({}, this.props.getValue());
    if(!currentValue || !Object.keys(currentValue).length) {
      // console.log('here 4 => ', selectedMenuitemgroupaddons);
      this.props.setValue(undefined);
    } else {
      // if the currently selected menu item is the parent
      // of the currently selected addon then we merge both
      if(selectedMenuitemgroupaddons && currentValue.id === selectedMenuitemgroupaddons.menuitemId) {
        // if we are adding addon group items
        if(selectedMenuitemgroupaddons.selectedAddons.length > 0) {
          for (let index = 0; index < selectedMenuitemgroupaddons.selectedAddons.length; index++) {
            const addonItem = selectedMenuitemgroupaddons.selectedAddons[index];
            addonItem.addongroupid = selectedMenuitemgroupaddons.selectedAddonGroup.id;
            addonItem.addongroupname = selectedMenuitemgroupaddons.selectedAddonGroup.name;
            addonItem.addongroupname_i18n = selectedMenuitemgroupaddons.selectedAddonGroup.name_i18n;
          }
          if(currentValue.selectedAddons && currentValue.selectedAddons.length > 0) {
            currentValue.selectedAddons = currentValue.selectedAddons.filter((addon) => addon.addongroupid !== selectedMenuitemgroupaddons.selectedAddonGroup.id);
          }
        } else {
          if(currentValue.selectedAddons && currentValue.selectedAddons.length > 0) {
            // remove all addons related to the selected menuitem addons group
            currentValue.selectedAddons = currentValue.selectedAddons.filter((addon) => addon.addongroupid !== selectedMenuitemgroupaddons.selectedAddonGroup.id)
          }
        }
        // console.log('selectedMenuitemgroupaddons => ', selectedMenuitemgroupaddons);
        // console.log('currentValue => ', currentValue);
        currentValue.selectedAddonGroup = selectedMenuitemgroupaddons.selectedAddonGroup;
        if(currentValue.selectedAddons)
          currentValue.selectedAddons = currentValue.selectedAddons.concat(selectedMenuitemgroupaddons.selectedAddons);
        else
          currentValue = Object.assign(currentValue, selectedMenuitemgroupaddons);
        // console.log('here 5 => ', currentValue);
        this.props.setValue(currentValue);
      }
    }
  }

  toggleTooltip(id, field) {
    //console.log('toggleTooltip');
    const newState = [...this.state[field]];
    newState.fill(false);

    if (!this.state[field][id]) {
      newState[id] = true;
    }

    this._isMounted && this.setState({
      [field]: newState,
    });
  }

  render() {

    const {menuitemgroup, locale} = this.state;

    // get list of menu items in the current menu group
    const listOfMenuItemsInMenuitemgroup = menuitemgroup.orderedmenuitems.map((orderedMenuItem) => {
      orderedMenuItem.menuitem.orderedmenuitemId =  orderedMenuItem.id;
      return orderedMenuItem.menuitem;
    });

    //console.log(listOfMenuItemsInMenuitemgroup);
    //console.log('this.state.filteredMenuitems => ', this.state.filteredMenuitems);
    // filter the list of menuitem in the current menuitemsgroup based on the filters selected
    // in the menu items page
    const listOfMenuItemsToDisplay = listOfMenuItemsInMenuitemgroup.filter((menuitem) => {
      return this.props.filteredMenuitems.filter(filteredMenuItem => filteredMenuItem.id === menuitem.id).length > 0;
    });

    return (
      <div>
        {
          <Widget title={
                    <h4>
                      <span className="fw-semi-bold">
                        {menuitemgroup.name_i18n[locale]}
                      </span>
                    </h4>
                  } 
            collapse>
            <div className="menuitemgroupsgalleryroot">
              <div className="menuitemgroupsgallery">
                  {
                    //###############################################################
                    // for every Menu Item
                    //###############################################################
                    listOfMenuItemsToDisplay && listOfMenuItemsToDisplay.length > 0 ? 
                    // we only display the menu items that are not part of a menu item group as a gallery
                    // the others will be displayed in widget of menuitem groups
                    listOfMenuItemsToDisplay.map((menuItemObj, index) => {
                      //console.log('menuItemObj => ', menuItemObj);
                      const key = "menuitemgroupsgallery-" + menuItemObj.id + index;
                      return (
                        <div id={'menuitem-link-' + key} key={key} className="menuitemgroupsgallerypicture card">
                          <CarouselMenuItem keyForId={key} menuItem={menuItemObj} height={"320px"}/>
                          {
                            menuItemObj.isRecommendedByTheChef ? 
                            <div>
                              <div className='menuitemgroupsgallerylabel bg-danger' id={"menuitemgroupsgallery-ribbon-recommendedByTheChef-" + index}>
                                <img width="15px" src={chefHat} alt="" />
                              </div>
                              <Tooltip placement="top" isOpen={this.state.tooltipsRecommendedByTheChef[index]} toggle={() => this.toggleTooltip(index, 'tooltipsRecommendedByTheChef')} 
                              target={"menuitemgroupsgallery-ribbon-recommendedByTheChef-" + index}>
                                <FormattedMessage id="MenuitemgroupsGallery.MenuItem.ribbon.recommandedByTheChef" defaultMessage="Recommended by the Chef" />
                              </Tooltip>
                            </div>
                            : null
                          }
                          <div className="menuitemgroupsgallerydescription">
                            <h6 className="mt-0 mb-xs fw-semi-bold">{menuItemObj.name_i18n[locale]}</h6>
                            <RealPictureText isRealPicture = {menuItemObj.isRealPictures} />
                          </div>
                          {
                            menuItemObj ?
                              (
                                menuItemObj.allergens.length > 0 ?
                                    <Allergens 
                                      customAllergensIcon={this.props.customAllergensIcon}
                                      customFoodtypesIcon={this.props.customFoodtypesIcon}
                                      listOfAllergens={menuItemObj.allergens} 
                                      islistview={true} 
                                      locale={locale}/>
                                  :
                                  null
                              )
                            :null
                          }
                          {/* Menu item sizes */}
                          {
                            <PriceTableWithoutFormsy
                              isHidePriceColumn
                              isShowInWidget={false}
                              menugroupidforinput={menuitemgroup.id}
                              setValue={(val) => {
                                this.setValueForMenuitemgroup(val);
                              }}
                              menuid={this.props.activeMenuCategoryId}
                              menuitem={menuItemObj}
                              currency={this.props.restaurantCurrency} 
                              currencyNumberOfDecimals={this.props.currencyNumberOfDecimals}
                              locale={locale} />
                          }
                          {/* Add on groups */}
                          {
                            menuItemObj && 
                            menuItemObj.addongroups.length > 0 ? 
                            menuItemObj.addongroups.map((addongroup, index) => {
                                //console.log(addongroup.name, addongroup.isMandatory);
                                return (
                                  <AddongroupItemsTableWithoutFormsy 
                                    key={"menugroup-addongroupitemstablecell-" + addongroup.id + "-" + index}
                                    //required={ addongroup.isMandatory ? true : false }
                                    menugroupidforinput={menuitemgroup.id}
                                    menuid={this.props.activeMenuCategoryId}
                                    addongroup={addongroup}
                                    menuitemid={menuItemObj.id}
                                    isShowPrice={false}
                                    setValue={(val) => {
                                      this.setValueForMenuitemgroupAddons(val);
                                    }}
                                    customAllergenIcons={this.props.customAllergensIcon}   
                                    customFoodtypeIcons={this.props.customFoodtypesIcon}     
                                    disableSelection={!(this.state.selectedMenuitemgroupsize &&
                                            menuItemObj.id === this.state.selectedMenuitemgroupsize.id)}
                                    currency={this.props.restaurantCurrency} 
                                    currencyNumberOfDecimals={this.props.currencyNumberOfDecimals}
                                    isHidePriceColumn={true}
                                    isShowInWidget={false}
                                    locale={locale}
                                  />
                                );
                              })
                            : null
                            }
                        </div>
                      );
                    }): null // end of menucategory.map
                  }
                </div>
              </div>
            </Widget>
          }
      </div>
    );
  }

}

export default withFormsy(withRouter(MenuitemgroupsGallery));
