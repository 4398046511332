import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Button,
  /*Input,
  Label,*/
  Row,
  Col,
  Badge
} from 'reactstrap';

import {FormattedMessage, injectIntl} from 'react-intl';
import PrepTimeClock from '../PrepTimeClock/PrepTimeClock';

import './PopupInfoMenuItemSize.module.scss';

class PopupInfoMenuItemSize extends React.Component {
    static propTypes = {
        menuItemSize: PropTypes.object.isRequired,
        currencyForMenuItemSize: PropTypes.object.isRequired,
        locale: PropTypes.string.isRequired,
        isModalHideDishOpen: PropTypes.bool.isRequired,
        isShowPrice: PropTypes.bool,
        onModalClosed: PropTypes.func.isRequired
    };

    constructor(props) {
      super(props);

      this._isMounted = false;
  
      this.state = {
        menuItemSize: this.props.menuItemSize,
        isModalHideDishOpen: this.props.isModalHideDishOpen,
        locale: this.props.locale,
        currencyForMenuItemSize: this.props.currencyForMenuItemSize
      }

      this.hideModal = this.hideModal.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;

        //console.log('this.state.isModalHideDishOpen => ', this.state.isModalHideDishOpen);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    hideModal() {
      this.props.onModalClosed();
    }

    render() {
        const {menuItemSize, currencyForMenuItemSize} = this.state;

        //let inputForSwitcher = <Input type="checkbox" className="ios" disabled defaultChecked />;

        /*if(!menuItemSize.isPreselected)
          inputForSwitcher = <Input type="checkbox" className="ios"  disabled />;*/

        /*const inputForIsPreselectedSwitcher = (
            <Label className="switch">
              {inputForSwitcher}
              <i />
            </Label>
        );*/
        //console.log(menuItemSize.price);
        const { locale } = this.props.intl

        return(
          <Modal size="lg" isOpen={this.state.isModalHideDishOpen} toggle={this.hideModal}>
            <ModalHeader
              style={locale === 'ar' ? {textAlign: 'center', display: 'initial'} : {}}>
              {menuItemSize.name_i18n[this.state.locale]} <FormattedMessage id="PopupInfoMenuItemSize.Details.Modal.Header.Title.Detail.Label" defaultMessage="details" />
            </ModalHeader>
            <ModalBody className="bg-white">
              <Row>
                <Col sm={5}>
                  <FormattedMessage id="PopupInfoMenuItemSize.form.price.label"
                                                  defaultMessage="Price" />
                </Col>
                <Col sm={7}>
                {
                    this.props.isShowPrice && menuItemSize.price?
                      <Badge color="primary">
                        {currencyForMenuItemSize.symbol} {currencyForMenuItemSize.numberOfDecimals <=2 ? parseFloat(menuItemSize.price.toFixed(currencyForMenuItemSize.numberOfDecimals)) : menuItemSize.price.toFixed(currencyForMenuItemSize.numberOfDecimals)}
                      </Badge> :
                      <Badge color="secondary">
                        N/A
                      </Badge>
                  }
                </Col>
              </Row>
              {
                menuItemSize.calories ? (
                  <Row>
                    <Col sm={5}>
                      <FormattedMessage id="PopupInfoMenuItemSize.form.calories.label"
                                                        defaultMessage="Calories" />
                    </Col>
                    <Col sm={7}>
                      {menuItemSize.calories}
                    </Col>
                  </Row>
                ) : null
              }
              {
                menuItemSize.preparationTimeInMinutes && menuItemSize.preparationTimeInMinutes !== null && menuItemSize.preparationTimeInMinutes > 0 ? (
                  <Row>
                    <Col sm={5}>
                      <FormattedMessage id="PopupInfoMenuItemSize.form.preparation.time.label"
                                                        defaultMessage="Prep. Time" />
                    </Col>
                    <Col sm={7}>
                      <div>
                        <PrepTimeClock idForComponent={"menuitemsizemodalpopupdetails-" + menuItemSize.id} 
                                      timeToDisplay={menuItemSize.preparationTimeInMinutes} />
                      </div>
                    </Col>
                  </Row>
                ) : null
              }
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.hideModal}>
                <FormattedMessage id="PopupInfoMenuItemSize.Details.Modal.Button.Close" defaultMessage="Close" />
              </Button>
            </ModalFooter>
          </Modal>
        );
    }
}

PopupInfoMenuItemSize.defaultProps = {
  isShowPrice: true
};

export default injectIntl(PopupInfoMenuItemSize);
