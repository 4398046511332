import { postOrder } from '../api/order.js';

export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART';
export const LOAD_CART = 'LOAD_CART';
export const RESET_CART = 'RESET_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const ADD_NOTE_TO_ITEM = 'ADD_NOTE_TO_ITEM';
export const POSTING_ORDER = 'POSTING_ORDER';
export const ORDER_POSTED_SUCCESS = 'ORDER_POSTED_SUCCESS';
export const ORDER_POSTED_ERROR = 'ORDER_POSTED_ERROR';

export function addProduct(itemToAdd) {
    //console.log(itemToAdd);
    return (dispatch) => {
      dispatch(addProductToCartAction(itemToAdd));
  }
}

export function addNoteToProduct(productWithNote) {
  return (dispatch) => {
    dispatch(addNoteToProductAction(productWithNote));
  }
}

export function removeProduct(productToRemove) {
  return (dispatch) => {
    dispatch(removeProductFromCartAction(productToRemove));
  }
} 

export function loadCart(cartProducts) {
  return (dispatch) => {
    dispatch(loadCartAction(cartProducts));
  }
}

export function resetCart(deliverymethod, restaurantSlug) {
  // reset locally stored cart
  // Check if we're in restaurant collection mode
  const urlParams = new URLSearchParams(window.location.hash.split('?')[1] || '');
  const rcid = urlParams.get('rcid');

  // If we're in restaurant collection mode we need to reset the right cart
  if (rcid) {
    // Get the restaurantCollection-type from localStorage
    const restaurantCollectionType = localStorage.getItem(`restaurantCollection-type-${rcid}`);

    // Determine the cart name based on the restaurantCollection-type
    let cartname;
    if (restaurantCollectionType === 'marketplace_combined_checkouts') {
      cartname = `cart-${rcid}`;
    } else {
      cartname = `cart-${rcid}-${deliverymethod}-${restaurantSlug}`;
    }

    // Clear the cart in localStorage
    localStorage.setItem(cartname, JSON.stringify({}));
  } else {
    // If not in restaurant collection mode, just clear the cart as before
    localStorage.setItem(`cart-${deliverymethod}-${restaurantSlug}`, JSON.stringify({}));
  }
  return (dispatch) => {
    dispatch(resetCartAction());
  }
}

function retrieveRestaurantIdFromUrl() {
  // in case we use a deeplink and we have items in the cart then
  // we need to extract the restaurant id from the url
  const urlHash = window.location.hash;
  const indexOfRestaurant = urlHash.indexOf("/restaurant/");
  // console.log('indexOfRestaurant => ', indexOfRestaurant)
  const indexOfMenus = urlHash.indexOf("/menu");
  // console.log('indexOfMenus => ', indexOfMenus)
  const extractRestaurantInformation = urlHash.substring(
    indexOfRestaurant,
    indexOfMenus
  );
  // console.log('extractRestaurantInformation => ', extractRestaurantInformation)
  return extractRestaurantInformation.replace("/restaurant/", "");
}

export function submitOrderToKitchen(order, appDeliveryMethod) {
  return (dispatch) => {
    dispatch(postingOrder());

    const urlParams = new URLSearchParams(window.location.hash.split('?')[1] || '');
    const rcid = urlParams.get('rcid');
    const collectionType = localStorage.getItem(`restaurantCollection-type-${rcid}`);
    // If we're in restaurant collection mode and that the collection type is marketplace_combined_checkouts
    // we need to set the mode to multi_vendor_order
    if (rcid && collectionType === 'marketplace_combined_checkouts') {
      order.mode = 'multi_vendor_order'
      order.restaurantCollectionSlug = rcid;
    } else {
      order.mode = 'single_vendor_order'
    }

    order.restaurantSlug = retrieveRestaurantIdFromUrl();
    order.deliveryMethodCode = appDeliveryMethod;

    postOrder(order)
        .then(submittedOrder => {
          // Retrieve rcid from URL params
          const urlParams = new URLSearchParams(window.location.hash.split('?')[1] || '');
          const rcid = urlParams.get('rcid');

          if (rcid) {
            // If rcid exists, get restaurantCollection-type from localStorage
            const restaurantCollectionType = localStorage.getItem(`restaurantCollection-type-${rcid}`);
            
            // Add rcid and restaurantCollection-type to the order
            submittedOrder.restaurantCollectionSlug = rcid;
            submittedOrder.restaurantCollectionType = restaurantCollectionType;
          }
          //console.log('in submitOrderToKitchen submittedOrder  => ' + JSON.stringify(submittedOrder));

          if(submittedOrder.errors) return dispatch(orderPostedErrors(submittedOrder.errors[0].message));
          if(submittedOrder.error) return dispatch(orderPostedErrors(submittedOrder.message));
          const restaurantSlug = submittedOrder.restaurant ? submittedOrder.restaurant.slug : null;
          // If we're in restaurant collection mode we need to reset the right cart
          let cartname
          if (rcid) {
            // Get the restaurantCollection-type from localStorage
            const restaurantCollectionType = localStorage.getItem(`restaurantCollection-type-${rcid}`);

            // Determine the cart name based on the restaurantCollection-type
            if (restaurantCollectionType === 'marketplace_combined_checkouts') {
              cartname = `cart-${rcid}`;
            } else {
              cartname = `cart-${rcid}-${appDeliveryMethod}-${restaurantSlug}`;
              const lastOrder = localStorage.getItem(cartname)
          // store order as last order
          localStorage.setItem(cartname, lastOrder)
            }
          } else {
            cartname = `cart-${appDeliveryMethod}-${restaurantSlug}`;
            const lastOrder = localStorage.getItem(cartname)
            // store order as last order
            localStorage.setItem(cartname, lastOrder)
          }
          // empty cart
          dispatch(resetCartAction());
          // send back the submitted order
          dispatch(orderPostedSuccess(submittedOrder));
        })
        .catch(error => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          if (error.response && error.response.data) {
            return dispatch(orderPostedErrors(error.response.data.message));
          }
          return dispatch(orderPostedErrors(error.toString()));
        })
  };
}

export function updateCart(cartProducts, deliverymethod, isVatIncludedInPrice, vatTaxRates, otherTaxRates, restaurantSlug) {

  let productQuantity = cartProducts.reduce((sum, p) => {
    sum += p.quantity;
    return sum;
  }, 0);

  // console.log('action update cart => ', isVatIncludedInPrice)
  // TODO Take into account other taxRates array for example to apply room service on top
  // of the netto of the brutto amount - To be defined

  let totalVat = 0;
  let totalNetPriceOfCart = 0;
  // menu item size
  let totalPrice = cartProducts.reduce((sum, p) => {
    //sum += p.price * p.quantity;
    // if the product only has one menu item size
    if(p.menuitemsizes && p.menuitemsizes.length === 1) {
      totalNetPriceOfCart += p.menuitemsizes[0].price * p.quantity;
      sum += p.menuitemsizes[0].price * p.quantity;
      // if the vat is not included in the price then we have to calculate it and add it
      if(!isVatIncludedInPrice) {
        const vatTaxValut = p.menuitemsizes[0].price * p.quantity * (p.menuitemsizes[0].taxRateInPercent / 100)
        totalVat += vatTaxValut
        sum += vatTaxValut
      }
    } else {
      // if the product has multiple sizes
      if (p.selectedmenuitemsize) {
        totalNetPriceOfCart += p.selectedmenuitemsize.price * p.quantity;
        sum += p.selectedmenuitemsize.price * p.quantity;
        if(!isVatIncludedInPrice) {
          const vatTaxValut = p.selectedmenuitemsize.price * p.quantity * (p.selectedmenuitemsize.taxRateInPercent / 100)
          totalVat += vatTaxValut
          sum += vatTaxValut
        }
      } else {
        // if the product is a menu group
        if (p.menuitemgroups && p.menuitemgroups.length > 0 &&
           p.listOfSelectedMenuItemsForMenuGroup && 
           p.listOfSelectedMenuItemsForMenuGroup.length > 0){
          totalNetPriceOfCart += p.price * p.quantity;
          sum += p.price * p.quantity;
          if(!isVatIncludedInPrice) {
            const vatTaxValue = p.price * p.quantity * (p.taxRateInPercent / 100)
            totalVat += vatTaxValue
            sum += vatTaxValue
          }
        }
      }
    }

    // addons price
    if(p.listOfSelectedAddongroupsItems && p.listOfSelectedAddongroupsItems.length > 0) {
      let totalPriceSelectedAddonGroups = p.listOfSelectedAddongroupsItems.reduce((sum, addongroup) => {
        if (addongroup && addongroup.selectedAddons && addongroup.selectedAddons.length > 0) {
          let totalPriceSelectedAddonItemsInAddonGroup = addongroup.selectedAddons.reduce((sum,addongroupitem) => {
            // sum up addon item price
            totalNetPriceOfCart += addongroupitem.selectedAddonItem.price;
            sum += addongroupitem.selectedAddonItem.price;
            if(!isVatIncludedInPrice) {
              const vatTaxValue = addongroupitem.selectedAddonItem.price * (addongroupitem.selectedAddonItem.taxRateInPercent / 100)
              totalVat += vatTaxValue
              sum += vatTaxValue
            }
            return sum;
          }, 0);
          //console.log('totalPriceSelectedAddonItemsInAddonGroup => ', totalPriceSelectedAddonItemsInAddonGroup);
          // add total price of all selected addon groups items
          sum += totalPriceSelectedAddonItemsInAddonGroup;
        }
        return sum;
      }, 0);

      // add total price of all selected addon groups
      sum += totalPriceSelectedAddonGroups * p.quantity;
    }

    // console.log('otherTaxRates => ', otherTaxRates)
    return sum;
  }, 0);
  let totalPriceBeforeOtherActiveNonIncludedTaxRates = 0

   // we are going to check if we have taxes that needs to be applied before VAT
   let allTaxesBeforeVAT1 = []
   let firstTaxToApplyBeforeVAT = {}
   firstTaxToApplyBeforeVAT.taxAmount = 0
   if (otherTaxRates && otherTaxRates instanceof Array && otherTaxRates.length > 0) {
     allTaxesBeforeVAT1 = otherTaxRates.filter((taxRate) => taxRate.type.includes('other_apply_before_vat_1'))
     if (allTaxesBeforeVAT1.length > 0) {
       const currentFirstTax = allTaxesBeforeVAT1[0]
       firstTaxToApplyBeforeVAT.taxAmount = currentFirstTax.taxValueType === 'percent' ? 
         parseFloat((Math.round((((parseFloat(totalPrice) - totalVat - totalPriceBeforeOtherActiveNonIncludedTaxRates) * currentFirstTax.taxValue / 100) + Number.EPSILON) * 100) / 100).toFixed(2)) :
         parseFloat((Math.round((currentFirstTax.taxValue + Number.EPSILON) * 100) / 100).toFixed(2))
       firstTaxToApplyBeforeVAT.description_i18n = currentFirstTax.description_i18n
     }
   } 

   let allTaxesBeforeVAT2 = []
   let secondTaxToApplyBeforeVAT = {}
   secondTaxToApplyBeforeVAT.taxAmount = 0
   if (otherTaxRates && otherTaxRates instanceof Array && otherTaxRates.length > 0) {
     allTaxesBeforeVAT2 = otherTaxRates.filter((taxRate) => taxRate.type.includes('other_apply_before_vat_2'))
     if (allTaxesBeforeVAT2.length > 0) {
       const currentSecondTax = allTaxesBeforeVAT2[0]
       secondTaxToApplyBeforeVAT.taxAmount = currentSecondTax.taxValueType === 'percent' ? 
         parseFloat((Math.round(((((parseFloat(totalPrice) - totalVat - totalPriceBeforeOtherActiveNonIncludedTaxRates) + firstTaxToApplyBeforeVAT.taxAmount ) * currentSecondTax.taxValue / 100) + Number.EPSILON) * 100) / 100).toFixed(2)) :
         parseFloat((Math.round((currentSecondTax.taxValue + Number.EPSILON) * 100) / 100).toFixed(2))
       secondTaxToApplyBeforeVAT.description_i18n = currentSecondTax.description_i18n
     }
   }

   if (otherTaxRates && otherTaxRates instanceof Array && otherTaxRates.length > 0) {
    otherTaxRates = otherTaxRates.filter((taxRate) => !taxRate.type.includes('apply_before_vat'))
   }

  // adding other tax rates that are fixed tax rate (e.g. 1.50 euros service tax)
  if (otherTaxRates && otherTaxRates instanceof Array && otherTaxRates.length > 0) {
    const otherActiveNonIncludedTaxRates = otherTaxRates.filter(
      tax => tax.isActive && !tax.isIncludedInPrices
    )
    // console.log('otherActiveNonIncludedTaxRates => ', otherActiveNonIncludedTaxRates)
    if (otherActiveNonIncludedTaxRates.length > 0) {
      for (const element of otherActiveNonIncludedTaxRates) {
        const currentTax = element
        if (currentTax.taxValueType === 'number') {
          totalPriceBeforeOtherActiveNonIncludedTaxRates += currentTax.taxValue
          totalPrice += currentTax.taxValue
        } else {
          const currentTaxCalculation = ((totalPrice - totalVat) * currentTax.taxValue / 100)
          totalPriceBeforeOtherActiveNonIncludedTaxRates += currentTaxCalculation
          totalPrice = totalPrice + currentTaxCalculation
        }
      }
    }
    // console.log('totalPrice => ', totalPrice)
  }

  let vatValueIncludingBeforeTaxRates = totalVat;
  let totalPriceWithBeforeVatTaxes = 0;
  if (otherTaxRates && (allTaxesBeforeVAT2.length > 0 || allTaxesBeforeVAT1.length > 0)) {
    vatValueIncludingBeforeTaxRates = (((parseFloat(totalPrice) - totalVat - totalPriceBeforeOtherActiveNonIncludedTaxRates) + firstTaxToApplyBeforeVAT.taxAmount  + secondTaxToApplyBeforeVAT.taxAmount) * (vatTaxRates[0].taxValue / 100)).toFixed(2);
    totalPriceWithBeforeVatTaxes = ((parseFloat(totalPrice) - totalVat - totalPriceBeforeOtherActiveNonIncludedTaxRates) + firstTaxToApplyBeforeVAT.taxAmount  + secondTaxToApplyBeforeVAT.taxAmount + parseFloat(vatValueIncludingBeforeTaxRates))
  }

  //console.log("totalPriceWithBeforeVatTaxes => ", totalPriceWithBeforeVatTaxes)
  //console.log("totalNetPriceOfCart => ", totalNetPriceOfCart)
  //console.log("totalVat => ", totalVat)
  //console.log("totalPrice => ", totalPrice)

  const round_up = (x,nd) => {
    const result = (Math.ceil(x * 100) / 100).toFixed(nd);
    return result
  }
  totalVat = round_up(totalVat, 2);
  totalPrice = round_up(totalPrice, 2);

  //totalPrice = totalPrice.toFixed(2);

  let cartTotal = {
    totalPriceBeforeOtherActiveNonIncludedTaxRates,
    productQuantity,
    totalPrice,
    totalNetPriceOfCart,
    totalPriceWithBeforeVatTaxes,
    totalVat: vatValueIncludingBeforeTaxRates,
    currencyFormat: cartProducts.length > 0 ? cartProducts[0].currencyFormat : ""
  };

  // as soon as the cart is updated it is then saved in
  // the browser localstorage for 24 hours until it is either
  // reset by the user or expired next day
  let cartToLocalStorage = {};
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  cartToLocalStorage.expirationDate = tomorrow;
  cartToLocalStorage.cartProducts = [...cartProducts];
  // Check if we're in restaurant collection mode
  const urlParams = new URLSearchParams(window.location.hash.split('?')[1] || '');
  const rcid = urlParams.get('rcid');

  let cartname;
  if (rcid) {
    // Get the restaurantCollection-type from localStorage
    const restaurantCollectionType = localStorage.getItem(`restaurantCollection-type-${rcid}`);

    // Determine the cart name based on the restaurantCollection-type
    if (restaurantCollectionType === 'marketplace_combined_checkouts') {
      cartname = `cart-${rcid}`;
    } else {
      cartname = `cart-${rcid}-${deliverymethod}-${restaurantSlug}`;
    }
  } else {
    // If not in restaurant collection mode, use the original cart name
    cartname = `cart-${deliverymethod}-${restaurantSlug}`;
  }
  localStorage.setItem(cartname, JSON.stringify(cartToLocalStorage));

  return (dispatch) => {
    dispatch(updateCartAction(cartTotal, cartProducts));
  }
}

function addNoteToProductAction(productToUpdateWithNote) {
  return {
    type: ADD_NOTE_TO_ITEM,
    productToUpdateWithNote
  };
}

function postingOrder() {
  return {
    type: POSTING_ORDER,
    isPostingOrder: true,
  };
}

function orderPostedErrors(message) {
  return {
    type: ORDER_POSTED_ERROR,
    isPostingOrder: false,
    message,
  };
}

function orderPostedSuccess(postedOrder) {
  return {
    type: ORDER_POSTED_SUCCESS,
    isPostingOrder: false,
    postedOrder
  };
}

function updateCartAction(data, cartProducts) {
  return {
    type: UPDATE_CART,
    data,
    cartProducts
  };
}

function loadCartAction(products) {
  return {
    type: LOAD_CART,
    products
  };
}

function resetCartAction() {
  return {
    type: RESET_CART
  };
}
  
function addProductToCartAction(productToAdd) {
  return {
    type: ADD_ITEM_TO_CART,
    productToAdd
  };
}

function removeProductFromCartAction(productToRemove) {
  return {
    type: REMOVE_ITEM_FROM_CART,
    productToRemove
  };
}