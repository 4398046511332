import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Thumb from '../Thumb/Thumb';

import Clearfix from '../Clearfix';

import s from '../../FloatCart.module.scss';

import TextareaAutosize from 'react-autosize-textarea';

import { GAEvent } from '../../../Tracking/Tracking';

import Widget from '../../../Widget';

import {
  Button,
} from 'reactstrap';

import { FormattedMessage, injectIntl } from 'react-intl';

class CartProduct extends Component {
  static propTypes = {
    outletSlug: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired,
    removeProduct: PropTypes.func.isRequired,
    addNoteToProduct: PropTypes.func.isRequired,
    addProduct: PropTypes.func.isRequired,
    currentDeliveryMethod: PropTypes.string.isRequired,
    isCommentAllowedAtCheckout: PropTypes.bool.isRequired
  };

  state = {
    isMouseOver: false,
  };

  handleMouseOver = () => {
    this.setState({ isMouseOver: true });
  };

  handleMouseOut = () => {
    this.setState({ isMouseOver: false });
  };

  onChangeNoteToKitchen = (evt) => {
    //console.log('evt.currentTarget.value => ', evt.currentTarget.value);
    const productToAddNoteTo = Object.assign({}, this.props.product);
    productToAddNoteTo.note = evt.currentTarget.value;
    //console.log('productToAddNoteTo => ', productToAddNoteTo);
    this.props.addNoteToProduct(productToAddNoteTo);
  }

  renderRestaurantSlug = () => {
    const { product } = this.props;
    const urlParams = new URLSearchParams(window.location.hash.split('?')[1] || '');
    const rcid = urlParams.get('rcid');
    const collectionType = localStorage.getItem(`restaurantCollection-type-${rcid}`);
    //console.log("Product => ", product);

    if (rcid && collectionType === 'marketplace_combined_checkouts' && product.restaurantSlug) {
      return <span className={s.restaurantSlug}>{product.restaurantSlug}<br/></span>;
    }
    return null;
  }

  render() {
    const { removeProduct, product, addProduct, currentDeliveryMethod, isCommentAllowedAtCheckout, outletSlug } = this.props;
    const { locale } = this.props.intl;

    const classes = [s.floatCartShelfContainerShelfItem];

    if (!!this.state.isMouseOver) {
      classes.push(s.floatCartShelfContainerShelfItemMouseover);
    }

    return (
      <div className={classes.join(' ')}>
        <div
          className={s.floatCartShelfContainerShelfItemDel}
          onMouseOver={() => this.handleMouseOver()}
          onMouseOut={() => this.handleMouseOut()}
          style={locale === 'ar' ? {right: 'auto', left: '5%'} : {}}
          onClick={() => {
              removeProduct(product);
              GAEvent("FRONTEND", "Delete specific item from cart using delete button", "FLOATCART_CARTITEM_DELETE_USING_ITEM_DEL_BUTTON");
            }
          }
        />
        {
          product.pictures && product.pictures.length > 0 ?
          <Thumb
            classes={s.floatCartShelfContainerShelfItemThumb +
                    ' ' + s.floatCartShelfContainerShelfItemThumbThumb}
            src={product.pictures[0].formats && product.pictures[0].formats.small ? product.pictures[0].formats.small.url : product.pictures[0].url}
            alt={product.name}
          />
          : (
            // menu group mode
            product.picture && product.pictures.length > 0 ?
            <Thumb
              classes={s.floatCartShelfContainerShelfItemThumb +
                      ' ' + s.floatCartShelfContainerShelfItemThumbThumb}
              src={product.picture.formats && product.picture.formats.small ? product.picture.formats.small.url : product.picture.url}
              alt={product.name}
            />
            : null
          )
        }
        <div
          className={s.floatCartShelfContainerShelfItemDetails}
          style={locale === 'ar' ? {
            marginLeft: '20px'
          } : {}}>
          <p className={(!!this.state.isMouseOver ? s.floatCartShelfContainerShelfItemMouseoverShelfItemDetailsTitle + ' ' : '') +
                        s.floatCartShelfContainerShelfItemDetailsTitle}
              style={locale === 'ar' ? {
                textAlign: 'right',
                marginRight: '35px'
              } : {}}>
              {this.renderRestaurantSlug()}
              {product.name_i18n[locale]}&nbsp;
              {
                // no need for size display for menu groups
                !product.listOfSelectedMenuItemsForMenuGroup ?
                <span className={"text-muted " + s.cartProductSize}>
                  {
                    product.menuitemsizes && product.menuitemsizes.length === 1 && product.menuitemsizes[0].name_i18n[locale] && product.menuitemsizes[0].name_i18n[locale] !== '' ?
                      `[${product.menuitemsizes[0].name_i18n[locale].trim()}]` :
                      (product.selectedmenuitemsize &&
                        product.selectedmenuitemsize.name_i18n[locale] &&
                        product.selectedmenuitemsize.name_i18n[locale] !== '' ? 
                        `[${product.selectedmenuitemsize.name_i18n[locale].trim()}]` 
                        : null // here manage menu groups
                      )
                  }
                </span>
                : null
              }
          </p>
          {
            <p className={(!!this.state.isMouseOver ? s.floatCartShelfContainerShelfItemMouseoverShelfItemDetailsDesc + ' ' : '') +
                          s.floatCartShelfContainerShelfItemDetailsDesc}
                style={locale === 'ar' ? {
                  textAlign: 'right',
                  marginRight: '35px'
                } : {}}>
              <FormattedMessage id="Floatcart.CartProduct.product.quantity.label" defaultMessage="Quantity" />:&nbsp;
              <Button color="white"
                      className={s.buttonQuantity}
                      style={locale === 'ar' ? {paddingRight: '0px', width: 45} : {}}
                      onClick={() => {
                        // if only one element selected then minus means delete it form the cart
                        if(product.quantity === 1) {
                          removeProduct(product);
                          GAEvent("FRONTEND", "Delete specific item from cart using using decrease quantity button", "FLOATCART_CARTITEM_DELETE_USING_MINUS_QTY_BUTTON");
                        } else{
                          const copyOfProductToRemove = Object.assign({}, product);
                          // setting a flag so the receiving function in floatcart
                          // knows that it is an action for decrementing the current
                          // product quantity and not delete it all from the cart
                          copyOfProductToRemove.isDecrementQuantity = true;
                          removeProduct(copyOfProductToRemove);
                          GAEvent("FRONTEND", "Delete specific item from cart using using decrease quantity button", "FLOATCART_CARTITEM_DELETE_USING_MINUS_QTY_BUTTON");
                        }
                      }}>
                <i className={"glyphicon glyphicon-minus-sign text-black " + s.buttonQuantityIcon} />
              </Button>
              &nbsp;&nbsp;
              {product.quantity}
              &nbsp;&nbsp;
              <Button color="white" 
                      className={s.buttonQuantity}
                      style={locale === 'ar' ? {paddingRight: '0px', width: 45} : {}}
                      onClick={() => {
                        // we make a copy of the current product in the float cart and
                        // we send it through the existing add product action and dispatchers
                        const copyOfProductToAdd = Object.assign({}, product);
                        copyOfProductToAdd.quantity = 1;
                        addProduct(copyOfProductToAdd);
                        GAEvent("FRONTEND", "Add product to cart using increase quantity button", "FLOATCART_CARTITEM_ADD_USING_PLUS_QTY_BUTTON");
                      }}>
                <i className={"glyphicon glyphicon-plus-sign " + s.buttonQuantityIcon} />
              </Button>
            </p>
          }
        </div>
        <div className={(!!this.state.isMouseOver ? s.floatCartShelfContainerShelfItemMouseoverShelfItemPrice + ' ' : '') +
        (locale === 'ar' ? s.floatCartShelfContainerShelfItemPriceLTR : s.floatCartShelfContainerShelfItemPrice)}>
          <p>{
            `${product.currencyFormat} 
            ${
              // menu item with one size
              product.menuitemsizes && product.menuitemsizes.length === 1?
              (
                product.currencyNumberOfDecimals <= 2 ?
                parseFloat((Math.round(((product.menuitemsizes[0].price * product.quantity) + Number.EPSILON) * 100) / 100).toFixed(product.currencyNumberOfDecimals)) :
                (Math.round(((product.menuitemsizes[0].price * product.quantity) + Number.EPSILON) * 100) / 100).toFixed(product.currencyNumberOfDecimals)
              )
              :
              (
                // menu item with multiple sizes
                product.selectedmenuitemsize ?
                  (
                    product.currencyNumberOfDecimals <= 2 ?
                    parseFloat((Math.round(((product.selectedmenuitemsize.price * product.quantity) + Number.EPSILON) * 100) / 100).toFixed(product.currencyNumberOfDecimals)) :
                    (Math.round(((product.selectedmenuitemsize.price * product.quantity) + Number.EPSILON) * 100) / 100).toFixed(product.currencyNumberOfDecimals)
                  )
                : (
                    // menu group mode
                    product.price?
                    (
                      product.currencyNumberOfDecimals <= 2 ?
                      parseFloat((Math.round(((product.price * product.quantity) + Number.EPSILON) * 100) / 100).toFixed(product.currencyNumberOfDecimals)) :
                      (Math.round(((product.price * product.quantity) + Number.EPSILON) * 100) / 100).toFixed(product.currencyNumberOfDecimals)
                    )
                  :null
                  )
              )
            }`
          }
          </p>
        </div>
        {
          product.listOfSelectedAddongroupsItems && product.listOfSelectedAddongroupsItems.length > 0 ?
            product.listOfSelectedAddongroupsItems.map((addongroup, index) => {
              let listOfSelectedAddonGroupItems = [];
              // if addon items have been selected in a group of addons
              if(addongroup && addongroup.selectedAddons) {
                listOfSelectedAddonGroupItems = addongroup.selectedAddons.map((addonitem, index) => {
                  const selectedAddonItem = addonitem.selectedAddonItem;
                  return (
                    <span key={`listOfSelectedAddongroupsItems-addonitem-${addonitem.addonitemId + index}`}>
                      <span className={s.cartProductAddonItemTitle}>
                        {selectedAddonItem.name_i18n[locale]}
                      </span>
                      <span className={s.cartProductAddonItemTitle} 
                        style={locale === 'ar' ? {position: "absolute", left: "5%"} : {position: "absolute", right: "5%"}}>
                        {selectedAddonItem.price > 0 ? ' (+' + (selectedAddonItem.price * product.quantity) + product.currencyFormat + ')' : ''}
                      </span>
                      <span>
                        {(index === addongroup.selectedAddons.length - 1 ? '' : <br />) }
                      </span>
                    </span>
                  );
                });
              }

              return (
                listOfSelectedAddonGroupItems.length > 0 ?
                <div key={`listOfSelectedAddongroupsItems-addongroup-${addongroup.addongroupsId + index}`}
                  style={locale === 'ar' ? {textAlign: 'initial'} : {}}>
                  <span className={"text-muted " + s.cartProductAddonGroupTitle}>
                    {addongroup.selectedAddonGroup.name_i18n[locale]}:
                  </span>&nbsp;{listOfSelectedAddonGroupItems}
                </div>
                : null
              );
            })
          : null
        }
        {
          // list of selected menu items in menu group
          
          product.listOfSelectedMenuItemsForMenuGroup && product.listOfSelectedMenuItemsForMenuGroup.length > 0 ?
          product.listOfSelectedMenuItemsForMenuGroup.map((addongroupmenuitem, index) => {
            // console.log('addongroupmenuitem => ', addongroupmenuitem);
            return (
              <div key={`listOfSelectedMenuItemsForMenuGroup-addongroupmenuitem-${addongroupmenuitem.id + index}`}
                style={
                  index === product.listOfSelectedMenuItemsForMenuGroup.length - 1 ?
                  {lineHeight: '11px', paddingBottom: '10px'} :
                  {lineHeight: '11px'}
                }>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className={(!!this.state.isMouseOver ? s.floatCartShelfContainerShelfItemMouseoverShelfItemDetailsTitle + ' ' : '') +
                          s.floatCartShelfContainerShelfItemDetailsTitle}
                          style={{fontSize: '16px'}}>
                1 x {addongroupmenuitem.name_i18n[locale]}&nbsp;
                {
                  // no need for size display for menu groups
                  !addongroupmenuitem.listOfSelectedMenuItemsForMenuGroup ?
                  <span className={"text-muted " + s.cartProductSize}>
                    {
                      addongroupmenuitem.menuitemsizes && addongroupmenuitem.menuitemsizes.length === 1 
                        && addongroupmenuitem.menuitemsizes[0].name_i18n[locale]
                        && addongroupmenuitem.menuitemsizes[0].name_i18n[locale] !== '' ?
                        `[${addongroupmenuitem.menuitemsizes[0].name_i18n[locale].trim()}]` :
                        (addongroupmenuitem.selectedmenuitemsize 
                          && addongroupmenuitem.selectedmenuitemsize.name_i18n[locale]
                          && addongroupmenuitem.selectedmenuitemsize.name_i18n[locale] !== '' ? 
                          `[${addongroupmenuitem.selectedmenuitemsize.name_i18n[locale].trim()}]`
                          : null // here manage menu groups
                        )
                    }
                  </span>
                  : null
                }
                {
                  addongroupmenuitem.selectedAddonGroup &&
                  addongroupmenuitem.selectedAddons &&
                  addongroupmenuitem.selectedAddons.length > 0 ?
                  <div className={s.selectedAddons}>
                    {
                      addongroupmenuitem.selectedAddons.map((selectedAddon, index) => {
                        if(index === addongroupmenuitem.selectedAddons.length - 1) {
                          return (
                            <div key={`selectedAddons-addongroupmenuitem-${selectedAddon.addonitemId + index}`}>
                              <span className={"text-muted " + s.cartProductAddonGroupTitle}>
                                {selectedAddon.addongroupname_i18n[locale]}:
                              </span>
                              <span className={s.cartProductAddonItemTitle}
                                style={{color: '#495057'}}>
                                &nbsp;{selectedAddon.selectedAddonItem.name_i18n[locale]}
                              </span>
                            </div>
                          );
                        } else {
                          return (
                            <div key={`selectedAddons-addongroupmenuitem-${selectedAddon.addonitemId + index}`}>
                              <span className={"text-muted " + s.cartProductAddonGroupTitle}>
                                  {selectedAddon.addongroupname_i18n[locale]}:
                                </span>
                              <span className={s.cartProductAddonItemTitle}
                                style={{color: '#495057'}}>
                                &nbsp;{selectedAddon.selectedAddonItem.name_i18n[locale]}
                              </span>
                            </div>
                          );
                        }
                      })
                    }
                  </div>
                  : null
                }
                </div>
              </div>
            );
          })
          : null
        }
        {
          !product.listOfSelectedMenuItemsForMenuGroup ?
          (
            isCommentAllowedAtCheckout ? (
              // simple mode menu item
              <Widget
                className={s.leaveNoteToKitchenWidget}
                title={
                  currentDeliveryMethod && currentDeliveryMethod.includes('massageatspa') ? 
                  <FormattedMessage id="Floatcart.CartProduct.widget.note.to.spa.title" defaultMessage="Leave a note to the spa" />
                  :
                  ( 
                    currentDeliveryMethod && currentDeliveryMethod.includes('servicerequest') ? 
                    <FormattedMessage id="Floatcart.CartProduct.widget.note.to.oulet.title" defaultMessage="Leave a note" />
                    : 
                    // hardcoded this for one outlet only - Cipriani beach club
                    outletSlug && outletSlug === "cipriani-beach-club" ?
                    <FormattedMessage id="Floatcart.CartProduct.widget.note.to.oulet.title.cipriani" defaultMessage="Specify your allergies here" />
                    :
                    <FormattedMessage id="Floatcart.CartProduct.widget.note.to.kitchen.title" defaultMessage="Leave a note to the kitchen (specify allergens here)" />
                  )
                 } 
                collapse={true}
                startcollapsed={product.note === '' ||  !product.note ? "true" : "false"}
                sectionclickable="true">
                <TextareaAutosize
                              onChange={this.onChangeNoteToKitchen}
                              rows={2} id={"elastic-textarea-menuitem-" + product.id}
                              defaultValue={product.note}
                              className={`form-control ${s.autogrow} transition-height`}
                            />
              </Widget>
            ) : null
          )
        :
        (
          isCommentAllowedAtCheckout ? (
            //combo menu mode
            <Widget
              className={s.leaveNoteToKitchenWidget}
              title={<FormattedMessage id="Floatcart.CartProduct.menugroupnote.widget.note.to.kitchen.title" defaultMessage="Leave a note to the kitchen (specify allergens here)" />} 
              collapse={true}
              startcollapsed={!product.note || 
                              product.note  === '' ? "true" : "false"}
              sectionclickable="true">
              {/*console.log('note menu combo => ', product.note)*/}
              <TextareaAutosize
                            onChange={this.onChangeNoteToKitchen}
                            rows={2} id={"elastic-textarea-menugroup-" + product.id + "-" + product.listOfSelectedMenuItemsForMenuGroupMD5}
                            defaultValue={product.note ? product.note : ''}
                            className={`form-control ${s.autogrow} transition-height`}
                          />
            </Widget>
          ) : null
        )
        }
        <Clearfix />
      </div>
    );
  }
}

export default injectIntl(CartProduct);