import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button
} from 'reactstrap';

import s from './FilterElement.module.scss';
import { injectIntl } from 'react-intl'; 

class FilterElement extends Component {
  static propTypes = {
    defaultLabel: PropTypes.string.isRequired,
    options: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      currentOption: this.props.options[0],
      selectedIndex: 0
    };

    this.changeOption = this.changeOption.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
  }

  changeOption(currentOption, selectedIndex) {
    this.setState({ currentOption, selectedIndex });
    this.props.onChange(currentOption);
  }

  resetFilter() {
    this.setState({ currentOption: this.props.options[0], selectedIndex: 0 });
    this.props.onChange(this.props.options[0]);
  }

  render() {
    const { options, defaultLabel, intl } = this.props;
    const { currentOption, selectedIndex } = this.state;
    const { locale } = intl
    return (
      <div className={s.filterElement}>
        <div className={s.filterElementLable}>{defaultLabel}</div>
        <UncontrolledButtonDropdown>
          <DropdownToggle
            caret color="default"
            className="dropdown-toggle-split mr-xs"
          >
            {currentOption}&nbsp;&nbsp;
          </DropdownToggle>
          <DropdownMenu>
            {options.map((item, index) =>
              <DropdownItem key={item}
               style={locale === 'ar' ? {textAlign: 'right'} : {}}
               onClick={() => this.changeOption(item, index)}>{item}</DropdownItem>,
            )}
          </DropdownMenu>
          {selectedIndex !== 0 ? <Button color="default" className="btn-rounded-f mt-lg btn btn-primary"
                                  style={
                                    locale === 'ar' ? {    
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                        marginTop: "0px"
                                      } : {marginTop: "0px"}
                                    } onClick={this.resetFilter}>
                                    <i className="fa fa-close" />
                                  </Button> : null}
        </UncontrolledButtonDropdown>
      </div>
    );
  }
}

export default injectIntl(FilterElement);

