import 'expose-loader?jQuery!jquery' // eslint-disable-line
import 'expose-loader?$!jquery' // eslint-disable-line
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux'
import ReduxThunk from 'redux-thunk'
import App from './components/App';
import * as serviceWorker from './serviceWorker';

import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import es from "react-intl/locale-data/es";
import fr from "react-intl/locale-data/fr";
import ar from "react-intl/locale-data/ar";
import nl from "react-intl/locale-data/nl";
import zh from "react-intl/locale-data/zh";
import de from "react-intl/locale-data/de";
// this is our customer intl provider that is connected to redux store for dynamic language switch
import ConnectedIntlProvider from './i18n/ConnectedIntlProvider';

import reducers from './reducers';
import * as $ from 'jquery'

import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { initGA } from './components/Tracking';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

initGA();

window.jQuery = window.$ = $

// adding 4 locales for now from react-intl 
addLocaleData([...en, ...es, ...fr, ...ar, ...nl, ...zh, ...de]);

const appReducer = reducers;

const rootReducer = (state, action) => {

  let newState = state;
  // we reset the entire redux store when user is logged out
  /*if( action.type === LOGOUT_SUCCESS) {
    //console.log('state when logged out => ', state);
    newState = {...state,
      menu: undefined,
      menuitem: undefined,
    };
  }*/

  return appReducer(newState, action);
}

const store = createStore(
  rootReducer,
  applyMiddleware(ReduxThunk)
)

ReactDOM.render(
      <Provider store={store}>
        <ConnectedIntlProvider>
          <App />
        </ConnectedIntlProvider>
      </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
