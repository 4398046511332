import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//import arrayLocales from './locales.json';
import { changeLocaleActiveItem } from "../../actions/locale";
import { apiGetListOfLanguagePacks } from "../../api/languagepack";
import { initializeSSE, closeSSE } from "../../actions/eventSource";
import { GAEvent } from "../Tracking/Tracking";

import s from "./LocaleSelector.module.scss";

// init array of locales
let arrayLocales = [];

class LocaleSelector extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    currentRestaurant: PropTypes.object,
    languageCodeToLoad: PropTypes.string,
    isSSEModeEnabled: PropTypes.bool.isRequired, // enable server side events updates
  };

  constructor(props) {
    super(props);

    this.changeLocale = this.changeLocale.bind(this);
    this.reloadLanguagePacks = this.reloadLanguagePacks.bind(this);
    this.loadLanguagePacksFromServer = this.loadLanguagePacksFromServer.bind(
      this
    );
  }

  componentWillMount() {
    const { dispatch } = this.props;

    // we do not want to reload from server if we already got the data previously in the store
    if (this.props.activeLocaleItem === null) {
      this.loadLanguagePacksFromServer();
    }

    // if we want to connect the locale selector to a realtime feed for realtime updates
    if (this.props.isSSEModeEnabled) {
      dispatch(initializeSSE());
    }
    // adding an event to listen to a specific message to add a languge pack in real-time
    /*this.props.websocket.on("newlyCreatedLanguagePack", 
        (data) => {
            const newlyCreatedLanguagePack = JSON.parse(data).message;
            arrayLocales.push(newlyCreatedLanguagePack);
            this.reloadLanguagePacks();
        });

        // adding an event to listen to a specific message to add a languge pack in real-time
        // THIS ONE IS NOT WORKING YET - ISSUE FROM SERVER SIDE NEED TO BE SOLVED
        this.props.websocket.on("deletedLanguagePack", 
        (data) => {
            const deletedLanguagePack = JSON.parse(data).message;
            // console.log('deletedLanguagePack => ', deletedLanguagePack)
            arrayLocales = arrayLocales.filter(languagePack => languagePack.localeCode !== deletedLanguagePack.localeCode);
            // console.log('updated arrayLocales => ', arrayLocales);
            this.reloadLanguagePacks();
        });

        // adding an event to listen to a specific message to add a languge pack in real-time
        this.props.websocket.on("updatedLanguagePack", 
        () => {
            this.loadLanguagePacksFromServer();
        });*/
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentRestaurant !== prevProps.currentRestaurant) {
      // console.log('updated current restaurant => ', this.props.currentRestaurant);
      this.loadLanguagePacksFromServer();
    }
  }

  componentWillUnmount() {
    // if SSE mode enabled for this component
    if (this.props.isSSEModeEnabled) {
      const { eventSource, dispatch } = this.props;
      // close event stream when locale selector is destroyed so server is notified
      // and will also close the stream from its side
      dispatch(closeSSE(eventSource));
    }
  }

  loadLanguagePacksFromServer() {
    // Dynamically load the list of available language packs from the backend
    // console.log("currentRestaurant => ", this.props.currentRestaurant);
    if (this.props.currentRestaurant) {
        const languagePacksForCurrentRestaurant = this.props.currentRestaurant.languagepacks;
        const defaultRestaurantLanguagePack = this.props.currentRestaurant.defaultLanguageForCustomerApp;
      apiGetListOfLanguagePacks(languagePacksForCurrentRestaurant)
        .then((data) => {
          // assign the loaded array of locales from the backend to local array var
          arrayLocales = data;
          const pathname = this.props.location.pathname
          // we check if the activeLocalItem exists if not then we put the one
          // specified as default in the locales.json in the local storage
          const localeInLocalStorage = JSON.parse(
            localStorage.getItem(`activeLocaleItem-${pathname.includes('dineout') ? 'dineout' : 'dinein'}`)
          );
          if (!localeInLocalStorage) {
            /* const defaultLocale = arrayLocales.filter(
              (localeObject) => localeObject.isDefault
            ); */
            // save the active locale to the locale storage
            localStorage.setItem(
              `activeLocaleItem-${pathname.includes('dineout') ? 'dineout' : 'dinein'}`,
              JSON.stringify(defaultRestaurantLanguagePack)
            );
          }
          this.reloadLanguagePacks();
        })
        .catch((error) => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          // return dispatch(loginError(error.response.data.message));
          // TODO - Dispatch the error at application level
          console.log(error);
        });
    } else {
      apiGetListOfLanguagePacks()
        .then((data) => {
          // assign the loaded array of locales from the backend to local array var
          arrayLocales = data;
          const pathname = this.props.location.pathname
          // we check if the activeLocalItem exists if not then we put the one
          // specified as default in the locales.json in the local storage
          const localeInLocalStorage = JSON.parse(
            localStorage.getItem(`activeLocaleItem-${pathname.includes('dineout') ? 'dineout' : 'dinein'}`)
          );
          // console.log('this.props.languageCodeToLoad => ', this.props.languageCodeToLoad)
          if (!localeInLocalStorage) {
            let defaultLocale = arrayLocales.filter(
              (localeObject) => localeObject.isDefault
            );
            if (this.props.languageCodeToLoad) {
              const forcedLocale = arrayLocales.filter(
                (localeObject) => localeObject.localeCode === this.props.languageCodeToLoad
              );
              if (forcedLocale.length > 0) {
                defaultLocale = forcedLocale
              }
            }
            // save the active locale to the locale storage
            localStorage.setItem(
              `activeLocaleItem-${pathname.includes('dineout') ? 'dineout' : 'dinein'}`,
              JSON.stringify(defaultLocale[0])
            );
          } else {
            if (this.props.languageCodeToLoad) {
              const forcedLocale = arrayLocales.filter(
                (localeObject) => localeObject.localeCode === this.props.languageCodeToLoad
              );
              if (forcedLocale.length > 0) {
                // save the active locale to the locale storage
                localStorage.setItem(
                  `activeLocaleItem-${pathname.includes('dineout') ? 'dineout' : 'dinein'}`,
                  JSON.stringify(forcedLocale[0])
                );
              }
            }
          }
          this.reloadLanguagePacks();
        })
        .catch((error) => {
          //console.log('An error occurred inLoginUser: ', error.response.data.message);
          // return dispatch(loginError(error.response.data.message));
          // TODO - Dispatch the error at application level
          console.log(error);
        });
    }
  }

  reloadLanguagePacks() {
    const { dispatch, location } = this.props;
    // console.log('this.props => ', this.props)
    const { pathname } = location
    // the locale is stored in the local storage and if retrieved
    // make sure the selection filters the list of remaining locales
    const activeLocaleItem = JSON.parse(
      localStorage.getItem(`activeLocaleItem-${pathname.includes('dineout') ? 'dineout' : 'dinein'}`)
    );
    // make sure the selection filters the list of remaining locales
    dispatch(changeLocaleActiveItem(activeLocaleItem, arrayLocales, pathname.includes('dineout') ? 'dineout' : 'dinein'));
  }

  // function to manage when a new locale is selected
  // triggers an action that is then intercepted by the reducer
  // that updates the state of the full application
  changeLocale(selectedLocale, arrayOfLocales) {
    const { dispatch, appDeliveryMethod, location } = this.props;
    if (appDeliveryMethod) {
      dispatch(changeLocaleActiveItem(selectedLocale, arrayOfLocales, appDeliveryMethod));
    } else {
      const { pathname } = location
      dispatch(changeLocaleActiveItem(selectedLocale, arrayOfLocales, pathname.includes('dineout') ? 'dineout' : 'dinein'));
    }
    
    GAEvent("FRONTEND", "Change language", "LOCALE_SELECTOR_CHANGE_LOCALE");
  }

  render() {
    return this.props.remainingAvailableLocales &&
      this.props.remainingAvailableLocales.length > 0 ? (
      <UncontrolledDropdown>
        {this.props.activeLocaleItem ? (
          <DropdownToggle nav>
            <img
              className={s.imageStyleForActiveLocaleItem}
              src={this.props.activeLocaleItem.imageURL}
              alt={this.props.activeLocaleItem.alt}
            ></img>
          </DropdownToggle>
        ) : null}
        {
          <DropdownMenu className={s.dropDownMenuStyle} right>
            {this.props.remainingAvailableLocales.map((localeObject, index) => {
              return (
                <DropdownItem
                  className={s.dropDownMenuItemStyle}
                  key={index}
                  onClick={() => {
                    this.changeLocale(localeObject, arrayLocales);
                  }}
                >
                  <span>{localeObject.label}</span>
                  <img
                    className={
                      s.imageStyleForNonActiveLocaleItem + " float-right"
                    }
                    src={localeObject.imageURL}
                    alt={localeObject.alt}
                  />
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        }
      </UncontrolledDropdown>
    ) : <div style={{marginLeft: "48px"}}></div>;
  }
}

function mapStateToProps(state) {
  return {
    activeLocaleItem: state.locale.activeLocaleItem,
    remainingAvailableLocales: state.locale.nonActiveLocales,
    websocket: state.websocket.socket,
    eventSource: state.eventSource.evtSource,
  };
}

export default withRouter(connect(mapStateToProps)(LocaleSelector));
