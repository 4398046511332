import React from 'react';
import PropTypes from 'prop-types';
//`import { Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { actionGetRestaurantsFromServer, actionGetRestaurantCollectionFromServer } from '../../actions/restaurant';
import { getRestaurantAvailability } from '../../api/menu';
import { navigateToNewPage } from '../../actions/navigation';
import {
  // Button,
  // ButtonGroup,
  // Breadcrumb,
  // BreadcrumbItem,
  // Badge,
  // Tooltip,
  Row,
  // Col
} from 'reactstrap';

import { FormattedMessage, injectIntl } from 'react-intl'; 
import ProgressIndicator from '../../components/ProgressIndicator';

import queryString from 'query-string';

import s from './Restaurants.module.scss';

import CustomBanner from '../../components/CustomBanner/CustomBanner';

import '../../components/MarkdownContent/MarkdownContent.css';

// Not best practice but this flag has been added to avoid a double call to the server
// looking at the isFetching property connected to the reducer which is not fast enougth
// changing value when a call to the backend is sent in the didupdate function and end
// up with another useless call to the server before the isFetching property is updated
// by default this flag is at false indicating that no call has been made to the server
// as soon as a call is made then it is put as true and only back to false when the
// component is unmounted
let isAFetchFromApiInProgress = false;

class Restaurants extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    this._isMounted = false;

    this.state = {
      tooltipRefreshDataFromServer: [false],
      restaurantStatus: {}
    };

    this.refreshRestaurantsFromServer = this.refreshRestaurantsFromServer.bind(this);
    this.refreshRestaurantCollectionFromServer = this.refreshRestaurantCollectionFromServer.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.scrollToRestaurant = this.scrollToRestaurant.bind(this);
    this.checkRestaurantAvailability = this.checkRestaurantAvailability.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    // dispatch back button url and page title name
    const { dispatch } = this.props;
    dispatch(navigateToNewPage());
    //console.log('this.props.isFetching in componentDidMount => ', this.props.isFetching);
    //console.log('isAFetchFromApiInProgress => ', isAFetchFromApiInProgress);
    if (this.props.listOfRestaurants === undefined && !this.props.isFetching && !isAFetchFromApiInProgress) {
      //console.log('çalling server 0');
      const restaurantCollectionId = this.props.match.params.restaurantcollectionid;
      // set that we are in restaurant collection mode
      localStorage.setItem(`restaurantCollection-slug-${restaurantCollectionId}`, restaurantCollectionId);
      // we check if we are in restaurant colletion mode
      if(restaurantCollectionId) {
         this.refreshRestaurantCollectionFromServer();
      } else {
        // this is the list of restaurants mode
        this.refreshRestaurantsFromServer();
      }
    }

    // Check availability for each restaurant in the collection
    if (this.props.restaurantCollection && 
        this.props.restaurantCollection.groupOfRestaurants) {
      this.checkAvailabilityForCollection();
    }
  }

  componentDidUpdate(prevProps) {
    // dispatch back button url and page title name
    const { dispatch } = this.props;
    dispatch(navigateToNewPage());

    /*const el = this.myRef.current;
    console.log("el => ", el);
    //const elem = el.getElementsByClassName('Banner_bannerSlideDown__NAV-_');
    const elem = el.getElementsByTagname('a');
    if (elem) {
      console.log("elem => ", elem);
    }*/

    const restaurantCollectionId = this.props.match.params.restaurantcollectionid;
      // we check if we are in restaurant colletion mode
    if(restaurantCollectionId) {
      if(this.props.restaurantCollection === undefined &&
        prevProps.restaurantCollection === undefined &&
        !this.props.isFetching && !isAFetchFromApiInProgress) {
        //console.log('çalling server 1');
        this.refreshRestaurantCollectionFromServer(true);
      }
    } else {
      if(this.props.listOfRestaurants === undefined &&
        prevProps.listOfRestaurants === undefined &&
        !this.props.isFetching && !isAFetchFromApiInProgress) {
        //console.log('çalling server 1');
        this.refreshRestaurantsFromServer(true);
      }
    }

    if(this.props.restaurantCollection) {
      const {intl} = this.props;
      const {locale} = intl;
      const { restaurantCollection } = this.props;
      if (this.props.restaurantCollection.type) {
        localStorage.setItem(`restaurantCollection-type-${restaurantCollectionId}`, this.props.restaurantCollection.type);
      }
      dispatch(navigateToNewPage(restaurantCollection.name_i18n[locale]));
    }

    const restaurantAnchor = localStorage.getItem('restaurant-link') 
    if (restaurantAnchor) {
      // when the selected category is in the state and in the active menu category
      // which means we have now selected the right tab then we scroll to the menu
      // item in this tab
      //console.log('this.state.activeMenuCategoryIdInState => ', this.state.activeMenuCategoryIdInState);
      //console.log('this.activeMenuCategoryId => ', this.activeMenuCategoryId);
      // if(this.state.activeMenuCategoryIdInState === this.activeMenuCategoryId) {
        //console.log('scroll to menu item => ', this.props.location.hash);
      this.scrollToRestaurant(restaurantAnchor);
      //}
    }

    // If restaurant collection has changed, check availability again
    if (prevProps.restaurantCollection !== this.props.restaurantCollection &&
        this.props.restaurantCollection && 
        this.props.restaurantCollection.groupOfRestaurants) {
      this.checkAvailabilityForCollection();
    }
  }

  componentWillUnmount() {
    isAFetchFromApiInProgress = false;
    this._isMounted = false;
    
    // Cancel any pending restaurant availability checks
    if (this._availabilityChecks) {
      this._availabilityChecks.forEach(cancelToken => {
        if (cancelToken.cancel) {
          cancelToken.cancel('Component unmounted');
        }
      });
    }
  }

  scrollToRestaurant(restaurantAnchor) {
    const el = document.getElementById(restaurantAnchor);
    //console.log('el => ', el);
    if (el) {
      el.scrollIntoView({behavior: "auto", block: "center", inline: "nearest"});
      // remove the hash anchor from the local storage
      localStorage.removeItem('restaurant-link') 
    }
  }


  refreshRestaurantsFromServer(forceCallToServer = false) {
    const { dispatch } = this.props;
    // we put the flag that a call to the server is being done
    isAFetchFromApiInProgress = true;

    if((!this.props.isFetching 
      && (typeof this.props.listOfRestaurants === 'undefined')) || forceCallToServer) {
        dispatch(actionGetRestaurantsFromServer());
    }
  }

  refreshRestaurantCollectionFromServer(forceCallToServer = false) {
    const { dispatch } = this.props;
    // we put the flag that a call to the server is being done
    isAFetchFromApiInProgress = true;

    if((!this.props.isFetching 
      && (typeof this.props.restaurantCollection === 'undefined')) || forceCallToServer) {
        const restaurantCollectionId = this.props.match.params.restaurantcollectionid;
        dispatch(actionGetRestaurantCollectionFromServer(restaurantCollectionId));
    }
  }

  toggleTooltip(id, field) {
    const newState = [...this.state[field]];
    newState.fill(false);

    if (!this.state[field][id]) {
      newState[id] = true;
    }

    this._isMounted && this.setState({
      [field]: newState,
    });
  }

  // Check availability for all restaurants in the collection
  checkAvailabilityForCollection() {
    const { restaurantCollection } = this.props;
    
    if (!restaurantCollection || !restaurantCollection.groupOfRestaurants) {
      return;
    }
    
    // Initialize the array to store cancel tokens
    this._availabilityChecks = [];
    
    // Process restaurants in batches to avoid too many simultaneous requests
    const batchSize = 3;
    const restaurants = [...restaurantCollection.groupOfRestaurants];
    
    const processBatch = async (startIndex) => {
      const batch = restaurants.slice(startIndex, startIndex + batchSize);
      
      if (batch.length === 0) {
        return; // All batches processed
      }
      
      // Process this batch
      const promises = batch.map(restaurantConfig => {
        const { restaurant, deliverymethod } = restaurantConfig;
        if (restaurant && restaurant.slug && deliverymethod && deliverymethod.code) {
          return this.checkRestaurantAvailability(restaurant.slug, deliverymethod.code)
            .catch(error => {
              console.error(`Error checking availability for ${restaurant.slug}:`, error);
              return false; // Consider restaurant closed on error
            });
        }
        return Promise.resolve();
      });
      
      // Wait for this batch to complete
      await Promise.all(promises);
      
      // If component is still mounted, process the next batch
      if (this._isMounted) {
        processBatch(startIndex + batchSize);
      }
    };
    
    // Start processing batches
    processBatch(0);
  }

  // Check if a restaurant has any available menus
  async checkRestaurantAvailability(restaurantSlug, deliveryMethodCode) {
    try {
      // Use the new function from menu.js
      const isAvailable = await getRestaurantAvailability(restaurantSlug, deliveryMethodCode);
      
      // Only update state if component is still mounted
      if (this._isMounted) {
        this.setState(prevState => ({
          restaurantStatus: {
            ...prevState.restaurantStatus,
            [`${restaurantSlug}-${deliveryMethodCode}`]: isAvailable
          }
        }));
      }
    } catch (error) {
      console.error("Error checking restaurant availability:", error);
    }
  }

  render() {
    const {intl} = this.props;
    const {locale} = intl;
    let listOfRestaurantConfigs = this.props.listOfRestaurants;
    const { restaurantCollection } = this.props;
    const restaurantCollectionId = this.props.match.params.restaurantcollectionid;
    const queryStringParamMode = queryString.parse(this.props.location.search).mode
    const queryStringParamLocationNumber = queryString.parse(this.props.location.search).locationnumber
    const queryStringParamGroupCode = queryString.parse(this.props.location.search).groupcode
    // we check if we are in restaurant colletion mode
    if(restaurantCollectionId && restaurantCollection && restaurantCollection.groupOfRestaurants) {
      // assign the restaurants that are part of the restaurant collection that is displayed
      listOfRestaurantConfigs = restaurantCollection.groupOfRestaurants.filter(restaurantConfig => restaurantConfig.restaurant.isActive && restaurantConfig.isActive);
    }

    let promotionBanner = null 
    if (restaurantCollection) {
      promotionBanner = restaurantCollection.promotionBanner
    }

    const {restaurantcollectionid} = this.props.match.params;

    // Get display mode from restaurantCollection
    let displayModeClass = '';
    if (restaurantCollection && restaurantCollection.displayMode) {
      switch(restaurantCollection.displayMode) {
        case 'two_per_row':
          displayModeClass = s.twoPerRow;
          break;
        case 'three_per_row':
          displayModeClass = s.threePerRow;
          break;
        default:
          displayModeClass = '';
      }
    }

    return (
      <div ref={this.myRef} className={s.root}>
        {
          promotionBanner && promotionBanner.isActive ? 
          <CustomBanner
            title={promotionBanner.promotionDescription_i18n[locale]}
            ctaLink={promotionBanner.urlToOpen}
            ctaTitle="Learn More"
            animationTime={1}
            delayToShowBanner={0}
            wrapperStyle={{ backgroundColor: "" }}
            mainStyleTitle={{ color : "" }}
            mainStyleLink={{ color: "" }}
            isCenter={false}
            bannerId={`promo-banner-${promotionBanner.id || 'default'}`}
          /> : null
        }
        {
          <Row>
            {/*
              <Col>
                <Breadcrumb>
                  <BreadcrumbItem active>
                    <FormattedMessage id="Restaurants.Breadcrumbitem.Restaurants" defaultMessage="Kiosks" />&nbsp;&nbsp;
                    {listOfRestaurantConfigs && listOfRestaurantConfigs.length > 0 ? <Badge color="primary">{listOfRestaurantConfigs.length}</Badge> : null}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            */}
            {/*
              <Col className={locale === 'ar' ? "d-sm-down-none" : "d-sm-down-none text-right"}>
                <div className={s.galleryControls} style={locale === 'ar' ? {display: 'block', position: 'initial'} : {}}>
                  <ButtonGroup id="buttonRefreshDataFromServer">
                    <Button onClick={() => {
                        const restaurantCollectionIdVal = this.props.match.params.restaurantcollectionid;
                        // we check if we are in restaurant colletion mode
                        if(restaurantCollectionIdVal) {
                            this.refreshRestaurantCollectionFromServer(true);
                        } else {
                          this.refreshRestaurantsFromServer(true);
                        }
                      }} color="default"><i className="la la-refresh"></i></Button>
                  </ButtonGroup>
                  <Tooltip placement="top" isOpen={this.state.tooltipRefreshDataFromServer[0]} toggle={() => this.toggleTooltip(0, 'tooltipRefreshDataFromServer')} 
                    target={"buttonRefreshDataFromServer"}>
                    <FormattedMessage id="Restaurants.button.refresh.data.from.server" defaultMessage="Refresh list of restaurants" />
                  </Tooltip>
                </div>
              </Col>
            */}
          </Row>
        }
        {this.props.isFetching ? <ProgressIndicator />: null}
        {listOfRestaurantConfigs && listOfRestaurantConfigs.length === 0 ?<span><FormattedMessage id="Restaurants.Page.No.Restaurants.Available" defaultMessage="No available restaurants" />.</span> : null}
        <div className={`${s.gallery} ${displayModeClass}`}>
          {
            listOfRestaurantConfigs && listOfRestaurantConfigs.length > 0?
            listOfRestaurantConfigs.map((restaurantConfig, index) => {
              const { restaurant, deliverymethod, description_i18n, picture } = restaurantConfig
              const key = 'restaurants-page-' + restaurant.name + index;
              const anchor = restaurant ? restaurant.slug + '-' + index : null;
              
              // Check if we have status information for this restaurant
              const statusKey = `${restaurant.slug}-${deliverymethod.code}`;
              const isOpen = this.state.restaurantStatus[statusKey];
              
              // If status is undefined, we're still loading
              const isLoading = isOpen === undefined;
              // Only apply closed styling if we know for sure the restaurant is closed
              const isClosed = isOpen === false;
              
              return (
                <div id={'restaurant-link-' + anchor} key={key} className={`${s.picture} card`}>
                  {/* Only make it clickable if it's open or still loading */}
                  {(!isClosed) ? (
                    <a href='#/' onClick={(e) => {
                      e.preventDefault();
                      if (restaurantcollectionid && restaurant) {
                        localStorage.setItem('restaurant-link', `restaurant-link-${anchor}`);
                        window.location.href = `#/app/${deliverymethod.code}/restaurant/${restaurant.slug}/menus?rcid=${restaurantcollectionid}${queryStringParamMode ? '&mode=' + queryStringParamMode : ''}${queryStringParamLocationNumber ? '&locationnumber=' + queryStringParamLocationNumber : ''}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`;
                      } else {
                        if (queryStringParamLocationNumber && restaurant) {
                          window.location.href = `#/app/${deliverymethod.code}/restaurant/${restaurant.slug}/menus?locationnumber=${queryStringParamLocationNumber}${queryStringParamMode ? '?mode=' + queryStringParamMode : '?'}${queryStringParamGroupCode ? '&groupcode=' + queryStringParamGroupCode : ''}`;
                        } else {
                          if (restaurant) {
                            window.location.href = `#/app/${deliverymethod.code}/restaurant/${restaurant.slug}/menus${queryStringParamMode ? '?mode=' + queryStringParamMode : ''}`;
                          }
                        }
                      }
                    }}>
                      <div style={{ position: 'relative' }}>
                        <img 
                          className="figure-img" 
                          src={
                            picture && picture.url ? picture.url :
                            (
                              restaurant.backgroundPicture.formats && restaurant.backgroundPicture.formats.medium ? 
                              restaurant.backgroundPicture.formats.medium.url : restaurant.backgroundPicture.url
                            )
                          } 
                          alt={restaurant.name}
                          style={isLoading ? { opacity: 0.7 } : {}}
                        />
                        
                        {isLoading && (
                          <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            color: '#333',
                            padding: '5px 15px',
                            borderRadius: '4px',
                            fontWeight: 'bold',
                            fontSize: '1rem'
                          }}>
                            LOADING...
                          </div>
                        )}
                      </div>
                      
                      <div className={s.description} style={isLoading ? { opacity: 0.7 } : {}}>
                        <h5 
                          className="mt-0 mb-xs fw-semi-bold"
                          dangerouslySetInnerHTML={{__html: description_i18n[locale]}}
                        >
                        </h5>
                        <span>
                          {
                            restaurant.cuisinetypes.map((cuisinetype) => {
                              return cuisinetype.name_i18n[locale] + " ";
                            })
                          }
                        </span>
                        <div className="w-100 d-flex justify-content-between align-items-center">
                          <span className="text-muted">{restaurant.streetNameAndNumber}</span>
                        </div>
                      </div>
                    </a>
                  ) : (
                    // Non-clickable version for closed restaurants
                    <div>
                      <div style={{ position: 'relative' }}>
                        <img 
                          className="figure-img" 
                          src={
                            picture && picture.url ? picture.url :
                            (
                              restaurant.backgroundPicture.formats && restaurant.backgroundPicture.formats.medium ? 
                              restaurant.backgroundPicture.formats.medium.url : restaurant.backgroundPicture.url
                            )
                          } 
                          alt={restaurant.name}
                          style={{ filter: 'grayscale(100%) brightness(70%)' }}
                        />
                        
                        <div style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          backgroundColor: 'rgba(0, 0, 0, 0.7)',
                          color: 'white',
                          padding: '5px 15px',
                          borderRadius: '4px',
                          fontWeight: 'bold',
                          fontSize: '1.2rem'
                        }}>
                          CLOSED
                        </div>
                      </div>
                      
                      <div className={s.description} style={{ opacity: 0.7 }}>
                        <h5 
                          className="mt-0 mb-xs fw-semi-bold"
                          dangerouslySetInnerHTML={{__html: description_i18n[locale]}}
                        >
                        </h5>
                        <span>
                          {
                            restaurant.cuisinetypes.map((cuisinetype) => {
                              return cuisinetype.name_i18n[locale] + " ";
                            })
                          }
                        </span>
                        <div className="w-100 d-flex justify-content-between align-items-center">
                          <span className="text-muted">{restaurant.streetNameAndNumber}</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            }) : null
          }
        </div>
      </div>);
  } // enf of render
} // end of class

function mapStateToProps(store) {
  return {
    isFetching: store.restaurant.isFetching,
    errorMessage: store.restaurant.errorMessage,
    listOfRestaurants: store.restaurant.listOfRestaurants,
    restaurantCollection: store.restaurant.restaurantCollection
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(Restaurants)));
