//import runtimeEnv from '@mars/heroku-js-runtime-env';

export const SOCKET_CONNECTION_INIT = 'SOCKET_CONNECTION_INIT';
export const SOCKET_CONNECTION_SUCCESS = 'SOCKET_CONNECTION_SUCCESS';
export const SOCKET_CONNECTION_ERROR = 'SOCKET_CONNECTION_ERROR';
export const SOCKET_CONNECTION_CLOSED = 'SOCKET_CONNECTION_CLOSED';
export const SOCKET_ON_MESSAGE_RECEIVED = 'SOCKET_ON_MESSAGE_RECEIVED';

let wsClient = null;

export function initializeSocket(restaurantSlug) {
    return (dispatch) => {
      // set the socket.io endpoint
      //const env = runtimeEnv();
      /* const  endpoint = (process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
      '://' + (process.env.REACT_APP_STRAPI_HOST || 'localhost') +
       ":" + (process.env.REACT_APP_STRAPI_PORT || '1337'); */
      const urlToWebsocketServer = `${process.env.REACT_APP_ALACARTE_WSS_URL}?restaurant-slug=${restaurantSlug}&mode=ca`;

      wsClient = new WebSocket(urlToWebsocketServer);

      const setupHeartbeat = (socket) => {
        // console.log(new Date(), 'wss heartbeat')
        if (!socket) return
        if (socket.readyState !== 1) return
        socket.send(JSON.stringify({'message': 'ping'}));
        // console.log('sent heartbeat message to server')
        setTimeout(() => {
          setupHeartbeat(socket)
        }, 30000)
      };

      const retrieveConnectionId = (socket) => {
        // console.log(new Date(), 'wss heartbeat')
        if (!socket) return
        if (socket.readyState !== 1) return
        const { v4: uuidv4 } = require('uuid');
        socket.send(JSON.stringify({'message': uuidv4()}));
      }

      wsClient.addEventListener("open", () => {
        setupHeartbeat(wsClient);
        retrieveConnectionId(wsClient);
        dispatch(socketConnectionSuccess());
      });
      // listen server side error
      wsClient.addEventListener("error", error => {
        console.log(
          "Closing socket with error...", restaurantSlug
        );
        wsClient.close();
        dispatch(socketConnectionError());  
        // throw new Error(error)
      });

      wsClient.addEventListener("message", async data => {
        // console.log('received message from alacarte wss server => ', data)
        const receivedMessage = JSON.parse(data.data);
        const { action, response } = receivedMessage;
        switch (action) {
          case 'Default':
            // console.log("received websocket data => ", restaurantSlug, response);
            // Save data to sessionStorage
            sessionStorage.setItem('connectionId', response);
            break;
          case 'Ping':
            console.log("received websocket data => ", restaurantSlug, response);
            break;
          case 'Restaurant-Data-Updated': {
            const { v4: uuidv4 } = require('uuid');
            receivedMessage.uuid = uuidv4();
            dispatch(socketOnMessageReceived(receivedMessage));
            break;
          }
          default:
            break;
        }
        
      });

      // we use this flag to check if the user forced to close the websocket connection manually
      // like for example a logout action
      wsClient.isForcedToClose = false;

      wsClient.addEventListener('close', (reason) => {
        sessionStorage.removeItem('connectionId');
        dispatch(socketConnectionClosed()); 
        if (wsClient && !wsClient.isForcedToClose) {
          // we check first is it is not an intentional close event from the user
          // console.log(new Date(), `[----- CUSTOMER APP WEBSOCKET DISCONNECTED from ALACARTE reconnect will be attempted in 1 second -----> ${reason} ]`)
          setTimeout(() => {
            // console.log('restarting connection to alacarte websocket server for ', restaurantSlug);
            dispatch(initializeSocket(restaurantSlug))
          }, 1000)
        } else {
          // console.log(new Date(), `[----- CUSTOMER APP WEBSOCKET FORCED from ALACARTE WSS service]`);
          console.log('socket closed!', restaurantSlug)
          wsClient = null;
        }
      })
      
      dispatch(socketConnectionInit(wsClient));
    }
  }
  
  function socketConnectionInit(socket) {
    return {
      type: SOCKET_CONNECTION_INIT,
      socket,
    };
  }
  
  function socketConnectionSuccess() {
    return {
      type: SOCKET_CONNECTION_SUCCESS,
    };
  }
  
  function socketConnectionError() {
    return {
      type: SOCKET_CONNECTION_ERROR,
    };
  }

  function socketOnMessageReceived(messageObject) {
    return {
      type: SOCKET_ON_MESSAGE_RECEIVED,
      messageObject
    };
  }
  
  function socketConnectionClosed() {
    return {
      type: SOCKET_CONNECTION_CLOSED,
      socket: null
    };
  }