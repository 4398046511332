import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import './CustomBanner.css';

const CustomBanner = ({
  title,
  ctaLink,
  ctaTitle,
  animationTime = 1,
  delayToShowBanner = 0,
  wrapperStyle = {},
  mainStyleTitle = {},
  mainStyleLink = {},
  isCenter = false,
  bannerId = 'default-banner'
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isBannerDismissed, setIsBannerDismissed] = useState(false);
  
  useEffect(() => {
    const dismissedBanners = JSON.parse(localStorage.getItem('dismissedBanners') || '{}');
    const isBannerDismissedInStorage = dismissedBanners[bannerId];
    
    if (isBannerDismissedInStorage) {
      setIsBannerDismissed(true);
    } else {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, delayToShowBanner * 1000);
      
      return () => clearTimeout(timer);
    }
  }, [delayToShowBanner, bannerId]);
  
  const handleClose = () => {
    setIsClosing(true);
    
    const dismissedBanners = JSON.parse(localStorage.getItem('dismissedBanners') || '{}');
    dismissedBanners[bannerId] = true;
    localStorage.setItem('dismissedBanners', JSON.stringify(dismissedBanners));
    
    setTimeout(() => {
      setIsVisible(false);
      setIsBannerDismissed(true);
    }, 500);
  };
  
  if (isBannerDismissed || (!isVisible && !isClosing)) {
    return null;
  }
  
  return (
    <div 
      className={`custom-banner ${isVisible && !isClosing ? 'visible' : ''} ${isClosing ? 'closing' : ''}`}
      style={{
        ...wrapperStyle,
        transition: `all ${animationTime}s ease-in-out`,
        textAlign: 'left'
      }}
    >
      <div 
        className="banner-content"
        style={{
          alignItems: 'flex-start',
          textAlign: 'left'
        }}
      >
        <div 
          className="banner-title" 
          style={{
            ...mainStyleTitle,
            textAlign: 'left'
          }}
        >
          {typeof title === 'string' ? (
            <ReactMarkdown>{title.replace(/\\n/g, '\n')}</ReactMarkdown>
          ) : (
            title
          )}
        </div>
        
        {ctaLink && (
          <a
            href={ctaLink}
            className="banner-link"
            style={mainStyleLink}
          >
            {ctaTitle}
          </a>
        )}
      </div>
      
      <button 
        className="close-button"
        onClick={handleClose}
        title="Close banner (won't show again)"
      >
        ×
      </button>
    </div>
  );
};

export default CustomBanner; 