import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, /*Redirect*/ } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
//import $ from 'jquery';
import Hammer from 'rc-hammerjs';

import ErrorPage from '../../pages/error';

import Menus from '../../pages/menus';
//import Restaurants from '../../pages/restaurants';
import Landing from '../../pages/landing/Landing.js';
import Menuitems from '../../pages/menuitems';
import MenuItem from '../../pages/menuitem';
import Restaurants from '../../pages/restaurants';
import Orderstatus from '../../pages/orderstatus';
import Hyperpaycheckout from '../../pages/hyperpaycheckout';
import { injectIntl } from 'react-intl';
import { initializeSocket } from '../../actions/websocket';

import Header from '../Header';
import s from './Layout.module.scss';

import $ from 'jquery';

const {slugs} = require ('../../styles/themable-outlets-slugs.json')
// console.log('themableOutletsSlug => ', slugs)

function getRestaurantSlugFromURL() {
  let restoSlug = null;
  let url = document.URL;
  if (url.indexOf('?') > -1){
    url = url.split('?')[0]
  }
  if (url.indexOf('#') > -1) {
    // http://mydomain.com/#/app/dinein/restaurant/somos-peru/menus
    // [0] -> http://mydomain.com/
    // [1] -> /app/dinein/restaurant/somos-peru/menus
    let array1 = url.split('#');
    if (array1.length > 1) {
      let path = array1[1];
      let array2 = path.split('/');
      if (array2.length > 4) {
        restoSlug = array2[4];
      }
    }
  }
  return restoSlug;
}

const restaurantSlug = getRestaurantSlugFromURL();
// this is where the theme switching can happen
console.log('Theming for ', restaurantSlug);

//$("html").addClass("alacarte");
// if the current restaurant slug has a css class it means it has its own theme then we load it
// otherwise we load the alacarte one by default
if (slugs.includes(restaurantSlug)) {
  $("html").addClass(restaurantSlug);
} else {
  $("html").addClass('alacarte');
}

let isSocketBeingInitiated = false

class Layout extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    //console.log(this.props.websocket);
  }

  componentDidUpdate(prevProps) {
    if(!this.props.websocket && !isSocketBeingInitiated &&
        this.props.restaurantInfo && this.props.restaurantInfo.slug &&
        this.props.location.pathname.indexOf('/restaurantcollection/') === -1) {
      // flag that socket is being initiated
      isSocketBeingInitiated = true;
      //console.log('ínitializing socket did mount');
      this.props.dispatch(initializeSocket(this.retrieveRestaurantIdFromUrl()));
    }

    if (this.props.location.pathname.indexOf('/restaurantcollection/') > -1) {
      isSocketBeingInitiated = false;
    }
  }

  retrieveRestaurantIdFromUrl() {
    // in case we use a deeplink and we have items in the cart then
    // we need to extract the restaurant id from the url
    const urlHash = window.location.hash;
    const indexOfRestaurant = urlHash.indexOf("/restaurant/");
    // console.log('indexOfRestaurant => ', indexOfRestaurant)
    let indexOfMenus = urlHash.indexOf("/menu");
    if (indexOfMenus === -1)
      indexOfMenus = urlHash.indexOf("/orderstatus")
    if (indexOfMenus === -1)
      indexOfMenus = urlHash.indexOf("/checkout")
    // console.log('indexOfMenus => ', indexOfMenus)
    const extractRestaurantInformation = urlHash.substring(
      indexOfRestaurant,
      indexOfMenus
    );
    // console.log('extractRestaurantInformation => ', extractRestaurantInformation)
    return extractRestaurantInformation.replace("/restaurant/", "");
  }

  render() {
    const { intl } = this.props
    const { locale } = intl
    return (
      <div
        className={s.root}
        dir={locale === 'ar' ? 'rtl' : ''}
      >
        <div className={s.wrap}>
          <Header />
          {/*<Helper />*/}
          <Hammer onSwipe={this.handleSwipe}>
            <main id="layoutContent" className={s.content}>
              <TransitionGroup>
                <CSSTransition
                  key={this.props.location.pathname}
                  classNames="fade"
                  timeout={200}
                >
                <Switch>
                  <Route path="/app/main" exact component={Landing} />
                  {/*<Route path="/app/restaurants" exact component={Restaurants} />*/}
                  <Route path="/app/:deliverymethod/restaurant/:restaurantid/checkout/hyperpay/:paymentid/order/:orderuuid" exact component={Hyperpaycheckout} />
                  <Route path="/app/:deliverymethod/restcollection/:restaurantcollectionid/restaurant/:restaurantid/orderstatus/:orderid" exact component={Orderstatus} />
                  <Route path="/app/:deliverymethod/restaurant/:restaurantid/orderstatus/:orderid" exact component={Orderstatus} />
                  <Route path="/app/:deliverymethod/restaurantcollection/:restaurantcollectionid" exact component={Restaurants} />
                  <Route path="/app/:deliverymethod/restaurant/:restaurantid/menus" exact component={Menus} />
                  <Route path="/app/:deliverymethod/restaurant/:restaurantid/menu/:menuid" exact component={Menuitems} />
                  <Route path="/app/:deliverymethod/restaurant/:restaurantid/menu/:menuid/menucategory/:menucategoryid/menuitem/:menuitemid" exact component={MenuItem} />
                  <Route path="/app/:deliverymethod/restaurant/:restaurantid/menu/:menuid/menucategory/:menucategoryid/menugroup/:menugroupid/menuitem/:menuitemid" exact component={MenuItem} />
                  <Route path="/app/:deliverymethod/restaurant/:restaurantid/menu/:menuid/menucategory/:menucategoryid/menugroup/:menugroupid/menuitemsgroup/:menuitemsgroupid/menuitem/:menuitemid" exact component={MenuItem} />
                  <Route component={ErrorPage} />
                </Switch>
              </CSSTransition>
              </TransitionGroup>
              <footer className={s.contentFooter}>
                2023 &copy; Alacarte
              </footer>
            </main>
          </Hammer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
    websocket: store.websocket.socket,
    restaurantInfo: store.restaurant.restaurantInfo,
    isConnectedToWebSocketServer: store.websocket.connected,
    restaurantWithMenus: store.menu.restaurantWithMenus
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(Layout)));
