import React from 'react';
import s from './ProgressIndicator.module.scss';
import { injectIntl } from 'react-intl'; 

class ProgressIndicator extends React.PureComponent {

  render() {

    const { intl } = this.props
    const { locale } = intl

    return (
      <div className={s.spinner}>
        <span>
          <i className="fa fa-spinner fa-5x fa-spin" 
            style={locale === 'ar' ? {position: 'absolute', bottom: '5px', color: "lightgrey"} : {color: "lightgrey"}}
          ></i>
          <img 
            src="/favicon.png"
            className={s.alacarteSpinnerLogo}
            style={locale === 'ar' ? {right: '16px'} : {}}
            alt="Alacarte" />
        </span>
      </div>
    );
  }
}

export default injectIntl(ProgressIndicator);
