exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PrepTimeClock_easyPieChartSM__3uCoU {\n  position: relative;\n  display: inline-block;\n  text-align: center;\n  line-height: 85px;\n  height: 85px;\n  width: 85px; }\n  .PrepTimeClock_easyPieChartSM__3uCoU canvas {\n    position: absolute;\n    top: 0;\n    left: 0; }\n", ""]);

// exports
exports.locals = {
	"easyPieChartSM": "PrepTimeClock_easyPieChartSM__3uCoU"
};