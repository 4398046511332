import axios from 'axios';
//import runtimeEnv from '@mars/heroku-js-runtime-env';

export async function postOrder (order) {
    // endpoint path
    const apiEndpointPath = '/orders';
    const connectionId = sessionStorage.getItem('connectionId');
    // init runtime env var
    //const env = runtimeEnv();
    const options = order;
    // Request API.
    return await axios
    .post( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
    '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
     ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath, 
           options
           ,
           {
            headers: { 
             'X-Connection-Id' : connectionId
             }
           }
        )
    .then(response => {
        // Handle success.
        //console('menu axios response', response);
        return response.data;
    });
}

export async function getMultivendorOrder (orderId) {
    // endpoint path
    let apiEndpointPath = '/publicmultivendororders';
    // init runtime env var
    //const env = runtimeEnv();
    // Request API.
    const orderResponseData = await axios
    .get( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
    '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
     ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath + "/" + orderId)
    .then(response => {
        // Handle success.
        // console('menu axios response data', response.data);
        return response.data;
    })
    .catch(error => {
        // Handle error.
        console('error retrieving order error', error);
        return error;
     });

    return orderResponseData;
}

export async function getOrder (orderId) {
    // endpoint path
    let apiEndpointPath = '/publicorders';
    // init runtime env var
    //const env = runtimeEnv();
    // Request API.
    const orderResponseData = await axios
    .get( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
    '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
     ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath + "/" + orderId)
    .then(response => {
        // Handle success.
        // console('menu axios response data', response.data);
        return response.data;
    })
    .catch(error => {
        // Handle error.
        console('error retrieving order error', error);
        return error;
     });

    return orderResponseData;
}