import axios from 'axios';
//import runtimeEnv from '@mars/heroku-js-runtime-env';

export async function getListOfMenusFromServerForRestaurant (restaurantSlug, deliveryMethod) {
    //console.log('getListOfMenusFromServerForRestaurant => ', restaurantSlug);
    if (!restaurantSlug  || restaurantSlug === 'undefined') {
        return [];
    } else {
        // console.log('deliveryMethod => ', deliveryMethod);
        const isInDineInMode = deliveryMethod.isDineIn;
        // endpoint path
        const apiEndpointPath = '/graphql';
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const todayDate = new Date();
        const dayName = days[todayDate.getDay()];
        const graphqlQuery = `
        query GetListOfMenusForRestaurant {
            restaurantsForPublic(slug: "${restaurantSlug}") {
                id
                slug
                name
                outletLogoForCustomerApp { url formats }
                isCommentAllowedAtCheckout
                isDisplayTakeawayMessageForDineIn
                isDigitalMenuOnlyForDineIn
                discountOnDineOutOrders
                discountOnCollectAtKiosk
                isRepeatLastOrderAllowed
                deliveryMethodsConfiguration {
                    deliverymethod {
                        code
                    }
                    customerEmailForOrder
                    customerPhoneForOrder
                    groupCodeForOrder
                    customerFullnameForOrder
                    isActive
                    locationNumberLabelTranslations
                    overwriteLocationNumberLabel
                }
                taxRates {
                    isActive
                    isIncludedInPrices
                    description_i18n
                    type
                    taxValue
                    taxValueType
                }
                defaultLanguageForCustomerApp {
                    id
                    localeCode
                    imageURL
                    alt
                    label
                }
                paymentMethods {
                    code
                    isOnlinePaymentMethod
                }
                companyRegistrationNumber
                collectTimeslotsForWeek {
                    isActive
                    ${dayName} {
                        timeslots {
                            id
                            label
                            fromTimeHours
                            fromTimeMinutes
                            toTimeHours
                            toTimeMinutes
                        }
                    }
                }
                takeawayTimeslotsForWeek {
                    isActive
                    ${dayName} {
                        timeslots {
                            id
                            label
                            fromTimeHours
                            fromTimeMinutes
                            toTimeHours
                            toTimeMinutes
                        }
                    }
                }
                languagepacks {
                    id
                }
                deliverymethods {
                    code
                    label
                    isDineIn
                    isWithOnlinePayment
                    description_i18n
                    isCollectAtLocation
                }
                city {
                    name
                    momentJsTimezoneCode
                    timezone {
                        utc
                    }
                    country {
                        cioc
                        currency {
                            symbol
                            numberOfDecimals
                        }
                    }
                }
                menus(sort: "displayOrder:asc", where: { isActive: true }) {
                    id
                    userLabel
                    startTimeInHours
                    startTimeInMinutes
                    availabilityTimeslots
                    endTimeInHours
                    endTimeInMinutes
                    userLabel_i18n
                    availabilityDescription_i18n
                    isDisplayAvailabilityTimes
                    isAvailabilityGoingOverNextDay
                    isAlwaysVisible
                    themePicture {
                        url
                        formats
                    }
                    weekdaysAvailableOn (where: { code: "${dayName.toLowerCase()}" }) {
                        code
                    }
                    deliverymethods (where: { code: "${deliveryMethod.code}" }) {
                        code
                        label
                        isDineIn
                        isWithOnlinePayment
                        description_i18n
                        isCollectAtLocation
                    }
                    isActive
                    menucategories {
                        id
                        orderedmenuitems {
                            id
                        }
                        menugroups(where: { isActive: true, ${(isInDineInMode ? "isAvailableForDineIn" : "isAvailableForDineOut")}: true }) {
                            id
                        }
                    }
                }
            }
        }
        `
        // init runtime env var
        //const env = runtimeEnv();
        // Request API.
        // Only return active menus
        //  menucategories {\n    id\n    orderedmenuitems {\n    id\n   }\n   menugroups (where: {isActive: true, isAvailableForDineIn: true}) {\n   id\n   }\n   }\n
        return await axios
        .post( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
            '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
            ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + apiEndpointPath  + '?restaurantId=' + restaurantSlug, 
            {
                "query": graphqlQuery
            }
            )
        .then(response => {
            // Handle success.
            // console.log('menu axios response', response);
            if(response.data.errors) return response.data;

            const foundRestaurant = response.data.data.restaurantsForPublic[0];
            // we are looking for menus that may not have any items under them
            if (foundRestaurant.menus &&
                foundRestaurant.menus.length > 0) {
                
                foundRestaurant.menus = foundRestaurant.menus.filter((menu) => {
                    const { deliverymethods, menucategories, weekdaysAvailableOn } = menu

                    const filteredMenuCategories = menucategories.filter((menuCategory) => {
                        return menuCategory.menugroups.length > 0 || menuCategory.orderedmenuitems.length > 0;
                    })

                    // if we have non empty menu categories then we can keep the menu
                    return filteredMenuCategories.length > 0 && deliverymethods.length > 0 && weekdaysAvailableOn.length > 0;
                })
            }

            return response.data.data.restaurantsForPublic[0];
        });
    }
}