import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { navigateToNewPage } from "../../actions/navigation";
import ProgressIndicator from "../../components/ProgressIndicator/ProgressIndicator";
import queryString from 'query-string';

class Hyperpaycheckout extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {};

    this.dispatchPageNavigationToHeader = this.dispatchPageNavigationToHeader.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
  }

  async componentWillReceiveProps(nextProps) {
    if (this._isMounted) {
      // console.log("paymentId", paymentId);
      this.dispatchPageNavigationToHeader();
    }
  }

  dispatchPageNavigationToHeader(strTitleToDisplay = "") {
    const { dispatch } = this.props;
    const restaurantid = this.props.match.params.restaurantid;
    const deliveryMethod = this.props.match.params.deliverymethod;
    const { intl } = this.props;
    let urlToGoTo = ''
    const queryStringParamMode = queryString.parse(this.props.location.search).mode
    urlToGoTo = `/app/${deliveryMethod}/restaurant/${restaurantid}/menus${queryStringParamMode ? '?mode=' + queryStringParamMode : ''}`
    //console.log('dispatchPageNavigationToHeader urlToGoTo => ', urlToGoTo);
    dispatch(
      navigateToNewPage(
        intl.formatMessage({
          id: "Hyperpaycheckout.page.header.title",
          defaultMessage: "Payment",
        }),
        urlToGoTo
      )
    );
  }

  render() {
    const { params } = this.props.match;
    const restaurantid = params.restaurantid;
    const deliveryMethod = params.deliverymethod;
    const paymentId = params.paymentid;
    const orderUuid = params.orderuuid;
    const getUrl = window.location;
    const baseUrl = getUrl.protocol + "//" + getUrl.host;
    const redirectUrl = `${baseUrl}/#/app/${deliveryMethod}/restaurant/${restaurantid}/orderstatus/${orderUuid}`;
    return this.state.pdfInvoiceUrl === "" ? (
      <ProgressIndicator />
    ) : (
      <iframe
        title="Hyperpay Payment"
        src={`/hyperpay/index.html?paymentId=${paymentId}&redirectUrl=${encodeURIComponent(redirectUrl)}&hyperpayApiBaseUrl=${encodeURIComponent(process.env.REACT_APP_HYPERPAY_API_BASE_URL)}&locale=${this.props.intl.locale}&restaurantName=${queryString.parse(this.props.location.search).restaurantName}&orderTotalPrice=${queryString.parse(this.props.location.search).orderTotalPrice}&orderCurrency=${queryString.parse(this.props.location.search).orderCurrency}&customerEmail=${queryString.parse(this.props.location.search).customerEmail}`}
        style={{
          width: "100%",
          height: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
          position: "absolute",
          paddingLeft: "10px",
          paddingRight: "30px",
          paddingBottom: "150px",
        }}
        marginWidth="0"
        marginHeight="0"
        frameBorder="0"
        seamless="seamless"
      />
    );
  }
}

function mapStateToProps(store) {
  return {
    restaurantInfo: store.restaurant.restaurantInfo,
    websocket: store.websocket.socket
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(Hyperpaycheckout)));
