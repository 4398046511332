exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/**\n * Customized version of bootstrap using variables from _variables.scss.\n * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.\n */\n/*\n * Typography\n * ======================================================================== */\n/**\n * Custom application mixins available through out the app\n */\n/*html, body {\n    max-width: 100%;\n    overflow-x: hidden;\n    -webkit-overflow-scrolling: touch;\n}*/\n.Allergens_allergens__2sQQW {\n  display: flex;\n  position: absolute;\n  bottom: 10px;\n  position: initial;\n  padding-top: 20px; }\n  .Allergens_allergens__2sQQW div {\n    border: #e9ecef solid 2px;\n    width: 45px;\n    height: 45px;\n    margin-right: 15px;\n    border-radius: 25px; }\n    .Allergens_allergens__2sQQW div div {\n      border: none;\n      margin-left: 3px;\n      margin-top: -2px;\n      width: 35px;\n      background-size: contain;\n      background-position: center;\n      background-repeat: no-repeat; }\n\n.Allergens_title__3gdtT {\n  font-size: 1.25rem;\n  font-weight: 400;\n  color: #a3aeb7;\n  margin-right: 10px;\n  margin-top: 8px; }\n\n.Allergens_allergensForListView__3YREn {\n  display: flex;\n  flex-wrap: wrap;\n  position: absolute;\n  position: initial;\n  padding-top: 20px; }\n  .Allergens_allergensForListView__3YREn div {\n    width: 36px;\n    height: 36px;\n    text-align: center; }\n    .Allergens_allergensForListView__3YREn div div {\n      border: none;\n      margin-top: -10px;\n      height: 20px;\n      background-size: contain;\n      background-position: center;\n      background-repeat: no-repeat; }\n\n.Allergens_allergenLabelListView__4OBh1 {\n  position: relative;\n  top: -5px;\n  font-size: 0.45rem;\n  color: black;\n  white-space: nowrap; }\n\n.Allergens_titleForListView__3gakr {\n  font-size: 0.75rem;\n  font-weight: 400;\n  color: #798892;\n  margin-right: 10px;\n  margin-top: 5px; }\n", ""]);

// exports
exports.locals = {
	"allergens": "Allergens_allergens__2sQQW",
	"title": "Allergens_title__3gdtT",
	"allergensForListView": "Allergens_allergensForListView__3YREn",
	"allergenLabelListView": "Allergens_allergenLabelListView__4OBh1",
	"titleForListView": "Allergens_titleForListView__3gakr"
};