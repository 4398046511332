import React, { Component } from "react";
import PropTypes from "prop-types";

import Lightbox from "react-images";

import s from "./CarouselMenu.module.scss";

import fullscreenButton from "../../images/carousel/fullscreen.svg";
import LazyBackground from "../LazyBackground";

class CarouselMenu extends Component {
  static propTypes = {
    menu: PropTypes.object.isRequired,
    height: PropTypes.string,
    isLightboxEnabled: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      theme: {
        arrow: {
          ":focus": {
            outline: 0,
          },
        },
        close: {
          ":focus": {
            outline: 0,
          },
        },
      },
    };

    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.handleClickImage = this.handleClickImage.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  openLightbox(event) {
    event.preventDefault();
    if (this._isMounted) {
      this.setState({
        currentImage: 0,
        lightboxIsOpen: true,
      });
    }
  }

  closeLightbox() {
    if (this._isMounted) {
      this.setState({
        currentImage: 0,
        lightboxIsOpen: false,
      });
    }
  }

  handleClickImage() {
    this.closeLightbox();
  }

  render() {
    const { menu } = this.props;

    const arrayWithSelectedImage = menu
      ? [{ src: menu.themePicture.url }]
      : undefined;

    const fullScreenComponent = (
      <div className={s.fullscreenButtonContainer}>
        <button
          className={s.fullscreenButton}
          onClick={(e) => this.openLightbox(e)}
        >
          <span
            className={s.fullscreenButtonIcon}
            style={{ backgroundImage: `url(${fullscreenButton})` }}
          ></span>
        </button>
        {menu && !this.props.isFetching ? (
          <Lightbox
            currentImage={this.state.currentImage}
            images={arrayWithSelectedImage}
            isOpen={this.state.lightboxIsOpen}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            onClose={this.closeLightbox}
            onClickImage={this.handleClickImage}
            onClickThumbnail={this.gotoImage}
            backdropClosesModal
            enableKeyboardInput
            theme={this.state.theme}
          />
        ) : null}
      </div>
    );

    return menu.themePicture ? (
      <div
        className={s.productCardContainer}
        style={this.props.height ? { height: this.props.height } : {}}
      >
        {!this.props.height ? (
          <LazyBackground
            className={
              s.productCardPhoto +
              " figure-img" +
              (menu.isActive ? "" : " disableWithGrayscale")
            }
            height={120}
            src={menu.themePicture.formats && menu.themePicture.formats.medium ? menu.themePicture.formats.medium.url : menu.themePicture.url}
            style={{ marginBottom: 0 }}
          ></LazyBackground>
        ) : (
          <LazyBackground
            className={
              s.productCardPhoto +
              " figure-img" +
              (menu.isActive ? "" : " disableWithGrayscale")
            }
            height={120}
            src={menu.themePicture.formats && menu.themePicture.formats.medium ? menu.themePicture.formats.medium.url : menu.themePicture.url}
            style={{ height: this.props.height, marginBottom: 0 }}
          ></LazyBackground>
        )}
        {this.props.isLightboxEnabled ? fullScreenComponent : null}
      </div>
    ) : null;
  }
}

CarouselMenu.defaultProps = {
  isLightboxEnabled: false,
};

export default CarouselMenu;
