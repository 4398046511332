import React from 'react';
import PropTypes from 'prop-types';
import {
  /*Input,
  Label,*/
  Badge
} from 'reactstrap';

import PrepTimeClock from '../PrepTimeClock/PrepTimeClock';

import {
  BootstrapTable,
  TableHeaderColumn,
} from 'react-bootstrap-table';

import {FormattedMessage} from 'react-intl';
import $ from 'jquery';

import Widget from '../Widget/Widget';
import s from './PriceTable.modules.scss';
import PopupInfoMenuItemSize from '../PopupInfoMenuItemSize/PopupInfoMenuItemSize';

class PriceTableWithoutFormsy extends React.Component {

  static propTypes = {
    menuitem: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    currencyNumberOfDecimals: PropTypes.number.isRequired,
    menugroupidforinput: PropTypes.string,
    menuid: PropTypes.string,
    isShowPrice: PropTypes.bool,
    isHidePriceColumn: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    isShowInWidget: PropTypes.bool,
    setValue: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      menuitemSizes: this.props.menuitem.menuitemsizes,
      currencyForMenuitemSize: this.props.currency,
      currencyNumberOfDecimals: this.props.currencyNumberOfDecimals,
      locale: this.props.locale,
      showModalPopup: false,
      clickedMenuItemSize: null,
      selectedmenuitemsize: null
    };

    this.displayModalPopup = this.displayModalPopup.bind(this);
    this.sizeNameFormatter = this.sizeNameFormatter.bind(this);
    this.hideModalPopup = this.hideModalPopup.bind(this);
    this.priceFormatter = this.priceFormatter.bind(this);
    this.inputFormatter = this.inputFormatter.bind(this);
    this.changeValue = this.changeValue.bind(this);
  }

  componentDidMount() {

    this.props.menuitem.menuitemsizes.forEach(menuitemsize => {
      // if there is a menu item size that should be preselected
      // and that we did not set any value yet then we do it once
      if(!this.state.selectedmenuitemsize && menuitemsize.isPreselected) {
        this.setState({
          selectedmenuitemsize: menuitemsize
        });
        const valueToReturn = Object.assign({}, this.props.menuitem);
        valueToReturn.selectedmenuitemsize = menuitemsize;
        this.props.setValue(valueToReturn);
      }
    });
    
  }

  componentDidUpdate(prevProps) {
    if(prevProps.menuitem.id !== this.props.menuitem.id) {
      //console.log('here 1');
      this.setState({
        menuitemSizes: this.props.menuitem.menuitemsizes,
        currencyForMenuitemSize: this.props.currency,
        locale: this.props.locale,
        selectedmenuitemsize: null
      });

      // resetting the value
      this.props.setValue(undefined);
      // reset all radio buttons
      const {menugroupidforinput} = this.props;
      $('input[name="'+(menugroupidforinput !== '' ? "dishsize-" + menugroupidforinput : "dishsize")+'"]').prop('checked', false);


      //console.log('this.state.selectedmenuitemsize => ', this.state.selectedmenuitemsize);

      this.props.menuitem.menuitemsizes.forEach(menuitemsize => {
        // if there is a menu item size that should be preselected
        // and that we did not set any value yet then we do it once
        if(!this.state.selectedmenuitemsize && menuitemsize.isPreselected) {
          this.setState({
            selectedmenuitemsize: menuitemsize
          });
          const valueToReturn = Object.assign({}, this.props.menuitem);
          valueToReturn.selectedmenuitemsize = menuitemsize;
          this.props.setValue(valueToReturn);
        }
      });
    }

    // if we are in combo mode then menus are switched by tabs
    // and if they have the same menuitems then they do not get updated
    // that is why we use the menu id to make sure we detect a change between
    // combo menus
    if(prevProps.menuid !== '' && prevProps.menuid !== this.props.menuid) {
      //console.log('here 2');
      this.setState({
        menuitemSizes: this.props.menuitem.menuitemsizes,
        currencyForMenuitemSize: this.props.currency,
        locale: this.props.locale,
        selectedmenuitemsize: null
      });

      // resetting the value
      this.props.setValue(undefined);
      // reset all radio buttons
      const {menugroupidforinput} = this.props;
      $('input[name="'+(menugroupidforinput !== '' ? "dishsize-" + menugroupidforinput : "dishsize")+'"]').prop('checked', false);

      //console.log('this.state.selectedmenuitemsize => ', this.state.selectedmenuitemsize);

      this.props.menuitem.menuitemsizes.forEach(menuitemsize => {
        // if there is a menu item size that should be preselected
        // and that we did not set any value yet then we do it once
        if(!this.state.selectedmenuitemsize && menuitemsize.isPreselected) {
          this.setState({
            selectedmenuitemsize: menuitemsize
          });
          const valueToReturn = Object.assign({}, this.props.menuitem);
          valueToReturn.selectedmenuitemsize = menuitemsize;
          this.props.setValue(valueToReturn);
        }
      });
    }
  }

  displayModalPopup(menuItemSize) {
    //console.log('menuItemSize => ', menuItemSize);

    this.setState({
      showModalPopup: true,
      clickedMenuItemSize: menuItemSize
    });
  }

  hideModalPopup() {
    this.setState({
      showModalPopup: false,
      clickedMenuItemSize: {}
    });
  }

  sizeNameFormatter(cell, row) {
    return (
      <div className="buttonLinkToDetailsPopup" onClick={() => this.displayModalPopup(row)}>
        {row.name_i18n[this.state.locale]}&nbsp;
        <i className="no-display-md fa fa-external-link" aria-hidden="true"></i>
      </div>
    );
  }

  priceFormatter(cell) {
    let price = cell
    if (price > 0) {
      if (this.state.currencyNumberOfDecimals <= 2) {
        price = parseFloat(price.toFixed(this.state.currencyNumberOfDecimals))
      } else {
        price = price.toFixed(this.state.currencyNumberOfDecimals)
      }
    }
    return (
      this.props.isShowPrice ?
      <Badge color="primary" className="priceMenuItem">
        {this.state.currencyForMenuitemSize} {price}
      </Badge>
      : 
      <Badge color="secondary" className="priceMenuItem">
        N/A
      </Badge>
    );
  };

  changeValue(event) {
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    const valueToReturn = Object.assign({}, this.props.menuitem);
    valueToReturn.selectedmenuitemsize = JSON.parse(event.currentTarget.value);
    //console.log('valueToReturn => ', valueToReturn);
    this.setState({
      selectedmenuitemsize: Object.assign({}, valueToReturn.selectedmenuitemsize)
    });

    this.props.setValue(valueToReturn)
  }

  inputFormatter(cell, row) {

    const {menugroupidforinput} = this.props;

    /*console.log('menugroupidforinput => ', menugroupidforinput);
    console.log('selectedmenuitemsize => ', this.state.selectedmenuitemsize);
    console.log('row => ', row);
    console.log('checked => ', this.state.selectedmenuitemsize && (row.id === this.state.selectedmenuitemsize.id));*/
    return (
      <input type="radio"
              className="option-input radio"
              id={row.id} 
              name={menugroupidforinput !== '' ? "dishsize-" + menugroupidforinput : "dishsize"}
              value={JSON.stringify(row)}
              onChange={this.changeValue}
              ></input>
    );


  }


  render() {
    if(this.props.menuitem) {
      const options = {
        sizePerPage: 10,
        paginationSize: 3,
      };

      const {menuitemSizes, 
            currencyForMenuitemSize, 
            showModalPopup,
            clickedMenuItemSize} = this.state;

      const {isHidePriceColumn} = this.props;

      function prepTimeFormatter(cell, row) {
        return (
          <div>
            {
              cell && cell > 0 ? <PrepTimeClock idForComponent={"priceTableRow-"+ row.id} timeToDisplay={cell} /> : <span>N/A</span>
            }
          </div>
        );
      }

      function caloriesFormatter(cell, row) {
        return (
          <div>
            {
              cell && cell > 0 ? cell : <span>N/A</span>
            }
          </div>
        );
      }

      const bootstrapTable = (
        <BootstrapTable data={menuitemSizes}
                            version="4" 
                            options={options} 
                            tableContainerClass={`table-striped table-hover ${s.bootstrapTable}`}
                            onClickCell={this.displayModalPopup}>
              <TableHeaderColumn className="d-none" columnClassName="d-none" dataField="id" isKey>
                <span className="fs-sm">ID</span>
              </TableHeaderColumn>
              <TableHeaderColumn className="colTwentyPercentWidth headerRadioButton"
                                columnClassName="colTwentyPercentWidth radiobutton"
                                dataField="name"
                                dataFormat={this.inputFormatter.bind(this)}>
              </TableHeaderColumn>
              <TableHeaderColumn dataField="name" 
                                className="colThirtyPercentWidth"
                                columnClassName="colThirtyPercentWidth"
                                dataFormat={this.sizeNameFormatter.bind(this)}>
                <span className="fs-sm">
                  <FormattedMessage id="PriceTableWithoutFormsy.BootstrapTable.TableHeaderColumn.size.label"
                                                  defaultMessage="Size" />
                </span>
              </TableHeaderColumn>
              {
                !isHidePriceColumn ?
                <TableHeaderColumn dataField="price" 
                                  className="colTwentyPercentWidth priceField"
                                  columnClassName="colTwentyPercentWidth priceField"
                                  dataFormat={this.priceFormatter.bind(this)}>
                  <span className="fs-sm">
                    <FormattedMessage id="PriceTableWithoutFormsy.BootstrapTable.TableHeaderColumn.price.label"
                                                      defaultMessage="Price" />
                  </span>
                </TableHeaderColumn>
                :null
              }
              <TableHeaderColumn dataField="calories"
                                dataFormat={caloriesFormatter}
                                className="d-none d-md-table-cell colTwentyFivePercentWidth caloriesField"
                                columnClassName="d-none d-md-table-cell colTwentyFivePercentWidth caloriesField">
                <span className="fs-sm">
                  <FormattedMessage id="PriceTableWithoutFormsy.BootstrapTable.TableHeaderColumn.calories.label"
                                                      defaultMessage="Calories" />
                </span>
              </TableHeaderColumn>
              <TableHeaderColumn dataField="preparationTimeInMinutes" 
                                className="d-none d-md-table-cell colThirtyPercentWidth prepTimeField"
                                columnClassName="d-none d-md-table-cell colThirtyPercentWidth prepTimeField"
                                dataFormat={prepTimeFormatter}>
                <span className="fs-sm">
                  <FormattedMessage id="PriceTableWithoutFormsy.BootstrapTable.TableHeaderColumn.preparationtime.label"
                                                      defaultMessage="Prep. Time" />
                </span>
              </TableHeaderColumn>
            </BootstrapTable>
      );

      return (
        <div>
          {
            this.props.isShowInWidget ?
            <Widget title={
                          <h4>
                            <span className="fw-semi-bold">
                              <FormattedMessage id="PriceTableWithoutFormsy.widget.title.available.dish.sizes"
                                                defaultMessage="Available dish sizes" />
                            </span>
                          </h4>
                        } 
                  collapse>
              {bootstrapTable}
            </Widget>
            :
            bootstrapTable
          }
          {
            showModalPopup ?
              <PopupInfoMenuItemSize 
                  isModalHideDishOpen={showModalPopup} 
                  menuItemSize={clickedMenuItemSize}
                  currencyForMenuItemSize={currencyForMenuitemSize}
                  locale={this.state.locale}
                  isShowPrice={this.props.isShowPrice}
                  onModalClosed={this.hideModalPopup} /> : null
          }
        </div>
      );
    } else {
      return null;
    }
  }
}

PriceTableWithoutFormsy.defaultProps = {
  isShowPrice: true,
  isHidePriceColumn: false,
  isShowInWidget: true,
  menugroupidforinput: '',
  menuid: ''
};

export default PriceTableWithoutFormsy;
