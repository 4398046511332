import React from 'react';
import PropTypes from 'prop-types';
import Widget from '../Widget/Widget';
import { withFormsy } from 'formsy-react';
import {
  Badge
} from 'reactstrap';

import {
  BootstrapTable,
  TableHeaderColumn,
} from 'react-bootstrap-table';

import {FormattedMessage, injectIntl} from 'react-intl';

import './AddongroupItemsTable.modules.scss';
import Properties from '../Properties/Properties';

class AddongroupItemsTable extends React.Component {

  static propTypes = {
    addongroup: PropTypes.object.isRequired,
    currency: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    customAllergenIcons: PropTypes.array,
    customFoodtypeIcons: PropTypes.array
  };

  constructor(props) {
    super(props);

    this.state = {
      listOfSelectedAddongroupItems: []
    };

    this.pictureFormatter = this.pictureFormatter.bind(this);
    this.priceFormatter = this.priceFormatter.bind(this);
    this.inputFormatter = this.inputFormatter.bind(this);
    this.changeValue = this.changeValue.bind(this);
  }

  componentDidMount() {

    const {addongroup} = this.props;

    // set the default value to an empty object
    this.props.setValue(undefined);

    //console.log('addongroup => ', addongroup);

    const addongroupitems = addongroup.addonitems;

    addongroupitems.forEach(addongroupitem => {
      // if there is a menu item size that should be preselected
      // and that we did not set any value yet then we do it once
      if(addongroupitem.isPreselected) {
        const arrayTemp = [...this.state.listOfSelectedAddongroupItems, addongroupitem];
        this.setState({
          listOfSelectedAddongroupItems: arrayTemp
        });
        const objectToReturn = {};
        objectToReturn.addongroupsId = this.props.addongroup.id;
        objectToReturn.selectedAddonGroup = this.props.addongroup;
        objectToReturn.selectedAddons = arrayTemp.map(addonitem => {
          return {
            addonitemId: addonitem.id,
            selectedAddonItem: addonitem
          }
        });
        this.props.setValue(objectToReturn);
      }
    });
    
  }
  
  priceFormatter(cell) {
    let price = cell
    if (price > 0) {
      if (this.props.currency.numberOfDecimals <= 2) {
        price = parseFloat(price.toFixed(this.props.currency.numberOfDecimals))
      } else {
        price = price.toFixed(this.props.currency.numberOfDecimals)
      }
    }
    return (
      <Badge color="primary" className="priceMenuItem">
        {this.props.currency.symbol} {price}
      </Badge>
    );
  };

  pictureFormatter (cell, row) {
    if(row.pictures.length > 0) {
      /*<div className="pictureAddonitem" key={"pictureMenuItemSizeCell-"+row.id} style={{
        backgroundImage: `url(${row.pictures[0].formats && row.pictures[0].formats.thumbnail ? row.pictures[0].formats.thumbnail.url : row.pictures[0].url})`
      }}>
      </div>*/
      return (
        <div className="pictureAddonitem" key={"pictureMenuItemSizeCell-"+row.id} >
          <img className="imageAddonitem"
            src={row.pictures[0].formats && row.pictures[0].formats.thumbnail ? row.pictures[0].formats.thumbnail.url : row.pictures[0].url}
            alt={row.name_i18n[this.props.locale]} />
        </div>
      );
    } else {
      return (<div></div>);
    }
  }

  nameFormatter (cell, row) {
    //console.log(row);
    const addongroupitem = row;
    const { allergens, foodtypes } = addongroupitem;
    const { locale } = this.props;

    return (
      <div style={{display: 'inline-flex'}}>
        <div>
          {row.name_i18n[locale]}
        </div>
        {
          ((allergens && allergens.length > 0) || (foodtypes && foodtypes.length > 0)) && locale ? (
            <Properties 
              listOfAllergens={allergens} 
              listOfFoodtypes={foodtypes}
              customAllergenIcons={this.props.customAllergenIcons}   
              customFoodtypeIcons={this.props.customFoodtypeIcons}     
              locale={locale} />
          ) : null
        }

      </div>
    );
  }

  inputFormatter(cell, row) {
    let isInputChecked = false;
    if (this.state.listOfSelectedAddongroupItems.length > 0) {
      const tempFilteredArray = this.state.listOfSelectedAddongroupItems
                                  .filter(addongroupitem => addongroupitem.id === row.id);
      isInputChecked = tempFilteredArray.length > 0;
    }
    let reachedMaxNumberOfSelectedAddonItems = false;
    // if we reached the maximum required number of items to be selected in an addongroup
    const { addongroup } = this.props;
    const { maxNumberWhenMandatory, isMandatory } = addongroup;

    if (isMandatory && maxNumberWhenMandatory > 1 && this.state.listOfSelectedAddongroupItems.length >= maxNumberWhenMandatory) {
      reachedMaxNumberOfSelectedAddonItems = true;
    }

    // if checkbox or radio button - multiple choice or single choice

    if(this.props.addongroup.isMultipleSelectionAllowed) {
      if (reachedMaxNumberOfSelectedAddonItems) {
        if(isInputChecked) {
          // checkbox
          return (<input type="checkbox" 
              id={"addongroup-" + this.props.addongroup.name + '-addonitem-' + row.id} 
              className="option-input checkbox"
              name={"addongroup-" + this.props.addongroup.name}
              value={JSON.stringify(row)} 
              checked={isInputChecked}
              onChange={this.changeValue}>
              </input>);
        } else {
          return (<input type="checkbox" 
            id={row.id} 
            className="option-input checkbox"
            name={"addongroup-" + this.props.addongroup.name}
            value={JSON.stringify(row)} 
            checked={isInputChecked}
            disabled={true}
            onChange={this.changeValue}></input>);
        }
      } else {
        // checkbox
        return (<input type="checkbox" 
              id={"addongroup-" + this.props.addongroup.name + '-addonitem-' + row.id} 
              className="option-input checkbox"
              name={"addongroup-" + this.props.addongroup.name}
              value={JSON.stringify(row)} 
              checked={isInputChecked}
              onChange={this.changeValue}></input>);
      }
    } else {
      // radio button
      return (
        <input type="radio" 
              id={"addongroup-" + this.props.addongroup.name + '-addonitem-' + row.id}
              className="option-input radio"
              name={"addongroup-" + this.props.addongroup.name}
              value={JSON.stringify(row)} 
              checked={isInputChecked}
              onChange={this.changeValue}
              ></input>
      );
    }
  }

  changeValue(event) {
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    const selectedAddongroupItem = JSON.parse(event.currentTarget.value);
    if(this.props.addongroup.isMultipleSelectionAllowed) {
      const { addongroup} = this.props;
      const { maxNumberWhenMandatory, isMandatory, minNumberWhenMandatory} = addongroup
      // if element already exists it means that we are unselecting it
      const indexOfAddongroupItem = this.state.listOfSelectedAddongroupItems
                                      .findIndex(aoi => aoi.id === selectedAddongroupItem.id);
      const arrayTemp = [...this.state.listOfSelectedAddongroupItems];
      // element already exists and we remove it
      if (indexOfAddongroupItem >= 0) {
        arrayTemp.splice(indexOfAddongroupItem, 1);
        const objectToReturn = {};
        objectToReturn.addongroupsId = this.props.addongroup.id;
        objectToReturn.selectedAddonGroup = this.props.addongroup;
        objectToReturn.selectedAddons = arrayTemp.map(addonitem => {
          return {
            addonitemId: addonitem.id,
            selectedAddonItem: addonitem
          }
        });
        if (isMandatory && maxNumberWhenMandatory > 1 && minNumberWhenMandatory >= 1) {
          if (arrayTemp.length >= minNumberWhenMandatory && arrayTemp.length <= maxNumberWhenMandatory) {
            this.props.setValue(objectToReturn);
          } else {
            this.props.setValue(undefined);
          }
          this.setState({
            listOfSelectedAddongroupItems: arrayTemp
          });
        } else {
          this.props.setValue(objectToReturn);
          this.setState({
            listOfSelectedAddongroupItems: arrayTemp
          });
        }
      } else {
        // element does not exist and we add it
        arrayTemp.push(selectedAddongroupItem);
        const objectToReturn = {};
        objectToReturn.addongroupsId = this.props.addongroup.id;
        objectToReturn.selectedAddonGroup = this.props.addongroup;
        objectToReturn.selectedAddons = arrayTemp.map(addonitem => {
          return {
            addonitemId: addonitem.id,
            selectedAddonItem: addonitem
          }
        });
        if (isMandatory && maxNumberWhenMandatory > 1 && minNumberWhenMandatory >= 1) {
          if (arrayTemp.length >= minNumberWhenMandatory && arrayTemp.length <= maxNumberWhenMandatory) {
            this.props.setValue(objectToReturn);
          } else {
            this.props.setValue(undefined);
          }
          this.setState({
            listOfSelectedAddongroupItems: arrayTemp
          });
        } else {
          this.props.setValue(objectToReturn);
          this.setState({
            listOfSelectedAddongroupItems: arrayTemp
          });
        }
      }
    } else {
      // single selection mode
      const arrayTemp = [selectedAddongroupItem];
      //console.log('valueToReturn => ', valueToReturn);
      const objectToReturn = {};
        objectToReturn.addongroupsId = this.props.addongroup.id;
        objectToReturn.selectedAddonGroup = this.props.addongroup;
        objectToReturn.selectedAddons = arrayTemp.map(addonitem => {
          return {
            addonitemId: addonitem.id,
            selectedAddonItem: addonitem
          }
        });
      this.props.setValue(objectToReturn);
      this.setState({
        listOfSelectedAddongroupItems: arrayTemp
      });
    }
  }

  render() {
    const options = {
      sizePerPage: 10,
      paginationSize: 3,
    };

    const { addongroup, intl } = this.props;
    const { locale } = intl;

    // console.log('addongroup => ', addongroup);

    const { maxNumberWhenMandatory, isMandatory, minNumberWhenMandatory} = addongroup

    const addongroupitems = addongroup.addonitems;

    //console.log('addongroupitemsTable => ', addongroupitems);

    return (
      <div className="root">
        <Widget title={
                        <h4>
                          <span className="fw-semi-bold">
                              {addongroup.name_i18n[locale]}
                          </span>
                        </h4>
                      } 
                collapse>
          {
            isMandatory && maxNumberWhenMandatory > 1 && minNumberWhenMandatory >= 1 ? (
              minNumberWhenMandatory === maxNumberWhenMandatory ? (
                <span style={{marginBottom: '7px', display: 'block'}}>
                  <FormattedMessage id="AddongroupItemTable.isMandatory.SelectMaxNumberOfItems.Label"
                                                  defaultMessage="Number of required items" />
                  :&nbsp;{maxNumberWhenMandatory}
                </span>
              ) : (
                <span>min. {minNumberWhenMandatory} - max. {maxNumberWhenMandatory}</span>
              )
            ) : null
          }
          <BootstrapTable data={addongroupitems}
                          version="4" 
                          options={options} 
                          tableContainerClass={`table-striped table-hover`}
                          onClickCell={this.displayModalPopup}>
            <TableHeaderColumn className="d-none" columnClassName="d-none" dataField="id" isKey>
              <span className="fs-sm">ID</span>
            </TableHeaderColumn>
            <TableHeaderColumn className="colTenPercentWidth"
                                columnClassName="colTenPercentWidth radiobutton"
                                dataField="name"
                                dataFormat={this.inputFormatter.bind(this)}>
              </TableHeaderColumn>
            <TableHeaderColumn dataField="name"
                              className="d-none d-md-table-cell colTenPercentWidth"
                              columnClassName="d-none d-md-table-cell colTenPercentWidth"
                              dataFormat={this.pictureFormatter}>
            </TableHeaderColumn>
            <TableHeaderColumn dataField="name"
                              className={locale === 'ar' ? 'colSixtyPercentWidth nameField rtl' : 'colSixtyPercentWidth nameField'}
                              columnClassName={locale === 'ar' ? 'colSixtyPercentWidth nameField rtl' : 'colSixtyPercentWidth nameField'}
                              dataFormat={this.nameFormatter.bind(this)}>
              <span className="fs-sm">
                <FormattedMessage id="AddongroupItemTable.BootstrapTable.TableHeaderColumn.name.label"
                                                defaultMessage="Name" />
              </span>
            </TableHeaderColumn>
            <TableHeaderColumn dataField="price" 
                              className="colTwentyPercentWidth priceField"
                              columnClassName="colTwentyPercentWidth priceField"
                              dataFormat={this.priceFormatter}>
              <span className="fs-sm">
                <FormattedMessage id="AddongroupItemTable.BootstrapTable.TableHeaderColumn.price.label"
                                                  defaultMessage="Price" />
              </span>
            </TableHeaderColumn>
          </BootstrapTable>
        </Widget>
      </div>
    );
  }

}

export default withFormsy(injectIntl(AddongroupItemsTable));
