import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { HashRouter } from 'react-router-dom';
// import { initializeSocket } from '../actions/websocket';
import PropTypes from 'prop-types';

import LayoutComponent from '../components/Layout';
/* eslint-disable */
import ErrorPage from '../pages/error';
/* eslint-enable */

import '../styles/theme.scss';

class App extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    socket: PropTypes.object
  };

  componentDidMount() {}

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route path="/error" exact component={ErrorPage} />
          <Route path="/" exact render={() => <Redirect to="/app/main" />} />
          <Route path="/app" exact render={() => <Redirect to="/app/main" />} />
          <Route path="/app" component={LayoutComponent} />
          <Route path="/error" exact component={ErrorPage} />
          <Redirect from="*" to="/app/main" />
        </Switch>
      </HashRouter>
    );
  }
}

const mapStateToProps = state => ({
  //isAuthenticated: state.auth.isAuthenticated,
  //socket: state.socket,
})

export default connect(mapStateToProps)(App);
