import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
  Navbar,
  Nav,
} from 'reactstrap';
import $ from 'jquery';
//import queryString from 'query-string';
import {PageView} from '../Tracking';

import LocaleSelector from '../../components/LocaleSelector';
//import Notifications from '../Notifications';
import { injectIntl } from 'react-intl'; 

import s from './Header.module.scss'; // eslint-disable-line css-modules/no-unused-class

import { showErrorMessage } from '../../components/ToastMessenger';
import FloatCart from '../FloatCart';
import { resetCart } from '../../actions/cart';
import { getRestaurantInformation } from '../../actions/restaurant';
import queryString from 'query-string';

class Header extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.updateWindowWidth = this.updateWindowWidth.bind(this);

    this.state = {
      windowWidth: window.innerWidth,
      appDeliveryMethod: ''
    };
  }

  componentDidMount() {

    $('#search-input').on('blur focus', (e) => {
      $('#search-input').parents('.input-group')[e.type === 'focus' ? 'addClass' : 'removeClass']('focus');
    });

    const currentWindowUrl = window.location.href
    if (currentWindowUrl.indexOf('/restaurant/') > -1) {
      let deliveryMethodCode = ''
      let restaurantSlug = ''
      const url = new URL(window.location.href);
      let urlHash = url.hash // => #/app/collect/restaurant/frida-mexican-food/menus
      urlHash = urlHash.replace('#/app/', '') // => collect/restaurant/frida-mexican-food/menus 
      const urlHashArray = urlHash.split('/') // => [collect, restaurant, frida-mexican-food, menus]
      deliveryMethodCode = urlHashArray[0]
      restaurantSlug = urlHashArray[2]
      // Enabling tracking with google analytics
      PageView({deliveryMethodCode, restaurantSlug});
    }
    // we add an event listener when resizing the window as for the profile selector we want to
    // be able to hide the dropdown arrow in a smaller screen
    window.addEventListener("resize", this.updateWindowWidth);

    const appDeliveryMethod = this.getAppDeliveryMethodFromPathname();
    this.setState({
      appDeliveryMethod
    });
  }

  componentDidUpdate(prevProps) {
    const { dispatch } =  this.props
    // if there is any error message then we display it via a notification
    if(this.props.errorMessage !== undefined && this.props.errorMessage !== ''
      && prevProps.errorMessage !== this.props.errorMessage) { 
      showErrorMessage(this.props.errorMessage);
    }

    // if we are in restaurant collection mode
    if(this.props.location.pathname.indexOf('/restaurantcollection/') > -1) {
      // Extract rcid from URL for restaurant collection mode
      const urlParts = this.props.location.pathname.split('/');
      const rcidIndex = urlParts.indexOf('restaurantcollection');
      let rcid = ''
      if (rcidIndex !== -1 && urlParts.length > rcidIndex + 1) {
        rcid = urlParts[rcidIndex + 1];
      }
      //console.log('rcid header => ', rcid);
      // if we are going back to the restaurant collection page
      // we only empty the cart if we are not in marketplace with combined checkouts
      const restaurantCollectionType = localStorage.getItem(`restaurantCollection-type-${rcid}`);
      // console.log('restaurantCollectionType componentDidUpdate => ', restaurantCollectionType);
      if(restaurantCollectionType !== 'marketplace_combined_checkouts') {
        const appDeliveryMethod = this.getAppDeliveryMethodFromPathname();
        //console.log('appDeliveryMethod header => ', appDeliveryMethod);
        if(appDeliveryMethod) {
          const restaurantSlug = this.retrieveRestaurantIdFromUrl();
          if (restaurantSlug) {
            //console.log('resetting cart header => ', appDeliveryMethod, restaurantSlug);
            dispatch(resetCart(appDeliveryMethod, restaurantSlug));
          }
        }
      }

      // we are also closing the current websocket as we left the restaurant
      if (this.props.websocket) {
        this.props.websocket.isForcedToClose = true;
        // console.log('this.props.websocket.readyState => ', this.props.websocket.readyState);
        this.props.websocket.close();
      }
    }

    // if a location number (room or table or xxx) is provided in the url then we save it in the app local storage
    if(this.props.location.search.indexOf('locationnumber=') > -1) {
      const locationNumber = queryString.parse(this.props.location.search).locationnumber
      localStorage.setItem('locationnumber', locationNumber)
      // extract location number
      // console.log('locationNumber detected => ', locationNumber)
    } else {
      // if it is not in the url then we delete it from localstorage
      if (localStorage.getItem('locationnumber')) {
        localStorage.removeItem('locationnumber')
      }
    }

    // if a groupcode is provided in the url then we save it in the app local storage
    if(this.props.location.search.indexOf('groupcode') > -1) {
      const groupcode = queryString.parse(this.props.location.search).groupcode
      localStorage.setItem('groupcode', groupcode)
    } else {
      // if it is not in the url then we delete it from localstorage
      if (localStorage.getItem('groupcode')) {
        localStorage.removeItem('groupcode')
      }
    }

    // tracking location path change with google analytics
    if(prevProps.location.pathname !== this.props.location.pathname) {
      const currentWindowUrl = window.location.href
      if (currentWindowUrl.indexOf('/restaurant/') > -1) {
        let deliveryMethodCode = ''
        let restaurantSlug = ''
        const url = new URL(window.location.href);
        let urlHash = url.hash // => #/app/collect/restaurant/frida-mexican-food/menus
        urlHash = urlHash.replace('#/app/', '') // => collect/restaurant/frida-mexican-food/menus 
        const urlHashArray = urlHash.split('/') // => [collect, restaurant, frida-mexican-food, menus]
        deliveryMethodCode = urlHashArray[0]
        restaurantSlug = urlHashArray[2]
        // Enabling tracking with google analytics
        PageView({deliveryMethodCode, restaurantSlug});
      }
      const appDeliveryMethod = this.getAppDeliveryMethodFromPathname();
      this.setState({
        appDeliveryMethod
      })
    }

    // if we are not in food court landing page
    if (this.props.location.pathname.indexOf('/restaurantcollection/') === -1) {
      const restaurantId = this.retrieveRestaurantIdFromUrl()
      if (!this.props.restaurantInfo && restaurantId) {
        dispatch(getRestaurantInformation(restaurantId))
      }
  
      if (prevProps.restaurantInfo && this.props.restaurantInfo &&
        prevProps.restaurantInfo.id !== this.props.restaurantInfo.id && restaurantId) {
          dispatch(getRestaurantInformation(restaurantId))
      }

      // if we are changing restaurants by going back and forth via the food court landing page
      // then we refresh the restaurant page to load the new menus
      if (this.props.location.pathname !== prevProps.location.pathname && prevProps.location.pathname.indexOf('/restaurantcollection/') > -1) {
        // console.log('here 3')
        if (restaurantId) {
          dispatch(getRestaurantInformation(restaurantId))
        }
      }
    }

  }

  updateWindowWidth() {
    this.setState({windowWidth: window.innerWidth});
  }

  componentWillUnmount() {
    // when component in unmounted then we remove the listener on window resize
    window.removeEventListener("resize", this.updateWindowWidth);
  }

  toggleMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  }

  retrieveRestaurantIdFromUrl() {
    // in case we use a deeplink and we have items in the cart then
    // we need to extract the restaurant id from the url
    const urlHash = window.location.hash;
    const indexOfRestaurant = urlHash.indexOf("/restaurant/");
    // console.log('indexOfRestaurant => ', indexOfRestaurant)
    let indexOfMenus = urlHash.indexOf("/menu");
    if (indexOfMenus === -1) {
      indexOfMenus = urlHash.indexOf("/orderstatus");
    }
    if (indexOfMenus === -1) {
      indexOfMenus = urlHash.indexOf("/checkout");
    }
    // console.log('indexOfMenus => ', indexOfMenus)
    const extractRestaurantInformation = urlHash.substring(
      indexOfRestaurant,
      indexOfMenus
    );
    const resultToReturn =  extractRestaurantInformation.replace("/restaurant/", "");
    // console.log('extractRestaurantInformation => ', resultToReturn);
    return resultToReturn;
  }

  getAppDeliveryMethodFromPathname(){
    const pathname = this.props.location.pathname;
    let cleanedPathname = pathname.replace('/app/','');
    cleanedPathname = cleanedPathname.substring(0, cleanedPathname.indexOf('/', 0));
    // console.log('getAppDeliveryMethodFromPathname => ', cleanedPathname);
    return cleanedPathname;
  }

  render(){

    //console.log('header here => ', queryString.parse(this.props.location.search).rcid);
    const { intl } = this.props
    const { locale } = intl
    const queryStringParamMode = queryString.parse(this.props.location.search).mode

    let outletInfo = {}
    if (this.props.location.pathname.indexOf('/restaurantcollection/') > -1) {
      outletInfo = this.props.restaurantCollection
    } else {
      outletInfo = this.props.restaurantInfo || this.props.restaurantWithMenus
    }

    // if a language code is in the url then we force the switch to that language
    // code like this one needs to be sent en_EN
    const queryStringParamLang = queryString.parse(this.props.location.search).lang
    // console.log('queryStringParamMode => ', queryStringParamMode)

    return (
      <Navbar id='alacarte-header' className={`${s.root} d-print-none`}>
        {/*
          <Form className="d-sm-down-none ml-5" inline>
            <FormGroup>
              <InputGroup className="input-group-no-border">
                <InputGroupAddon addonType="prepend">
                  <i className="la la-search" />
                </InputGroupAddon>
                <Input id="search-input" placeholder="Search Dashboard" />
              </InputGroup>
            </FormGroup>
          </Form>*/
          /*this.state.showCartModal ? <CartModal onClose={this.closeCartModal} /> : null*/
        }
        {
          this.props.urlToGoBackTo !== '' ?
          <div className={s.headerWithOptions}>
            <div className={s.backButtonInHeaderBar}
              onClick={() => {
                  // we check if we are going back to the food court landing page and that we have items in the cart
                  if (this.props && this.props.urlToGoBackTo && this.props.urlToGoBackTo.indexOf('/restaurantcollection/') > -1 
                      && this.props.cartTotal && this.props.cartTotal.productQuantity > 0) {
                    // get the restaurantCollection-type from localStorage  
                    const urlParams = new URLSearchParams(window.location.hash.split('?')[1] || '');
                    const rcid = urlParams.get('rcid');
                    const restaurantCollectionType = localStorage.getItem(`restaurantCollection-type-${rcid}`);
                    //console.log('restaurantCollectionType header => ', restaurantCollectionType);
                    if (restaurantCollectionType !== 'marketplace_combined_checkouts') {
                      const isOK = window.confirm(
                        intl.formatMessage({ 
                          id: "Header.alert.popup.confirm.cart.reset", 
                          defaultMessage: 'Be aware that if you leave this outlet your cart content will be automatically reset. Choose OK if you wish to proceed.'
                        })
                      );
                      if(isOK) {
                        this.props.history.push(this.props.urlToGoBackTo);
                      }
                    } else {
                      // we are going back to the previous page
                      // we do not need to reset the cart as we are in marketplace with combined checkouts
                      this.props.history.push(this.props.urlToGoBackTo);
                    }
                  } else {
                    // we are going back to the previous page
                    // we do not need to reset the cart as there are no items in the cart
                    this.props.history.push(this.props.urlToGoBackTo);
                  }
              }}>
                {
                  locale === 'ar' ? <i className='fa fa-arrow-right' /> : <i className='fa fa-arrow-left' />
                }
            </div>
            <div className={s.alacarteHeaderTitleForNavigation} style={locale === 'ar' ? {marginRight: '0px', marginLeft: '60px'} : {}}>
              {
                outletInfo && outletInfo.outletLogoForCustomerApp ? (
                  <img alt={this.props.pageTitle} src={outletInfo.outletLogoForCustomerApp.url} style={{height: "50px"}} />
                ) : (
                <h2 id="alacarteHeaderTitleLabel">{this.props.pageTitle}</h2>
                )
              }
            </div> 
          </div> 
          :
          <div className={s.headerWithOptions}>
            <Nav style={locale === 'ar' ? {position: 'absolute', right: '-35px'} : {}}>
              <div className={s.langSelectorHeader}>
                <LocaleSelector
                  currentRestaurant={ this.props.restaurantCollection || this.props.restaurantWithMenus }
                  languageCodeToLoad={ queryStringParamLang }
                  isSSEModeEnabled = { false } />
              </div>
            </Nav>
            <div className={s.alacarteHeaderTitle} style={locale === 'ar' ? {marginLeft: '60px'} : {}}>
              {
                outletInfo && outletInfo.outletLogoForCustomerApp ? (
                  <img alt={this.props.pageTitle} src={outletInfo.outletLogoForCustomerApp.url} style={{height: "50px"}} />
                ) : (
                  <h2 id="alacarteHeaderTitleLabel">{this.props.pageTitle !== '' ? this.props.pageTitle : 'alacarte'}</h2>
                )
              }
              </div> 
          </div>
        }
        {
          // we hide the cart when we show a list of restaurants
          this.props.location.pathname.indexOf('/restaurantcollection/') === -1 &&
          this.props.location.pathname.indexOf('/checkout/hyperpay/') === -1 &&
          this.state.appDeliveryMethod !== '' &&
          this.state.appDeliveryMethod !== 'viewonly' &&
          this.state.appDeliveryMethod !== 'spaviewonly' &&
          this.state.appDeliveryMethod !== 'servicerequestvo' &&
          queryStringParamMode !== 'website' ? 
            <FloatCart 
              appDeliveryMethod={this.state.appDeliveryMethod}
              isCommentAllowedAtCheckout={this.props.restaurantInfo ? this.props.restaurantInfo.isCommentAllowedAtCheckout : true}
              />
            : null
        }
      </Navbar>
    );
  }
}

function mapStateToProps(store) {
  const globalErrorMessage = store.menu.errorMessage ||
                             store.menuitem.errorMessage ||
                             store.restaurant.errorMessage ||
                             store.cart.errorMessage;
  return {
    errorMessage: globalErrorMessage,
    urlToGoBackTo: store.navigation.urlToGoBackTo,
    pageTitle: store.navigation.pageTitle,
    restaurantWithMenus: store.menu.restaurantWithMenus,
    restaurantInfo: store.restaurant.restaurantInfo,
    restaurantCollection: store.restaurant.restaurantCollection,
    websocket: store.websocket.socket,
    // cartProducts: store.cart.cartProducts
    cartTotal: store.cart.data,
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(Header)));

