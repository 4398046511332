import axios from 'axios';
//import runtimeEnv from '@mars/heroku-js-runtime-env';

export async function apiGetListOfLanguagePacks (arrayOfLanguagePacks) {
    //const env = runtimeEnv();
    // Request API.
    // console.log('arrayOfLanguagePacks => ', arrayOfLanguagePacks);
    let queryString = "";
    if (arrayOfLanguagePacks && arrayOfLanguagePacks.length > 0) {
        queryString = "id_in=" + arrayOfLanguagePacks[0].id;
        for (let index = 1; index < arrayOfLanguagePacks.length; index++) {
            const languageId = arrayOfLanguagePacks[index].id;
            queryString += "&id_in=" + languageId
        }
    }
        
    const responseData = await axios
    .get( (/*env.REACT_APP_STRAPI_PROTOCOL || */process.env.REACT_APP_STRAPI_PROTOCOL || 'http') +
          '://' + (/*env.REACT_APP_STRAPI_HOST || */process.env.REACT_APP_STRAPI_HOST || 'localhost') +
           ":" + (/*env.REACT_APP_STRAPI_PORT || */process.env.REACT_APP_STRAPI_PORT || '1337') + '/aplanguagepacks?' + queryString, {
    })
    .then(response => {
        // Handle success.
        //console.log('response =>', response);
        return response.data;
    });

    return responseData;
}